import { twdClass } from "../../../../constants/tailwind.constants";
import styled from "styled-components";
import { useState } from "react";
import { get } from "lodash";
import { useEffect } from "react";
import { getUpToWith, Device, States} from "../util";


const CI_1 = twdClass("border-2 border-gray-300 hover:border-gray-500  pb-2 rounded-lg px-2");
const CI_1b = twdClass("border border-gray-100  hover:border-gray-300 rounded-lg px-2");
const CI_2 = twdClass("flex items-center py-2 cursor-pointer");
const CI_3 = twdClass("w-16 text-xs text-gray-900 font-semibold ");
const CI_4 = twdClass("flex-grow text-blue-500");
const CI_5 = twdClass("flex items-center");
const CI_8 = twdClass("flex flex-wrap bg-gray-100 rounded-md p-2 w-full mr-5");

const Expand = ({ content='', displayValue='', content_top='', label=''}) => {
  const [device, setDevice] = useState("");
  const [expandCol, setExpandCol] = useState(false);  
  const imgSrc = `/assets/images/chevron-${expandCol ? "up" : "down"}.svg`;
  return (
    <div className={expandCol ? CI_1 : CI_1b}>
      <div className={CI_2} onClick={() => setExpandCol(!expandCol)}>
        {<div className={CI_3}  >{label}</div>}
        <div className={CI_4}>
          {displayValue && <div className={CI_5}>
            {displayValue}            
          </div>}
        </div>
        <div
          className={twdClass("cursor-pointer")}
          onClick={() => setExpandCol(!expandCol)}
        >
          <img alt="" src={imgSrc} />
        </div>
      </div>
      <div className={`${!expandCol ? twdClass("hidden py-2") : "py-2"}`}>
        {content_top}
        <div className={CI_8}>          
          {content}          
        </div>
      </div>
    </div>
  );
};

export default Expand;
