import { useState, useEffect } from "react";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import { set, isEmpty, cloneDeep, find } from "lodash";
import { fetchSupabaseColumns, addNewConnection } from "../../../api/services";
import { toast } from "react-toastify";
import FaIcons from "../../common/FaIcons";
import { useSelector } from "react-redux";
import {Button} from "../../ui/com_ui";

export const SupabaseConnection = ({ selectConnect, setCurrentPanel }) => {
  const {
    projectData: { project_id, connections },
  } = useSelector((state) => state.studioDetails);

  useEffect(() => {
    const val = find(connections, ["connection_id", selectConnect]);
    if (val) {
      setConnection(val);
    }
  }, []);

  const [tableInfo, setTableInfo] = useState({
    name: "",
    columns: [],
  });

  const [connection, setConnection] = useState({
    connection_type: "supabase",
    connection_details: {
      displayName: "",
      api_url: "",
      anon_key: "",
      tables: [],
    },
  });

  const updateconnection = (key, value) => {
    setConnection((data) => {
      const d = set(data, key, value);
      return { ...d };
    });
  };

  const getColumnsInfo = (name) => {
    const {
      connection_details: { api_url, anon_key, tables },
    } = connection;

    const tableName = name ? name : tableInfo.name;

    fetchSupabaseColumns({
      api_url,
      anon_key,
      tableName,
    }).then((resp) => {
      if (resp.success) {
        if (isEmpty(resp.data)) {
          toast.error("Table not available or Table columns empty");
        } else {
          let tbls = cloneDeep(tables);
          if (find(tbls, ["name", tableName])) {
            tbls = tbls.map((itm) => {
              if (itm.name === tableName) {
                return { ...itm, columns: resp.data };
              }
              return itm;
            });
          } else {
            tbls.push({
              name: tableName,
              columns: resp.data,
            });
          }
          updateconnection("connection_details.tables", tbls);
          setTableInfo({
            name: "",
            columns: [],
          });
          toast.success("Successfully fetched table coulmn names");
        }
      } else {
        toast.error("Supabase Anon Key or API URL or Table Name is wrong. Please check");
      }
    });
  };

  const syncColumnsData = (table) => {
    getColumnsInfo(table.name);
  };

  const addConnection = () => {
    addNewConnection({ ...connection, project_id }).then((resp) => {
      if (resp.success) {
        toast.success("Connection added to project");
        setCurrentPanel("main");
      }
    });
  };

  const removeTableInfo = (vals) => {
    const {
      connection_details: { tables },
    } = connection;
    let tbls = cloneDeep(tables);
    tbls = tbls.filter((itm) => itm.name !== vals.name);
    console.log(tbls);
    updateconnection("connection_details.tables", tbls);
  };

  return (
    <div className={twdClass("rounded-xl px-6 py-6 bg-white -mx-4 -mb-1 ")}>
      <div className={twdClass("w-full")}>
        <div className={twdClass("w-full flex mr-2 items-center mb-2")}>
          <label className={twdClass("text-sm font-semibold w-1/5")}>
            Display Name
          </label>
          <div className={twdClass("w-4/5")}>
            <input
              className={twdClass(INPUT_TEXT)}
              value={connection.connection_details.displayName}
              placeholder="Display Name"
              onChange={(e) => {
                updateconnection(
                  "connection_details.displayName",
                  e.target.value
                );
              }}
            />
          </div>
        </div>
        <div className={twdClass("w-full flex mr-2 items-center mb-2")}>
          <label className={twdClass("text-sm font-semibold w-1/5")}>
            Anon Key
          </label>
          <div className={twdClass("w-4/5")}>
            <input
              className={twdClass(INPUT_TEXT)}
              value={connection.connection_details.anon_key}
              placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1xc3doam5pbnhqd3V4cmVydHprIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTM3NTI3OTksImV4cCI6MTk2OTMyODc5OX0.w4bVf_dTHO0SS7s7FFxkpMUN7nPEAt-sJHGwewe2232"
              onChange={(e) => {
                updateconnection("connection_details.anon_key", e.target.value);
              }}
            />
          </div>
        </div>
        <div className={twdClass("w-full flex mr-2 items-center mb-2")}>
          <label className={twdClass("text-sm font-semibold w-1/5")}>
            API URL
          </label>
          <div className={twdClass("w-4/5")}>
            <input
              className={twdClass(INPUT_TEXT)}
              value={connection.connection_details.api_url}
              placeholder="https://mqswhjninxjwuxgtwen.supabase.co"
              onChange={(e) => {
                updateconnection("connection_details.api_url", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={twdClass("w-full mt-2")}>
        <div className={twdClass("flex items-center text-sm")}>
          <label className={twdClass("w-1/5 font-semibold")}>Table Name:</label>
          <div className={twdClass("flex-grow")}>
            <input
              className={twdClass(INPUT_TEXT)}
              value={tableInfo.name}
              placeholder="Enter exact Table name"
              onChange={(e) => {
                setTableInfo({ name: e.target.value });
              }}
            />
          </div>
          <div className={twdClass("text-center ml-2")}>
            <Button
              type="default"  color="black" className={'inline-block'}                
              onClick={() => {
                if(tableInfo.name) getColumnsInfo() 
              }}
              disabled={!tableInfo.name}
            >
              Fetch Columns
            </Button>
          </div>
        </div>
      </div>
      <div className={twdClass("mt-6 bg-gray-50 rounded-xl px-2 py-0 border border-gray-200")}>
        {connection.connection_details.tables.map((item, i) => {
          return (
            <TablesDetails
              table={item}
              removeTableInfo={removeTableInfo}
              syncColumnsData={syncColumnsData}
              notLast={i < (connection.connection_details.tables.length-1) ? true : false }
            />
          );
        })}
      </div>
      <div className={twdClass("w-full text-right mt-10")}>
        <Button
          type="default"  color="primary" className={'inline-block'}  
          onClick={addConnection}
        >
          {connection.connection_id ? "Save" : "Add"} Connection
        </Button>
      </div>
    </div>
  );
};

const TablesDetails = ({ table, removeTableInfo, syncColumnsData, notLast }) => {
  const [showCols, setShowCols] = useState(false);
  return (
    <div className={twdClass(`mt-2   border-gray-150 ${notLast? "border-b" : ""}`)}>
      <div className={twdClass("p-2 w-full flex items-center")}>
        <div
          className={twdClass("font-semibold text-sm flex-grow cursor-pointer flex items-center")}
          onClick={() => setShowCols(!showCols)}
        >
          <Button type={"icon"} iconImg={`${showCols? 'chevron-up.svg' : 'chevron-down.svg'}`} color={"white"} size={"sm"} className={'mr-4'}>            
          </Button>{" "}
          Table Name: {table.name}
        </div>
        <div className={twdClass("flex items-center")}>
          <Button
            type={"default"} cType={"iconText"} color={"white"} size={"sm"} iconImg={"arrow-refresh-06.svg"} className={'p-1 mr-2'}          
            onClick={() => syncColumnsData(table)}
          >
            Refresh
          </Button>
          <Button
            type={"default"} cType={"iconText"} color={"white"} size={"sm"} iconImg={"trash-04-red.svg"} className={'bg-red-100 p-1'}    
            onClick={() => removeTableInfo(table)}
          >       
            Delete     
          </Button>
        </div>
      </div>
      {showCols && (
        <div className={twdClass("bg-white mb-2 border border-gray-100 rounded-xl px-4 py-4")}>
          {table.columns.map((item, i) => (
            <span
              className={twdClass("text-xs py-1 px-2 m-1 bg-primary-100 border-2 border-primary-200 rounded font-semibold")}
            >
              {item}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
