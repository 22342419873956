let p01=[
  {key:'Title', value:"Title"},
  {key:'Date', value:"Date"},
  {key:'Status', value:"Status"},
  {key:'Department', value:"Category"},
  {key:'ViewMore', value:"ViewMore"},
  {key:'Skills', value:"Skills"},
]
let d01=[
  {Title:'UI Developer', Department:'UI Development', Status:'Open', ViewMore:'/ui-developer', Skills:'React, Node', Date:"20-12-2018"},
  {Title:'Full Stack Developer', Department:'App Development', Status:'Open', ViewMore:'/fullstack-developer', Skills:'React, Node', Date:"20-12-2018"},
]
let sd01=[]
let a01={
  dataTypes:[
    {key:'Title', value:"Title", type:'headingEl'},
    {key:'Date', value:"Date", type:'dateEl'},
    {key:'Status', value:"Status", type:'statusEl'},
    {key:'Department', value:"Department", type:'categoryEl'},
    {key:'ViewMore', value:"ViewMore", type:'detailsLinkEl'},
    {key:'Skills', value:"Skills", type:'tagsEl'},
  ]
}

let p02=[
  {key:'Facility', value:"Facility"},
  {key:'Key', value:"Key"},
  {key:'Type', value:"Type"},
  {key:'Employees', value:"Employees"},
  {key:'Details', value:"Details"},
  {key:'Phone', value:"Phone"},
  {key:'Email', value:"Email"},
]
let d02=[
  {Facility:'Abbot', key:'https://googlefiles.com/file/1', Type:'ID-ADT', Employees:'23', Details:'/abbot', Phone:"5874069555", Email:"sd@sd.com"},
  {Facility:'FASAT', key:'https://googlefiles.com/file/2', Type:'ID-CDT', Employees:'44', Details:'/fasat', Phone:"5874069555", Email:"s2d@sd.com"},
]
let sd02=[]
let a02={
  dataTypes:[
    {key:'Facility', value:"Facility", type:'headingEl'},
    {key:'Key', value:"Key", type:'linkEl'},
    {key:'Type', value:"Type", type:'typeEl'},
    {key:'Employees', value:"Employees", type:'countEl'},
    {key:'Details', value:"Details", type:'detailsLinkEl'},
    {key:'Phone', value:"Phone", type:'phoneEL'},
    {key:'Email', value:"Email", type:'emailEl'},
  ]
}

let p03=[
  {key:'Title', value:"Title"},
  {key:'Address', value:"Address"},
  {key:'Beds', value:"Beds"},
  {key:'Tier', value:"Tier"},
  {key:'ViewMore', value:"ViewMore"},
  {key:'Pharmacy', value:"Pharmacy"},
  {key:'Janitorial Service', value:'Janitorial Service', }
]
let d03=[
  {Title:'UI Developer', Address:'1200 Abbott Drive Pittsburgh PA 15200', Beds:'4', ViewMore:'/ui-developer', Tier:'A', Pharmacy:"Yes"},
  {Title:'Full Stack Developer', Address:'1200 Abbott Drive Pittsburgh PA 15200', Beds:'NA', ViewMore:'/fullstack-developer', Tier:'A', Pharmacy:"No"},
]
let sd03=[]
let a03={
  dataTypes:[
    {key:'Title', value:"Title", type:'headingEl'},
    {key:'Date', value:"Date", type:'dateEl'},
    {key:'Beds', value:"Beds", type:'countEl'},
    {key:'Tier', value:"Tier", type:'categoryEl'},
    {key:'ViewMore', value:"ViewMore", type:'detailsLinkEl'},
    {key:'Pharmacy', value:"Pharmacy", type:'availabilityEl'},
    {key:'Janitorial Service', value:"Janitorial Service", type:'availabilityEl'},
  ]
}


export default {
  a01, sd01, d01, p01,
  a02, sd02, d02, p02,
  a03, sd03, d03, p03,
}
