import { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { twdClass } from "../constants/tailwind.constants";
import { INPUT_TEXT, BTN_NORMAL, BTN_CLS } from "../constants/var.constants";
import { createUser } from "../api/services";
import { PRICING_METADATA } from "../components/pricing/pricing.metadata";
import {Button} from "../components/ui/com_ui";
import {Layout, Error} from "./Layout";
import styles from "./login.module.css";

export function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const { signUp } = useAuth();

  const history = useHistory();
  const [error, setError] = useState(null);

  async function handleSignUpSubmit(e) {
    e.preventDefault();
    setError(null);
    // Get email and password input values
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const first_name = firstNameRef.current.value;
    const last_name = lastNameRef.current.value;
    // lastNameRef;

    // Calls `signUp` function from the context
    const result = await signUp({ email, password });
    if (result.error) {
      setError(result.error.message);
    } else {
      const {
        user: { id, email },
      } = result;
      

      const plan = PRICING_METADATA.filter(
        (item) => item.planType === "base"
      )[0];
      let userData=await createUser({
        uid:id,
        email,
        last_name,
        first_name,
        plan_id: plan.planIdM,
        max_cust_domain: plan.max_cust_domain,
        max_sub_domain: plan.max_sub_domain,
      });
      if(userData && userData.user && userData.user.existing_user){
        setError('This email already exists. Please login directly');
      }else{
        history.push("/");
      }  
      console.log('userData', userData)    
      
    }

  }

  useEffect(() => {
    //document.body.classList.add(twdClass("bg-blue-100"));
    return () => {
     // document.body.classList.remove(twdClass("bg-blue-100"));
    };
  }, []);

  const SignupLink = (<Link to="/login" className={twdClass("text-gray-700 font-semibold")}>Log in</Link>)
  return (
    <Layout
      title={"Create account"}
      desc={"Let’s get started!"}
      action={SignupLink}
    >
      <form onSubmit={handleSignUpSubmit}>
        {/*<label
          htmlFor="input-firstname"
          className={twdClass("text-sm font-semibold text-gray-600")}
        >
          First Name <span className={twdClass("text-red-400")}>*</span>
        </label>*/}
        <input
          id="input-firstname"
          type="text"
          required
          ref={firstNameRef}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"First name"}
        />

        {/*<label
          htmlFor="input-lastname"
          className={twdClass("text-sm font-semibold text-gray-600")}
        >
          Last Name <span className={twdClass("text-red-400")}>*</span>
        </label>*/}
        <input
          id="input-lastname"
          type="text"
          required
          ref={lastNameRef}
          className={twdClass(INPUT_TEXT)}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Last name"}
        />

        {/*<label
          htmlFor="input-email"
          className={twdClass("text-sm font-semibold text-gray-600")}
        >
          Email <span className={twdClass("text-red-400")}>*</span>
        </label>*/}
        <input
          id="input-email"
          type="email"
          required
          ref={emailRef}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Email"}
        />

        {/*<label
          htmlFor="input-password"
          className={twdClass("text-sm font-semibold text-gray-600")}
        >
          Password <span className={twdClass("text-red-400")}>*</span>
        </label>*/}
        <input
          id="input-password"
          type="password"
          required
          ref={passwordRef}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Password"}
        />

        <br />

        <button
          type="submit" 
          className={twdClass("block w-full")}                  
        >
          <Button type={"defualt"} color={"primary"} size="lg">Sign up</Button>
        </button>

        <Error error={error}/>
      </form>     
    </Layout>
  );
}
