import { twdClass } from "../../constants/tailwind.constants";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { find } from "lodash";
import { getSheetInfo, getSheetColumns } from "../../api/services";
import CustomSelectBox from "../common/CustomSelectBox";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { Button} from "../ui/com_ui";

export const GoogleConnectionInfo = ({
  updateElementValue,
  setColumnNames,
  connectionType,
}) => {
  const {
    selectedBlock,
    pageInfo: { blocks },
    projectData: { connections, project_id },
  } = useSelector((state) => state.studioDetails);
  const currentBlock = find(blocks, ["metaDataId", selectedBlock.metaDataId]);
  const [sheetNames, setSheetNames] = useState([]);
  const [sheetColumns, setSheetColumns] = useState([]);

  const fetchSheetNames = (id) => {
    getSheetInfo(id).then((resp) => {
      if (resp.success) {
        setSheetNames(resp.data);
      }
    });
  };

  const getColumnNames = (value) => {
    getGoogleSheetColumns(value);
  };

  const getGoogleSheetColumns = (sheet_name) => {
    const {
      connection_details: { spreadsheetId },
      connection_id,
    } = find(connections, [
      "connection_id",
      currentBlock.configs.connection.connectionId,
    ]);
    getSheetColumns({
      project_id,
      sheet_name,
      spreadsheetId,
      connectionId: connection_id,
      ranges: [`${sheet_name}!A1:Z3`],
    }).then((resp) => {
      if (resp.success) {
        setSheetColumns(resp.data);
        setColumnNames(resp.data);
      }
    });
  };

  useEffect(() => {
    const { connectionId, sheetName } = currentBlock.configs.connection;
    const con = find(connections, ["connection_id", connectionId]);
    if (con && con.connection_details) {
      const {
        connection_details: { spreadsheetId },
      } = find(connections, ["connection_id", connectionId]);

      fetchSheetNames(spreadsheetId);
      if (sheetName) {
        getColumnNames(sheetName);
      }
    }
    updateElementValue("configs.id", project_id);
  }, []);

  const connectionOptions = connections
    .filter((itm) => itm.connection_type === connectionType)
    .map((item) => ({
      key: item.connection_id,
      value: item.connection_details.displayName,
    }));
  const connectValue = find(connectionOptions, [
    "key",
    currentBlock.configs.connection.connectionId,
  ])?.key;

  return (
    <>
      <div className={twdClass("flex items-center mt-2 mb-2")}>
        <label className={twdClass("w-48 text-sm")}>Google Sheet</label>
        <div className={twdClass("w-full")}>
          <CustomSelectBox
            value={connectValue}
            options={connectionOptions}
            updateValue={(val) => {
              const itm = find(connections, ["connection_id", val]);
              updateElementValue("configs.connection.connectionId", val);
              fetchSheetNames(itm?.connection_details.spreadsheetId);
              updateElementValue("configs.connection.sheetName", "");
            }}
          />
        </div>
      </div>
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-48 text-sm")}>Sheet Names</label>
        <div className={twdClass("w-full")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={currentBlock.configs.connection.sheetName}
            onChange={(e) => {
              updateElementValue(
                "configs.connection.sheetName",
                e.target.value
              );
              getColumnNames(e.target.value);
            }}
          >
            <option value="">Select</option>
            {sheetNames &&
              sheetNames.map((item) => <option value={item}>{item}</option>)}
          </select>
        </div>
      </div>
      <div className={twdClass("flex justify-center mb-2 mt-4 bg-gray-50  px-2 py-2 rounded-2xl -mx-3 -mb-1")}>
        <Button
          type={"default"} cType={"iconText"} color={'white'} iconImg={'arrow-refresh-06.svg'}          
          disabled={!currentBlock.configs.connection.sheetName}
          onClick={() => {
            const timestamp = new Date().getTime();
            updateElementValue("configs.connection.timestamp", timestamp);
          }}
        >          
          Sync/Refresh Data
        </Button>
      </div>
    </>
  );
};
