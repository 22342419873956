export const IMAGE_ELEMENT_BLOCK = {
  blockType: "ELEMENTS",
  blockDesign: "ImageElement",
  editFormName: "IMAGE_ELEMENT_FORM",
  styleProps: {
    "background-color": "",
    "background-image": "url('')",
  },
  stylePropsTab: {},
  stylePropsMob: {},
  imagesPerRow: {
    desktop: 1,
  },
  images: {
    styleProps: {
      "background-image": "url('')",
      padding: "10px 10px 10px 10px",
    },
    stylePropsTab: {},
    stylePropsMob: {},
    list: [
      {
        url:
          "https://images.unsplash.com/photo-1590602391165-edb096edb9de?auto=format&fit=crop&w=1488&q=80",
        alt: "",
        styleProps: {
          "justify-content": "center",
        },
        innerDivStyle: {
          width: "50%",
        },
      },
      {
        url:
          "https://images.unsplash.com/photo-1590602391165-edb096edb9de?auto=format&fit=crop&w=1488&q=80",
        alt: "",
        styleProps: {
          "justify-content": "center",
        },
        innerDivStyle: {
          width: "100%",
        },
      },
    ],
  },
};
