export const PRICING_01 = {
  variation: "v2",
  blockType: "PRICING",
  blockDesign: "Pricing_01",
  styleProps: {
    test: "sd",
  },
  test_sl: ["New Text 1", "New Text 2"],
  heading: {
    styleProps: {},
    text: "Pricing",
  },
  description: {
    text: "One-tenth the price of an office, ten times the value.",
  },
  textys: [
    {
      text:
        'Powered by your data: <img src="https://freshflowxyz.b-cdn.net/sly-projects/1638092364746-1636304775960-_i_play_store.png"/>',
    },
  ],
  pricing: [
    {
      title: {
        text: "Team",
      },
      month_price_monthly: {
        text: "$7",
      },
      month_price_yearly: {
        text: "$5",
      },
      description: {
        text: "For smaller teams that need to replace a VPN for remote access",
      },
      features: {
        value: ["Up to 50 users", "5 devices per user", "5 remote networks"],
      },
      cta_button: {
        text: "Start 14-day Trial",
      },
      styleProps: {},
      bordered: "",
    },
    {
      title: {
        text: "Business",
      },
      month_price_monthly: {
        text: "$12",
      },
      month_price_yearly: {
        text: "$10",
      },
      description: {
        text: "For larger teams that need more advanced access controls",
      },
      features: {
        value: [
          "Up to 150 users",
          "5 devices per user",
          "10 remote networks",
          "Resource-level access control",
        ],
      },
      cta_button: {
        text: "Start 14-day Trial",
      },
      styleProps: {},
      bordered: "enable",
    },
    {
      title: {
        text: "Enterprise",
      },
      month_price_monthly: {
        text: "Custom",
      },
      month_price_yearly: {
        text: "Custom",
      },
      description: {
        text:
          "For organizations that need comprehensive access controls, detailed auditing, and deployment automation",
      },
      features: {
        value: [
          "No user or device limits",
          "Network analytics",
          "Secure service accounts",
          "Device Posture",
          "Admin API",
          "Priority support",
        ],
      },
      cta_button: {
        text: "Contact Us",
      },
      styleProps: {},
      bordered: "enable",
    },
  ],
  footer: {
    text: "Want to use a web browser? Log in to Gusto",
  },
  editFormName: "PRICING_01_FORM",
};
