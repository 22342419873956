import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import { saveProject, getProjectInfo, getGenerateDesign, promptChatGPTMini } from "../../api/services";

import {
  RESET_POPUP,
  UPDATE_BLOCK_DATA,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  RESET_GENERATED
} from "../../constants/action.constants";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button} from "../ui/com_ui";

import t_navabar from "./transform/t_navbar";
import t_header from "./transform/t_header";
import t_cards from "./transform/t_cards";
import t_footer from "./transform/t_footer";
import t_textElement from "./transform/t_textElement";
import t_buttonElement from "./transform/t_buttonElement";
import t_imageElement from "./transform/t_imageElement";
import t_headerDyn from "./transform/t_headerDyn";

import { getTimeForUniqID, getParams, title2Slug, styles_, links_} from "./transform/t_util";
import {get, set, find, cloneDeep} from 'lodash'

const Apply = (props) => {
  const { regenerate=false, type='all', cards_block={}} = props
  const dispatch = useDispatch();
  const {
    pageInfo,
    generated={},
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);
  
  const [isExecuted, setExecuted] = useState(false);

  const {blocks=[]}= pageInfo
  const {settings={}, cards={}, navbar={}, header={}, footer={}, headerDyn={}, textElement={}, buttonElement={}, imageElement={}} = generated
  const {websiteFor='', pageName='', pageSlug='', pageType='', slugParamColumn='', connectionType='', connectionId='', googleSheetName='', sheetColumns=[]} = settings

  //console.log("Apply pageInfo", pageInfo)
  const applyStatic = () => {
    let blocks_=[]
    if(type=='cards' && cards_block.metaDataId){
      let tCards=t_cards({generated, obj:cards})   
      //console.log("Apply generated", generated)   
      const mBlocks = blocks.map((item) => {
        if (item.metaDataId === cards_block.metaDataId) {
          return { ...tCards };
        }
        return item;
      });
      blocks_=mBlocks
    }else{
      let tNavbar=t_navabar({generated, obj:navbar})
      let tHeader=t_header({generated, obj:header})
      let tCards=t_cards({generated, obj:cards})
      let tFooter=t_footer({generated, obj:footer})
      console.log("tApply", generated, tNavbar, tCards,tHeader) 
      
      blocks.unshift(tFooter)
      blocks.unshift(tCards)
      blocks.unshift(tHeader)
      blocks.unshift(tNavbar)
      console.log("pageInfo", project_id, blocks)
      //dispatch({ type: UPDATE_PROJECT_PAGE, data:pageInfo });
      blocks_=[...blocks]
    }
    return blocks_       
  }


  const applyDynamic = () => {
    let paramsValues=[]

    let blocks_=[]
    if(type=='textElement'){
      let tTextElement=t_textElement({generated, obj:textElement})   
      /*//console.log("Apply generated", generated)   
      const mBlocks = blocks.map((item) => {
        if (item.metaDataId === cards_block.metaDataId) {
          return { ...tTextElement };
        }
        return item;
      });
      blocks_=mBlocks*/
    }else{
      let headerDynParams=getParams({template:JSON.stringify(headerDyn), captureFrom:"flower_brackets"})
      let textParams=getParams({template:JSON.stringify(textElement), captureFrom:"flower_brackets"})
      let buttonParams=getParams({template:JSON.stringify(buttonElement), captureFrom:"flower_brackets"})
      let imageParams=getParams({template:JSON.stringify(imageElement), captureFrom:"flower_brackets"})
      let params=[]
      if(headerDynParams.length) params=[...params, ...headerDynParams]
      if(textParams.length) params=[...params, ...textParams]
      if(buttonParams.length) params=[...params, ...buttonParams]
      if(imageParams.length) params=[...params, ...imageParams]
      paramsValues=params.map((param)=>{
        return({
          "replaceTo": `{{${param.value.trim()}}}`,
          "columnValue": `${param.value.trim()}`
        })
      })
      console.log("paramsValues", paramsValues)
      let tHeaderDyn=t_headerDyn({generated, obj:headerDyn})
      let tTextElement=t_textElement({generated, obj:textElement})
      let tImageElement=t_imageElement({generated, obj:imageElement})
      let tButtonElement=t_buttonElement({generated, obj:buttonElement})
      console.log("tDApply", generated, tTextElement, tButtonElement) 
      
       
      
      blocks.unshift(tButtonElement)       
      blocks.unshift(tTextElement)   
      blocks.unshift(tImageElement)  
      blocks.unshift(tHeaderDyn)   
      console.log("pageInfo", pageInfo, project_id, blocks)
      //dispatch({ type: UPDATE_PROJECT_PAGE, data:pageInfo });
      blocks_=[...blocks]
    }

    if(!pageInfo.settings.paramsValues) pageInfo.settings.paramsValues=[]
    pageInfo.settings.connectionType= connectionType
    pageInfo.settings.connectionId= connectionId
    pageInfo.settings.googleSheetName= googleSheetName
    pageInfo.settings.paramsValues=paramsValues
    pageInfo.settings.pageType= 'dynamic'
    pageInfo.settings.pageName= pageName ? pageName : pageInfo.settings.pageName
    pageInfo.settings.pageSlug= pageSlug ? pageSlug : pageInfo.settings.pageName
    pageInfo.settings.sheetColumns= sheetColumns
    pageInfo.settings.websiteFor= websiteFor

    if(slugParamColumn) pageInfo.settings.slugParamColumn=slugParamColumn
    
    dispatch({ type: UPDATE_PROJECT_PAGE,  data: {...pageInfo}});   
    return blocks_     
  }

  const apply = () => {
    let blocks_=[]
    if(pageType=="dynamic"){
      blocks_=applyDynamic()
    }else{
      blocks_=applyStatic()
    }  
    console.log("blocks_", blocks_)
    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks_,
    });
    dispatch({ type: RESET_POPUP });   
    setExecuted(true)
  }


  
  let text=''
  if(type=="all"){
    text='Apply this Design to Page'
  }else{
    text= 'Replace the block with this Design'
  }
  return (
    <Button
      type={'default'}  
      cType={'iconText'}        
      color={'primary'}
      size={'md'}         
      iconImg={'download-02.svg'}         
      className={'ml-2'}   
      onClick={()=>{apply()}}
    >              
      {text}
    </Button>
  );
};

export default Apply;

