import { twdClass } from "../../constants/tailwind.constants";
import { BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { useState, useEffect } from "react";
import { CardSectionPanel } from "./CardSectionPanel";
import { CardLayoutPanel } from "./CardLayoutPanel";
import { CardLinkPanel } from "./CardLinkPanel";

import { cloneDeep, findIndex, find } from "lodash";
import { CardElementStylesPanel } from "./CardElementStylesPanel";
import { CardLocationStylesPanel } from "./CardLocationStylesPanel";
import { Button, BtnStyle, BtnBack, BtnAdd, BtnNext} from "../ui/com_ui";

const MC2 = twdClass(
  "text-sm font-semibold bg-gray-50 p-2 border rounded-md shadow-sm cursor-pointer"
);
const MC3 = twdClass("flex items-center justify-between");

export const ManageElementsPanel = ({
  setActivePanel,
  currentBlock,
  columnNames,
  updateElementValue,
}) => {
  const sections = currentBlock.cardElements;
  const locations = currentBlock.cardLocations;
  const config = currentBlock.cardConfig;
  const cardLink = currentBlock.cardLink ? currentBlock.cardLink : {};

  const [activeLayout, setActiveLayout] = useState(config.layout ? config.layout : '');
  const [activeLoc, setActiveLoc] = useState(0);
  const [activeSecId, setActiveSecId] = useState(0);
  const [showStylePanel, setStylePanel] = useState(false);
  const [styleId, setStyleId] = useState(false);

  

  const addElement = () => {
    const id = new Date().getTime();
    const values = [...sections, { type: "", id }];
    updateElementValue("cardElements", values);
  };

  const deletedElement = (id) => {
    const values = sections.filter((item) => item.id !== id);
    updateElementValue("cardElements", values);
  };

  const updateValues = (id, data) => {
    const values = sections.map((item) => {
      if (item.id === id) {
        return { ...item, ...data };
      }
      return item;
    });
    updateElementValue("cardElements", values);
  };
  

  const shaffilElements = (action, id) => {
    const mSections = cloneDeep(sections);
    const blockIndex = findIndex(mSections, ["id", id]);
    const record = find(mSections, ["id", id]);
    mSections.splice(blockIndex, 1);
    const moveTo = action === "MOVE_UP" ? blockIndex - 1 : blockIndex + 1;
    mSections.splice(moveTo, 0, record);
    updateElementValue("cardElements", mSections);
  };

  const updateStylePanel = (data) => {
    setStylePanel(data.showStyles);
    setActiveSecId(data.sectionId);
    setStyleId(data.styleId)
  };

  const updateLocationStylePanel = (data) => {
    setStylePanel(data.showStyles);
    setActiveLoc(data.location);
    const indexOf = findIndex(locations, ["name", data.location]);
    if(!locations){
      updateElementValue(`cardLocations[0]`, {name:data.location}); 
    }else if(indexOf==-1){
      updateElementValue(`cardLocations[${locations.length}]`, {name:data.location});      
    }
  };

  

  return (
    <>
      {(showStylePanel && activeSecId!=0) && (
        <div className={twdClass("px-3")}>
          <div className={twdClass("py-3 flex items-center")}>
            <BtnBack
              className={"mr-4"}
              onClick={() =>
                updateStylePanel({
                  sectionId: 0,
                  showStyles: false,
                })
              }
            >              
            </BtnBack>
            <div className={twdClass("flex-grow")}>
              <div className={twdClass("text-gray-800 text-sm font-semibold")}>
                Element Style
              </div>
            </div>
          </div>
          <div>
            <CardElementStylesPanel
              activeSecId={activeSecId}
              sections={sections}
              updateElementValue={updateElementValue}
              styleId={styleId}
            />
          </div>
        </div>
      )}
      {(showStylePanel && activeLoc!=0) && (
        <div className={twdClass("px-3")}>
          <div className={twdClass("py-3 flex items-center")}>
            <BtnBack
              className={"mr-4"}
              onClick={() =>
                updateLocationStylePanel({
                  location: 0,
                  showStyles: false,
                })
              }
            >              
            </BtnBack>
            <div className={twdClass("flex-grow")}>
              <div className={twdClass("text-gray-800 text-sm font-semibold")}>
                Location Style
              </div>
            </div>
          </div>
          <div>
            <CardLocationStylesPanel
              activeLoc={activeLoc}
              locations={locations}
              updateElementValue={updateElementValue}
            />
          </div>
        </div>
      )}
      {!showStylePanel && (
        <div className={twdClass("px-3")}>
          <div className={twdClass("pt-3 pb-3 flex items-center")}>
            <BtnBack
              className={"mr-4"}
              onClick={() => setActivePanel("main")}
            >              
            </BtnBack>
            <div className={twdClass("flex-grow")}>
              <div className={twdClass("text-gray-800 text-sm font-semibold")}>
                Card Design
              </div>
            </div>
            <BtnStyle              
              onClick={() => setActivePanel("cardstyle")}
            > 
            </BtnStyle>            
          </div>
          {/* <div className={MC2} onClick={() => setActivePanel("cardstyle")}>
            <span className={MC3}>
              <span>Configure Card Styles</span>
              <span>
                <img alt="" src="/assets/images/icon_arrow_right.png" />
              </span>
            </span>
          </div> */}
          <CardLinkPanel   
            columnNames={columnNames}
            cardLink={cardLink}
            cardConfig={config}            
            updateElementValue={updateElementValue}
          />
          <CardLayoutPanel   
            cardConfig={config}
            activeLayout={activeLayout}
            setActiveLayout={setActiveLayout} 
            updateElementValue={updateElementValue}
            updateLocationStylePanel={updateLocationStylePanel}
          />          
          <div className={twdClass("flex justify-between mt-8")}>
            <div className={twdClass("text-sm font-semibold pl-4")}>
             Elements
            </div>
            {/* <button
              className={twdClass(`${BTN_NORMAL} -ml-0`)}
              onClick={() => {
                setCollapseAll(new Date().getTime());
              }}
            >
              <FaIcons
                icon="angleUp"
                className={twdClass("mr-2 text-blue-500")}
              />
              Collapse All
            </button> */}
            <BtnAdd              
              onClick={() => addElement()}
            >              
              Add Element
            </BtnAdd>
          </div>
          <div>
            {sections.map((item, index) => {
              return (
                <CardSectionPanel
                  elementIndex={index}
                  columnNames={columnNames}
                  section={item}
                  deletedElement={deletedElement}
                  updateValues={updateValues}
                  shaffilElements={shaffilElements}
                  updateStylePanel={updateStylePanel}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
