import { useState } from "react";
import { twdClass } from "../../../constants/tailwind.constants";
import { find } from "lodash";

const Dropdown = ({
  options,
  value,
  updateValue,
  hideLabel,
  label,
  inputArgs,
}) => {
  if (!inputArgs) inputArgs = {};
  const { inputStyle } = inputArgs;
  const [expandOpt, setExpandOpt] = useState(false);
  const option = options && find(options, ["value", value]);
  const displayValue = option ? option.display : "Select";
  const imgSrc = `/assets/images/chevron-${expandOpt ? "up" : "down"}.svg`;

  let SB_CLS_1 = "border border-gray-100 hover:border-gray-300 rounded-lg px-2";
  let SB_CLS_11 = "border-2 border-gray-300 hover:border-gray-500 pb-2  rounded-lg px-2";
  const SB_CLS_2 = twdClass("flex items-center py-2 cursor-pointer");
  const SB_CLS_3 = twdClass("w-20 text-xs text-gray-700 font-semibold");
  const SB_CLS_4 = twdClass(
    "flex flex-wrap bg-gray-100 rounded-md  pr-1 py-2 w-auto mr-5 border"
  );
  const SB_CLS_5 = twdClass("flex flex-col text-lg w-full overflow-auto ");
  const SB_CLS_6 = twdClass("px-2 py-1 hover:bg-gray-200 cursor-pointer text-sm rounded-sm");
  const SB_CLS_7 = twdClass(
    "px-2 py-1 hover:bg-gray-300 cursor-pointer bg-gray-200 text-sm rounded-sm font-semibold"
  );

  const sbStyle = {
    maxHeight: "200px",
  };

  SB_CLS_1 += inputStyle === 2 ? " px-2" : "";
  SB_CLS_11 += inputStyle === 2 ? " px-2" : "";

  return (
    <div className={twdClass(expandOpt ? SB_CLS_11 : SB_CLS_1)}>
      <div
        className={SB_CLS_2}
        onClick={() => {
          setExpandOpt(!expandOpt);
        }}
      >
        {!hideLabel && <div className={SB_CLS_3}>{label}</div>}
        <div className={twdClass("flex-grow text-xs font-semibold")}>
          <span dangerouslySetInnerHTML={{ __html: displayValue }}></span>
        </div>
        <div>
          <img alt="" src={imgSrc} />
        </div>
      </div>
      <div className={`${!expandOpt ? twdClass("hidden py-2") : "py-2"}`}>
        <div className={SB_CLS_4}>
          <div className={SB_CLS_5} style={sbStyle}>
            {options.map((item) => {
              const act = item.value === value ? SB_CLS_7 : SB_CLS_6;
              return (
                <div className={act} onClick={() => updateValue(item.value)}>
                  <span
                    dangerouslySetInnerHTML={{ __html: item.display }}
                  ></span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
