export const textElement_prompt=({opts={}, sdata=false, data=false, sample=false})=>{
    let pagePrompt=''
    if(!sdata) sdata={        
        texts:[
            {"label":"Job Title", "value":"Senior Full Stack Developer"},
            {"label":"Job Type", "value":"Full Time"},
            {"label":"Category", "value":"Software Development"},
            {"label":"Location", "value":"Remote - USA"},
            {"label":"Job Description", "value":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "},
            {"label":"Job Requiremnts", "value":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "},
        ]
    }
    
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'

    //console.log("textElement_prompt", opts, sdata)
    
    return(`
    #variables

    [SECTION_DATA]=${JSON.stringify(sdata)}   

    #prompt

    Create Website details page in json

    Use all [SECTION_DATA]->texts data for generating output

    `)
}


const schema={
    "name": "textElement_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": { 
            "texts": {
                "type":"object",
                "description": "Text contains dynamic variables in double flower brackets {{}}", 
                "properties":{
                    "list": {
                        "type":"array",
                        "description": "Text contains dynamic variables in double flower brackets {{}}", 
                        "items":{
                            "$ref": "#/$defs/text_obj"
                        }                
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}        
                },
                "required": [ 
                    "list",
                    "styleProps",
                    "stylePropsMob",
                    "stylePropsTab"          
                ],             
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_obj"}                     
        },  
        "required": [ 
            "texts",
            "styleProps",
            "stylePropsMob",
            "stylePropsTab"          
        ],
        "additionalProperties": false,
        "$defs": {           
            "text_obj":{
                "type": "object",                
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "Text contains dynamic variables in double flower brackets {{}}"   
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },           
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                            
        }
    }
}
export const textElement_format = {
    "type":"json_schema",
    "json_schema": schema
}