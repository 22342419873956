import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { UPDATE_PAGE_SETTINGS } from "../../../constants/action.constants";
import ExternalJS from "../site-settings/ExternalJS";
import EmbedCode from "../sui/EmbedCode";
import Visible from "../sui/Visible";
import Collapse from "../sui/Collapse";
import {Button} from "../../ui/com_ui";

const PageAdvancedPanel = ({saveSettings}) => {
  const dispatch = useDispatch();
  const { pageInfo } = useSelector((state) => state.studioDetails);
  const [settingsData, setSettingsData] = useState({});

  const { settings={}, page_id } = pageInfo;

  useEffect(() => {
    if(settings.pageName){
      setSettingsData({ ...settings });
    }else{

    }    
  }, [settings.pageName]);

  const saveSettings2 = () => {
    //console.log("settingsData", settingsData)
    dispatch({
      type: UPDATE_PAGE_SETTINGS,
      data: settingsData,
    });
  };

  const updateSettings = (data) => {
    //console.log("updateSettings", data)
    setSettingsData(data);
  };

  return (
    <div>
      <div className={`pages-settings-layout  ${twdClass("px-6")}`}> 
        <Collapse label={'Visibility'} desc="Set this page Visible or Not Visible to Users">
          <Visible
            sitesettings={settingsData}
            updateSettings={updateSettings}
          />
        </Collapse> 
        {settings.pageType!='dynamic' &&  <Collapse label={'Embed Code'} desc="Embed this page in websites such as webflow, wix, Squarespace, wordpress and others. ">
          <EmbedCode/>
        </Collapse> }        
        {/*<ExternalJS
          sitesettings={settingsData}
          updateSettings={updateSettings}
        />*/}
        <div className={twdClass("w-full text-right mb-2 flex justify-end mt-4")}>
            <Button
              type="default"  color="primary" className={''} 
              onClick={() => saveSettings("update", settingsData)}
            >
              {(pageInfo && pageInfo.page_id) ? 'Update' : 'Add'}  Advanced Settings
            </Button>
        </div>
      </div>
    </div>      
  );
};

export default PageAdvancedPanel;
