import { useEffect, useState } from "react";
import { twdClass } from "../constants/tailwind.constants";
import { Route, Redirect } from "react-router-dom";
import { useParams  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button} from "../components/ui/com_ui";
import { debug, getUserCheck, updateOnboarding} from "../api/services";
import { useAuth } from "../contexts/Auth";


const Iframe = (props) => {
  const user = useSelector(
    (state) => state.userdetails
  );
  const {plan_id=0, email, first_name='', last_name=''} = useSelector(
    (state) => state.userdetails
  );
  console.log("user", user)
  console.log("email", email)

  useEffect(async () => {
    const { data } = await updateOnboarding({action:'display'});  
    console.log("update Onboarding", data)
    return () => {};
  }, []);

  return (
    <div className={twdClass("h-screen")}>     
     <iframe src={`https://app.formester.com/f/e2d6e348-6c9c-4c74-ae3a-0a6ee1e28dcd?Email=${email}&firstname=${first_name}&lastname=${last_name}`} frameborder="0" height="100%" width="100%"></iframe >
    
    </div>
  );
};

const Onboarding = ({ component: Component, ...rest }) => {
  const { user } = useAuth();
  const { id, header } = useParams(); 

  const [isCheckDone, setCheckDone] = useState(false);
  const [isCheck, setCheck] = useState({});

  const {plan_id=0} = useSelector(
    (state) => state.userdetails
  );

  useEffect(async() => {
    const { user : check } = await getUserCheck();
    console.log("check data", check)
    setCheck(check)
    setCheckDone(true)
    return () => {};
  },[]);

  let {onboardingFormDisplayed=0, onboardingFormFilled} = isCheck
  console.log("isCheck data", onboardingFormFilled)
  if(isCheckDone){
    return (
      <Route
        {...rest}
        render={(props) => {
          // Renders the page only if `user` is present (user is authenticated)
          // Otherwise, redirect to the login page
          //return user ? <Iframe {...props} /> : <Redirect to="/login" />;
          return user ? (plan_id==100000 && !onboardingFormFilled) ? <Iframe {...props} /> : <Redirect to="/" /> : <Redirect to="/login" />;
        }}
      ></Route>
    );
  }else{
    return (<></>)
  }
};

export default Onboarding;
