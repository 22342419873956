import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, getParams, styles_, links_} from "./t_util";

import imageElement from '../TestDesign/data/imageElement'

const process_=({obj, d})=>{
  //console.log("t_image", d)
  let {images=''}=d
  let out={}
  obj.images=images
  obj.images=styles_({obj:obj.images, el:images})  
  obj.images.styleProps.margin='0px auto'
  obj.images.list=[{url:images.image}]
  //obj.images.innerDivStyle={ "width": "50%", "justify-content": "center"}
  return obj
}

const imageElement_ = ({generated={}, obj=false}) => {
  let d = obj ? obj : imageElement.a01.imageElement
  let uid=  getTimeForUniqID()
  //console.log("textElement", d.styleProps)
  //console.log("getTimeForUniqID", uid)
  if(!d.styleProps) d.styleProps={}
  d.styleProps.display='block'
  d.styleProps.width='100%'
  d.styleProps.margin='0px auto'

  let out={   
    "metaDataId": uid,
    "blockId": uid,
    "blockType": "ELEMENTS",
    "blockDesign": "ImageElement",
    "editFormName": "IMAGE_ELEMENT_FORM",
    "styleProps": d.styleProps,
    "stylePropsTab": d.stylePropsTab ? d.stylePropsTab : {},
    "stylePropsMob": d.stylePropsMob ? d.stylePropsMob : {},    
    "images": {
      list:[]
    },
  }
  out=process_({obj:out, d}) 
  out=styles_({obj:out, el:d})
  //out.links=links_({links:d.links})
  return out
};

export default imageElement_;

