export const GenericCardMetadata = {
  blockType: "CARDS",
  blockDesign: "BYOCards",
  editFormName: "BYO_CARDS_PANEL",
  styleProps: {
    "background-color": "#1b1b31",
    padding: "10px 20px 10px 20px",
  },
  configs: {
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-justify-items-center sly-gap-4 sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateName: "Box Simple 1",
    templateId: "TEMP_1",
    templateHTML: `
    <div class="sly-w-full">
      <div class="sly-relative sly-h-full sly-ml-0 md:sly-mr-0 sly-block sly--rotate-1 sly-transform hover:sly-scale-105 hover:sly--rotate-2">
        <span class="sly-absolute sly-top-0 sly-left-0 sly-w-full sly-h-full sly-mt-1 sly-ml-1 sly-bg-blue-400 sly-rounded-lg" style="background-color: red;"></span>
        <div class="sly-relative sly-h-full sly-p-5 sly-bg-white sly-transform sly-border-2 sly-border-blue-400 sly-rounded-lg" style="border-color: red;">
            <div class="sly-flex sly-items-center sly--mt-1">
                <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Landing Page Checklist</title>
                    <g id="Landin'-Cool" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Square-Logo" transform="translate(3.000000, 3.000000)">
                            <rect id="Rectangle" fill="#0080FE" x="0" y="0" width="42" height="42" rx="11"></rect>
                            <g id="cool-landing-page-logo" transform="translate(21.000000, 19.762563) rotate(90.000000) translate(-21.000000, -19.762563) translate(7.291667, 6.054230)" fill-rule="nonzero">
                                <path d="M23.4659406,0.145833333 C22.0267932,0.170998017 20.611777,0.520964059 19.3259349,1.16975195 L2.48234976,9.3468798 C-0.651010772,10.8827577 -0.353270631,13.7980816 3.10618624,14.5660205 L11.0742795,16.3720992 L12.8890766,24.3216896 C13.640516,27.7347516 16.561205,28.0902789 18.09244,24.9474176 L26.2448486,8.15230808 C28.4140982,3.35980011 26.9821099,0.216938793 23.4659406,0.145833333 Z" id="Path" fill="#FFFFFF"></path>
                                <path d="M22.9944726,2.82231694 C24.8617568,2.55407638 25.1305326,3.90939708 23.7866538,6.86004318 L15.695089,23.40625 L13.7712204,14.7237268 C13.6497014,14.1972547 13.2377861,13.7861595 12.7102635,13.6648825 L4.01041667,11.7307269 L20.6320752,3.65527445 C21.3804784,3.2776346 22.1744649,2.99768299 22.9944726,2.82231694 Z" id="Path" fill="#6AB5FF"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <h3 class="sly-my-2 sly-ml-3 sly-text-lg sly-font-bold sly-text-gray-800">{{val1}}</h3>
            </div>
            <p style="BX_1" class="sly-mt-3 sly-mb-1 sly-text-xs sly-font-bold sly-text-blue-600 sly-uppercase">{{val4}}</p>
            <p class="sly-mb-2 sly-text-gray-600">A curated library of landing page resources. You'll have a up-to-date landing pages in no time.</p> 
            [REPEATER_START]
              {{val6}}|<a class="sly-block sly-p-2 sly-m-2 sly-w-1/5 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</a>
            [REPEATER_END]    
            [REPEATER_START]
              {{val7}}|<a class="sly-block sly-p-2 sly-m-2 sly-w-1/5 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</a>
            [REPEATER_END] 
        </div>
      </div>
    </div> 
    `,
    dataType: "static",
    connection: {
      spreadsheetId: "",
      sheetName: "",
      connectionId: "",
    },
    id: "",
    params: [],
    filters: {},
    stylesList: [],
  },
  data: [
    {
      title: "Ugur Peker",
      subTitle: "Turkey",
      description: "Engineer & Photographer & Traveller",
      linkTo: "https://yahoo.com",
      backgroundImage:
        "https://images-na.ssl-images-amazon.com/images/I/81-QB7nDh4L.jpg",
      Extra_col_1: "SEO",
      Extra_col_2: "Paddle boards",
      Extra_col_3: "Marketing",
      Extra_col_4: "",
      filter: "SEO Paddle boards Marketing",
    },
    {
      title: "Julius Jansson",
      subTitle: "Finland",
      description:
        "Follow for snapshots and birds eye view from Northern Europe.",
      linkTo: "https://yahoo.com",
      backgroundImage:
        "https://images-na.ssl-images-amazon.com/images/I/81fcWvYdukL.jpg",
      Extra_col_1: "SEO",
      Extra_col_2: "Paddle boards",
      Extra_col_3: "Marketing",
      Extra_col_4: "",
      filter: "SEO Paddle boards Marketing",
    },
    {
      title: "Robynne Hu",
      subTitle: "Location",
      description: "Looking for beauty in the imperfect things",
      linkTo: "https://yahoo.com",
      backgroundImage: "https://m.media-amazon.com/images/I/41xXPYLVw1L.jpg",
      Extra_col_1: "SEO",
      Extra_col_2: "Paddle boards",
      Extra_col_3: "Marketing",
      Extra_col_4: "",
      filter: "SEO Paddle boards Marketing",
    },
  ],
  headings: [
    {
      text: "Heading Placeholder",
      styleProps: {
        color: "#ffffff",
        "text-align": "center",
        "font-size": "3rem",
        "line-height": "1",
        padding: "20px 10px 20px 10px",
      },
      stylePropsTab: {
        "font-size": "1.875rem",
        "line-height": "2.25rem",
      },
      stylePropsMob: {
        "font-size": "1.25rem",
        "line-height": "1.75rem",
      },
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      styleProps: {
        "text-align": "center",
        color: "#ffffff",
      },
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      styleProps: {
        "text-align": "center",
        color: "#ffffff",
      },
    },
  ],
};
