export const TEXT_ELEMENT_BLOCK = {
  blockType: "ELEMENTS",
  blockDesign: "TextElement",
  editFormName: "TEXT_ELEMENT_FORM",
  styleProps: {
    "background-color": "#1b1b31",
    "background-image": "url('')",
    padding: "20px 50px 20px 50px",
  },
  stylePropsTab: {},
  stylePropsMob: {},
  texts: {
    styleProps: {},
    stylePropsTab: {},
    stylePropsMob: {},
    list: [
      {
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        styleProps: {
          color: "white",
          "font-size": "20px",
        },
        stylePropsTab: {},
        stylePropsMob: {},
      },
    ],
  },
};
