export const CAROUSEL_ELEMENT_BLOCK = {
  blockType: "ELEMENTS",
  blockDesign: "CarouselElement",
  editFormName: "CAROUSEL_ELEMENT_FORM",
  styleProps: {},
  stylePropsDiv: {
    "margin-right": "auto",
    "margin-left": "auto",
    width: "50%",
    margin: "20px 20px 20px 20px",
  },
  alignSection: "center",
  list: [
    {
      image:
        "https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80",
    },
  ],
};
