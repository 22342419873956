

let d=[
  {
    title:"Senior Full Stack Developer",
    company:"ForwardThink",
    location:"Remote - USA",
    requirement:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.    Aliquam vel maximus enim, non semper dui.    Vivamus et hendrerit metus. Morbi vehicula porta massa, sed pellentesque est pretium et.",
    type:"Full Time",
    apply:"/job/1",
    image:"https://iili.io/HxOyhHQ.png"
  },
  {
    title:"Head of Marketing",
    company:"BrightSpark",
    location:"Lisbon",
    requirement:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.    Aliquam vel maximus enim, non semper dui.    Vivamus et hendrerit metus. Morbi vehicula porta massa, sed pellentesque est pretium et.",
    type:"Contract",
    apply:"/job/2",
    image:"https://iili.io/HxOyjRV.png"
  }
]



let sd={
  title:"Explore New Roles",
  description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam egestas lectus risus, ac porttitor metus tempus eu. Phasellus varius, ipsum et vehicula feugiat.",
}

let a={
  cards:{
    title:{text:"Explore New Roless", styleProps:{textAlign: 'center', color:' #333333', fontSize: '2.25rem', lineHeight: '2.5rem', margin: '40px 0px 20px'}},
    description:{text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam egestas lectus risus, ac porttitor metus tempus eu. Phasellus varius, ipsum et vehicula feugiat.", styleProps:{color: '#333333', fontSize: '1.25rem', lineHeight: '1.75rem',  margin: '10px 100px', textAlign: 'center'}},
    searchbar:{
      styleProps:{ display:'flex', backgroundColor: '#6f6f6f', borderRadius: '1.5rem', padding: '10px 20px 10px 10px', margin: '30px 0px'},
      search_input:{ 
        styleProps:{borderRadius: '9999px',  padding: '5px 20px', color: 'rgb(51, 51, 51)', fontWeight: 100, backgroundColor: 'rgb(255, 255, 255)', width: 'auto'}
      },
      search_button:{
        styleProps:{color: 'rgb(51, 51, 51)', padding: '5px 20px', backgroundColor: '#86cfc8', borderRadius: '1.5rem', margin: '0px 10px',        fontWeight: 'bold', width: 'auto'}
      },
      clear_button:{
        styleProps:{backgroundColor: 'rgb(236, 236, 236)',  color: 'rgb(51, 51, 51)', padding: '5px 20px', borderRadius: '9999px'}
      }
    },
    cards_per_row:{
      desktop:1,
      tab:1,
      mobile:1
    },
    card_template:{
      card_columns:[
        {
          styleProps:{width:'10%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start', padding:'0px 20px 0px 0px '} ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps:{width:'100%', height:"auto", borderRadius:'20px'} },
          ]
        },
        {
          styleProps:{width:'65%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 0px '} ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[ 
            {elementType:"headingEl", text:"[[title]]", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
            {elementType:"textEl", text:"[[company]]", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 10px 0px '} }, 
            {elementType:"textEl", text:"[[location]]", styleProps:{padding: '0px 0px px 0px', color: 'rgb(51, 51, 51)'} }, 
            {elementType:"textEl", text:"[[type]]", styleProps:{fontWeight: 700, margin: '10px 0px 0px 0px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
          ]
        },
        {
          styleProps:{width:'25%', display:'flex', flexDirection:'column', alignItems:'end', justifyContent:'start'} ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[              
            {elementType:"buttonEl", text:"Apply", url:"[[apply]]", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
          ]
        }          
      ],
    },
    card_items:[
      {        
        card_columns:[
          {
            styleProps:{width:'10%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start', padding:'0px 20px 0px 0px '} ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[              
              {elementType:"imageEl", src:"https://iili.io/HxOyhHQ.png", styleProps:{width:'100%', height:"auto", borderRadius:'20px'} },
            ]
          },
          {
            styleProps:{width:'65%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 20px '} ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Senior Full Stack Developer", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
              {elementType:"textEl", text:"ForwardThink", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 10px 0px '} }, 
              {elementType:"textEl", text:"Remote - USA", styleProps:{padding: '0px 0px px 0px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"Full Time", styleProps:{fontWeight: 700, margin: '10px 0px 0px 0px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
            ]
          },
          {
            styleProps:{width:'25%', display:'flex', flexDirection:'column', alignItems:'end', justifyContent:'start'} ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[              
              {elementType:"buttonEl", text:"Apply", url:"/job/1", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }          
        ],
      },
      {        
        card_columns:[
          {
            styleProps:{width:'10%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start', padding:'0px 20px 0px 0px '} ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[              
              {elementType:"imageEl", src:"https://iili.io/HxOyjRV.png", styleProps:{width:'100%', height:"auto", borderRadius:'20px'} },
            ]
          },
          {
            styleProps:{width:'65%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 20px '} ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Head of Marketingr", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
              {elementType:"textEl", text:"BrightSpark", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 10px 0px '} }, 
              {elementType:"textEl", text:"Lisbon", styleProps:{padding: '0px 0px px 0px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"Contract", styleProps:{fontWeight: 700, margin: '10px 0px 0px 0px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
            ]
          },
          {
            styleProps:{width:'25%', display:'flex', flexDirection:'column', alignItems:'end', justifyContent:'start'} ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[              
              {elementType:"buttonEl", text:"Apply", url:"/job/2", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }          
        ],
      },         
    ],
    styleProps:{backgroundColor:'#eeeeee', color:'#333333', padding:'80px 20px'},
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{display:'flex', padding:'20px', backgroundColor: 'rgb(246, 255, 255)', borderRadius: '1rem', color:'#000000', boxShadow:'4px 4px 2px rgba(0,0,0,0.1)'} 
    }
  }
}


export default {
  a, d, sd,
}
