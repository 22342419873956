let sd01={
  heading:"New Openings for {{Title}}",
  description:"Job Location: {{Location}}",
}
let a01={
  header:{
    "position":"",
    "heading":{
        "text":"{{Title}}",
        "styleProps":{
          "fontSize": "36px",
          "color": "#ffffff",
          "fontWeight": "bold",
          "font-size": "4.5rem",
          "line-height": "1",
          "margin": "0px 0px 50px 0px",
          "background-color": "#333333",
          "padding": "50px 50px 50px 50px",
          "text-align": "center"
        }
    },
    "description":{
        "text":"Job Location: {{Location}}",
        "styleProps":{
          "fontSize": "20px",
          "color": "#000000",
          "fontWeight": "500",
          "text-align": "center",
          "font-size": "1.875rem",
          "line-height": "2.25rem"
        }
    },
    "styleProps": {
      "padding": "50px 50px 50px 50px",
      "color": "#ffffff",
      "background-color": "#ececec",     
    }, 
  }
}

let sd02={
  heading:" {{Title}}",
  description:"{{location}} • {{date}}",
}
let a02={
  header:{
    "position":"",
    "heading":{
        "text":"{{Title}}",
        "styleProps":{
          "fontWeight": "bold",
          "fontSize": "28px",
          "color": "#1d1d1f",
          "font-size": "3.75rem",
          "line-height": "1",
          "font-weight": "700"
        }
    },
    "description":{
        "text":"{{location}} • {{date}}",
        "styleProps":{
          "color": "#6e6e73",
          "font-size": "3rem",
          "line-height": "1",
          "font-weight": "700",
          "margin": "20px 0px 0px 0px"
        }
    },
    "styleProps": {
      "padding": "50px 50px 50px 50px", 
      "backgroundGradient": "linear-gradient(to bottom right, #FFA500, #FF0000)",
      "background-color": "#f5f5f7"         
    }, 
  }
}

let sd03={
  heading:" {{Title}}",
  description:"{{location}} • {{date}}",
}
let a03={
  header:{
    "position":"",
    "heading":{
        "text": "{{name}}",
        "styleProps": {
          "fontWeight": "bold",
          "fontSize": "28px",
          "color": "#f5f5f5",
          "font-size": "3.75rem",
          "line-height": "1",
          "font-weight": "700"
        },
    },
    "description":{
        "text":"{{location}} • {{date}}",
        "styleProps": {
          "fontSize": "18px",
          "color": "#c3c3c3",
          "font-size": "1.25rem",
          "line-height": "1.75rem",
          "font-weight": "700",
          "margin": "20px 0px 0px 0px"
        },
    },
    "styleProps": {
      "padding": "30px 20px",
      "backgroundGradient": "linear-gradient(to bottom right, #FFA500, #FF0000)",
      "background-color": "#242424",
      "background-image": "linear-gradient(to bottom right,,)"   
    }, 
  }
}



export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
