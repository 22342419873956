import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import  Header  from "../parts/Header";
import  Footer  from "../parts/Footer";
import  Navbar  from "../parts/Navbar";
import  Cards  from "../parts/Cards";
import  TextElement  from "../parts/TextElement";
import  ButtonElement  from "../parts/ButtonElement";
import  ImageElement  from "../parts/ImageElement";

import  DataTypes  from "../parts/training/DataTypes";

import  header  from "./data/header";
import  footer  from "./data/footer";
import  navbar  from "./data/navbar";
import  cards  from "./data/cards";
import  headerDyn  from "./data/headerDyn";
import  textElement  from "./data/textElement";
import  buttonElement  from "./data/buttonElement";
import  imageElement  from "./data/imageElement";

import  dataTypes  from "./data/dataTypes";



import  Apply  from "../Apply";

const TestDesign = (props) => {
  let opts={}
  if(!opts.forWebsite) opts.forWebsite='Generative AI Developer Profile'
  const [isView, setView] = useState('header')
  const [isThemeOpen, setThemeOpen] = useState(false)
  const dispatch = useDispatch();
  const {
    generated={},
    pageInfo,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);   


  return (
    <div  className={twdClass("relative ")} >
      <div>  
        <div className={twdClass("flex justify-center mb-4")}><Apply {...props}/></div>
        <div className={twdClass("flex justify-center mb-24 gap-4")}> 
          <div onClick={()=>setView("headerDyn")}>HeaderDyn</div>  
          <div onClick={()=>setView("imageElement")}>ImageElement</div>  
          <div onClick={()=>setView("textElement")}>TextElement</div> 
          <div onClick={()=>setView("buttonElement")}>ButtonElement</div> 
          <div onClick={()=>setView("dataTypes")}>dataTypes</div>       
          <div onClick={()=>setView("navbar")}>Navbar</div>
          <div onClick={()=>setView("header")}>Header</div>
          <div onClick={()=>setView("cards1")}>Cards1</div>
          <div onClick={()=>setView("cards2")}>Cards2</div>
          <div onClick={()=>setView("footer")}>Footer</div>
        </div>
        {isView=='headerDyn' && <Header {...{header:headerDyn.a01, sdata:headerDyn.sd01, opts, design:true}}/>}
        {isView=='headerDyn' && <Header {...{header:headerDyn.a02, sdata:headerDyn.sd02, opts, design:true}}/>}
        {isView=='headerDyn' && <Header {...{header:headerDyn.a03, sdata:headerDyn.sd03, opts, design:true}}/>}
        {isView=='dataTypes' && <DataTypes {...{dataTypes:dataTypes.a01, data:dataTypes.d01, params:dataTypes.p01, opts, design:true}}/>}
        {isView=='dataTypes' && <DataTypes {...{dataTypes:dataTypes.a02, data:dataTypes.d02, params:dataTypes.p02, opts, design:true}}/>}
        {isView=='dataTypes' && <DataTypes {...{dataTypes:dataTypes.a03, data:dataTypes.d03, params:dataTypes.p03, opts, design:true}}/>}
        {isView=='imageElement' && <ImageElement {...{imageElement:imageElement.a01, sdata:imageElement.sd01, opts, design:true}}/>}
        {isView=='imageElement' && <ImageElement {...{imageElement:imageElement.a02, sdata:imageElement.sd02, opts, design:true}}/>}    
        {isView=='imageElement' && <ImageElement {...{imageElement:imageElement.a03, sdata:imageElement.sd03, opts, design:true}}/>}        
        {isView=='textElement' && <TextElement {...{textElement:textElement.a01, sdata:textElement.sd01, opts, design:true}}/>}
        {isView=='textElement' && <TextElement {...{textElement:textElement.a02, sdata:textElement.sd02, opts, design:true}}/>}
        {isView=='textElement' && <TextElement {...{textElement:textElement.a03, sdata:textElement.sd03, opts, design:true}}/>}
        {isView=='buttonElement' && <ButtonElement {...{buttonElement:buttonElement.a01, sdata:buttonElement.sd01, opts, design:true}}/>}
        {isView=='buttonElement' && <ButtonElement {...{buttonElement:buttonElement.a02, sdata:buttonElement.sd02, opts, design:true}}/>}
        {isView=='buttonElement' && <ButtonElement {...{buttonElement:buttonElement.a03, sdata:buttonElement.sd03, opts, design:true}}/>}

        {isView=='header' && <Header {...{header:header.a03, sdata:header.sd03, opts, design:true}}/>}
        {isView=='header' && <Header {...{header:header.a02, sdata:header.sd02, opts, design:true}}/>}
        {isView=='header' && <Header {...{header:header.a01, sdata:header.sd01, opts, design:true}}/>} 
        {isView=='footer' && <Footer {...{footer:footer.a01, sdata:footer.sd01, opts, design:true}}/>} 
        {isView=='footer' && <Footer {...{footer:footer.a02, sdata:footer.sd02, opts, design:true}}/>}
        {isView=='footer' && <Footer {...{footer:footer.a03, sdata:footer.sd03, opts, design:true}}/>}  
        {isView=='cards1' && <Cards {...{cards:cards.atest, sample:cards.asample, data:cards.dtest, sdata:cards.sdtest, opts, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c15.a,  sample:cards.asample,  data:cards.c15.d, sdata:cards.c15.sd, opts:cards.c15.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c14.a,  sample:cards.asample,  data:cards.c14.d, sdata:cards.c14.sd, opts:cards.c14.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c13.a,  sample:cards.asample,  data:cards.c13.d, sdata:cards.c13.sd, opts:cards.c13.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c12.a,  sample:cards.asample,  data:cards.c12.d, sdata:cards.c12.sd, opts:cards.c12.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c11.a,  sample:cards.asample,  data:cards.c11.d, sdata:cards.c11.sd, opts:cards.c11.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c10.a,  sample:cards.asample,  data:cards.c10.d, sdata:cards.c10.sd, opts:cards.c10.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c09.a,  sample:cards.asample,  data:cards.c09.d, sdata:cards.c09.sd, opts:cards.c09.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c08.a,  sample:cards.asample,  data:cards.c08.d, sdata:cards.c08.sd, opts:cards.c08.o, design:true}}/>}
        {isView=='cards2' && <Cards {...{cards:cards.c07.a,  sample:cards.asample,  data:cards.c07.d, sdata:cards.c07.sd, opts:cards.c07.o, design:true}}/>}
        {isView=='cards1' && <Cards {...{cards:cards.a06,  sample:cards.asample,  data:cards.d06, sdata:cards.sd06, opts, design:true}}/>}
        {isView=='cards1' && <Cards {...{cards:cards.a05,  sample:cards.asample,  data:cards.d05, sdata:cards.sd05, opts, design:true}}/>}
        {isView=='cards1' && <Cards {...{cards:cards.a04,  sample:cards.asample, data:cards.d04, sdata:cards.sd04, opts, design:true}}/>}    
        {isView=='cards1' && <Cards {...{cards:cards.a03,  sample:cards.asample, data:cards.d03, sdata:cards.sd03, opts, design:true}}/>}       
        {isView=='cards1' && <Cards {...{cards:cards.a01,  sample:cards.asample, data:cards.d01, sdata:cards.sd01, opts,  design:true}}/>} 
        {isView=='cards1' && <Cards {...{cards:cards.a02,  sample:cards.asample, data:cards.d02, sdata:cards.sd02, opts, design:true}}/>} 
        {isView=='navbar' && <Navbar {...{navbar:navbar.a01, sdata:navbar.sd01, opts, design:true}}/>}  
        {isView=='navbar' && <Navbar {...{navbar:navbar.a02, sdata:navbar.sd02, opts, design:true}}/>} 
        {isView=='navbar' && <Navbar {...{navbar:navbar.a03, sdata:navbar.sd03, opts, design:true}}/>}
      </div>      
    </div>
  );
};

export default TestDesign
