import { getDefaultFields } from "../common/common.fields";

export const HTML_FORM_1 = {
  sectionName: "HTML Section",
  elements: [
    {
      inputType: "select",
      label: "Position",
      updateTo: "position",
      options: [
        {
          value: "full",
          display: "Full",
        },
        {
          value: "left",
          display: "Left",
        },
        {
          value: "center",
          display: "Center",
        },
        {
          value: "right",
          display: "Right",
        },
      ],
    },
    {
      inputType: "textarea",
      label: "HTML",
      updateTo: "html",
      hasStyleProps: false,
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Hero",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
  ],
};
