import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";
import ToggleBtn from "./ui/ToggleBtn";


const Manifest = ({ sitesettings, updateSettings }) => {
  const updateItem = (value) => {
   // const sst = cloneDeep(sitesettings);
   // const data = set(sst, `manifest.${field}`, value);
    //updateSettings(data);
  };
  const updateField = (field, value) => {    
    const sst = cloneDeep(sitesettings);
    const data = set(sst, `manifest.${field}`, value);
    updateSettings(data);
  };

  return (
    <div>
      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md font-semibold mb-4")}>Manifest</div>
        <ToggleBtn
          toggleId={"showfilter"}
          value={sitesettings?.manifest?.isEnable}
          falseLabel={`Off`}
          trueLabel="On"
          updateValue={(value) => {
            //console.log("manifest", value)
            updateField("isEnable", value);
          }}
        />    
        <textarea
          rows={10}
          value={sitesettings?.manifest?.text}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateField('text', e.target.value)}
          placeholder = {`{ \n\t"background_color": "purple",\n\t"display": "fullscreen"\n}`}
        ></textarea>
      </div>
      <div className={twdClass("w-full flex items-center mb-2")}>        
        <div className={twdClass("w-1/4 text-sm ")}>File Type:</div>
        <div className={twdClass("w-3/4")}>
          <select
            value={sitesettings?.manifest?.fileType}
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {               
              updateField("fileType", e.target.value);
            }}
            
          >
            <option value="json">json</option>
            <option value="webmanifest">webmanifest</option>                      
          </select>            
        </div>
      </div>   
    </div> 
  );
};

export default Manifest;
