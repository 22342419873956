import React, { useState } from "react";
import { twdClass } from "../../constants/tailwind.constants";
import { ToolTip } from "../ui/tooltip_ui";
import { project_vs, tutorials_vs, customise_card_vs, connect_data_source_vs} from "./vs_data";

const ProjectTip = () => {  
  return (
    <ToolTip       
      {...project_vs}
      position={'right'}
      visualSteps='Project'
    />
  );
};

const TutorialsTip = () => {  
  return (
    <ToolTip 
      {...tutorials_vs}
      position={'right'}
      visualSteps='tutorials'
    />
  );
};
const CustomiseCardTip = () => {  
  return (
    <ToolTip       
      {...customise_card_vs}
      position={'right'}
      visualSteps='Project'
    />
  );
};
const ConnectDataSourceTip = () => {  
  return (
    <ToolTip       
      {...connect_data_source_vs}
      position={'right'}
      visualSteps='Project'
    />
  );
};

    
export {
  TutorialsTip,
  ProjectTip,
  CustomiseCardTip,
  ConnectDataSourceTip
}
