import { getDefaultFields } from "../common/common.fields";

export const NAVBAR_FORM_1 = {
  sectionName: "Nav Bar Section",
  elements: [
    {
      inputType: "group",      
      label: "Configure Navbar",
      hasStyleProps: false,
      elements: [        
        {
          inputType: "select",
          label: "Sticky",
          updateTo: "sticky",
          hasStyleProps: false,
          hideLabel: false,
          options: [
            {
              value: false,
              display: "Disable",
            },
            {
              value: true,
              display: "Enable",
            },            
          ],
        }, 
      ],
    },
    {
      inputType: "group",      
      label: "Logo",
      hasStyleProps: false,
      elements: [
        {
          inputType: "select",
          label: "Logo Type",
          updateTo: "logoType",
          hasStyleProps: false,
          hideLabel: true,
          options: [
            {
              value: "text",
              display: "Text",
            },
            {
              value: "image",
              display: "Image",
            },
            {
              value: "html",
              display: "HTML/SVG",
            },
          ],
        },
        {
          inputType: "text",
          label: "Logo Name",
          updateTo: "logoText.text",
          hasStyleProps: true,
          styleElementId: 1,
          hideLabel: true,
          showBasedElemVal: {
            elem: "logoType",
            value: "text",
          },
        },
        {
          inputType: "image",
          label: "Logo",
          updateTo: "logoImg.src",
          hasStyleProps: true,
          styleElementId: 2,
          hideLabel: true,
          showBasedElemVal: {
            elem: "logoType",
            value: "image",
          },
        },
        {
          inputType: "text",
          updateTo: "logoImg.alt",
          label: "Alt",
          defaultValue:"",
          hasStyleProps: false,
          showBasedElemVal: {
            elem: "logoType",
            value: "image",
          },
        },   
        {
          inputType: "textarea",
          label: "HTML/SVG",
          updateTo: "logoHTML.html",
          hasStyleProps: true,
          styleElementId: 2,
          hideLabel: true,
          showBasedElemVal: {
            elem: "logoType",
            value: "html",
          },
        },            
      ],
    },    
    {
      inputType: "group",
      label: "Logo Options",
      elements: [
        {
          inputType: "text",
          label: "Target",
          defaultValue: "/",
          updateTo: "logoUrl",
          hasStyleProps: false,
        },
        {
          inputType: "select",
          label: "Open Link",
          updateTo: "logoTarget",
          hasStyleProps: false,
          hideLabel: false,
          options: [
            {
              value: "sametab",
              display: "Same Tab",
            },
            {
              value: "newtab",
              display: "New Tab",
            },
          ],
        },
      ]
    },
    {
      inputType: "list",
      label: "Links",
      hasStyleProps: false,
      styleElementId: 3,
      updateTo: "links",
      mockElements: {
        hasStyleProps: false,
        styleElementId: 3,
        elements: [
          {
            inputType: "select",
            label: "Link Type",
            updateTo: "links[i].linkType",
            hasStyleProps: false,
            hideLabel: false,
            options: [
              {
                value: "default",
                display: "Default",
              },
              {
                value: "scroll_to_section",
                display: "Scroll to section",
              }
            ],
          },
          {
            inputType: "text",
            label: "Item Name",
            defaultValue: "Home",
            updateTo: "links[i].text",
            hasStyleProps: true,
            styleElementId: 3,
          }, 
          {
            inputType: "text",
            label: "Target",
            defaultValue: "/home",
            updateTo: "links[i].linkUrl",
            hasStyleProps: false,
            hideBasedElemVal: {
              elem: "links[i].linkType",
              value: "scroll_to_section",
            },
          },
          {
            inputType: "select",
            label: "Open Link",
            updateTo: "links[i].linkTarget",
            hasStyleProps: false,
            hideLabel: false,
            options: [
              {
                value: "sametab",
                display: "Same Tab",
              },
              {
                value: "newtab",
                display: "New Tab",
              },
            ],
            hideBasedElemVal: {
              elem: "links[i].linkType",
              value: "scroll_to_section",
            },
          },  
          {
            inputType: "text",
            label: "Section ID",
            defaultValue: "aboutsection",
            updateTo: "links[i].sectionID",
            hasStyleProps: false,
            showBasedElemVal: {
              elem: "links[i].linkType",
              value: "scroll_to_section",
            },
          },          
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Nav Section",
      elements: [
        { inputType: "section", label: "Section Configs" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundgradient", "backgroundimage", "padding", "margin"],
        }),
        { inputType: "section", label: "Hamburger Configs (for mobile view)" },
        ...getDefaultFields({
          type: "TEXT",
          hasStyleProps: false,
          updateTo: "hamburgerStyleProps",
          fields: ["bordercolor", "color"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Logo Text",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "logoText.styleProps",
          fields: ["color", "fontfamily", "fontsize", "fontweight"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Logo Image",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "logoImg.styleProps",
          fields: ["height2px", "width2px"],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Link",
      styleStates:true,     
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "links[i].styleProps",
          fields: [
            "color",
            "fontfamily",
            "fontsize",
            "fontweight",
            "padding",
            "margin",
            "bordercolor",
            "backgroundcolor",
            "borderradius",
          ],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Hamburger",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          hasStyleProps: false,
          updateTo: "hamburgerStyleProps",
          fields: ["bordercolor", "color"],
        }),
      ],
    },
  ],
};
