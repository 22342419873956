export const FEATURE_BLOCK_1 = {
  blockType: "FEATURES",
  blockDesign: "FeaturesDesign1",
  editFormName: "FEATURES_FORM_1",
  styleProps: {
    "background-color": "#1b1b31",
  },
  icon: {
    position: "top",
    styleProps: {},
  },
  headings: [
    {
      text: "Why Choose FlexBee?",
      styleProps: {
        color: "#ffffff",
        "text-align": "center",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
        padding: "10px 0px 10px 0px",
      },
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      styleProps: {
        color: "#ffffff",
        "text-align": "center",
        padding: "10px 0px 10px 0px",
      },
    },
  ],
  featuresList: [
    {
      textPrimary: "Lorem ipsum dolor sit amet",
      textPrimaryStyleProps: {},
      icon: "🧸",
      iconType: "emoji",
      textSec: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ],
      textSecStyleProps: {},
      styleProps: {
        "background-color": "#ffffff",
      },
    },
    {
      textPrimary: "Lorem ipsum dolor sit amet",
      textPrimaryStyleProps: {},
      icon: "🧸",
      iconType: "emoji",
      textSec: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ],
      textSecStyleProps: {},
      styleProps: {
        "background-color": "#ffffff",
      },
    },
    {
      textPrimary: "Lorem ipsum dolor sit amet",
      textPrimaryStyleProps: {},
      icon: "🧸",
      iconType: "emoji",
      textSec: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ],
      textSecStyleProps: {},
      styleProps: {
        "background-color": "#ffffff",
      },
    },
  ],
  featuresStyleProps: {
    padding: "10px 0px 10px 0px",
  },
  metaDataId: 1640947356484,
};
