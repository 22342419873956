export const FEATURE_BLOCK_2 = {
  blockType: "FEATURES",
  blockDesign: "FeaturesDesign2",
  editFormName: "FEATURES_FORM_2",
  styleProps: {
    "background-color": "",
    "background-image": "url('')",
    padding: "0px 20px 0px 20px",
  },
  sectionPerRow: {
    desktop: 3,
    tab: 2,
    mobile: 1,
  },
  sectionConfigs: {
    imagePosition: "left",
    imageAlign: "center",
    imageSectionStyleProps: {},
    imageStyleProps: {
      width: "100px",
      padding: "20px 20px 20px 20px",
      margin: "20px 20px 20px 20px",
      "background-color": "red",
      "border-radius": "50%",
    },
    headerTextStyleProps: {
      padding: "0px 0px 10px 0px",
      "text-align": "center",
      "font-size": "1.125rem",
      "line-height": "1.75rem",
    },
    bodyTextStyleProps: {},
    footerTextStyleProps: {
      padding: "20px 0px 0px 0px",
      "text-align": "right",
      "font-size": "1.125rem",
      "line-height": "1.75rem",
    },
    sectionStyleProps: {},
  },
  headings: [
    {
      text: "Lorem Ipsum is simply dummy text industry",
      styleProps: {
        "text-align": "center",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
        padding: "50px 0px 20px 0px",
      },
    },
    {
      text:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with",
      styleProps: {
        "text-align": "center",
        padding: "10px 0px 20px 0px",
      },
    },
  ],
  list: [
    {
      imageSrc:
        "https://d11lx8wl9i3fvs.cloudfront.net/1637339991272/1670751794116.png",
      headerText: "Lorem Ipsum is simply dummy text industry",
      bodyText:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with",
      footerText: "Lorem Ipsum is simply dummy text industry",
    },
    {
      imageSrc:
        "https://d11lx8wl9i3fvs.cloudfront.net/1637339991272/1670751813168.png",
      headerText: "Lorem Ipsum is simply dummy text industry",
      bodyText:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with",
      footerText: "Lorem Ipsum is simply dummy text industry",
    },
    {
      imageSrc:
        "https://d11lx8wl9i3fvs.cloudfront.net/1637339991272/1670751832967.png",
      headerText: "Lorem Ipsum is simply dummy text industry",
      bodyText:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with",
      footerText: "Lorem Ipsum is simply dummy text industry",
    },
  ],
};
