import { twdClass } from "../../constants/tailwind.constants";
import { PRICING_METADATA } from "./pricing.metadata";

const PB1 = twdClass(
  "relative text-center bg-blue-900 text-white shadow-lg hover:shadow-2xl p-3 rounded-md transform hover:scale-105 transition duration-200"
);
const PB2 = twdClass(
  "text-5xl flex justify-center items-center font-bold pb-4 tracking-tight"
);
const PBS1 = twdClass(
  "mx-3 w-full grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 lg:w-3/4"
);

export const PricingBlocks = ({ myPlan, updatePlan, plan_id }) => {
  return (
    <div className={twdClass("flex justify-center")}>
      <div className={PBS1}>
        {PRICING_METADATA.filter((item) => item.showInList).map((item) => {
          return (
            <PriceBlock
              item={item}
              myPlan={myPlan}
              updatePlan={updatePlan}
              plan_id={plan_id}
            />
          );
        })}
      </div>
    </div>
  );
};

const PBB = twdClass(
  "rounded bg-blue-600 py-2 w-3/4 text-white shadow-lg text-md text-sm font-bold"
);
const PBD = twdClass("opacity-50 cursor-not-allowed");
export const PriceBlock = ({ item, myPlan, updatePlan, plan_id }) => {
  const isCurrentPlan =
    item.planIdM === parseInt(plan_id) || item.planIdY === parseInt(plan_id);
  const disbaleBtn =
    isCurrentPlan && parseInt(plan_id) === parseInt(item["planId" + myPlan]);
  return (
    <div
      className={`${PB1} ${
        isCurrentPlan ? twdClass("border-blue-400 border-2") : ""
      }`}
    >
      {disbaleBtn && (
        <div className={twdClass("w-full absolute -mx-3 -top-3")}>
          <span
            className={twdClass(
              "text-black text-xs font-bold bg-blue-400 px-3 py-1 rounded"
            )}
          >
            Current Plan
          </span>
        </div>
      )}
      <h3 className={twdClass("text-2xl font-bold my-3")}>{item.planName}</h3>
      <h3 className={PB2}>
        <span className={twdClass("text-xl mr-1 mb-4")}>$</span>
        <span>{item[`planPrice${myPlan}`]}</span>
        <span className={twdClass("text-sm ml-1 mt-4 text-gray-300")}>{`${
          myPlan === "M" ? "/ month" : "/ year"
        }`}</span>
      </h3>
      {item.features.map((itm) => {
        return <div className={twdClass("py-1 text-sm")}>{itm}</div>;
      })}
      <div className={twdClass("my-3")}>
        {item.planType !== "base" && (
          <button
            disabled={disbaleBtn}
            className={`${PBB} ${
              disbaleBtn ? PBD : twdClass("hover:bg-blue-700")
            }`}
            onClick={() => updatePlan(item)}
          >
            Subscribe
          </button>
        )}
      </div>
    </div>
  );
};
