import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "./Common";
import {footer_prompt} from "../prompts/footer_prompt";

const Link = (link) => {
  const {text}= link
  return(<DivTag {...link}>{text}</DivTag>)
}

const Links = ({links}) => {
  let links_=links.map((link, i)=>{
    return(<Link key={i} {...link}/>)
  })
  return(<DivTag className={twdClass("")}>
    <div className={twdClass("flex items-center gap-2")}>{links_}</div>
  </DivTag>)
}

const Text = ({textEl}) => {
  const {text}= textEl
  return(<DivTag {...textEl}>{text}</DivTag>)
}

const Design2 = ({obj={}, opts={}, sample='', sdata={}}) => {  
  let user_prompt = footer_prompt({opts, sample, sdata }) 
  return (<Design {...{type:'footer', obj, user_prompt}}/>)
}

const Footer = ({footer={}, opts={}, design=false, sample='', sdata={}}) => {
  let obj=footer.footer ? footer.footer : footer
  const {logo={}, description={}, copy={}, terms={}, privacy={}, ...obj1} = obj
  
  return(
    <DivTag {...obj1} className={twdClass("relative mb-4 px-4 py-2")}>
      {design && <Design2 {...{design,  obj, opts, sample, sdata}}/>}
      <div className={twdClass("relative mx-auto")} style={{'max-width':'1536px'}}>
        <div className={twdClass("flex flex-col")}>
          <div >
            <Link {...logo}/>
            <Text {...{textEl:description}}/>          
          </div>
          <div className={twdClass("flex justify-between")}>
            <div >
              <Text {...{textEl:copy}}/>                  
            </div> 
            <div className={twdClass("flex-grow")}></div> 
            <div className={twdClass("flex items-center gap-4")}>
              <Link {...terms}/>   
              <Link {...privacy}/>                
            </div>            
          </div>
        </div>
      </div>
    </DivTag>
  )
}
export  default Footer