export const PRICING_BLOCK_1 = {
  blockType: "PRICING",
  blockDesign: "PricingDesign1",
  editFormName: "PRICING_FORM_1",
  styleProps: {
    "background-color": "#ffe1b0",
  },
  title1: {
    text: "OUR FLEXIBLE PRICES",
    styleProps: {
      color: "red",
    },
  },
  title2: {
    text: "Pricing Plans for Everyone",
    styleProps: {
      color: "blue",
    },
  },
  title3: {
    text: `Our plans were crafted specificially to help you succeed. Our
    flexible pricing model will help you gain the most out of our
    service.`,
  },
  plansList: [
    {
      title: { text: "Free" },
      subTitle: { text: "Starter Plan" },
      description: {
        text:
          "All the free features you need to get started creating your project",
      },
      features: [
        "Dashboard Access",
        "2 Team Members",
        "100 Free Api Calls",
        "Community Support",
      ],
      button: {
        text: "Get a Free Plan",
      },
    },
  ],
};
