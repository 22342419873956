import React, { useState } from "react";
import { twdClass } from "../../constants/tailwind.constants";
import { Button, BtnVS } from "../ui/com_ui";
import { ToolTip } from "../ui/tooltip_ui";
import { VisualSteps } from "../ui/visual_steps_ui";
import { project_vs, tutorials_vs, customise_card_vs, connect_data_source_vs, dynamic_cards_vs, dynamic_page_vs} from "./vs_data";


const ConnectDataSourceVs_play = () => {  
  return (
    <VisualSteps       
      {...connect_data_source_vs}  
    >
      <Button type={'default'} color={'secondary'}>
        <div className={twdClass("flex text-sm ")}><img alt="" width="20px" src="/assets/images/play-03-w.svg" /> <div className={twdClass("ml-2 text-md")}>Play Tutorial</div> </div>                 
      </Button>
    </VisualSteps>  
  );
};
const DynamicCardsVs_play = ({size="sm"}) => {  
  return (
    <VisualSteps       
      {...dynamic_cards_vs}  
    >
      <BtnVS size={size}/>
    </VisualSteps>  
  );
};
const DynamicPageVs_play = ({size="sm"}) => {  
  return (
    <VisualSteps       
      {...dynamic_page_vs}  
    >
      <BtnVS size={size}>Dynamic Page</BtnVS>
    </VisualSteps>  
  );
};

    
export {
  ConnectDataSourceVs_play,
  DynamicCardsVs_play,
  DynamicPageVs_play
}
