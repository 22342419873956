

let o={ 
  layout:{
    value:{
      desktop:3,
      tab:2,
      mobile:1
    }
  }
}


let d=[
  {
    title:"THE 4 BEST HOSTELS IN SINGAPORE",
    location:"Singapore",
    desc:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the",
    image:"https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1600",
    details_url:'/details/1',
  },
]



let sd={
  title:"Explore the top Best Hotels",
  description:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the world.",
}

let a={
  cards:{
    title:{text:"Explore New Roless", styleProps:{
      "fontSize": "2.25rem",
      "lineHeight": "2.5rem",
      "fontWeight": "700",
      "color": "#ffffff",
      "textAlign": "center",
      "padding": "0px 20px 10px 20px"
    }},
    description:{text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam egestas lectus risus, ac porttitor metus tempus eu. Phasellus varius, ipsum et vehicula feugiat.", styleProps:{
      "fontSize": "24px",
      "lineHeight": "2.25rem",
      "color": "#ffffff",
      "textAlign": "center",
      "opacity": 0.8,
      "padding": "10px 40px 20px 40px"
    }},
    searchbar:{
      styleProps:{
        "color":"#ffffff",
        "backgroundColor": "#000000",
        "padding": "20px 20px 20px 20px",
        "border-radius": "1rem"
      },
      search_input:{ 
        styleProps:{
          "color": "#000000",
          "backgroundColor": "#ffffff",
          "padding": "8px 20px",
          "margin": "0px 10px 0px 10px",
          "width": "auto",
          "borderRadius": "20px"
        }
      },
      search_button:{
        styleProps:{
          "color": "#000000",
          "backgroundColor": "#FFD700",
          "padding": "8px 20px",
          "borderRadius": "20px",
          "marginRight": "10px"
        }
      },
      clear_button:{
        styleProps: {
          "color": "#000000",
          "backgroundColor": "#FFD700",
          "padding": "8px 20px",
          "borderRadius": "20px"
        }
      }
    },
    cards_per_row:{
      desktop:3,
      tab:2,
      mobile:1
    },
    card_template:{      
      card_columns:[
        {
          styleProps:{
            display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start', 
            "padding": "10px 10px 10px 10px",
            "margin": "0px 0px 0px 0px",
            "width": "100%"
          } ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[  
            {elementType:"headingEl", text:"[[title]]", styleProps:{             
              "fontWeight": "600",
              "color": "#000000",
              "padding": "0px 0px 10px 0px",
              "font-size": "1.25rem",
              "line-height": "1.5rem",
              "margin": "0px 0px 10px 0px"
            } },            
            {elementType:"imageEl", src:"[[image]]", styleProps:{
              "objectFit": "cover",
              "borderRadius": "20px",
              "height": "100%",
              "width": "100%",
              "border-radius": "0px"
            }},            
            {elementType:"textEl", text:"[[location]]", styleProps:{
              "marginTop": "10px",
              "color": "#000000",
              "font-size": "1rem",
              "line-height": "1.5rem"
            }}
          ]
        },
        {
          styleProps:{
            display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', borderRadius:"20px",
            "padding": "20px 20px 20px 10px",
            "width": "100%"
          } ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[                           
            {elementType:"buttonEl", text:"See more", url:"[[details_url]]", styleProps:{
              "color": "#00b32d",
              "border-width": "0px",
              "padding": "0px 0px 0px 0px",
              "font-size": "1.25rem",
              "line-height": "1.75rem"
            }},
          ]
        }         
      ],
    },
    card_items:[   
      {       
        card_columns:[
          {
            styleProps:{
              display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start', 
              "padding": "10px 10px 10px 10px",
              "margin": "0px 0px 0px 0px",
              "width": "100%"
            } ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[  
              {elementType:"headingEl", text:"THE 4 BEST HOSTELS IN SINGAPORE", styleProps:{             
                "fontWeight": "600",
                "color": "#000000",
                "padding": "0px 0px 10px 0px",
                "font-size": "1.25rem",
                "line-height": "1.5rem",
                "margin": "0px 0px 10px 0px"
              } },            
              {elementType:"imageEl", src:"https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1600", styleProps:{
                "objectFit": "cover",
                "borderRadius": "20px",
                "height": "100%",
                "width": "100%",
                "border-radius": "0px"
              }},            
              {elementType:"textEl", text:"Singapore", styleProps:{
                "marginTop": "10px",
                "color": "#000000",
                "font-size": "1rem",
                "line-height": "1.5rem"
              }}
            ]
          },
          {
            styleProps:{
              display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', borderRadius:"20px",
              "padding": "20px 20px 20px 10px",
              "width": "100%"
            } ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[                           
              {elementType:"buttonEl", text:"See more", url:"/details/1", styleProps:{
                "color": "#00b32d",
                "border-width": "0px",
                "padding": "0px 0px 0px 0px",
                "font-size": "1.25rem",
                "line-height": "1.75rem"
              }},
            ]
          }         
        ],
      },              
    ],
    styleProps:{
      "backgroundColor": "#1a1a1a",
      "padding": "70px 20px",
      "backgroundImage": "linear-gradient(to right, #2ea3f2, #071b26)",
      "background-color": "#ececec",
      "background-image": "linear-gradient(to right,#7F7FD5,#91EAE4)"
    },
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{
        "padding": "12px 12px 12px 12px",
        "backgroundColor": "#f6cf73",
        "margin": "20px 0px 0px 0px",
        "color": "#1a1a1a",
        "display": "flex",
        "flex-wrap":"wrap",
        "marginBottom": "-20px",
        "width": "",
        "background-color": "#ffffff",
        "border-radius": "0px"
      } 
    }
  }
}


export default {
 o, a, d, sd,
}
