import { getDefaultFields } from "../common/common.fields";

const textStyle = (name) => {
  return [
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `${name}.styleProps`,
      fields: [
        "color",
        "fontsize",
        "fontweight",
        "fontfamily",
        "padding",
        "margin",
      ],
    }),
  ]
}

const imageStyle = (name) => {
  return [
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `${name}.styleProps`,
      fields: [
        "border",
        "bordercolor",
        "borderradius",
        "padding",
        "margin",
        "width-percent",
      ],
    }),
  ]
}

const columnStyle = (name) => {
  return [
    ...getDefaultFields({
      type: "TEXT",
      updateTo: name ? `${name}.styleProps` : `styleProps`,
      fields: [        
        "backgroundcolor",
        "border",
        "bordercolor",
        "borderradius",
        "color",
        "fontsize",
        "fontweight",
        "fontfamily",
        "padding",
        "margin",
        "width-percent",
        "editall"
      ],
    }),
  ]
}

const sm_list=(name="sm", styleElementId, imageStyleId)=>{
  return {
    inputType: "list",
    label: "Social Media",
    hasStyleProps: false,    
    updateTo: `${name}.links`,
    mockElements: {
      hasStyleProps: false,
      elements: [
        {
          inputType: "text",
          updateTo: `${name}.links[i].label.text`,
          label: "Link Label",
          defaultValue: "Company",
          hasStyleProps: true,
          styleElementId      
        },
        {
          inputType: "text",
          updateTo: `${name}.links[i].url`,
          label: "Link Url",
          defaultValue: "",
          hasStyleProps: false               
        }, 
        {
          inputType: "image",
          label: "Image",
          updateTo: `${name}.links[i].image.text`,
          hasStyleProps: true,
          styleElementId :imageStyleId,
          hideLabel: true        
        }             
      ]
    },
    elements: [],
  } 
}
const links_list=(name, styleElementId)=>{
  return {
    inputType: "list",
    label: "Links",
    hasStyleProps: false,
    updateTo: `${name}.links`,
    mockElements: {
      hasStyleProps: false,
      elements: [
        {
          inputType: "text",
          updateTo: `${name}.links[i].label.text`,
          label: "Link Label",
          defaultValue: "Company",
          hasStyleProps: true,
          styleElementId
        },
        {
          inputType: "text",
          updateTo: `${name}.links[i].url`,
          label: "Link Url",
          defaultValue: "",
          hasStyleProps: false,                
        },              
      ],
    },
    elements: [],
  } 
}

export const FOOTER_FORM_2 = {
  sectionName: "Footer Section",
  elements: [    
    {
      inputType: "group",      
      label: "Column1",
      hasStyleProps: true,
      styleElementId: 30,
      elements: [        
        {
          inputType: "text",
          updateTo: "company.name.text",
          label: "Name",
          defaultValue:"PromoSite",
          hasStyleProps: true,
          styleElementId: 301,
        },  
        {
          inputType: "text",
          updateTo: "company.copy.text",
          label: "Copy",
          defaultValue:"PromoSite 2023. All rights reserved.",
          hasStyleProps: true,
          styleElementId: 302,
        },
        {
          inputType: "simplelist",
          updateTo: "company.description.list",
          label: "Description",
          defaultValue: [
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          ],
          hasStyleProps: true,
          styleElementId: 303,
        },
        {
          inputType: "image",
          label: "Image",
          updateTo: "company.logo.text",
          hasStyleProps: true,
          styleElementId: 304,
          hideLabel: true,
          /*showBasedElemVal: {
            elem: "imageType",
            value: "image",
          },*/
        },
        {
          inputType: "text",
          updateTo: "company.logo.alt",
          label: "Alt",
          defaultValue:"",
          hasStyleProps: false
        },
      ],
    },
    {
      inputType: "group",      
      label: "Column 2",
      hasStyleProps: true,
      styleElementId: 70,
      elements: [
        {
          inputType: "text",
          updateTo: "column1.title.text",
          label: "Title",
          defaultValue: "Quick Links",
          hasStyleProps: true,
          styleElementId: 701,
        },
        links_list("column1", 702)        
      ],
    },
    {
      inputType: "group",      
      label: "Column 3",
      hasStyleProps: true,
      styleElementId: 80,
      elements: [
        {
          inputType: "text",
          updateTo: "column2.title.text",
          label: "Title",
          defaultValue: "Get in touch",
          hasStyleProps: true,
          styleElementId: 801,
        },
        links_list("column2", 802)               
      ],
    },    
    {
      inputType: "group",      
      label: "Column 4",
      hasStyleProps: true,
      styleElementId: 90,
      elements: [
        {
          inputType: "text",
          updateTo: "column4.title.text",
          label: "Title",
          defaultValue: "Connect",
          hasStyleProps: true,
          styleElementId: 901,
        },
        {
          inputType: "select",
          label: "Type",
          hasStyleProps: false,
          updateTo: "column4.type",
          options: [
            { display: "Only Icon", value: "only_icon"},
            { display: "Only Text", value: "only_text"},
            { display: "Icon Text", value: "icon_text"},
          ],
        },        
        sm_list("column4", 902, 903)               
      ],
    },
    {
      inputType: "group",      
      label: "Bottom Links",
      hasStyleProps: true,
      styleElementId: 20,
      elements: [
        {
          inputType: "text",
          updateTo: "bottom.title.text",
          label: "Title",
          defaultValue: "Bottom Links",
          hasStyleProps: true,
          styleElementId: 201,
        },
        links_list("bottom_links", 202)        
      ],
    }, 
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Features Body",
      elements: columnStyle("")
    }, 

    {
      styleElementId: 30,
      label: "Column Style",
      elements: columnStyle("company")
    },
      {
        styleElementId: 301,
        label: "Title Style",
        elements: textStyle("company.name")
      },
      {
        styleElementId: 302,
        label: "Copy Style",
        elements: textStyle("company.copy")
      },
      {
        styleElementId: 303,
        label: "Description Style",
        elements: textStyle("company.description")
      },
      {
        styleElementId: 304,
        label: "Logo Style",
        elements: imageStyle("company.logo")
      },     

    {
      styleElementId: 40,
      label: "Name Style",
      elements: textStyle("company.name")
    },
      {
        styleElementId: 41,
        label: "Title Style",
        elements: textStyle("column1.title")
      },
      {
        styleElementId: 42,
        label: "Title Style",
        elements: textStyle("column2.title")
      },
      {
        styleElementId: 43,
        label: "Title Style",
        elements: textStyle("column4.title")
      },

    {
      styleElementId: 70,
      label: "Column Style",
      elements: columnStyle("column1")
    },
      {
        styleElementId: 701,
        label: "Title Style",
        elements: textStyle("column1.title")
      },
      {
        styleElementId: 702,
        label: "Label Style",
        elements: textStyle("column1.links[i].label")
      }, 

    {
      styleElementId: 80,
      label: "Column Style",
      elements: columnStyle("column2")
    },
      {
        styleElementId: 801,
        label: "Title Style",
        elements: textStyle("column2.title")
      },
      {
        styleElementId: 802,
        label: "Label Style",
        elements: textStyle("column2.links[i].label")
      }, 

    {
      styleElementId: 90,
      label: "Column Style",
      elements: columnStyle("column4")
    },
      {
        styleElementId: 901,
        label: "Title Style",
        elements: textStyle("column4.title")
      },
      {
        styleElementId: 902,
        label: "Label Style",
        elements: textStyle("column4.links[i].label")
      },  
      {
        styleElementId: 903,
        label: "Image Style",
        elements: textStyle("column4.links[i].image")
      }, 

    {
      styleElementId: 20,
      label: "Column Style",
      elements: columnStyle("bottom_links")
    },
      {
        styleElementId: 201,
        label: "Title Style",
        elements: textStyle("bottom_links.title")
      },
      {
        styleElementId: 202,
        label: "Label Style",
        elements: textStyle("bottom_links.links[i].label")
      }, 
  ],
};
