import { 
  connect_supabase_vs,
  connect_google_vs 
} from "./vs_data_com";

const dynamic_cards_vs= { 
  id:8,
  title:"Dynamic Cards" ,
  desc:"The pre-built blocks have a card section. Simply select and place it on the block like any other block. The cards can have dynamic as well as static data and cen be customised as shown in the next sections.",
  steps:[
    {t:"Build Dynamic Cards using data source.", 
      img:'8_dc_00.jpg', lx:'6%', ly:'39%',
      note:'First you need to connect data source.',
      vs:[connect_google_vs, connect_supabase_vs],
      links_bk:[
        {t:'Support', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
        {t:'Connect', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'}
      ]},
    {t:"Click on 'Style' button to style the cards section.", 
      img:'8_dc_01.jpg', lx:'94%', ly:'31%',
    },
    {t:"Configure the cards section background color.", 
      img:'8_dc_01a.jpg', lx:'64%', ly:'77%',
    },
    {t:"Click 'Configure Headings' to change & Style the headings.", 
      img:'8_dc_01b.jpg', lx:'30%', ly:'47%',
    },
    {t:'In Configure data source section, Select the data source type -> Google Spreadsheet.', 
      img:'8_dc_02.jpg', lx:'70%', ly:'55%',
    },
    {t:'Select the Google Sheet.', 
      img:'8_dc_02.jpg', lx:'70%', ly:'62%',
    },
    {t:'Select the Sheet.', 
      img:'8_dc_02.jpg', lx:'70%', ly:'70%',
    },
    {t:"Click 'Sync/Refresh Data'", 
      img:'8_dc_02.jpg', lx:'65%', ly:'81%',
    },
    {t:"Click 'Design & Edit Cards'.", 
      img:'8_dc_01c.jpg', lx:'80%', ly:'73%',
    },  
    {t:"Click 'Style' icon to configure Card style.", 
      img:'8_dc_01d.jpg', lx:'44%', ly:'21%',
    }, 
    {t:"Set the card background color, border radius, shadow etc.", 
      img:'8_dc_01d.jpg', lx:'74%', ly:'31%',
    }, 
    {t:"Click 'Add Elements' button.", 
      img:'8_dc_01e.jpg', lx:'44%', ly:'31%',
    },  
    {t:"Select element type 'Text'.", 
      img:'8_dc_01e.jpg', lx:'75%', ly:'45%',
    },  
    {t:"Select Map Column.", 
      img:'8_dc_01e.jpg', lx:'75%', ly:'54%',
    }, 
    {t:"Click on 'Style' button to style the element.", 
      img:'8_dc_01e.jpg', lx:'94%', ly:'64%',
    },
     {t:"Click 'Add Elements' button.", 
      img:'8_dc_01f.jpg', lx:'34%', ly:'17%',
    },  
    {t:"Select element type 'Image'.", 
      img:'8_dc_01f.jpg', lx:'78%', ly:'28%',
    },  
    {t:"Select Map Column.", 
      img:'8_dc_01f.jpg', lx:'70%', ly:'40%',
    },
    {t:"Click on 'Style' button to style the element.", 
      img:'8_dc_01f.jpg', lx:'94%', ly:'48%',
    },   
    {t:"Click on 'Filter & Search' button.", 
      img:'8_dc_03.jpg', lx:'27%', ly:'42%',
    },
    {t:"Click on 'Filter panel' -> show button.", 
      img:'8_dc_03.jpg', lx:'88%', ly:'46%',
    },
    {t:"Click on 'Add Category' button.", 
      img:'8_dc_03.jpg', lx:'85%', ly:'65%',
    },
    {t:"Change category label.", 
      img:'8_dc_03a.jpg', lx:'25%', ly:'29%',
    },
    {t:"Change Filter values.", 
      img:'8_dc_03a.jpg', lx:'25%', ly:'55%',
    },                   
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}

    
export {  
  dynamic_cards_vs
}
