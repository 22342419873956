import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED,
  UPDATED_CONNECTIONS
} from "../../../constants/action.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { detectGoogleSheetId } from "../../../utils/common.utils";
import { checkSheetAccess, addNewConnection, loadConnections} from "../../../api/services";
import { toast } from "react-toastify";
import {Button} from "../../ui/com_ui";
import { getTimeForUniqID, getTimestamp} from "../transform/t_util";

import styled from "styled-components";
import {get, set, cloneDeep, isEmpty} from 'lodash'




const CreateGoogleSheetConnection = ({settingsData={}, isForm={}, updateForm, updateSettingsData, setConnectionType}) => {
  const dispatch = useDispatch();
  const {
    pageInfo,
    generated,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);  

  const [connection, setConnection] = useState({
    connection_type: "googleSheet",
    connection_details: {
      spreadsheetId: "",
      displayName: "",
    },
  });

  const [isGotData, setGotData] = useState(false);
 
  const addConnection = async () => {
    const data = {
      ...connection, 
      connection_details:{
        ...connection.connection_details,
        spreadsheetId:isForm.spreadsheetId,
        displayName:`Generated_google_sheet_${getTimeForUniqID()}`
      },
      project_id
    }
    console.log("data", data)
   const result = await checkSheetAccess({
      spreadsheetId: isForm.spreadsheetId,
    });
    if (result && result.success) {
      addNewConnection(data).then((resp) => {
        if (resp.success) {
          console.log("resp", resp)
          toast.success("Connection added to project");
          //updateConnection("connection_details.spreadsheetId", "");
          //updateConnection("connection_details.displayName", "");
          //setCurrentPanel("main");
          if(resp.data[0]){
            let con=resp.data[0]
            setGotData(con)
            //setConnection(con)
            reloadConnections()                    
          }
        }
      });
    } else {
      toast.error(
        "Sheet does not have permission to access, Please make it public."
      );
    }
  };
  
  const reloadConnections = async () => {
    try {
      const resp = await loadConnections(project_id);
      dispatch({ type: UPDATED_CONNECTIONS, data: resp.data });
    } catch (error) {}
  };

  useEffect(() => { 
    //console.log("settings.sheetColumns", settings.sheetColumns)
    if(isGotData){
      console.log("isGotData", isGotData)
      setConnectionType(isGotData.connection_type)
      updateSettingsData("triggerFrom", "createGoogleSheetConnection")
      updateSettingsData("connectionId", isGotData.connection_id)   
      updateForm("spreadsheetId", "") 
      setTimeout(()=>{ 
        updateSettingsData("connectionId", isGotData.connection_id) 
      }, 3000)  
      setTimeout(()=>{ 
        setGotData(false)
      }, 10000)
    }      
  }, [isGotData]);

  const btnEnable = isEmpty(isForm.spreadsheetId);

  return(
    <div className={twdClass("relative flex items-center")}>
      <div className={twdClass("flex-grow")}>
        <input
          type="text"
          placeholder="Google Sheet Url/ID"
          value={isForm.spreadsheetId}
          className={twdClass(INPUT_TEXT)}
          onChange={(e) => {
            const id = detectGoogleSheetId(e.target.value);
            updateForm('spreadsheetId', id);
          }}
        />
        {isGotData.connection_id && <div  className={twdClass("bg-primary-200 font-semibold px-4 py-2 rounded-xl mt-2")}>
          <span  className={twdClass("text-secondary-500")}>Connection Created</span> : {isGotData.connection_details.displayName}
        </div>}
      </div>
      <div className={twdClass("text-right ml-4")}>
        <Button
          type="default"  color="black" className={'inline-block'}            
          disabled={btnEnable}
          size={'md'} 
          onClick={addConnection}
        >
          {connection.connection_id ? "Save" : "Add"}  Connection
        </Button>
      </div>
    </div>
  )
}
export  default CreateGoogleSheetConnection