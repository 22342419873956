import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";

const ExternalJS = ({ sitesettings, updateSettings }) => {
  const updateItem = (key, value) => {
    const sst = cloneDeep(sitesettings);
    const data = set(sst, key, value);
    updateSettings(data);
  };

  return (
    <div>
      <div className={twdClass("text-md text-2xl font-semibold mb-8")}>
          External Javascript         
      </div>
      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md  mb-4")}>         
        Placed <span className={twdClass('font-semibold')}> after </span> body open tag
        </div>
        <textarea
          rows={10}
          value={sitesettings.externalJS}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateItem("externalJS", e.target.value)}
          placeholder = {`<script> \n\r\t console.log{ 'init'} \n\r</script>`}
        ></textarea>
      </div>
      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md  mb-4")}>          
          Placed <span className={twdClass('font-semibold')}>before </span> body close  tag
        </div>
        <textarea
          rows={10}
          value={sitesettings.externalBodyCloseJS}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateItem("externalBodyCloseJS", e.target.value)}
          placeholder = {`<script> \n\r\t console.log{ 'init'} \n\r</script>`}
        ></textarea>
      </div>
    </div>
  );
};

export default ExternalJS;
