import StudioLayout from "../components/studio/StudioLayout";
import { useParams } from "react-router-dom";
import { decodeLowerCaseId } from "../utils/hashid.utils";
import { useEffect } from "react";
import { getProjectInfo, getPageJSONData } from "../api/services";
import { useDispatch } from "react-redux";
import {
  UPDATE_PROJECT_INFO,
  RESET_PROJECT_DETAILS,
  UPDATE_PROJECT_PAGE,
  SELECT_PAGE_ID,
} from "../constants/action.constants";

const StudioPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const project_id = decodeLowerCaseId(id);
    fetchProjectInfo(project_id);
    return () => {
      dispatch({ type: RESET_PROJECT_DETAILS });
    };
  }, []);

  const fetchProjectInfo = async (project_id) => {
    const { data } = await getProjectInfo(project_id);
    // console.log(projInfo);
    dispatch({ type: UPDATE_PROJECT_INFO, data });
    if (data.pages && data.pages.length > 0) {
      const pageInfo = await getPageJSONData({
        project_id,
        page_id: data.pages[0].page_id,
      });
      if (pageInfo.success) {
        // console.log(pageInfo.data);
        dispatch({ type: UPDATE_PROJECT_PAGE, data: pageInfo.data });
        dispatch({ type: SELECT_PAGE_ID, id: data.pages[0].page_id });
      }
    }
  };

  return (
    <div>
      <StudioLayout />
    </div>
  );
};

export default StudioPage;
