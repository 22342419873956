import { get, forEach} from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { AddItemBtn, DeleteItemBtn} from "../../ui/presets";
import  InputText  from "./InputText";
import { getUpToWith, Device} from "./util";
import Expand from "./parts/Expand";


const EditAll = ({ element, data, updateElementValue }) => {
  const [device, setDevice] = useState("");
  const [value, setValue] = useState();
  const [isAddProp, setAddProp] = useState({property:''});
  const { updateTo, defaultValue } = element;

  let {
    styleElementPart= ''
  } = useSelector((state) => state.studioDetails);  

  useEffect(() => {
    const dataValue = get(data, updateTo);
    const val = dataValue ? dataValue : defaultValue;
    let value2_=[]
    forEach(val, function(mv, mk) {    
      value2_.push({mv, mk})    
    });
    setValue(value2_);
  }, [get(data, updateTo)]);


  useEffect(() => {
    //console.log("EditAll", get(data, updateTo))
  }, [get(data, updateTo)]);

  if(!styleElementPart) return false
  if(styleElementPart!='editall') return false
  
  const deleteElement = (prop)=> {
    const dataValue = get(data, updateTo);
    delete dataValue[prop];
    console.log(prop, dataValue[prop], dataValue)
    updateElementValue(updateTo, dataValue);
    let value2_=[]
    forEach(dataValue, function(mv, mk) {    
      value2_.push({mv, mk})    
    });
    setValue(value2_);
    /*setList((state) => {
      const cList = clone(state);
      cList.splice(index, 1);
      return cList;
    });*/
  }

  

  const el=({mv, mk})=>{    
    const updateTo2=`${updateTo}.${mk}`
    const upToWith= getUpToWith({prop:mk, updateTo:updateTo2, device})
    const baseVal = get(data, updateTo2)
    const thisVal = get(data, upToWith)
    const c = (baseVal==thisVal || !thisVal) ? 'border-transparent' : 'border-green-300'  
    //console.log("EditAll upToWith", mk, device, updateTo, updateTo2, upToWith)
    return(
      <div className={twdClass(`flex flex-row items-center w-full gap-1 `)}> 
        <div className={twdClass(`font-semibold text-xs w-20 mr-2`)}>{mk}</div>
        <div className={twdClass(`flex-grow w-full ${c} border-2 rounded-lg`)}>
          <InputText {...{
            element:{            
              label:mk,
              name:mk,        
              updateTo:upToWith
            },
            data, updateElementValue: (k, v) =>{          
              //console.log(k, v);
              //updateElementValue(k, v)
              //console.log("EditAll", mk, device, updateTo,updateTo2, upToWith, v)
              updateElementValue(upToWith, v); 
            }         
          }}/>
        </div>
        <div className={twdClass(`w-8 ml-4`)}>
          <DeleteItemBtn type={'icon'} cType={""} size={'xs'} onClick={() => {deleteElement(mk)}} />
        </div>  
      </div>
    )
  }
  const addNewElement = ()=> {
    if(!isAddProp.property) return
    let out=[...value]
    out.push({mv:'', mk:isAddProp.property}) 
    setValue(out);
    updateElementValue(`${updateTo}.${isAddProp.property}`, '');     
    setAddProp({property:''});     
    //console.log("out", isAddProp, out)
  }
  const aadEl=()=>{  
    return(
      <div className={twdClass(`flex flex-row items-center w-full gap-1 bg-gray-200 mt-4 px-2 py-2 rounded-lg`)}> 
        <div className={twdClass(`font-semibold text-xs w-20 mr-2`)}>
          Property
        </div>
        <div className={twdClass(`flex-grow w-full`)}>         
          <InputText {...{
            element:{            
              label:'Property',
              name:'property',        
              updateTo:'property',
              defaultValue:''
            },
            data:isAddProp, updateElementValue: (k, v) =>{ 
              //updateElementValue(v, ''); 
              setAddProp({property:v})
            }         
          }}/>
        </div>
        <div className={twdClass(`w-8 ml-4`)}>
          <AddItemBtn type={'icon'} cType={""} size={'xs'} onClick={() => {addNewElement()}} />
        </div>    
      </div>
    )
  }

  let out=[]
  forEach(value, function({mv, mk}, i) {    
    out.push( el({mv, mk}))    
  });

  return(
    <div  className={twdClass(`relative flex flex-col border border-gray-200 px-2 py-2 rounded-lg`)}>   
      <div className={twdClass(`relative flex items-center py-2`)}>
        <div className={twdClass(`font-semibold mr-2`)}>Edit All</div>
        <div className={twdClass("flex flex-grow")}>            
            <Device {...{device, setDevice}}/>
        </div>  
      </div>       
      <div   className={twdClass(`flex flex-col gap-1 w-full`)}>        
        {out}
        {aadEl()}
      </div>  
    </div>
  ) 
  return(
    <div  className={twdClass(`relative flex flex-col`)}>      
      <Expand {...{
        content:(<div   className={twdClass(`flex flex-col gap-1 w-full`)}>
          {out}
          {aadEl()}
        </div>),
        label:'Edit All',
        content_top:(<div className={twdClass("absolute flex top-2 right-0 mr-10")}>            
            <Device {...{device, setDevice}}/>
        </div>)
      }}/>     
    </div>
  ) 
};

export default EditAll;
