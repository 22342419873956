const TAILWIND_PREFIX = process.env.REACT_APP_TAILWIND_PREFIX;

const TWDPseudos = [
  "hover:",
  "focus:",
  "active:",
  "group-hover:",
  "group-focus:",
  "focus-within:",
  "motion-safe:",
  "motion-reduce:",
  "disabled:",
  "visited:",
  "checked:",
  "first:",
  "last:",
  "odd:",
  "even:",
  "sm:",
  "md:",
  "lg:",
  "xl:",
  "2xl:",
];

export const twdClass = (classNames) => {
  if (classNames) {
    return classNames
      .split(" ")
      .map((item) => {
        const name = item.trim();
        let pseudoCls = name.split(":")[0] + ":";
        if (TWDPseudos.indexOf(pseudoCls) !== -1) {
          return name.replace(pseudoCls, `${pseudoCls}${TAILWIND_PREFIX}`);
        }
        return `${TAILWIND_PREFIX}${name}`;
      })
      .join(" ");
  }
  return classNames;
};
