import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, getParams, styles_, links_} from "./t_util";

import buttonElement from '../TestDesign/data/buttonElement'

const process_=({obj, d})=>{
  //console.log("t_buttons", d)
  let {buttons={}}=d
  obj.buttons=buttons
  obj.texts=styles_({obj:obj.buttons, el:buttons})  
  if(obj.buttons?.styleProps) obj.buttons.styleProps.margin='0px auto'
  return obj
}

const buttonElement_ = ({generated={}, obj=false}) => {
  let d = obj ? obj : buttonElement.a01.buttonElement
  let uid=  getTimeForUniqID()
  //console.log("textElement", d.styleProps)
  //console.log("getTimeForUniqID", uid)
  if(!d.styleProps) d.styleProps={}
  d.styleProps.display='flex'
  d.styleProps.width='100%'
  d.styleProps.margin='0px auto'

  let out={   
    "metaDataId": uid,
    "blockId": uid,
    "blockType": "ELEMENTS",
    "blockDesign": "ButtonElement",
    "editFormName": "BUTTONS_ELEMENT_FORM",
    "styleProps": {},
    "stylePropsTab": {},
    "stylePropsMob": {},    
    "buttons": { 
        "styleProps": {
            "margin": "0px",
            "padding": "10px",
            "background-color": "#1b1b31",      
        },
        "stylePropsTab": {},
        "stylePropsMob": {},
        "list": [
          {
            "text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            "styleProps": {
              "color": "black",
              "font-size": "20px"
            },
            "stylePropsTab": {},
            "stylePropsMob": {}
          }
        ],
    }
  }
  out=process_({obj:out, d}) 
  out=styles_({obj:out, el:d})
  //out=styles_({obj:out, el:d})
  //out.links=links_({links:d.links})
  return out
};

export default buttonElement_;

