import { GoogleSheetForm } from "./GoogleSheetForm";
import { twdClass } from "../../../constants/tailwind.constants";
import CustomSelectBox from "../../common/CustomSelectBox";
import { useState, useEffect } from "react";
import { SupabaseConnection } from "./SupabaseConnection";
import { CONNECTIONS_METADATA } from "./connection.metadata";
import { useSelector } from "react-redux";
import { find } from "lodash";

export const AddConnectionPanel = ({ selectConnect, setCurrentPanel }) => {
  const [connectionValue, setConnectionValue] = useState("googleSheet");
  const {
    projectData: { connections },
  } = useSelector((state) => state.studioDetails);

  useEffect(() => {
    const val = find(connections, ["connection_id", selectConnect]);
    if (val) {
      setConnectionValue(val.connection_type);
    }
  }, []);

  return (
    <div className={twdClass("w-full")}>
      {selectConnect === null && (
        <div className={twdClass("w-2/3 flex items-center mb-6 -mt-14 ml-2")}>
          <div className={twdClass("w-1/3 text-sm font-semibold")}>
            Connection Type <span className={twdClass("text-red-600")}>*</span>
          </div>
          <div className={twdClass("w-2/3")}>
            <CustomSelectBox
              value={connectionValue}
              options={CONNECTIONS_METADATA}
              updateValue={(value) => {
                setConnectionValue(value);
              }}
            />
          </div>
        </div>
      )}
      {connectionValue === "googleSheet" && (
        <GoogleSheetForm
          selectConnect={selectConnect}
          setCurrentPanel={setCurrentPanel}
        />
      )}
      {connectionValue === "supabase" && (
        <SupabaseConnection
          selectConnect={selectConnect}
          setCurrentPanel={setCurrentPanel}
        />
      )}
    </div>
  );
};
