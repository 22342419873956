import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faDatabase,
  faDesktop,
  faFileAlt,
  faMobileAlt,
  faPaintRoller,
  faTabletAlt,
  faThList,
  faTimes,
  faTimesCircle,
  faPlusCircle,
  faPlus,
  faGlobe,
  faSave,
  faWifi,
  faExternalLinkAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPalette,
  faLayerGroup,
  faTrashAlt,
  faCircleNotch,
  faLink,
  faAngleDown,
  faAngleUp,
  faInfoCircle,
  faTrash,
  faCopy,
  faGlobeAmericas,
  faEdit,
  faArrowRight,
  faArrowLeft,
  faSync,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faPencilAlt,
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";

const ICONS = {
  circle: faTimesCircle,
  cross: faTimes,
  coffee: faCoffee,
  cog: faCog,
  database: faDatabase,
  desktop: faDesktop,
  filealt: faFileAlt,
  mobile: faMobileAlt,
  paintroller: faPaintRoller,
  tablet: faTabletAlt,
  thlist: faThList,
  plus: faPlusCircle,
  plusN: faPlus,
  globe: faGlobe,
  save: faSave,
  wifi: faWifi,
  link: faExternalLinkAlt,
  links: faLink,
  angledoubleleft: faAngleDoubleLeft,
  angledoubleright: faAngleDoubleRight,
  palette: faPalette,
  layergroup: faLayerGroup,
  trashalt: faTrashAlt,
  circleSpinner: faCircleNotch,
  angleDown: faAngleDown,
  angleUp: faAngleUp,
  infoCircle: faInfoCircle,
  trash: faTrash,
  copy:faCopy,
  globameric: faGlobeAmericas,
  edit: faEdit,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  arrowAltDown: faLongArrowAltDown,
  arrowAltUp: faLongArrowAltUp,
  arrowAltLeft: faLongArrowAltLeft,
  arrowAltRight: faLongArrowAltRight,
  sync: faSync,
  pencilalt: faPencilAlt,
};

const FaIcons = ({ icon, className }) => {
  return <FontAwesomeIcon icon={ICONS[icon]} className={className} />;
};

export default FaIcons;
