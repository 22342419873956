import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, getTimestamp, styles_, links_} from "./t_util";

import header from '../TestDesign/data/navbar'

const process_=({obj, d})=>{
  //console.log("t_header", d)

  let {position='', heading={}, description={}, typewriterText={}, button={}, helptext={}}=d
  obj.position=position
  obj.headings=[heading]
  obj.descriptions=[description]  
  obj.buttons=[button]
  obj.texts=[helptext]
  obj.dynamicText={...typewriterText, strings:[typewriterText.text]}  
  return obj
}

const header_ = ({generated={}, obj=false}) => {
  let d = obj ? obj : header.a01.header
  let uid=  getTimeForUniqID()
  //console.log("header", d.styleProps)
  //console.log("getTimeForUniqID", uid)

  d.styleProps.display='block'
  d.styleProps.width='100%'
  d.styleProps.margin='0px'
  
  let out={
    "metaDataId": uid,
    "blockId": uid,
    "blockType": "HEADER",
    "blockDesign": "header_01",
    "editFormName": "HEADER_FORM_01",   
    "styleProps": d.styleProps,
    "stylePropsTab": d.stylePropsTab ? d.stylePropsTab : {},
    "stylePropsMob": d.stylePropsMob ? d.stylePropsMob : {},    
    "position": "left",    
    "headings": [
      {
        "text": "",
        "styleProps": {},
        "stylePropsTab": {},
        "stylePropsMob": {}      
      }
    ],
    "headingsStyleProps": {},
    "headingsStylePropsTab": {},
    "headingsStylePropsMob": {},
    "dynamicText": {
      "styleProps": {},
      "stylePropsTab": {},
      "stylePropsMob": {},
      "strings": []
    },
    "descriptions": [
      {
        "styleProps": {},
        "stylePropsTab": {},
        "stylePropsMob": {},
        "text": ""
      }
    ],
    "descriptionsStyleProps": {      
    },
    "descriptionsStylePropsTab": {      
    },
    "descriptionsStylePropsMob": {      
    }, 
    "buttons": [
      {
        "styleProps": {},
        "alt": "",
        "href": "",
        "text": "",
        "openType": "sametab"
      },     
    ],   
    "buttonsStyleProps": {},    
    "emailSecStyleProps": {},
    "subscription": {
      "showSection": "show",
      "display": "enable",
      "subConfigs": {
        "configType": "convertkit",
        "configUrl": "https://app.convertkit.com/forms/xxxxxx/subscriptions",
        "configPlaceholder": ""
      },
      "email_placeholder": {
        "text": "example@mail.com"
      },
      "buttonText": {
        "text": "Subscribe",
        "styleProps": {
          "background-color": "#1b1b31",
          "color": "#ffffff"
        }
      },
      "successMessage": {
        "text": "Thank you for the signup",
        "styleProps": {}
      },
      "errorMessage": {
        "text": "Something went wrong. Please try again.",
        "styleProps": {}
      }
    },    
    "texts": [
      {
        "type": "el",
        "sub_type": "html",
        "text": ""
      }
    ],  
    "textsStyleProps": {},  
  }
  out=process_({obj:out, d}) 
  out=styles_({obj:out, el:d})
  //out.links=links_({links:d.links})
  return out
};

export default header_;

