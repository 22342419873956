import { twdClass } from "../../../constants/tailwind.constants";
import { BTN_CLS } from "../../../constants/var.constants";
import FaIcons from "../../common/FaIcons";
import { Button } from "../../ui/com_ui";
import PageSectionLayout from "./PageSectionLayout";
import {
  UPDATE_PROJECT_PAGE,
  SELECT_PAGE_ID,
  CREATE_NEW_PAGE,
  UPADTE_PAGES_LIST,
  UPDATE_SITE_SETTINGS,
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageJSONData, saveProject, getPagesList, getSiteSettings, saveSiteSettings} from "../../../api/services";

import { toast } from "react-toastify";
import Sortable from "./com/Sortable";
import _ from "lodash";
import { cloneDeep, set } from "lodash";

const PG_1 = twdClass("flex w-full");
const PG_2 = twdClass("w-52 mr-6 mt-4");
const PG_4 = twdClass(
  `${BTN_CLS} bg-indigo-600 text-white hover:bg-indigo-800 w-full font-bold`
);



const PagesLayout = () => {
  const dispatch = useDispatch();
  const {
    sitesettings,
    selectedPageId,
    pageInfo,
    projectData={ },
  } = useSelector((state) => state.studioDetails);
  const {project_id, pages } = projectData
  const [pagesChange, setPagesChange] = useState(false);

  const {pagesOrder=[]} = sitesettings.appSettings ? sitesettings.appSettings : {}

  let pages4=pages.map((page, index)=>{
    let p=_.find(pagesOrder, (pO)=>{
      return pO.page_id==page.page_id
    })
    p ? page.pageOrder=p.order : page.pageOrder=100
    return page
  })
  

  let pages3 = _.sortBy([...pages4], [function(page) { return page.pageOrder; }]);


  //console.log("pages4", pages4, pages3)
  const fetchPageInfo = async (page_id) => {
    const json = await getPageJSONData({
      project_id,
      page_id,
    });
    if (json.success) {   
      let hasDraft=false        
      /*
      let forDraft = true   
      let orig_blocks=[]
      
      if(forDraft==true){        
        hasDraft=true       
        if(json.data.blocks) {
          orig_blocks=json.data.blocks
        }
      }
      dispatch({ type: UPDATE_PROJECT_PAGE, data:{...json.data, hasDraft, orig_blocks}});
      */
      dispatch({ type: UPDATE_PROJECT_PAGE, data:{...json.data, hasDraft}});
    } else {
      toast.error("Error while fetching page info");
    }
  };

  const saveSettings = () => {
    saveSiteSettings(project_id, sitesettings).then((resp) => {
      toast.success("Site settings saved!");
    });
  };
  const updateSettings = (data) => {
    dispatch({ type: UPDATE_SITE_SETTINGS, data });
  };
  const updateSettingsItem = (key, value) => {
    const sst = cloneDeep(sitesettings);
    const data = set(sst, key, value);
    updateSettings(data);
  };

  useEffect(() => {
    getSiteSettings(project_id).then((resp) => {
      if (resp.success) {
        dispatch({ type: UPDATE_SITE_SETTINGS, data: resp.data });
      }
    });
  }, []);

  useEffect(() => {
    
    if(pagesChange){
      //console.log("change, pages2", pages3)  
      setPagesChange(false)  
      saveSettings() 
    }
    //console.log("sitesettings", sitesettings)  
   
  }, [pagesChange]);    
  

  const item_cb=({value})=>{
    return(
      <div
        className={twdClass(
          `mb-1 px-4 py-2 text-sm rounded-tl-2xl rounded-bl-2xl cursor-pointer hover:bg-white flex items-center select-none ${
            selectedPageId === value.page_id ? "bg-white" : ""
          }`
        )}
        onClick={() => {
          fetchPageInfo(value.page_id);
          dispatch({ type: SELECT_PAGE_ID, id: value.page_id });
        }}
      >
        <img
          width="18px"
          src="/assets/images/file-04.svg"
          className={twdClass("mr-2")}
          alt=""
        />{" "}
        {value.page_name}
      </div>
    )
  }
  const cb = ({lst}) => {
    lst=lst.map((item, index)=>{   
      item.settings.order=index
      return item
    })
    dispatch({
      type: UPADTE_PAGES_LIST,
      data: lst,
    });
    let pagesOrder=lst.map((page, index)=>{
      return({page_id:page.page_id, page_name:page.page_name, order:index})
    })
    updateSettingsItem("appSettings", {
      pagesOrder
    })    
    setPagesChange(true)
  }
  let items_c='relative flex flex-col'
  
  return (
    <div className={`pages-layout ${PG_1}`}>
      <div className={PG_2}>
        <div>
          <Button
            type="default"
            color="black"
            className={"mr-6"}
            onClick={() => {
              const pageName =
                pages.length > 0 ? `New Page - ${pages.length + 1}` : "Home";
              const pageSlug =
                pages.length > 0 ? `/new-page-${pages.length + 1}` : "";
              //console.log("add Page -INIT- for Create_new_page", pageName, pageSlug)
              dispatch({
                type: CREATE_NEW_PAGE,
                data: { pageName, pageSlug },
              });
            }}
          >
            <FaIcons icon="layergroup" className={twdClass("mr-4")} />
            Add Page
          </Button>
        </div>
        <div className={`pages-list ${twdClass("mt-3")}`}>
          <Sortable {...{list_items:pages3, cb, item_cb, items_c}}/>          
          {/*pages.map((item) => (
            <div
              className={twdClass(
                `mb-1 px-4 py-2 text-sm rounded-tl-2xl rounded-bl-2xl cursor-pointer hover:bg-white flex items-center ${
                  selectedPageId === item.page_id ? "bg-white" : ""
                }`
              )}
              onClick={() => {
                fetchPageInfo(item.page_id);
                dispatch({ type: SELECT_PAGE_ID, id: item.page_id });
              }}
            >
              <img
                width="18px"
                src="/assets/images/file-04.svg"
                className={twdClass("mr-2")}
                alt=""
              />{" "}
              {item.page_name}
            </div>
          ))*/}
        </div>
      </div>
      <div className={twdClass("relative flex-1 flex-grow  -ml-6 -mr-3")}><PageSectionLayout /></div>
    </div>
  );
};

export default PagesLayout;
