import { useState, useEffect } from "react";
import { twdClass } from "../../constants/tailwind.constants";
import ToggleBtn from "../common/ToggleBtn";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import { get } from "lodash";
import FaIcons from "../common/FaIcons";
import { Button, BtnStyle, BtnBack } from "../ui/com_ui";

const CE1 = twdClass("p-2 clear-both  flex items-center");
const CE2 = twdClass("flex items-center justify-center h-6 w-6 cursor-pointer");
const CE3 = twdClass("text-sm font-semibold cursor-pointer flex-1 capitalize");

export const SearchBarPanel = ({
  updateElementValue,
  configs,
  columnNames,
  setActivePanel,
  setActiveElementId,
  currentConectType,
}) => {
  const columns = get(configs, "searchConfig.columns", []);
  const searchOnKeyup = get(configs, "searchConfig.searchOnKeyup", false);
  const [expand, setExpand] = useState(true);
  return (
    <div className={twdClass("my-3 bg-gray-50 border border-gray-100  hover:border-gray-200 rounded-2xl px-1 py-1")}>
      <div className={CE1}>
        <span className={CE2} onClick={() => setExpand(!expand)}>
          <FaIcons
            icon={`${expand ? "angleUp" : "angleDown"}`}
            className={twdClass("text-sm")}
          />
        </span>
        <span className={CE3} onClick={() => setExpand(!expand)}>
          Search
        </span>
        <span className={twdClass("flex items-center")}>          
        <BtnStyle
          onClick={() => {
            setActiveElementId('searchContainer');
            setActivePanel("searchstyles");
          }}
        />    
        </span>
      </div>
      {expand && (
      <div
        className={twdClass(
          "p-2 bg-white border border-gray-100 hover:border-gray-200 rounded-xl "
        )}
      >
        <div className={twdClass("flex items-center justify-between  mb-2")}>
          <label className={twdClass("text-sm mr-2   ")}>Search Bar:</label>
          <ToggleBtn
            toggleId={"showsearchbar"}
            value={get(configs, "searchConfig.showSearchBar", false)}
            falseLabel={`Hide`}
            trueLabel="Show"
            updateValue={(value) => {
              updateElementValue("configs.searchConfig.showSearchBar", !value);
            }}
          />
        </div>
        <div className={twdClass("flex items-center justify-between  mb-2")}>
          <label className={twdClass("text-sm mr-2   ")}>Search as you type:</label>
          <ToggleBtn
            toggleId={"searchOnKeyup"}
            value={searchOnKeyup}
            falseLabel={`Off`}
            trueLabel="On"
            updateValue={(value) => {
              updateElementValue("configs.searchConfig.searchOnKeyup", !value);
            }}
          />
        </div>
        <div
            className={twdClass("border border-gray-100 hover:border-gray-200 rounded-xl -mx-1 p-2 mb-2")}
          >
          <div
              className={twdClass("items-center justify-between clear-both mb-2")}
            >
            <div className={twdClass("flex justify-between items-center mb-1")}>
              <label className={twdClass("text-sm font-semibold w-36")}>
                Search Input:
              </label>  
              <BtnStyle
                onClick={() => {
                  setActiveElementId('input');
                  setActivePanel("searchstyles");
                }}
              />         
            </div>          
          </div>       
          <div
              className={twdClass("items-center justify-between clear-both ")}
            >
            <div className={twdClass("flex justify-between items-center mb-1")}>
              <label className={twdClass("text-sm w-36")}>
                Placeholder:
              </label>  
                
            </div>
            <input
              className={twdClass(INPUT_TEXT)}
              value={get(configs, "searchConfig.placeholder.text", 'Search...')}
              onChange={(e) => {
                updateElementValue(
                  `configs.searchConfig.placeholder.text`,
                  e.target.value
                );
              }}
            />
          </div>  
        </div>      
        <div
            className={twdClass("items-center justify-between clear-both mb-2")}
          >
          <div className={twdClass("flex justify-between items-center mb-1")}>
            <label className={twdClass("text-sm w-36")}>
              Search Button:
            </label>  
            <BtnStyle
              onClick={() => {
                setActiveElementId('searchButton');
                setActivePanel("searchstyles");
              }}
            />          
          </div>
          <input
            className={twdClass(INPUT_TEXT)}
            value={get(configs, "searchConfig.searchButton.text", 'Search')}
            onChange={(e) => {
              updateElementValue(
                `configs.searchConfig.searchButton.text`,
                e.target.value
              );
            }}
          />
        </div>
        <div
            className={twdClass("items-center justify-between clear-both mb-2")}
          >
          <div className={twdClass("flex justify-between items-center mb-1")}>
            <label className={twdClass("text-sm  w-36")}>
              Clear Button:
            </label>  
            <BtnStyle
              onClick={() => {
                setActiveElementId('clearButton');
                setActivePanel("searchstyles");
              }}
            />          
          </div>
          <input
            className={twdClass(INPUT_TEXT)}
            value={get(configs, "searchConfig.clearButton.text", 'Clear')}
            onChange={(e) => {
              updateElementValue(
                `configs.searchConfig.clearButton.text`,
                e.target.value
              );
            }}
          />
        </div>
        <div>
          <div className={twdClass("text-sm font-semibold mt-5")}>
            Column Names
            <span className={twdClass("text-xs font-normal")}>
              (Click on select)
            </span>
            :
          </div>
          {columnNames &&
            columnNames.map((item) => {
              const selectd = columns.indexOf(item.key) !== -1;
              return (
                <button
                  className={twdClass(
                    `m-px px-1 text-xs border rounded-md border-primary-200 ${
                      selectd ? "bg-primary-500" : "bg-primary-200"
                    }`
                  )}
                  onClick={() => {
                    // const hasItem = columns.indexOf(item.key) !== -1;
                    let values = columns;
                    if (currentConectType === "supabase") {
                      values = [item.key];
                    } else {
                      if (selectd) {
                        values = columns.filter((itm) => itm !== item.key);
                      } else {
                        values.push(item.key);
                      }
                    }
                    updateElementValue(`configs.searchConfig.columns`, values);
                  }}
                >
                  {item.value}
                  <span className={twdClass("w-3 inline-block ml-1")}>
                    {selectd && (
                      <FaIcons icon="cross" className={twdClass("font-thin")} />
                    )}
                  </span>
                </button>
              );
            })}
        </div>
      </div>
      )}
    </div>
  );
};
