import { twdClass } from "../../../constants/tailwind.constants";
import { useSelector, useDispatch } from "react-redux";
import { BTN_CLS } from "../../../constants/var.constants";
import { useEffect, useState } from "react";
import MetadataSettings from "../site-settings/MetadataSettings";
import { UPDATE_PAGE_SETTINGS } from "../../../constants/action.constants";
import {Button} from "../../ui/com_ui";

const PageSiteSettingsPanel = ({saveSettings}) => {
  const dispatch = useDispatch();
  const { pageInfo } = useSelector((state) => state.studioDetails);
  const [settingsData, setSettingsData] = useState({});
  useEffect(() => {
    setSettingsData({ ...pageInfo.settings });
  }, []);

  const saveSettings2 = () => {
    dispatch({
      type: UPDATE_PAGE_SETTINGS,
      data: settingsData,
    });
  };

  const updateSettings = (data) => {
    setSettingsData(data);
  };

  return (
    <div className={`pages-settings-layout  ${twdClass("px-6")}`}>
      <div className={twdClass("w-full flex")}>
        <div className={twdClass("w-full")}>
          <MetadataSettings
            sitesettings={settingsData}
            updateSettings={updateSettings}
          />
          <div className={twdClass("w-full text-right mb-2 flex justify-end")}>
            <Button
              type="default"  color="primary" className={''} 
              onClick={() => saveSettings("update", settingsData)}
            >
              {(pageInfo && pageInfo.page_id) ? 'Update' : 'Add'}  Page SEO
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSiteSettingsPanel;
