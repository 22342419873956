

let d=[
  {
    title:"Lee Hawlkings",
    role:"Bubble",
    desc:"An innovative problem-solver with a proven track record in creating dynamic and complex web applications using Bubble",
    rate:"$100 per hour",
    email:"lee._hawlkings@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527389070.jpg"
  },
  {
    title:"Adam Grey",
    role:"Webflow, Zapier",
    desc:"A seasoned Webflow designer who excels in automating workflows with Zapier to create seamless user experiences",
    rate:"$200 per hour",
    email:"adam._grey@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527401764.jpg"
  },
  {
    title:"Samra Chan",
    role:"Zapier, Flezr",
    desc:"A versatile no-code expert with a knack for integrating apps via Zapier and building robust dynamic websites with Flezr",
    rate:"$250 per hour",
    email:"samra._chan@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527330757.jpg"
  }
]



let sd={
  title:"No-Code Experts",
  description:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.",
}

let a={
  cards:{
    "title": {
      "text": "Explore the top Best Hotels",
      "styleProps": {
        "textAlign": "center",
        "color": "rgb(204, 204, 204)",
        "fontSize": "4.5rem",
        "lineHeight": 1,
        "fontWeight": 500,
        "padding": "10px 0px"
      }
    },
    "description": {
      "text": "Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the world.",
      "styleProps": {
        "color": "rgb(204, 204, 204)",
        "fontSize": "1.875rem",
        "lineHeight": "2.25rem",
        "margin": "10px 0px",
        "textAlign": "center",
        "opacity": 0.7,
        "padding": "30px 100px"
      }
    },
    "searchbar": {
      "styleProps": {
        "display": "flex",
        "borderRadius": " 1.5rem",
        "padding": "10px 20px",
        "margin": "30px 0px",
        "width": "auto",
        "borderColor": "rgb(110, 197, 191)",
        "borderWidth": "2px"
      },
      "search_input": {
        "styleProps": {
          "color": "rgb(36, 36, 36)",
          "padding": "5px 20px",
          "backgroundColor": "rgb(255, 255, 255)",
          "borderRadius": "1.5rem",
          "margin": "0px 10px",
          "fontWeight": "bold",
          "width": "auto"
        }
      },
      "search_button": {
        "styleProps": {
          "color": "rgb(36, 36, 36)",
          "padding": "5px 20px",
          "backgroundColor": "rgb(255, cccc, 204)",
          "borderRadius": "1.5rem",
          "margin": "0px 10px",
          "fontWeight": "bold",
          "width": "auto"
        }
      },
      "clear_button": {
        "styleProps": {
          "backgroundColor": "rgb(110, 197, 191)",
          "color": "rgb(36, 36, 36)",
          "padding": "5px 20px",
          "borderRadius": "9999px"
        }
      }
    },
    "card_template": {
      "card_columns": [
        {
          "styleProps": {},
          "elements": [
            {
              "elementType": "imageEl",
              "src": "[[image]]",
              "styleProps": {
                "borderRadius": "1rem",
                "margin": "0px 10px 0px 0px",
                "width": "calc(100% - 250px)"
              }
            },
            {
              "elementType": "headingEl",
              "text": "[[title]]",
              "styleProps": {
                "fontSize": "2.25rem",
                "lineHeight": "2.5rem",
                "fontWeight": 500,
                "color": "rgb(36, 36, 36)",
                "margin": "0px 0px 20px 0px"
              }
            },
            {
              "elementType": "textEl",
              "text": "[[desc]]",
              "styleProps": {
                "margin": "0px 0px 20px",
                "color": "rgb(110, 110, 110)",
                "fontSize": "1.125rem",
                "lineHeight": "1.75rem"
              }
            },
            {
              "elementType": "textEl",
              "text": "Located in: [[location]]",
              "styleProps": {
                "margin": "0px 0px 20px",
                "fontSize": "1.125rem",
                "lineHeight": "1.75rem",
                "fontWeight": 700,
                "color": "rgb(36, 36, 36)"
              }
            },
            {
              "elementType": "buttonEl",
              "text": "View details ➜",
              "url": "[[details_url]]",
              "styleProps": {
                "margin": "0px 0px 10px",
                "color": "rgb(255, 110, 102)",
                "fontSize": "1.125rem",
                "lineHeight": "1.75rem",
                "fontWeight": 500
              }
            }
          ]
        }
      ]
    },
    "card_items": [
      {
        "card_columns": [
          {
            "styleProps": {},
            "elements": [
              {
                "elementType": "imageEl",
                "src": "https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1600",
                "styleProps": {
                  "borderRadius": "1rem",
                  "margin": "0px 10px 0px 0px",
                  "width": "calc(100% - 250px)"
                }
              },
              {
                "elementType": "headingEl",
                "text": "THE 4 BEST HOSTELS IN SINGAPORE",
                "styleProps": {
                  "fontSize": "2.25rem",
                  "lineHeight": "2.5rem",
                  "fontWeight": 500,
                  "color": "rgb(36, 36, 36)",
                  "margin": "0px 0px 20px 0px"
                }
              },
              {
                "elementType": "textEl",
                "text": "Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the",
                "styleProps": {
                  "margin": "0px 0px 20px",
                  "color": "rgb(110, 110, 110)",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem"
                }
              },
              {
                "elementType": "textEl",
                "text": "Located in: Singapore",
                "styleProps": {
                  "margin": "0px 0px 20px",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem",
                  "fontWeight": 700,
                  "color": "rgb(36, 36, 36)"
                }
              },
              {
                "elementType": "buttonEl",
                "text": "View details ➜",
                "styleProps": {
                  "margin": "0px 0px 10px",
                  "color": "rgb(255, 110, 102)",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem",
                  "fontWeight": 500
                }
              }
            ]
          }
        ]
      },
      {
        "card_columns": [
          {
            "styleProps": {},
            "elements": [
              {
                "elementType": "imageEl",
                "src": "https://images.pexels.com/photos/3152126/pexels-photo-3152126.jpeg?auto=compress&cs=tinysrgb&w=1600",
                "styleProps": {
                  "borderRadius": "1rem",
                  "margin": "0px 10px 0px 0px",
                  "width": "calc(100% - 250px)"
                }
              },
              {
                "elementType": "headingEl",
                "text": "WHERE TO STAY IN SINGAPORE",
                "styleProps": {
                  "fontSize": "2.25rem",
                  "lineHeight": "2.5rem",
                  "fontWeight": 500,
                  "color": "rgb(36, 36, 36)",
                  "margin": "0px 0px 20px 0px"
                }
              },
              {
                "elementType": "textEl",
                "text": "Dedicated to a truly sustainable and sincerely responsible travel & tourism industry",
                "styleProps": {
                  "margin": "0px 0px 20px",
                  "color": "rgb(110, 110, 110)",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem"
                }
              },
              {
                "elementType": "textEl",
                "text": "Located in: Singapore",
                "styleProps": {
                  "margin": "0px 0px 20px",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem",
                  "fontWeight": 700,
                  "color": "rgb(36, 36, 36)"
                }
              },
              {
                "elementType": "buttonEl",
                "text": "View details ➜",
                "styleProps": {
                  "margin": "0px 0px 10px",
                  "color": "rgb(255, 110, 102)",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem",
                  "fontWeight": 500
                }
              }
            ]
          }
        ]
      },
      {
        "card_columns": [
          {
            "styleProps": {},
            "elements": [
              {
                "elementType": "imageEl",
                "src": "https://images.pexels.com/photos/1907049/pexels-photo-1907049.jpeg?auto=compress&cs=tinysrgb&w=1600",
                "styleProps": {
                  "borderRadius": "1rem",
                  "margin": "0px 10px 0px 0px",
                  "width": "calc(100% - 250px)"
                }
              },
              {
                "elementType": "headingEl",
                "text": "HOW TO TRAVEL SINGAPORE WITH A BABY",
                "styleProps": {
                  "fontSize": "2.25rem",
                  "lineHeight": "2.5rem",
                  "fontWeight": 500,
                  "color": "rgb(36, 36, 36)",
                  "margin": "0px 0px 20px 0px"
                }
              },
              {
                "elementType": "textEl",
                "text": "Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the",
                "styleProps": {
                  "margin": "0px 0px 20px",
                  "color": "rgb(110, 110, 110)",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem"
                }
              },
              {
                "elementType": "textEl",
                "text": "Located in: Singapore",
                "styleProps": {
                  "margin": "0px 0px 20px",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem",
                  "fontWeight": 700,
                  "color": "rgb(36, 36, 36)"
                }
              },
              {
                "elementType": "buttonEl",
                "text": "View details ➜",
                "styleProps": {
                  "margin": "0px 0px 10px",
                  "color": "rgb(255, 110, 102)",
                  "fontSize": "1.125rem",
                  "lineHeight": "1.75rem",
                  "fontWeight": 500
                }
              }
            ]
          }
        ]
      }
    ],
    "styleProps": {
      "backgroundColor": "rgb(36, 36, 36)",
      "padding": "80px 20px"
    },
    "cards_holder_styles": {},
    "cards_outer_styles": {
      "styleProps": {
        "backgroundColor": "",
        "gap": "20px",
        "marginTop": "40px",
        "display": "grid",
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))"
      },
      "stylePropsTab": {
        "display": "grid",
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
      },
      "stylePropsMob": {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))"
      }
    },
    "cards_item_outer_styles": {
      "styleProps": {}
    },
    "cards_item_styles": {
      "styleProps": {
        "display": "flex",
        "padding": "20px",
        "backgroundColor": "rgb(255, 255, 255)",
        "borderRadius": "1rem",
        "color": "rgb(36, 36, 36)",
        "boxShadow": "4px 4px 2px rgba(0,0,0,0.1)"
      }
    }
  }
}


export default {
  a, d, sd,
}
