import { find, set, get, replace } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import RenderElements from "../RenderElements";
import { twdClass } from "../../../constants/tailwind.constants";
import { UPDATE_BLOCK_DATA } from "../../../constants/action.constants";
import { useEffect, useState, Fragment } from "react";
import ToggleBtn from "../../common/ToggleBtn";
import FaIcons from "../../common/FaIcons";
import { INPUT_TEXT } from "../../../constants/var.constants";
import {Button} from "../../ui/com_ui";
import EmbedCode from "../../studio/sui/EmbedCode";
import Collapse from "../../studio/sui/Collapse";
import copy from 'copy-to-clipboard';
import createEncryptor from 'simple-encryptor'




const DetailsElementsProps = ({
  sectionElem,
  styleElementId,
  sectionData,
  blocks,
  listIndex,
}) => {

  //const [showDS, setShowDS] = useState(true);

  const {
    pageInfo: { settings},
    projectData: { project_id, pages, domain},
  } = useSelector((state) => state.studioDetails);

  const { metaDataId, blockId } = sectionData;
  const dispatch = useDispatch();
  const [isCopyText, setCopyText] = useState('Copy');
  //const { styleElements } = sectionElem;
  const [elementsProp, setElementProp] = useState([]);
  useEffect(() => {
    /*let { elements } = find(styleElements, ["styleElementId", styleElementId]);
    elements = elements.map((item) => ({
      ...item,
      updateTo: replace(item.updateTo, "[i]", `[${listIndex}]`),
    }));*/
    let elements_= [ 
      {
        inputType: "text",
        label: "Anchor ID",
        defaultValue: "sd",
        updateTo: "advanced.anchor.id",
        hasStyleProps: false,
        hideLabel: false,
      }, 
    ];
    setElementProp(elements_);
  }, []);

  //const encryptor = new createEncryptor('flezr-encryption-key')
  

  const inputArgs = { display_label: true, inputStyle: 2 };

  const updateElementValue = (to, value) => {
    //console.log("UEV", to, value)
    let blockData = set(sectionData, to, value);
    blocks = blocks.map((item) => {
      if (item.metaDataId === metaDataId) {
        return { ...blockData };
      }
      return item;
    });    
    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks,
    });
  };
  
  //console.log("settings", settings)
  //console.log("blocks", blocks)
  //console.log("sectionData", sectionData)    
  let domain_=`https://${domain}`
  let blockId_=get(sectionData, 'advanced.anchor.id')
  let pageSlug = settings.pageSlug ? settings.pageSlug : '/'
  if(!blockId_) blockId_=blockId

  //let en = encryptor.encrypt(domain_) // o2fXhV
  //let de = encryptor.decrypt(domain_) // [1, 2, 3]
  

  //console.log("encryptor",en, de)

  let embedCode2=`
  <div class="flezr-embed" data-site-id='https://defi-smartcontracts.flezr.co' data-page-id="1" data-block-id="LandingPages" style="height:500px;"></div>
<script type="text/javascript" src="https://defi-smartcontracts.flezr.co/platform.js"></script>
  `
  let embedCode=`<div class="flezr-embed" data-site-id='${domain_}' data-page-slug="${pageSlug}" data-section-id="${blockId_}" style="height:500px;"></div>\r
<script type="text/javascript" src="https://app.flezr.com/platform.js"></script>
  `

  let embedCodeDisplay=(
  <span>
    {`<div class="flezr-embed" data-site-id="${domain_}" `}
    <span className={twdClass("font-bold ")}>{'data-page-slug'}</span>
    {'="'}
    <span className={twdClass("font-bold text-yellow-500")}>{pageSlug}</span>
    {'" '}
    <span className={twdClass("font-bold ")}>{'data-section-id'}</span>
    {'="'}
    <span className={twdClass("font-bold text-yellow-500")}>{blockId_}</span>
    {'" '}
    {'style="height:500px;"></div> '}
    {`<script type="text/javascript" src="https://app.flezr.com/platform.js"></script>`}    
  </span>)
  return (
    <div className={twdClass("px-3 py-3")}>      
      <div className={twdClass("")}>
        <Collapse label={'Anchor Settings'}>
          <div className={twdClass("flex items-center justify-between mb-1 py-2")}>
            <label className={twdClass("text-sm mr-2")}>ID</label>
            <input
              type="text"
              className={twdClass(INPUT_TEXT)}
              value={sectionData?.advanced?.anchor?.id}
              onChange={(e) => {                
                updateElementValue("advanced.anchor.id", e.target.value);                
              }}
            />
          </div> 
        </Collapse>
      </div>  
      {settings.pageType!='dynamic' && <div className={twdClass("mt-2")}>
        <Collapse label={'Embed Code'} desc="Embed this section in websites such as webflow, wix, Squarespace, wordpress and others. ">
          <EmbedCode blockId={blockId} advancedAnchorId={blockId_}/>
        </Collapse> 
      </div>}   
    </div>
  );
};

export default DetailsElementsProps;
