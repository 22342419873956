import { useState } from "react";
import FaIcons from "./FaIcons";

const { twdClass } = require("../../constants/tailwind.constants");

const MP_CLS_2 = twdClass("absolute z-50 inset-0 bg-gray-600 opacity-20");
export let showLoading = () => {};
const LoadSpinner = () => {
  const [show, setShowLoading] = useState(false);
  showLoading = (val) => {
    setShowLoading(val);
  };
  return (
    <>
      {show && (
        <div
          className={twdClass(
            "fixed z-50 inset-0 flex items-center justify-center"
          )}
        >
          <div className={MP_CLS_2}></div>
          <div
            className={twdClass(
              "text-2xl p-2 flex items-center justify-center text-purple-800"
            )}
          >
            <FaIcons
              icon="circleSpinner"
              className={`fa-spin ${twdClass("text-5xl")}`}
            />
            {/* <span className={twdClass("ml-3 font-semibold")}>Loading...</span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default LoadSpinner;
