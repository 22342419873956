import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { UPDATE_PAGE_SETTINGS } from "../../../constants/action.constants";
import ExternalJS from "../site-settings/ExternalJS";
import {Button} from "../../ui/com_ui";

const PageExternalJsPanel = ({saveSettings}) => {
  const dispatch = useDispatch();
  const { pageInfo } = useSelector((state) => state.studioDetails);
  const [settingsData, setSettingsData] = useState({});

  useEffect(() => {
    setSettingsData({ ...pageInfo.settings });
  }, []);

  const saveSettings2 = () => {
    //console.log("settingsData", settingsData)
    dispatch({
      type: UPDATE_PAGE_SETTINGS,
      data: settingsData,
    });
  };

  const updateSettings = (data) => {
    //console.log("updateSettings", data)
    setSettingsData(data);
  };

  return (
    <div>
      <div className={`pages-settings-layout  ${twdClass("px-6")}`}>   
        <ExternalJS
          sitesettings={settingsData}
          updateSettings={updateSettings}
        />
        <div className={twdClass("w-full text-right mb-2 flex justify-end")}>
            <Button
              type="default"  color="primary" className={''} 
              onClick={() => saveSettings("update", settingsData)}
            >
              {(pageInfo && pageInfo.page_id) ? 'Update' : 'Add'}  External JS
            </Button>
          </div>
      </div>
    </div>      
  );
};

export default PageExternalJsPanel;
