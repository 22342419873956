import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "./Common";
import {imageElement_prompt, imageElement_format} from "../prompts/imageElement_prompt";

const Item = (args) => {
  const {image, image_sample}= args
  return(<ImgTag {...args} {...{src:image_sample, alt:image}}></ImgTag>)
}
const Design2 = ({obj={}, opts={}, sample='', sdata={}}) => {  
  let user_prompt = imageElement_prompt({opts, sample, sdata }) 
  return (<Design {...{type:'imageElement', obj, user_prompt, format:imageElement_format}}/>)
}
const ImageElement = ({imageElement={}, opts={}, design=false, sample='', sdata={}}) => {
  let obj=imageElement.imageElement ? imageElement.imageElement : imageElement
  const { images={}, ...obj1} = obj
  return(
    <DivTag {...obj1} className={twdClass("relative mb-4 px-4 py-2 ")}>
      {design && <Design2 {...{design,  obj, opts, sample, sdata}}/>}
      <div className={twdClass("flex flex-col gap-8 relative mx-auto")} style={{'max-width':'1536px'}}>  
        <div className={twdClass("ml-2")}> 
          <Item {...images}/>
        </div>
      </div>
    </DivTag>
  )
}
export  default ImageElement