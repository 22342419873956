import { useState, useEffect } from "react";
import { TextElementPanel } from "./TextElementPanel";
import { OpenLinkIn } from "./OpenLinkIn";
import { twdClass } from "../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../constants/var.constants";

export const ButtonElementPanel = ({
  columnNames = [],
  section,
  updateValues,
  updateStylePanel,
}) => {
  const [showTxt, setShowTxt] = useState(false);
  const ButtonID = () => {
    return(
      <div className={twdClass("flex items-center justify-between mb-2")}>
        <label className={twdClass("text-sm mr-2")}>Button&nbsp;ID:</label>
        <input
          className={twdClass(INPUT_TEXT)}
          value={section.button_id ? section.button_id : '' }
          onChange={(e) => {
            console.log(e.target.value, section)
            updateValues(section.id, {
              ...section,
              button_id: e.target.value,      
            });
          }}
        />        
      </div>  
    )
  }
  return (
    <div>      
      <div className={twdClass("p-2 bg-white border border-gray-100 hover:border-gray-300 rounded-xl mb-2")}>
        <div className={twdClass("w-36 text-sm mb-2")}>Button Text</div>
        <TextElementPanel
          columnNames={columnNames}
          updateValues={updateValues}
          section={section}
          updateStylePanel={updateStylePanel}
          textName="Text/Styles"
        />
      </div>   
      <div className={twdClass("p-2 bg-white border border-gray-100 hover:border-gray-300 rounded-xl mb-2")}> 
        <OpenLinkIn
          columnNames={columnNames}
          updateValues={updateValues}
          section={section}
          updateStylePanel={updateStylePanel}
        />
      </div>  
      <div className={twdClass("p-2 bg-white border border-gray-100 hover:border-gray-300 rounded-xl mb-2")}>
        <div className={twdClass("w-36 text-sm mb-2 ")}>Button ID</div>
        <TextElementPanel
          columnNames={columnNames}
          updateValues={updateValues}
          section={section}
          updateStylePanel={updateStylePanel}
          textName="Text"
          mapColumnsLabel="Map&nbsp;Columns:"
          updateProp = 'button_id'
          hasStyle = {false}
        />
      </div>      
    </div>
  );
};
