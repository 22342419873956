import { getDefaultFields } from "../common/common.fields";

export const FEATURES_FORM_1 = {
  sectionName: "Header Section",
  elements: [
    {
      inputType: "list",
      label: "Heading",
      hasStyleProps: false,
      updateTo: "headings",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 1,
        elements: [
          {
            inputType: "textarea",
            label: "Headings",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            updateTo: "headings[i].text",
            extraPropUpdateTo: "headings[i]",
            extraPropValues: {
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              styleProps: {
                "text-align": "center",
                "font-size": "1.125rem",
                "line-height": "1.75rem",
                color: "#ffffff",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Features",
      hasStyleProps: true,
      styleElementId: 3,
      updateTo: "featuresList",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 2,
        elements: [
          {
            inputType: "text",
            label: "Icon",
            defaultValue: "🧸",
            updateTo: "featuresList[i].icon",
            hasStyleProps: false,
            extraPropUpdateTo: "featuresList[i]",
            extraPropValues: {
              icon: "🧸",
              styleProps: {
                "background-color": "#ffffff",
              },
            },
          },
          {
            inputType: "text",
            label: "Heading",
            defaultValue: "Lorem ipsum dolor sit amet",
            updateTo: "featuresList[i].textPrimary",
            hasStyleProps: true,
            styleElementId: 4,
          },
          {
            inputType: "simplelist",
            label: "Description",
            defaultValue: [
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            ],
            updateTo: "featuresList[i].textSec",
            hasStyleProps: true,
            styleElementId: 5,
            showListStyleProps: true,
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Features Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Heading",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "headings[i].styleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Feature section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "featuresList[i].styleProps",
          fields: [
            "backgroundcolor",
            "backgroundimage",
            "borderradius",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Icons",
      elements: [
        {
          label: "Position",
          inputType: "select",
          updateTo: "icon.position",
          options: [
            { display: "Top", value: "top" },
            { display: "Left", value: "left" },
            { display: "Right", value: "right" },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "icon.styleProps",
          fields: ["backgroundcolor", "borderradius", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Heading",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "featuresList[i].textPrimaryStyleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 5,
      label: "Description",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "featuresList[i].textSecStyleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
  ],
};
