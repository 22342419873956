import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MainCardSection } from "./MainCardSection";
import { find, set, isEmpty, cloneDeep } from "lodash";
import { BlockStylePanel, CardStylePanel } from "./BlockStylePanel";
import { UPDATE_BLOCK_DATA } from "../../constants/action.constants";
import { FilterSettings } from "../cards/FilterSettings";
import { GeneralSettings } from "../cards/GeneralSettings";
import { HeadingText } from "../cards/HeadingText";
import { FilterStyles } from "../cards/FilterStyles";
import { SearchStyles } from "../cards/SearchStyles";
import { ManageElementsPanel } from "./ManageElementsPanel";
import { DividerElementPanel } from "./DividerElementPanel";
import { SortConfigPanel } from "./SortConfigPanel";

export const DesignCardPanel = () => {
  const dispatch = useDispatch();
  const [activePanel, setActivePanel] = useState("main");
  const [columnNames, setColumnNames] = useState([]);
  const [activeCatId, setactiveCatId] = useState("");
  const [activeElementId, setActiveElementId] = useState("");

  const {
    selectedBlock,
    pageInfo: { blocks },
    projectData: { connections },
  } = useSelector((state) => state.studioDetails);
  const currentBlock = find(blocks, ["metaDataId", selectedBlock.metaDataId]);
  const updateElementValue = (key, value) => {
    //const block = cloneDeep(currentBlock);
    const blk = set(currentBlock, key, value);
   // console.log("BLK", blk)
    const mBlocks = blocks.map((item) => {
      if (item.metaDataId === blk.metaDataId) return { ...blk };
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: mBlocks,
    });
  };

  const currentConectType =
    !isEmpty(currentBlock) &&
    find(connections, [
      "connection_id",
      currentBlock.configs.connection.connectionId,
    ])?.connection_type;

  if (isEmpty(currentBlock)) {
    return <></>;
  }

  if (activePanel === "main")
    return (
      <MainCardSection
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        currentBlock={currentBlock}
        updateElementValue={updateElementValue}
        setColumnNames={setColumnNames}
        columnNames={columnNames}
      />
    );
  else if (activePanel === "styles")
    return (
      <BlockStylePanel
        setActivePanel={setActivePanel}
        currentBlock={currentBlock}
        updateElementValue={updateElementValue}
      />
    );
  else if (activePanel === "headingtext" && !isEmpty(currentBlock))
    return (
      <HeadingText
        updateElementValue={updateElementValue}
        data={currentBlock}
        setActivePanel={setActivePanel}
      />
    );
  else if (activePanel === "generalsettings" && !isEmpty(currentBlock))
    return (
      <>
        <GeneralSettings
          updateElementValue={updateElementValue}
          data={currentBlock}
          setActivePanel={setActivePanel}
          columnNames={columnNames}
          setactiveCatId={setactiveCatId}
          setActiveElementId={setActiveElementId}
          currentConectType={currentConectType}
        />
      </>
    );
  else if (activePanel === "filtersettings" && !isEmpty(currentBlock))
    return (
      <>
        <FilterSettings
          updateElementValue={updateElementValue}
          data={currentBlock}
          setActivePanel={setActivePanel}
          columnNames={columnNames}
          setactiveCatId={setactiveCatId}
          setActiveElementId={setActiveElementId}
          currentConectType={currentConectType}
        />
      </>
    );
  else if (activePanel === "filterstyles" && !isEmpty(currentBlock))
    return (
      <FilterStyles
        setActivePanel={setActivePanel}
        activeCatId={activeCatId}
        data={currentBlock}
        activeElementId={activeElementId}
        updateElementValue={updateElementValue}
      />
    );
  else if (activePanel === "searchstyles" && !isEmpty(currentBlock))
    return (
      <SearchStyles
        setActivePanel={setActivePanel}
        activeElementId={activeElementId}
        data={currentBlock}
        updateElementValue={updateElementValue}
      />
    );
  else if (activePanel === "designcard" && !isEmpty(currentBlock))
    return (
      <ManageElementsPanel
        setActivePanel={setActivePanel}
        activeCatId={activeCatId}
        currentBlock={currentBlock}
        updateElementValue={updateElementValue}
        columnNames={columnNames}
      />
    );
  else if (activePanel === "cardstyle" && !isEmpty(currentBlock))
    return (
      <CardStylePanel
        setActivePanel={setActivePanel}
        updateElementValue={updateElementValue}
        currentBlock={currentBlock}
      />
    );
  else if (activePanel === "sortsettings" && !isEmpty(currentBlock))
    return (
      <SortConfigPanel
        setActivePanel={setActivePanel}
        columnNames={columnNames}
        updateElementValue={updateElementValue}
        currentBlock={currentBlock}
      />
    );
  else <></>;
};
