import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDetectClickOutside } from "react-detect-click-outside";
import classNames from "classnames";
import { twdClass } from "../../constants/tailwind.constants";
import { Button, BtnVS } from "./com_ui";
import { XyzTransition, XyzTransitionGroup } from "@animxyz/react";
import _ from "lodash";


const VisualStepsBaseLayer = ({visualSteps={}, setLayer, back=false}) => { 
  const {current, isActive, title='', steps=false, step=1, options_bk=false, title_bk='', oBack=false} = visualSteps   
  const [isStart, setStart] = useState(true);
  const [isStep, setStep] = useState(steps ? steps[0] : {t:'', img:'vs_project_01.png', lx:'14%', ly:'29%'});
  const [currentStep, setCurrentStep] = useState(0);
  
  const dispatch = useDispatch();
  const update_options_bk = async () => {
     if(oBack==true){
      dispatch({
        type: 'UPDATE_VISUAL_STEPS',
        data: { 
          options:options_bk,
          title:title_bk,
          steps:false,
          oBack:false
        },
      });
    }
  }

  let isStartEnd =''
  if(currentStep==0) isStartEnd='start'
  else if(currentStep==steps.length-1) isStartEnd='end'
  //console.log("isStartEnd", isStartEnd)

  useEffect(()=>{    
    if(visualSteps.step==1 && steps){
      //console.log('Step', visualSteps.step)
      //console.log("visualSteps", visualSteps)
      setCurrentStep(0)
      setStep(steps[0])
    }
  }, [visualSteps.step])
  
  const nextStep = () => {
    let next=currentStep+1
    if(next>=steps.length){
      next=currentStep
    } 
    changeStep(currentStep, next)    
  }
  const changeStep = (prev, next) => {
    if(next!=prev ){
      setCurrentStep(next)  
      setStep(steps[next])  
    }
  }
  const prevStep = () => {    
    let next=currentStep-1
    if(next<0){
      next=currentStep
    }   
    changeStep(currentStep, next)    
  }  

  let c2_='hidden'
  if(isActive) c2_=''

  const _links = (links) => {
    return links.map((i, index)=>{
      let {t, l} = i      
      return (
        <div key={index} className={twdClass(`px-2 py-1`)} >
          <a className={twdClass(`cursor-pointer text-secondary-500 hover:underline transform hover:-translate-y-px active:translate-y-0`)} href={l} target={'_blank'}>{t}</a>
        </div>
      )
    })  
  }
  const _vs = (vs) => {
    return (
      <div className={twdClass(`ml-12`)} >
        {vs.steps && <div>{_steps(vs.steps)}</div>}
      </div>
    )
  }
  const _steps = (st) => {
    return st.map((item, index)=>{
      let {t, img, lx='14%', ly='29%', links=false, vs=false, note='', hint=''} = item
      let ic_ = classNames({ 'bg-gray-100': currentStep==index ? true :  false, 'group-hover:bg-gray-100': currentStep==index ? false :  true});
      let ic1_ = classNames({ 'bg-primary-500 border-primary-500 text-gray-900 shadow-md': currentStep==index ? true :  false});

      return (
        <div key={index} className={twdClass(`border-b border-gray-200 ${ic_} cursor-default group hover:bg-gray-50`)}  onClick={()=>{setStep(item); setCurrentStep(index)}}>
          <div className={twdClass(`px-8 py-3 flex `)}>
            <div className={twdClass(`${ic1_}  w-8 h-8 flex items-center justify-center rounded-full mr-4 font-semibold `)} style={{minWidth:'30px'}}>{index+1}</div>
            <div>
              <div className={twdClass(`group-hover:bg-gray-50 px-3 py-1`)} >
                <div className={twdClass(``)} >{t}</div> 
                {hint && <div className={twdClass(`text-xs opacity-50 group-hover:opacity-100`)} >{hint}</div>} 
                {note && <div className={twdClass(`text-red-800 text-xs opacity-50 group-hover:opacity-100 mt-2`)} >{note}</div>} 
                {links!=false && <div className={twdClass(`text-sm flex -mx-2`)} >{_links(links)}</div>}           
              </div>            
            </div>
          </div>
          {vs!=false && <div className={twdClass(`text-sm flex pl-20  pb-4`)}>
            {_.isArray(vs) ?
              vs.map((v, i)=>{
                return (<BtnVS key={i} size="xs" className={"mr-2"} onClick={()=>setLayer({isA:true, data:v})}>{v.title}</BtnVS>)
              })
              :
              (<BtnVS size="xs" onClick={()=>setLayer({isA:true, data:vs})}>{vs.title}</BtnVS>)
            }               
          </div>}
        </div>
      )
    })  
  } 

  //const ref = useDetectClickOutside({ onTriggered: closeToggle });
  const Locator=()=>{
    return(
      <XyzTransition appear duration="auto">
        <div xyz="fade small ease-out-back delay-5 duration-10 iterate-infinite direction-alternate" className={twdClass("absolute w-3 h-3 rounded-full bg-red-500 bg-opacity-100")} style={{left:isStep.lx, top:isStep.ly}}>
          <div className={twdClass("absolute border transform scale-150 rounded-full border-red-500 border-opacity-100 bg-transparent")} style={{top:'-50%', left:'-50%', right:'-50%', bottom:'-50%'}}></div>
        </div>
      </XyzTransition>
    )
  }
  return (  
    <div className={twdClass("sd relative h-full bg-white")} > 
      <div className={twdClass("flex flex-wrap h-full ")} > 
        <div className={twdClass("relative hidden xl:w-5/12 border-r xl:block")}>
          <div className={twdClass("absolute top-0 left-0 right-0 bottom-0 flex flex-col")}>
            <div className={twdClass("flex-grow overflow-auto")}>
              <div className={twdClass("text-2xl font-semibold px-8 py-8 flex items-center")}>
                {back==true && <div className={twdClass(`mr-4`)} ><Button type={'icon'} iconImg={'arrow-left-sm.svg'} onClick={()=>setLayer({isA:false, data:{}})}></Button></div>}
                {oBack==true && <div className={twdClass(`mr-4`)} ><Button type={'icon'} iconImg={'arrow-left-sm.svg'} onClick={()=>update_options_bk()}></Button></div>}
                
                <div>{title}</div>
              </div>
              <div className={twdClass("")}>              
                {steps!=false && <div className={twdClass("h-full overflow-auto")}  >{_steps(steps)}</div>}
              </div>
            </div>
            <div className={twdClass("flex items-center px-8 py-4")}>
              <Button disabled={isStartEnd=='start' ? true : false} type={"icon"} iconImg={'chevron-left.svg'} onClick={()=>prevStep()}> </Button>
              <div className={twdClass("flex-grow")}></div>
              <Button disabled={isStartEnd=='end' ? true : false} type={"default"} color={'black'} onClick={()=>nextStep()}>             
                  Next
              </Button>           
            </div>
          </div>
        </div>
        <div className={twdClass("w-full h-full xl:w-7/12 relative")}>
          <div className={twdClass("py-12 pl-12 h-full bg-center bg-cover relative flex items-center justify-end")} style={{backgroundImage:'url("/assets/images/bg-1.png")'}}>
            <div className={twdClass("relative bg-white shadow-md rounded-tl-2xl rounded-bl-2xl overflow-hidden")} >
              <div className={twdClass("relative")} style={{maxHeight:'600px'}}>
                <img src={`/assets/images/content/${isStep.img}`} style={{minHeight:'100%', minWidth:'100%'}} />
                <Locator/>               
              </div>            
            </div>
          </div>  
        </div> 
      </div>     
    </div>
  );
}
  
const VisualStepsLayer = ({visualSteps={}, setLayer}) => { 
  return (
    <div className={twdClass(`absolute top-0 left-0 right-0 bottom-0 `)} style={{zIndex:52, backdropFilter:' blur(5px)'}} >
      <div  className={twdClass(`relative flex flex-col w-full h-full`)}>        
        <div  className={twdClass(`flex-grow relative bg-white rounded-xl shadow-lg`)} >
          <div className={twdClass(`absolute top-0 left-0 right-0 bottom-0`)} >
            {<VisualStepsBaseLayer {...{visualSteps, setLayer, back:true}}/>}  
          </div> 
        </div>
      </div>
    </div>
  )
}

const VSOptions=({title, desc, options=[], setLayer})=>{
  const dispatch = useDispatch();
  const update = async (data) => {
     dispatch({
      type: 'UPDATE_VISUAL_STEPS',
      data: {
        isActive: true,
        ...data,
        step:1,
        options:false,
        options_bk:options,
        options_title_bk:title,
        oBack:true
      },
    });
  }
  console.log("desc", desc)
  return(
    <div className={twdClass("flex items-center justify-center h-full bg-primary-500")} style={{backgroundImage2:'url("/assets/images/bg-1.png")'}}>
      <div className={twdClass("flex flex-col items-center justify-center bg-white shadow-lg rounded-lg px-12 py-12")} >
        <div className={twdClass("text-4xl font-semibold mb-4")}>{title}</div>
        <div className={twdClass("text-lg mb-12")}>{desc}</div>
        <div className={twdClass("flex items-center")}>
          {options.map((i, index)=>{
            const {t, data} = i
            return(
              <div key={index} className={twdClass("px-2 py-2")} >
                <Button type={'default'} color={'secondary'} onClick={()=>update(data)}>
                  {t}
                </Button>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const VisualStepsContainer = () => { 
  const dispatch = useDispatch();
  const {visualSteps} = useSelector((state) => state.appdetails);   
  const {current, isActive, title='', desc='', steps=false, options=false, options_bk=false,step=1, oBack=false} = visualSteps  ? visualSteps : {}
  const [isLayer, setLayer] = useState({isA:false, data:false});   
  /*
  useEffect(()=>{
    if(isActive && options==false && steps!=false){
      setData({isA:true, data:visualSteps})
    }
  },[isActive])
  */
  
  //console.log("visualSteps", visualSteps)
  //console.log("initData", initData)
  //console.log("isData", isData)
  const update = async () => {
     dispatch({
      type: 'UPDATE_VISUAL_STEPS',
      data: {
        isActive: false,
        current: "", 
        title :'',  
        desc:'',
        steps:[],
        options:false,
        step:0,
        oBack:false     
      },
    });    
  }
  const stopP = (e) => {
    e.stopPropagation();
  };
  const c_ = classNames({ "hidden": isActive ? false :  true });
  
  return (
    <div className={twdClass(`${c_} fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-20 hover:bg-opacity-10 active:bg-opacity-50 backdrop-filter backdrop-blur-lg flex items-center justify-center`)} style={{zIndex:51, backdropFilter:' blur(5px)'}} onClick={()=>update()}>        
      <div className={twdClass("relative flex flex-wrap z-30 mx-auto shadow-md rounded-2xl overflow-hidden w-full")} style={{minHeight:'800px', minWidth:'1000px', maxWidth:'1200px'}} onClick={(e)=>stopP(e)}>  
        <div className={twdClass("absolute top-0 left-0 w-full h-full ")} >
          {steps.length && <VisualStepsBaseLayer {...{visualSteps, setLayer}}/>}          
          {options.length && <VSOptions {...{title, desc, options, setLayer}}/>}
        </div>
        {isLayer.isA!=false && <VisualStepsLayer {...{visualSteps:isLayer.data, setLayer}}/>}  
        <div className={twdClass("absolute top-0 right-0 mr-4 mt-4 ")} style={{zIndex:52}} ><Button type={'icon'} iconImg={'x-02.svg'} color={'white'} onClick={()=>update()}></Button></div>      
      </div>        
    </div>
  );
};

const VisualSteps = (props) => { 
  let {title='', desc='', steps=false, options=false, visualSteps, children} = props
  const dispatch = useDispatch();
  const updateVisualSteps = async () => {
    dispatch({
      type: 'UPDATE_VISUAL_STEPS',
      data: {
        isActive: true,
        current: visualSteps, 
        title, 
        desc,
        steps,
        step:1,
        options,
        options_bk:options,
        title_bk:title,
        oBack:false
      },
    });
    //console.log("props", props)
  }
  return (
    <div onClick={updateVisualSteps} >        
      {children}
    </div>
  );
};

export {
  VisualSteps,
  VisualStepsContainer
}
