import { Scrollbars } from 'react-custom-scrollbars';
import { twdClass } from "../../../constants/tailwind.constants";
import { useDispatch } from "react-redux";
import FaIcons from "../../common/FaIcons";
import { Button} from "../../ui/com_ui";
import { RIGHT_PANEL_TOGGEL } from "../../../constants/action.constants";
// import { GenetateForm } from "../../form/GenerateForm";
import SettingsPanel from "../../form/SettingsPanel";
import styled from "styled-components";

const RS_CLS_1 = twdClass(
  "flex items-center text-2xl px-2 pt-4 pb-2 border-b border-gray-100 "
);
const RS_CLS_2 = twdClass("ml-4 text-2xl font-semibold text-black opacity-0");

const RightSidePanel = ({ showPanel }) => {
  const dispatch = useDispatch();
  return (
    <div className={`${showPanel ? "" : twdClass("hidden")}`}>
      <div className={RS_CLS_1}>        
        <Button
          type={'icon'}
          color={'black'}
          iconImg={'chevron-double-left.svg'}
          onClick={() => {
            dispatch({ type: RIGHT_PANEL_TOGGEL, data: false });
          }}
        >
          <FaIcons icon="angledoubleright" />
        </Button>
        <div className={RS_CLS_2}>Settings</div>
      </div>
      <SDiv>
        <Scrollbars style={{ width: '100%', height: '100%' }} autoHide>
          <SettingsPanel />
        </Scrollbars>
      </SDiv>
    </div>
  );
};

export default RightSidePanel;

const SDiv = styled.div`
  height: calc(100vh - 150px);
  overflow: auto;
`;
