import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "../common/FaIcons";
import { INPUT_TEXT } from "../../constants/var.constants";
import { useState, useEffect } from "react";
import Select from "react-select";
import { set, cloneDeep } from "lodash";
import { processBracesValues } from "../../utils/common.utils";
import { Button, BtnStyle, BtnDelete, BtnEdit} from "../ui/com_ui";

export const TextElementPanel = ({
  columnNames = [],
  section,
  updateValues,
  updateStylePanel,
  textName = "Text Style",
  mapColumnsLabel = 'Map Columns:',
  updateProp = 'text',
  hasStyle = true
}) => {
  const [showTxt, setShowTxt] = useState(false);
  const optionList = columnNames.map((itm) => ({
    value: itm.key,
    label: itm.value,
  }));
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    console.log(section);
    if (section[updateProp]) {
      setTextValue(section[updateProp]);
    }
  }, []);

  let defaultValues = processBracesValues(section[updateProp]).map((itm) => {
    return optionList.filter((item) => itm.value === item.label)[0];
  });

  const prepareText = (items) => {
    let mTextValue = textValue.trim();
    processBracesValues(mTextValue).forEach((itm) => {
      const isValue = items.filter((flt) => itm.value === flt.label);
      if (isValue.length === 0) {
        mTextValue = mTextValue.replace(`[[${itm.value}]]`, "");
      }
    });
    const addedText =
      mTextValue +
      items
        .map((itm) => {
          const value = `[[${itm.label}]]`;
          if (!mTextValue.includes(value)) {
            return value;
          }
          return "";
        })
        .join(" ");
    setProcessTextValue(addedText, items);
    // updateValues("params", items, section.id);
  };
  const appendPrepand = (item) => {
    const text = item;
    setProcessTextValue(text);
  };

  const setProcessTextValue = (text, items) => {
    const sec = cloneDeep(section);
    let params = ''
    if(updateProp =='text') {
      params = 'params'
    }else if(updateProp) {
      params = updateProp+'_params'
    }else{
      params = 'params'
    }
    if (items) {
      sec[params] = items;
    }
    //sec.text = text;
    sec[updateProp] = text
    setTextValue(text);
    console.log("Process", sec, text)
    updateValues(section.id, sec);
  };

  return (
    <div>
      <div className={twdClass("items-start mb-4")}>
        <div className={twdClass("w-36 text-sm opacity-75 mb-2")}>{mapColumnsLabel}</div>
        <div className={twdClass("w-full text-xs rounded-full")}>
          <Select
            isMulti
            name="text-element"
            defaultValue={defaultValues}
            options={optionList}
            onChange={(value) => {
              prepareText(value);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius:'20px',                
              }),
            }}
          />
        </div>
      </div>
      <div className={twdClass("text-sm mb-2")}>
        <div className={twdClass("flex justify-between items-center")}>
          <label className={twdClass("text-sm opacity-75")}>{textName}:</label>
          <div className={twdClass("flex items-center")}>
            <BtnEdit
              className={'mr-2'}
              title="Edit text"
              onClick={() => setShowTxt(!showTxt)}
            >              
            </BtnEdit>
            {hasStyle && <BtnStyle              
              title="Style"
              onClick={() => {
                updateStylePanel({ sectionId: section.id, showStyles: true });
              }}
            >              
            </BtnStyle>}
          </div>
        </div>
        {showTxt && (
          <div className={twdClass("mt-4")}>
            <textarea
              rows="2"
              className={twdClass(INPUT_TEXT)}
              value={textValue}
              onChange={(e) => {
                appendPrepand(e.target.value);
              }}
            ></textarea>
          </div>
        )}
      </div>
    </div>
  );
};
