import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, getParams, styles_, links_} from "./t_util";

import textElement from '../TestDesign/data/textElement'

const process_=({obj, d})=>{
  //console.log("t_text", d)
  let {texts={}}=d  
  if(!texts.list) texts.list=[]
  obj.texts=texts  
  obj.texts=styles_({obj:obj.texts, el:texts})  
  obj.texts.styleProps.margin='0px auto'  
  return obj
}

const textElement_ = ({generated={}, obj=false}) => {
  let d = obj ? obj : textElement.a01.textElement
  let uid=  getTimeForUniqID()
  //console.log("textElement", d.styleProps)
  //console.log("getTimeForUniqID", uid)

 

  let out={   
    "metaDataId": uid,
    "blockId": uid,
    "blockType": "ELEMENTS",
    "blockDesign": "TextElement",
    "editFormName": "TEXT_ELEMENT_FORM",
    "styleProps": {},
    "stylePropsTab": {},
    "stylePropsMob": {},    
    "texts": { 
        "styleProps": {
            "margin": "0px",
            "padding": "10px",
            "background-color": "#1b1b31",      
        },
        "stylePropsTab": {},
        "stylePropsMob": {},
        "list": [
          {
            "text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            "styleProps": {
              "color": "black",
              "font-size": "20px"
            },
            "stylePropsTab": {},
            "stylePropsMob": {}
          }
        ],
    }
  }
  out=process_({obj:out, d}) 
  out=styles_({obj:out, el:d})
  out.styleProps.display='block'
  out.styleProps.width='100%'
  out.styleProps.margin='0px auto'
  //out.links=links_({links:d.links})
  console.log("textElement2", out)
  return out
};

export default textElement_;

