export const FOOTER_BLOCK_2 = {
  "blockType": "FOOTER",
  "blockDesign": "FooterDesign2",
  "editFormName": "FOOTER_FORM_2",
  "styleProps": {
    "background-color": "#3e89ff",
    "padding": "50px 50px 50px 50px"
  },
  "metaDataId": 1676191497839,
  "company": {
    "description": {
      "list": [
        [
          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        ]
      ]
    },
    "name": {
      "text": "PromoSite",
      "styleProps": {
        "color": "#ffffff",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
        "margin": "0px 0px 20px 0px",
        "font-weight": "700"
      }
    },
    "copy": {
      "text": "PromoSite 2023. All rights reserved.",
      "styleProps": {
        "margin": "20px 0px 0px 0px",
        "color": "#808080"
      }
    },
    "styleProps": {
      "width": "100%",
      "background-color": "#333333",
      "padding": "20px 20px 20px 20px",
      "color": "#ffffff"
    },
    "logo": {
      "text": "https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676144614998.png"
    }
  },
  "column1": {
    "links": [
      {
        "label": {
          "text": "About",
          "styleProps": {
            "font-size": "1rem",
            "line-height": "1.5rem"
          },
          "stylePropsTab": {
            "font-size": "1.5rem",
            "line-height": "2rem"
          }
        },
        "url": "#about"
      },
      {
        "label": {
          "text": "Products"
        },
        "url": "#products"
      },
      {
        "label": {
          "text": "Services"
        },
        "url": "fasty"
      }
    ],
    "title": {
      "text": "Company",
      "styleProps": {
        "font-size": "1.5rem",
        "line-height": "2rem",
        "margin": "0px 0px 20px 0px"
      }
    },
    "styleProps": {
      "width": "none",
      "color": "",
      "background-color": "#808080",
      "border-radius": "0px",
      "padding": "20px 20px 20px 20px"
    }
  },
  "column2": {
    "links": [
      {
        "label": {
          "text": "hello@promosite.com",
          "styleProps": {
            "font-size": "1rem",
            "line-height": "1.5rem",
            "color": "#ffffff"
          }
        },
        "url": "fasty"
      },
      {
        "label": {
          "text": "careers@promosite.com",
          "styleProps": {
            "color": "#ffffff"
          }
        },
        "url": "fasty"
      }
    ],
    "title": {
      "text": "Get in touch",
      "styleProps": {
        "font-size": "1.5rem",
        "line-height": "2rem",
        "margin": "0px 0px 20px 0px"
      }
    },
    "styleProps": {
      "background-color": "#ffa611",
      "width": "100%",
      "padding": "20px 20px 20px 20px"
    }
  },
  "column4": {
    "title": {
      "text": "Connect",
      "styleProps": {
        "font-size": "1.5rem",
        "line-height": "2rem",
        "margin": "0px 0px 20px 0px"
      }
    },
    "links": [
      {
        "label": {
          "text": "Facebook",
          "styleProps": {
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px"
          }
        },
        "url": "http://facebook.com/promosite",
        "image": {
          "text": "https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676180930331.png"
        }
      },
      {
        "label": {
          "text": "Twitter"
        },
        "url": "http://twitter.com/promosite",
        "image": {
          "text": "https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676180951924.png"
        }
      },
      {
        "label": {
          "text": "Pinterest",
          "styleProps": {
            "padding": "0px 0px 0px 0px"
          }
        },
        "url": "",
        "image": {
          "text": "https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676181521667.png"
        }
      }
    ],
    "styleProps": {
      "background-color": "#7411ff",
      "padding": "20px 20px 20px 20px"
    },
    "type": "only_icon"
  },
  "bottom_links": {
    "links": [
      {
        "label": {
          "text": "Terms & Conditions",
          "styleProps": {
            "font-size": "1rem",
            "line-height": "1.5rem",
            "color": ""
          }
        },
        "url": ""
      },
      {
        "label": {
          "text": "Privacy Policy"
        },
        "url": ""
      },
      {
        "label": {
          "text": "Disclaimer"
        },
        "url": ""
      }
    ],
    "styleProps": {
      "padding": "50px 0px 50px 0px",
      "background-color": "#333333",
      "font-size": "1rem",
      "line-height": "1.5rem",
      "color": "#ffffff"
    }
  }
}