let sd01={ 
  buttons:[
    {"label":"Email", "url":"mailto:{{email}}"},   
    {"label":"Skype", "url":"skype:{{skype}}?chat"},  
    {"label":"Explore properties", "url":"{{details_link}}"},   
  ]
}
let a01={
  buttonElement:{    
    buttons:{
      list:[
        {
          "text": "Email",
          "url": "mailto:{{email}}",
          "styleProps": {
            "padding": "20px 40px",
            "margin": "10px",
            "color": "#333333",
            "backgroundColor": "#ed2939",
            "backgroundGradient": "linear-gradient(to right, #ed2939, #ff7f50)",
            "borderWidth": "2px",
            "borderColor": "#ffffff",
            "borderRadius": "10px",
            "fontWeight": "bold",
            "border-color": "#333333",
            "background-color": "#ffffff",
            "border-radius": "0px"
          },
          "openType": "sametab"
        },
        {
          "text": "Skype",
          "url": "skype:{{skype}}?chat",
          "styleProps": {
            "padding": "20px 40px",
            "margin": "10px",
            "color": "#333333",
            "backgroundColor": "#0a3780",
            "backgroundGradient": "linear-gradient(to right, #000080, #008080)",
            "borderWidth": "2px",
            "borderColor": "#ffffff",
            "borderRadius": "10px",
            "fontWeight": "bold",
            "background-color": "#ffffff",
            "border-color": "#333333",
            "border-radius": "0px"
          },
          "openType": "sametab"
        },
        {
          "text": "Explore properties",
          "url": "{{details_link}}",
          "styleProps": {
            "padding": "20px 40px",
            "margin": "10px",
            "color": "#ffffff",
            "backgroundColor": "#008000",
            "backgroundGradient": "linear-gradient(to right, #008000, #ffff00)",
            "borderWidth": "2px",
            "borderColor": "#ffffff",
            "borderRadius": "10px",
            "fontWeight": "bold",
            "background-color": "#116dff",
            "border-radius": "0px"
          },
          "openType": "sametab"
        }
      ],
      styleProps:{
        "padding": "50px 50px 50px 50px",
        "background-color": "#ffffff",
        "margin": "0px autopx 0px autopx"
      },
    },
    styleProps:{
      "backgroundColor": "#000000",
      "backgroundGradient": "linear-gradient(to right, #000000, #434343)",
      "padding": "50px 50px 50px 50px",
      "display": "block",
      "width": "100%",
      "margin": "0px autopx 0px autopx",
      "background-color": "#ececec"
    },  
  } 
}

let sd02={ 
  buttons:[
    {"label":"Send Email", "url":"{{email}}"}, 
    {"label":"Add Skype", "url":"{{skype}}"},    
  ]
}
let a02={
  buttonElement:{    
    buttons:{
      list:[
        {
          "text": "Send Email",         
          "styleProps": {
            "backgroundColor": "#084298",
            "color": "#ffffff",
            "borderRadius": "5px",
            "padding": "10px 30px 10px 30px",
            "margin": "10px 5px 0px 5px",
            "background-color": "#333333",
            "border-radius": "9999px"
          },
          "stylePropsMob": {
            "padding": "5px 10px",
            "margin": "5px 0px",
            "fontSize": "14px"
          }
        },
        {
          "text": "Add Skype",          
          "styleProps": {
            "backgroundColor": "#084298",
            "color": "#ffffff",
            "borderRadius": "5px",
            "padding": "10px 30px 10px 30px",
            "margin": "10px 5px 0px 5px",
            "background-color": "#333333",
            "border-radius": "9999px"
          },
          "stylePropsMob": {
            "padding": "5px 10px",
            "margin": "5px 0px",
            "fontSize": "14px"
          }
        }      
      ],
      styleProps:{        
        "borderRadius": "10px",
        "padding": "50px 50px 50px 50px",
        "textAlign": "center",
        "background-color": "#fffff",
        "margin": "0px auto",
        "border-radius": "1.5rem"
      }  
    },
    styleProps:{
      "padding": "50px 50px 50px 50px",
      "textAlign": "center",
      "display": "flex",
      "width": "100%",
      "margin": "0px auto",
      "background-color": "#f5f5f7"
    }  
  } 
}

let sd03={ 
  buttons:[
    {"label":"Send Email", "url":"{{email}}"}, 
    {"label":"Add Skype", "url":"{{skype}}"},    
  ]
}
let a03={
  buttonElement:{    
    buttons:{
      list:[
        {
          "text": "Send Email",         
          "styleProps": {
            "backgroundColor": "#084298",
            "color": "#ffffff",
            "borderRadius": "5px",
            "padding": "14px 60px 14px 60px",
            "margin": "10px 5px 0px 5px",
            "background-color": "#0078d4",
            "border-radius": "9999px",
            "border-width": "0px",
            "font-size": "1.5rem",
            "line-height": "2rem"
          }
        },
        {
          "text": "Add Skype",          
          "styleProps": {
            "color": "#333333",
            "borderRadius": "5px",
            "padding": "14px 60px 14px 60px",
            "margin": "10px 5px 0px 5px",
            "background-color": "#f5f5f5",
            "border-radius": "9999px",
            "border-width": "0px",
            "font-size": "1.5rem",
            "line-height": "2rem"
          }
        }      
      ],
      styleProps:{   
        "borderRadius": "10px",
        "padding": "50px 50px 50px 50px",
        "textAlign": "center",
        "background-color": "#fffff",
        "margin": "0px auto",
        "border-radius": "1.5rem"
      }  
    },
    styleProps:{
      "padding": "50px 50px 50px 50px",
      "textAlign": "center",
      "display": "flex",
      "width": "100%",
      "margin": "0px auto",
      "background-color": "#242424"
    }  
  } 
}



export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
