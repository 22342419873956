export const CTA_02_BLOCK = {
  blockType: "CTA",
  blockDesign: "CTADesign1",
  editFormName: "CTA_02_FORM",
  styleProps: {
    "background-color": "#1b1b31",
  },
  headings: [],
  showBtnSec: true,
  btnSecStyleProps: {},
  btnSecStylePropsTab: {},
  btnSecStylePropsMob: {},
  buttons: [
    {
      text: "Click Here",
      alt: "Click Here",
      href: "https://yahoo.com",
      styleProps: {
        "background-color": "#1b1b31",
        color: "#ffffff",
        "border-color": "#3b82f680",
      },
    },
    {
      text: "Click Here",
      alt: "Click Here",
      href: "https://yahoo.com",
      styleProps: {
        "background-color": "#1b1b31",
        color: "#ffffff",
        "border-color": "#3b82f680",
      },
    },
  ],
};
