import Header from "../components/common/Header";
import { twdClass } from "../constants/tailwind.constants";
import { BTN_CLS, INPUT_TEXT } from "../constants/var.constants";
import FaIcons from "../components/common/FaIcons";
import { useState } from "react";
import { createProjectWithTemlate } from "../api/services";

const Templates = () => {
  const [template, setTemplate] = useState({
    templateId: "",
    projectId: "",
  });

  const handleCreate = () => {
    if (template.templateId && template.projectId) {
      createProjectWithTemlate({
        ...template,
      }).then((resp) => {
        console.log(resp);
      });
    }
  };

  return (
    <div>
      <Header />
      <div className={twdClass("mt-20 m-auto w-96")}>
        <div>
          <div className={twdClass("pb-5")}>
            <div>Template Project</div>
            <input
              value={template.templateId}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                setTemplate((data) => ({
                  ...data,
                  templateId: e.target.value,
                }));
              }}
            ></input>
          </div>
          <div className={twdClass("pb-5")}>
            <div>Current Project</div>
            <input
              value={template.projectId}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                setTemplate((data) => ({
                  ...data,
                  projectId: e.target.value,
                }));
              }}
            ></input>
          </div>
          <button
            onClick={handleCreate}
            className={twdClass(
              `${BTN_CLS} text-xl mr-10 bg-blue-600 hover:bg-blue-800 text-white font-bold w-full`
            )}
          >
            <FaIcons icon="plus" className={twdClass("mr-4")} />
            Create Project With Template
          </button>
        </div>
      </div>
    </div>
  );
};

export default Templates;
