import {
  UPDATE_APP_PREF,
} from "../constants/action.constants";
import { cloneDeep, set } from "lodash";
let visualSteps = {
  current: '',
  isActive: false,
  title :'',
  desc:'',
  steps : '',
  step:1,
  options:false,
  options_bk:false,
  title_bk:'',  
  oBack:false,
}

const initialState = {
  name: "Flezr App",
  pref:{  
    autoSave: true,
  },
  visualSteps: {...visualSteps}
};

export const appdetails = (state = initialState, action) => {
  switch (action.type) {
    case "TESTING":
      return { ...state, name: action.name };
    case UPDATE_APP_PREF:
        const pref = { ...cloneDeep(state.pref), ...action.data };  
        return { ...state, pref };
      
    case "UPDATE_VISUAL_STEPS":
      return { ...state, visualSteps: { ...state.visualSteps, ...action.data } };

    default:
      return state;
  }
};
