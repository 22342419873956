import { twdClass } from "../../../../constants/tailwind.constants";
import { BTN_CLS } from "../../../../constants/var.constants";
import FaIcons from "../../../common/FaIcons";
import { Button } from "../../../ui/com_ui";


import { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import _ from "lodash";

export const arrayMove = (arr, index1, index2) => {
  let newArr = [...arr];
  newArr[index2] = arr[index1];
  newArr[index1] = arr[index2];
  return newArr;
};

const Sortable = ({list_items, cb, item_cb, items_c="relative flex flex-col"}) => {
  
  
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex == newIndex) return;
    //console.log({ oldIndex, newIndex });
    let lst = [...list_items];
    //console.log("before", lst);
    lst = arrayMove(lst, oldIndex, newIndex);
    //console.log("after", lst);
    /*lst=lst.map((item, index)=>{   
      item.settings.order=index
      return page
    })*/
    cb({      
      lst,
      oldIndex, newIndex
    }); 
  };

  const SortableItem2 = ({ v, i }) => {
    const SortableItem = SortableElement(({ value, sortIndex }) => {
      return(item_cb({value, i}))
    });
    return <SortableItem key={"item-" + i} index={i} value={v} />;
  };  

  const SortableList = SortableContainer(({ items }) => {
    //console.log("sortable items", items)
    return (
      <div className={`${twdClass(items_c)}`}>
        {items.map((value, index) => {
          return <SortableItem2 key={"item-" + index} i={index} v={value} />;
        })}
      </div>
    )
  });

  return (<SortableList items={list_items} onSortEnd={onSortEnd} hideSortableGhost={true} pressDelay={150}/>)
}
export default Sortable;