export const CONTENT_FORM_1 = {
  sectionName: "Content Section",
  elements: [
    {
      inputType: "richtext",
      label: "CONTENT",
      updateTo: "html",
      hasStyleProps: false,
    },
  ],
};
