import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";
import ToggleBtn from "./ui/ToggleBtn";


const ServiceWorker = ({ sitesettings, updateSettings }) => {
  const {script='', register_script='' } = sitesettings.serviceWorker | {}
  const updateItem = (value) => {
   // const sst = cloneDeep(sitesettings);
   // const data = set(sst, `manifest.${field}`, value);
    //updateSettings(data);
  };
  const updateField = (field, value) => {    
    const sst = cloneDeep(sitesettings);
    const data = set(sst, `serviceWorker.${field}`, value);
    updateSettings(data);
  };

  return (
    <div>
      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("mb-4")}>
          <p className={twdClass("text-md font-semibold")}>Service Worker</p>
          <p className={twdClass("text-sm text-gray-400 lg:pr-24")}>we will automatically register the Service Worker when you enable this and add the Service Worker Script.</p>
        </div>
        
        <ToggleBtn
          toggleId={"showfilter"}
          value={sitesettings?.serviceWorker?.isEnable}
          falseLabel={`Off`}
          trueLabel="On"
          updateValue={(value) => {
            console.log("isENable", value)
            updateField("isEnable", value);
          }}
        />        
      </div>
      {/*<div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md font-semibold mb-4")}>Register Script</div>
        <textarea
          rows={10}
          value={sitesettings.serviceWorker.register_script}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateField('register_script', e.target.value)}
          placeholder = {`{ \n\t"background_color": "purple",\n\t"display": "fullscreen"\n}`}
        ></textarea>
  </div>*/}


      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md font-semibold mb-4")}>Javascript</div>
        <textarea
          rows={10}
          value={sitesettings?.serviceWorker?.script}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateField('script', e.target.value)}
          placeholder = {`self.addEventListener('install', event => { \n\t event.waitUntil( \n\t\t ... \n\t); \n });`}
        ></textarea>
      </div>      
    </div> 
  );
};

export default ServiceWorker;

