import { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { twdClass } from "../constants/tailwind.constants";
import { INPUT_TEXT, BTN_NORMAL, BTN_CLS } from "../constants/var.constants";
import {Button} from "../components/ui/com_ui";
import {Layout, Error} from "./Layout";
import styles from "./login.module.css";

export function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const { signIn } = useAuth();

  const history = useHistory();
  const [error, setError] = useState(null);
  async function handleLoginSubmit(e) {
    e.preventDefault();
    setError(null);

    // Get email and password input values
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    // Calls `signIn` function from the context
    const { error } = await signIn({ email, password });
    console.log(error);
    if (error) {
      setError(error.message);
    } else {
      // Redirect user to Dashboard
      history.push("/");
    }
  }

  useEffect(() => {
    //document.body.classList.add(twdClass("bg-blue-100"));
    return () => {
     // document.body.classList.remove(twdClass("bg-blue-100"));
    };
  }, []);

  useEffect(() => {
    if(error){
      setTimeout(()=>{
        setError("")
      }, 2000)
    }
  }, [error]);

  const SignupLink = (<Link to="/signup" className={twdClass("text-gray-700 font-semibold")}>Create account</Link>)
  const ForgotPasswordLink = (<Link to="/forgot-password" className={twdClass("text-gray-700 font-semibold")}>Forgot Password?</Link>)
  return (
    <Layout
      title={"Log into Flezr"}
      desc={"Welcome back!"}
      action={SignupLink}
    >
      <form onSubmit={handleLoginSubmit}>
        {/*<label
          htmlFor="input-email"
          className={`input ${twdClass("")}`}
        >
          Email
        </label>*/}
        <input
          id="input-email"
          type="email"
          ref={emailRef}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Email"}
        />

        {/*<label
          htmlFor="input-password"
          className={`input ${twdClass("")}`}
        >
          Password
        </label>*/}
        <input
          id="input-password"
          type="password"
          ref={passwordRef}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Password"}
        />

        <br />

        {/* <button
          type="submit"
          className={`${twdClass(BTN_NORMAL)} ${twdClass("w-20")}`}
        >
          Login
        </button> */}
        <button
          type="submit"
          className={twdClass("block w-full")}          
        >
          <Button type={"defualt"} color={"primary"} size="lg">Login</Button>
        </button>
        <div className={twdClass("text-sm mt-4")}>{ForgotPasswordLink}</div>

        <Error error={error}/>
      </form>
      {/* <p>
        Don't have an account? <Link to="/signup">Sign Up</Link>
      </p> */}
      {/*<p className={twdClass("text-sm")}>
        Don't have an account?{" "}
        <Link
          to="/signup"
          className={twdClass("text-blue-600 font-semibold")}
        >
          Sign Up
        </Link>
      </p>*/}
    </Layout>
  );
}
