import { TextElementPanel } from "./TextElementPanel";
import { OpenLinkIn } from "./OpenLinkIn";

export const LinkElementPanel = ({
  columnNames = [],
  section,
  updateValues,
  updateStylePanel,
}) => {
  return (
    <div>
      <TextElementPanel
        columnNames={columnNames}
        updateValues={updateValues}
        section={section}
        updateStylePanel={updateStylePanel}
        textName="Link Text/Styles"
      />
      <OpenLinkIn
        columnNames={columnNames}
        updateValues={updateValues}
        section={section}
        updateStylePanel={updateStylePanel}
      />
    </div>
  );
};
