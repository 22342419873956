import { twdClass } from "../../../../constants/tailwind.constants";
import { INPUT_TEXT, BTN_CLS } from "../../../../constants/var.constants";
import { CONNECTIONS_METADATA } from "../../connections/connection.metadata";


const DynamicConnectionType = ({ connectionType, setConnectionType }) => {
  return (
    <div className={twdClass("flex items-center mb-2")}>
      <label className={twdClass("w-40 text-sm")}>
        Connection Type
        <span className={twdClass("text-red-600")}>*</span>
      </label>
      <div className={twdClass("flex-1")}>
        <select
          className={twdClass(INPUT_TEXT)}
          onChange={(e) => {
            setConnectionType(e.target.value);
          }}
          value={connectionType}
        >
          <option value="">Select</option>
          {CONNECTIONS_METADATA.map((item) => (
            <option value={item.key}>{item.value}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DynamicConnectionType;
