import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import {
  TOGGEL_CONFIRM_POPUP,
  RESET_CONFRIM_POPUP,
} from "../../constants/action.constants";
import { useState } from "react";
import {
  INPUT_TEXT,
  BTN_CLS,
  LABEL_INPUT_TEXT,
} from "../../constants/var.constants";
import { toast } from "react-toastify";
import ToggleBtn from "../common/ToggleBtn";
import { checkCustomDomainUrl } from "../../utils/common.utils";
import {
  CUSTOM_DOMAIN_ERROR,
  SUB_DOMAIN_ERROR,
} from "../../constants/message.constants";
import { checkDomainAvailbity, updateProject } from "../../api/services";
import FaIcons from "../common/FaIcons";
import { Button } from "../ui/com_ui";

const CreateProjectPanel = (props) => {
  const dispatch = useDispatch();
  const { REACT_APP_DOMAIN_NAME } = process.env;
  const {
    projectData: { project_name, domain, domain_type },
    projects,
  } = props;
  const [projectName, setProjectName] = useState(project_name);
  const [projectUrl, setProjectUrl] = useState(domain);
  const [domianType, setDomainType] = useState(domain_type || "SUB_DOMIAN");
  const [urlError, setUrlError] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const { max_cust_domain } = useSelector((state) => state.userdetails);

  const checkAvailbility = () => {
    checkDomainAvailbity({ domain: projectUrl }).then((resp) => {
      if (resp.success && resp.data) {
        setUrlError("");
      } else {
        setUrlError(
          "This domain name is already taken. Please use another domain name."
        );
      }
      setIsChecked(true);
    });
  };

  const updateProjectInfo = () => {
    const postData = {
      ...props.projectData,
      project_name: projectName,
      domain: projectUrl,
      domain_type: domianType,
    };

    let cusDomLen = projects.filter((itm) => itm.domain_type === "CUSTOM")
      .length;

    if (domianType === "CUSTOM" && cusDomLen + 1 > max_cust_domain) {
      props.closeProjectDetails();
      dispatch({
        type: TOGGEL_CONFIRM_POPUP,
        data: {
          showConfirmPopup: true,
          title: "Upgrade Plan",
          confirmLabel: `Max allowed cutom domain are ${max_cust_domain} only for this plan. Please upgrade current plan.`,
          confrimCallBack: (info) => {
            dispatch({ type: RESET_CONFRIM_POPUP });
          },
        },
      });
    } else {
      updateProject(postData).then(async (resp) => {
        if (resp.success) {
          toast.success("Page successfully saved");
          props.getProjects();
          props.closeProjectDetails();
        }
      });
    }
  };
  return (
    <div>
      <div className={"create-project-layout"}>
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>Project Name</label>
          <div className={twdClass("flex-1")}>
            <input
              type="text"
              placeholder="Project Name"
              value={projectName}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>Domain Type</label>
          <div className={twdClass("flex-1 flex items-center")}>
            <ToggleBtn
              value={domianType === "CUSTOM"}
              falseLabel={`Use .${REACT_APP_DOMAIN_NAME}`}
              trueLabel="Use custom domain"
              updateValue={(value) => {
                setDomainType(value ? "SUB_DOMIAN" : "CUSTOM");
                setProjectUrl("");
                setUrlError("");
              }}
            />
            {domianType === "CUSTOM" && (
              <a
                href="https://help.flezr.com/how-to/add-custom-domain"
                target="_blank"
                className={twdClass(
                  "text-xs font-bold text-blue-500 underline italic"
                )}
              >
                <FaIcons icon="infoCircle" className="mr-2" /> (Domain settings)
              </a>
            )}
          </div>
        </div>
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>
            Domain
            <span className={twdClass("ml-2 text-xs text-gray-400")}>
              (Ex: www.example.com)
            </span>
          </label>

          <div className={twdClass("flex-1 flex")}>
            {domianType === "CUSTOM" ? (
              <input
                type="text"
                placeholder={""}
                className={`${twdClass("w-2/3")} ${twdClass(
                  LABEL_INPUT_TEXT
                )} `}
                value={projectUrl}
                onChange={(e) => {
                  const url = e.target.value.toLowerCase();
                  setProjectUrl(url);
                  setIsChecked(false);
                  const httpreg = /^((http|https|ftp):\/\/)|(www\.)|[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/.test(
                    url
                  );
                  if (httpreg || checkCustomDomainUrl(url)) {
                    setUrlError(CUSTOM_DOMAIN_ERROR);
                  } else {
                    setUrlError("");
                  }
                }}
              />
            ) : (
              <div className={`${twdClass("inline-block w-2/3")}`}>
                <input
                  type="text"
                  placeholder={""}
                  className={`${twdClass(LABEL_INPUT_TEXT)} ${twdClass(
                    "w-2/3"
                  )}`}
                  value={projectUrl.split(`.${REACT_APP_DOMAIN_NAME}`)[0]}
                  onChange={(e) => {
                    const url = e.target.value.toLowerCase();
                    setProjectUrl(`${url}.${REACT_APP_DOMAIN_NAME}`);
                    setIsChecked(false);
                    if (
                      url.length < 6 ||
                      /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/.test(url)
                    ) {
                      setUrlError(SUB_DOMAIN_ERROR);
                    } else {
                      setUrlError("");
                    }
                  }}
                />{" "}
                <span>{`.${REACT_APP_DOMAIN_NAME}`}</span>{" "}
              </div>
            )}
            <div className={twdClass("w-48 text-center")}>
              <Button
                type="default"
                color="black"
                disabled={!projectUrl || !!urlError}
                onClick={() => checkAvailbility()}
              >
                Check Availbility
              </Button>
            </div>
          </div>
        </div>
        <div className={twdClass("text-red-500 text-sm")}>{urlError}</div>
        <div className={twdClass("mt-4 text-right")}>
          <div className={twdClass("w-full text-right mb-2 flex justify-end")}>
            <Button
              type="default"
              color="primary"
              disabled={!projectName || !projectUrl || !!urlError || !isChecked}
              onClick={() => updateProjectInfo()}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectPanel;
