import React from "react";
import { useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { useSelector, useDispatch } from "react-redux";
import { debug, getUserCheck} from "../../api/services";

export function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useAuth();
  const [isCheckDone, setCheckDone] = useState(false);
  const [isCheck, setCheck] = useState({});

  useEffect(async() => {
    const { user : check } = await getUserCheck();
    console.log("check data", check)
    setCheck(check)
    setCheckDone(true)
    return () => {};
  },[]);

  const {plan_id=0} = useSelector(
    (state) => state.userdetails
  );

  let {onboardingFormDisplayed=0, onboardingFormFilled} = isCheck
  console.log("isCheck data", onboardingFormFilled)
  
  if(isCheckDone){
    return (
      <Route
        {...rest}
        render={(props) => {
          // Renders the page only if `user` is present (user is authenticated)
          // Otherwise, redirect to the login page
          return user ? (plan_id==100000 && onboardingFormDisplayed < 4 && !onboardingFormFilled) ? <Redirect to="/onboarding/start" /> : <Component {...props} /> : <Redirect to="/login" />;
        }}
      ></Route>
    );
  }else{
    return (<></>)
  }
  
}
