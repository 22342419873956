import { find, findIndex } from "lodash";
import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "../common/FaIcons";
import { useState, useEffect } from "react";
import Select from "react-select";
import { INPUT_TEXT } from "../../constants/var.constants"
import { Button, BtnStyle, BtnBottom, BtnTop, BtnDelete} from "../ui/com_ui";
import ToggleBtn from "../common/ToggleBtn";

const CE1 = twdClass("p-2 clear-both  flex items-center");
const CE2 = twdClass("flex items-center justify-center h-6 w-6 cursor-pointer");
const CE3 = twdClass("text-sm font-semibold cursor-pointer flex-1 capitalize");


export const CardLinkPanel = ({
  columnNames,
  cardLink,
  cardConfig, 
  updateElementValue
}) => {
  const [expand, setExpand] = useState(false); 
  const optionList = columnNames.map((itm) => ({
    value: itm.key,
    label: itm.value,
  }));

  return (
    <div className={twdClass("my-3 bg-gray-50 border border-gray-100  hover:border-gray-200 rounded-2xl px-1 py-1")}>
      <div className={CE1}>
        <span className={CE2} onClick={() => setExpand(!expand)}>
          <FaIcons
            icon={`${expand ? "angleUp" : "angleDown"}`}
            className={twdClass("text-sm")}
          />
        </span>
        <span className={CE3} onClick={() => setExpand(!expand)}>
          Card Link
        </span>
        <span className={twdClass("flex items-center")}>          
          
        </span>
      </div>
      {expand && (
        <div className={twdClass("p-2 bg-white border border-gray-100 hover:border-gray-200 rounded-xl")}>
          <div className={twdClass("flex items-center justify-between mb-2")}>
            <label className={twdClass("text-sm mr-2")}>Active:</label>
            <ToggleBtn
              toggleId={"active"}
              value={cardLink.active}
              falseLabel={`Off`}
              trueLabel="On"
              updateValue={(value) => {
                updateElementValue("cardLink.active", !value);
              }}
            />
          </div>  
          <div className={twdClass("items-start mb-4")}>
            <div className={twdClass("w-36 text-sm mb-2")}>Map Column:</div>
            <div className={twdClass("w-full text-xs rounded-full")}>
              <Select
                isMulti = {false}
                name="text-element"
                defaultValue={cardLink.param ? cardLink.param : {}}
                options={optionList}
                onChange={(item) => {
                  console.log(item)
                  //prepareText(value);
                  updateElementValue("cardLink.param", item);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius:'20px',                
                  }),
                }}
              />
            </div>
          </div>
          <div className={twdClass("flex items-center mb-2")}>
            <div className={twdClass("w-36 text-sm mb-1")}>Open Link:</div>
            <div className={twdClass("w-full")}>
              <select
                value={cardLink.target ? cardLink.target : ''}
                className={twdClass(INPUT_TEXT)}
                onChange={(e) => {
                  updateElementValue("cardLink.target", e.target.value);
                }}
              >
                <option value="sametab">Same Tab</option>
                <option value="newtab">New Tab</option>
              </select>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};