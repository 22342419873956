let sd01={
  heading:"Make your paycheck work harder with Payment Wallet.",
  description:"If your employee uses Payment, the free Payment Wallet app is for you. It's a smart way to track, save, and access your hard earned money.",
  copy:"© Copyright 2024 Payment. All Rights Reserved.",
  button:"Get Started",
  helptext:"Want to use Wallet? Log in to Payment Wallet"
}

let a01={
  header:{
    position:"left",
    heading:{text:"Make your paycheck work harder with Payment Wallet.", styleProps:{fontSize:'48px', fontWeight:'bold', color:"#ffffff",  padding:"0px 0px 20px 0px"}},
    description:{text:"If your employee uses Payment, the free Payment Wallet app is for you. It's a smart way to track, save, and access your hard earned money.", styleProps:{fontSize:'14px', color:"#ffffff", padding:"0px 0px 40px 0px"}},
    typewriterText:{text:"Build custom apps less time", styleProps:{fontSize:'24px', color:"#ffffff", padding:"0px 0px 40px 0px"}},
    button:{text:"Get Started", styleProps:{border:'2px solid #ffffff', padding:'10px 20px', marginBottom:'20px', borderRadius:'10px', fontSize:'20px', color:"#ffffff"}},
    helptext:{text:"Want to use Wallet? Log in to Payment Wallet", styleProps:{fontSize:'14px', color:"#ffffff"}},
    styleProps:{backgroundColor:'#1b1b31', padding:'100px 20px', backgroundSize:'cover', "background-image": "url('https://images.pexels.com/photos/2678468/pexels-photo-2678468.jpeg?auto=compress&cs=tinysrg')"},
    stylePropsMob:{padding:'60px 20px'}  
  } 
}


let sd02={
  heading:"Make your paycheck work harder with Payment Wallet.",
  description:"If your employee uses Payment, the free Payment Wallet app is for you. It's a smart way to track, save, and access your hard earned money.",
  copy:"© Copyright 2024 Payment. All Rights Reserved.",
  button:"Get Started",
  helptext:"Want to use Wallet? Log in to Payment Wallet"
}

let a02={
  header:{
    position:"right",
    heading:{text:"Make your paycheck work harder with Payment Wallet.", 
      styleProps:{fontSize:'48px', fontWeight:'bold', color:"#ffffff",  padding:"0px 0px 20px 0px", lineHeight:1.2},
      stylePropsMob:{fontSize:'32px'}},
    description:{text:"If your employee uses Payment, the free Payment Wallet app is for you. It's a smart way to track, save, and access your hard earned money.", 
      styleProps:{fontSize:'14px', color:"#ffffff", padding:"0px 0px 40px 0px"},
      stylePropsMob:{fontSize:'16px'}},
    typewriterText:{text:"Build custom apps less time", 
      styleProps:{fontSize:'24px', color:"#ffffff", padding:"0px 0px 40px 0px"},
      stylePropsMob:{fontSize:'32px'}},
    button:{text:"Get Started", styleProps:{backgroundColor:'#000000', padding:'10px 20px', marginBottom:'20px', borderRadius:'10px', fontSize:'20px', color:"#ffffff"}},
    helptext:{text:"Want to use Wallet? Log in to Payment Wallet", styleProps:{fontSize:'14px', color:"#ffffff"}},
    styleProps:{backgroundColor:'#1b1b31', padding:'100px 20px', backgroundSize:'cover', "background-image": "linear-gradient(to right,#f12711,#f5af19)",},
    stylePropsMob:{padding:'60px 0px'}  
  } 
}

let sd03={
  heading:"Make your paycheck work harder with Payment Wallet.",
  description:"If your employee uses Payment, the free Payment Wallet app is for you. It's a smart way to track, save, and access your hard earned money.",
  copy:"© Copyright 2024 Payment. All Rights Reserved.",
  button:"Get Started",
  helptext:"Want to use Wallet? Log in to Payment Wallet"
}

let a03={
  header:{
    position:"center",
    heading:{text:"Make your paycheck work harder with Payment Wallet. R", 
      styleProps:{"text-align": "center", fontSize:'48px', fontWeight:'bold', color:"#333333",  padding:"0px 0px 20px 0px", lineHeight:1.2},
      stylePropsMob:{fontSize:'32px'}},
    description:{text:"If your employee uses Payment, the free Payment Wallet app is for you. It's a smart way to track, save, and access your hard earned money.", 
      styleProps:{"text-align": "center", fontSize:'14px', color:"#333333", padding:"0px 0px 40px 0px"},
      stylePropsMob:{fontSize:'16px'}},
    typewriterText:{text:"Build custom apps less time", 
      styleProps:{"text-align": "center", fontSize:'24px', color:"#333333", padding:"0px 0px 40px 0px"},
      stylePropsMob:{fontSize:'32px'}},
    button:{text:"Get Started", styleProps:{backgroundColor:'#000000', padding:'10px 20px', marginBottom:'20px', borderRadius:'10px', fontSize:'20px', color:"#ffffff"}},
    helptext:{text:"Want to use Wallet? Log in to Payment Wallet", styleProps:{fontSize:'14px', color:"#333333"}},
    styleProps:{backgroundColor:'#1b1b31', padding:'100px 20px', backgroundSize:'cover', "background-image": "linear-gradient(to right,#50C9C3,#96DEDA)",},
    stylePropsMob:{padding:'60px 0px'}  
  } 
}


export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
