import Dropdown from 'react-multilevel-dropdown';
import { twdClass } from "../../constants/tailwind.constants";
import { useAuth } from "../../contexts/Auth";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { BTN_NORMAL, INPUT_TEXT, BTN_CLS } from "../../constants/var.constants";
import { Dropdown as Dropdown2, ToolTip} from "../ui/com_ui";
import { TutorialsTip, ProjectTip, CustomiseCardTip, ConnectDataSourceTip} from "../content/tooltips";
import {VisualSteps} from "../ui/visual_steps_ui";
import {customise_card_vs, connect_data_source_vs} from "../content/vs_data";



import {
  createProject,
  getProjectList,
  deleteProject,
  duplicateProject,
  saveProject,
} from "../../api/services";
import { useEffect, useState } from "react";
import { getLowerCaseHashId } from "../../utils/hashid.utils";
import party from "party-js";
import {
  TOGGEL_POPUP,
  RESET_POPUP,
  TOGGEL_CONFIRM_POPUP,
  RESET_CONFRIM_POPUP,
} from "../../constants/action.constants";
import CreateProjectPanel from "./CreateProjectPanel";
import FaIcons from "../common/FaIcons";

const ProjectsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);

  const { pageInfo } = useSelector((state) => state.studioDetails);
  const { max_cust_domain, max_sub_domain } = useSelector(
    (state) => state.userdetails
  );
  const upgradePopup = () => {
    dispatch({
      type: TOGGEL_CONFIRM_POPUP,
      data: {
        showConfirmPopup: true,
        title: "Upgrade Plan",
        confirmLabel:
          "Please upgrade current plan to create more projects.",
        confrimCallBack: (info) => {
          dispatch({ type: RESET_CONFRIM_POPUP });
          history.push(`/pricing`);
        },
      },
    });
  }
  const projectTemplates = async () => {    
    /*const maxAllowed = max_cust_domain + max_sub_domain;
    if (projects.length + 1 > maxAllowed) {
      console.log(maxAllowed)
      upgradePopup()
    } else {
      console.log(maxAllowed)
      //history.push(`/projecttemplates`);
    }*/
    history.push(`/projecttemplates`);
  };
  const createNewProject = async () => {
    try {
      const maxAllowed = max_cust_domain + max_sub_domain;
      if (projects.length + 1 > maxAllowed) {
        upgradePopup()
      } else {
        const { data } = await createProject({
          project_name: "PROJECT",
        });
        await saveProject({ id: data.project_id, pageInfo });

        party.confetti(document.querySelector("body"), {
          count: party.variation.range(20, 40),
        });
        history.push(`/studio/${getLowerCaseHashId(data.project_id)}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delSelectedProject = async (info) => {
    try {
      await deleteProject(info.project_id);
      getProjects();
      dispatch({ type: RESET_CONFRIM_POPUP });
    } catch (error) {
      dispatch({ type: RESET_CONFRIM_POPUP });
    }
  };

  const onDeleteProject = (item) => {
    dispatch({
      type: TOGGEL_CONFIRM_POPUP,
      data: {
        showConfirmPopup: true,
        confirmLabel: "Are you sure, You want to delete Project?",
        selectProject: item,
        confrimCallBack: (info) => delSelectedProject(info),
      },
    });
  };

  const dupSelectedProject = async (info) => {
    try {
      //let data2=await duplicateProject({id: info.project_id, proInfo:{...info, project_name: "Duplicate PROJECT"}});  
      let data2=await duplicateProject({id: info.project_id, proInfo:{...info}});        
      console.log("duplicate", {id: info.project_id, proInfo:info}) 
      console.log("duplicate data", data2)  
      party.confetti(document.querySelector("body"), {
        count: party.variation.range(20, 40),
      });
      history.push(`/studio/${getLowerCaseHashId(data2.data.project_id)}`);
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: RESET_CONFRIM_POPUP });
    //console.log("duplicate", info)    
  };

  const onDuplicateProject = (item) => {
    const maxAllowed = max_cust_domain + max_sub_domain;
    if (projects.length + 1 > maxAllowed) {
      upgradePopup()
    } else {
      dispatch({
        type: TOGGEL_CONFIRM_POPUP,
        data: {
          showConfirmPopup: true,
          confirmLabel: "Are you sure, You want to duplicate this Project?",
          selectProject: item,
          confrimCallBack: (info) => dupSelectedProject(info),
        },
      });
    }
  };

  const changeProjectDetails = (item) => {
    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        title: "Project Info",
        component: (
          <CreateProjectPanel
            projects={projects}
            projectData={item}
            getProjects={getProjects}
            closeProjectDetails={closeProjectDetails}
          />
        ),
      },
    });
  };

  const closeProjectDetails = () => {
    dispatch({
      type: RESET_POPUP,
    });
  };

  const getProjects = async () => {
    const { success, data } = await getProjectList();
    if (success) {
      setProjects(data);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);
  //console.log("projects", projects)
  return (
    <div>
      <div className={twdClass("bg-gray-100 px-8 py-20")}>
        <div className={twdClass("mt-5 flex flex-wrap lg:container mx-auto mb-4")} style={{maxWidth:"1400px"}}>
          <div className={twdClass("flex-grow")}>            
            <div  className={twdClass("flex items-center")}>
              <div  className={twdClass("mr-8")} >
                <img alt="" width="44px" src="/assets/images/home-02.svg" />
              </div>
              <div className={twdClass("text-5xl font-semibold")}>
                My Projects
              </div>              
            </div>
            <div className={twdClass("mt-12 ml-20")}>
              Create, edit &amp; manage  your projects
            </div>
          </div>
          <div className={twdClass("flex flex-wrap items-center")}>
            <button
              onClick={projectTemplates}
              className={twdClass(
                `${BTN_CLS} flex items-center px-10 py-3 text-lg mr-4 bg-white hover:bg-primary-100 text-black font-semibold rounded-full `
              )}
            >  
              <span  className={twdClass("mr-4")} >
                <img alt="" width="36px" src="/assets/images/plus-01.svg" />   
              </span>                                  
              Create from Template
            </button>
            <button
              onClick={createNewProject}
              className={twdClass(
                `${BTN_CLS} flex items-center px-10 py-3 text-lg mr-10 bg-black hover:bg-gray-900 text-white font-semibold rounded-full `
              )}
            >  
              <span  className={twdClass("mr-4")} >
                <img alt="" width="36px" src="/assets/images/plus-01-w.svg" />   
              </span>                                  
              Create from Scratch
            </button>
          </div>
        </div>      
        <div
          className={twdClass(
            "grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 lg:container mx-auto bg-white rounded-xl "
          )}
          style={{maxWidth:"1400px"}}
        >
          {projects.map((item, index) => {
            return (
              <div key={index} className={twdClass("w-full relative  hover:z-10")}>
                <div className={twdClass("hover:bg-primary-100 px-12 py-8 h-full ml-0 md:mr-0 block border-b border-gray-200")}>                 
                  <div
                    className={twdClass(
                      "h-full transform flex items-center"
                    )}
                  >
                    <div className={twdClass("mr-8")}><ProjectTip /></div>
                    <div className={twdClass("flex-grow -mt-1")}>
                      <h3
                        className={twdClass(
                          "my-2 text-xl font-bold text-gray-800"
                        )}
                      >
                        {item.project_name}
                      </h3>
                      <p
                        className={twdClass(
                          "mt-2 text-md text-opacity-50"
                        )}
                      >
                        <a
                          href={`https://${item.domain}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`https://${item.domain}`}
                        </a>
                      </p>
                    </div>
                    
                    {/*<div className={twdClass("flex mt-10")}>
                      <Link
                        className={twdClass(
                          "block p-2 m-2 w-1/3 bg-blue-100 transform text-2xl text-gray-800 text-center rounded-xl cursor-pointer"
                        )}
                        to={`/studio/${getLowerCaseHashId(item.project_id)}`}
                      >
                        <FaIcons icon="edit" />
                      </Link>
                      <span
                        className={twdClass(
                          "block p-2 m-2 w-1/3 bg-blue-100 transform text-2xl text-gray-800 text-center rounded-xl cursor-pointer"
                        )}
                        onClick={() => changeProjectDetails(item)}
                      >
                        <FaIcons icon="cog" />
                      </span>
                      <span
                        className={twdClass(
                          "block p-2 m-2 w-1/3 bg-blue-100 transform text-2xl text-gray-800 text-center rounded-xl cursor-pointer"
                        )}
                        onClick={() => onDeleteProject(item)}
                      >
                        <FaIcons icon="trash" />
                      </span>
                    </div>*/} 
                    
                    <a href={`https://${item.domain}`}  target="_blank" rel="noreferrer" className={twdClass("flex items-center px-6 py-3 rounded-full cursor-pointer hover:shadow-md active:shadow-sm  hover:bg-white transform active:bg-white hover:-translate-y-px active:translate-y-0")}>
                      <img alt="" width="24px" src="/assets/images/link-external.svg" />
                      <span className={twdClass("ml-4 flex-grow")}>View</span>                  
                    </a>
                    <Link to={`/studio/${getLowerCaseHashId(item.project_id)}`} className={twdClass("flex items-center px-6 py-3 bg-primary-500 rounded-full mx-4 cursor-pointer  hover:shadow-md active:shadow-sm transform hover:-translate-y-px active:translate-y-0")}>
                      <img alt="" width="24px" src="/assets/images/edit-03.svg" />
                      <span className={twdClass("ml-4 flex-grow")}>Edit</span>                  
                    </Link>                          
                    {/*<Dropdown2 
                      title={<div className={twdClass("px-2 py-2 rounded-full bg-white hover:shadow-lg active:shadow-sm")}><img alt="" width="36px" src="/assets/images/dot-horizontal.svg" />  </div>}
                    >
                      <div >
                        <div className={twdClass(" py-1 text-md font-semibold")} style={{width:"200px"}}>
                          <Link to={`/studio/${getLowerCaseHashId(item.project_id)}`} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                           <span className={twdClass("ml-4 flex-grow")}>Edit</span>                  
                          </Link>
                          <div onClick={() => changeProjectDetails(item)} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                            <span className={twdClass("ml-4 flex-grow")}>Settings</span>                  
                          </div>
                          <div onClick={() => onDeleteProject(item)} className={twdClass("flex items-center px-6 py-3 bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                            <span className={twdClass("ml-4 flex-grow")}>Delete</span>                  
                          </div>                                              
                        </div>
                      </div>    
                    </Dropdown2>*/}                    
                    <div>
                    <Dropdown                   
                      menuClassName={twdClass(" ")}
                      position="right"
                      buttonVariant=""
                      title={<div className={twdClass("px-2 py-2 rounded-full transform hover:-translate-y-px active:translate-y-0")}><img alt="" width="30px" src="/assets/images/dot-horizontal.svg" />  </div>}
                    >
                      <div>
                        <div className={twdClass(" py-1 text-md font-semibold")} style={{width:"200px"}}>
                          <Link to={`/studio/${getLowerCaseHashId(item.project_id)}`} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                           <span className={twdClass("ml-4 flex-grow")}>Edit</span>                  
                          </Link>
                          <div onClick={() => changeProjectDetails(item)} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                            <span className={twdClass("ml-4 flex-grow")}>Settings</span>                  
                          </div>
                          <div onClick={() => onDuplicateProject(item)} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                            <span className={twdClass("ml-4 flex-grow")}>Duplicate</span>                  
                          </div> 
                          <div onClick={() => onDeleteProject(item)} className={twdClass("flex items-center px-6 py-3 bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
                            <span className={twdClass("ml-4 flex-grow")}>Delete</span>                  
                          </div>                                              
                        </div>
                      </div>            
                    </Dropdown>  
                    </div>               
                  </div>                  
                </div>
              </div>
            );
          })}
          <div className={twdClass(
            "bg-gray-50 rounded-xl px-12 py-8 text-right"
          )}>
            {projects.length >1 && <span className={twdClass("text-gray-900 text-opacity-50 ")}>{projects.length} projects</span>}
          
          </div>
        </div>
      </div>
      <Tutorials />
    </div>
  );
};

export default ProjectsList;

const Tutorials = () => {
  let data=[
    {title:'Customizing the design of a card', img:'Tutorials-01.png', vs_data:customise_card_vs,
      link:"https://help.flezr.com/building-your-app/creating-and-customizing-cards/customizing-the-design-of-a-card"},
    {title:'Connecting Data Source: Google Sheets/Supabase', img:'Tutorials-02.png', vs_data:connect_data_source_vs,
      link:"https://help.flezr.com/building-your-app/connecting-data-source-google-sheets-supabase"},
    {title:'External JavaScript & External CSS.', img:'Tutorials-03.png', vs_data:connect_data_source_vs,
      link:"https://help.flezr.com/building-your-app/creating-and-customizing-cards/configure-data-source"}
  ]
  return(
    <div className={twdClass("px-8 py-20")}>
      <div className={twdClass("mt-5 flex flex-wrap lg:container mx-auto mb-8")} style={{maxWidth:"1400px"}}>
        <div className={twdClass("flex-grow")}>            
          <div  className={twdClass("flex items-center")}>
            <div  className={twdClass("mr-4")} >
              <img alt="" width="36px" src="/assets/images/play-03.svg" />
            </div>
            <div className={twdClass("flex items-center")}>
              <div  className={twdClass("text-4xl font-semibold")}>Tutorials</div>
              <div className={twdClass("ml-4")}>
                <TutorialsTip />               
              </div>    
            </div>              
          </div>
          <div className={twdClass("mt-12 ml-14")}>
            Watch videos to learn about flezr
          </div>
          <div className={twdClass("flex flex-wrap")}>
            {data.map(({title, link, img, vs_data}, index)=>{
              return(
                <div key={index} className={twdClass("w-4/12 px-3 py-3")}>                  
                  <VisualSteps       
                    {...vs_data}  
                  >
                    <div className={twdClass("cursor-pointer block group bg-gray-100 shadow-sm overflow-hidden hover:shadow-lg active:shadow-sm rounded-lg transform hover:-translate-y-px active:translate-y-0")}>
                      <div className={twdClass("relative ")} style={{paddingTop:"60%"}}>
                        <div  className={twdClass("absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-center bg-cover")} style={{backgroundImage:`url("/assets/images/content/${img}")`}}>
                          {/*<img alt="" width="36px" src="/assets/images/play-03.svg" />*/}
                          <div className={twdClass("absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-0 ")} ></div>
                        </div>
                      </div>                    
                    </div>
                  </VisualSteps>  
                  <div className={twdClass("text-lg font-semibold px-3 py-3")}>{title}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )

}


const Tutorials2 = () => {
  let data=[
    {title:'Customizing the design of a card', 'img':'Tutorials-01.png', vs_data:customise_card_vs,
      link:"https://help.flezr.com/building-your-app/creating-and-customizing-cards/customizing-the-design-of-a-card"},
    {title:'Connecting Data Source: Google Sheets/Supabase', 'img':'Tutorials-02.png', vs_data:connect_data_source_vs,
      link:"https://help.flezr.com/building-your-app/connecting-data-source-google-sheets-supabase"},
    {title:'Configure data source', 'img':'Tutorials-03.png',
      link:"https://help.flezr.com/building-your-app/creating-and-customizing-cards/configure-data-source"}
  ]
  return(
    <div className={twdClass("px-8 py-20")}>
      <div className={twdClass("mt-5 flex flex-wrap lg:container mx-auto mb-8")} style={{maxWidth:"1400px"}}>
        <div className={twdClass("flex-grow")}>            
          <div  className={twdClass("flex items-center")}>
            <div  className={twdClass("mr-4")} >
              <img alt="" width="36px" src="/assets/images/play-03.svg" />
            </div>
            <div className={twdClass("flex items-center")}>
              <div  className={twdClass("text-4xl font-semibold")}>Tutorials</div>
              <div className={twdClass("ml-4")}>
                <TutorialsTip />
                <ConnectDataSourceTip/>
              </div>    
            </div>              
          </div>
          <div className={twdClass("mt-12 ml-14")}>
            Watch videos to learn about flezr
          </div>
          <div className={twdClass("flex flex-wrap")}>
            {data.map(({title, link, img}, index)=>{
              return(
                <div key={index} className={twdClass("w-4/12 px-3 py-3")}>
                  <a href={link} target="_blank" className={twdClass("block group bg-gray-100 shadow-sm overflow-hidden hover:shadow-lg active:shadow-sm rounded-lg transform hover:-translate-y-px active:translate-y-0")}>
                    <div className={twdClass("relative ")} style={{paddingTop:"60%"}}>
                      <div  className={twdClass("absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-center bg-cover")} style={{backgroundImage:`url("/assets/images/content/${img}")`}}>
                        {/*<img alt="" width="36px" src="/assets/images/play-03.svg" />*/}
                        <div className={twdClass("absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-0 ")} ></div>
                      </div>
                    </div>                    
                  </a>
                  <div className={twdClass("text-lg font-semibold px-3 py-3")}>{title}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )

}
