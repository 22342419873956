import { getDefaultFields } from "../common/common.fields";

/* export const HEADER_FORM_01 = {
  sectionName: "Header 01 Section",
  elements: [
    {
      inputType: "text",
      label: "Heading",
      styleElementId: 1,
      hasStyleProps: true,
      updateTo: "heading.text",
    },
    {
      inputType: "textarea",
      label: "Description",
      styleElementId: 2,
      hasStyleProps: true,
      updateTo: "description.text",
    },
    {
      inputType: "select",
      label: "Position",
      hasStyleProps: false,
      updateTo: "position",
      options: [
        {
          display: "Left",
          value: "left",
        },
        {
          display: "Right",
          value: "right",
        },
      ],
      hideLabel: true,
    },
    {
      inputType: "simplelist",
      label: "Test Simple List",
      defaultValue: ["Text 1", "Text 2"],
      hasStyleProps: false,
      updateTo: "test_sl",
    },
    {
      inputType: "list",
      label: "Buttons",
      hasStyleProps: true,
      styleElementId: 3,
      updateTo: "buttons",
      mockElements: {
        styleElementId: 3,
        hasStyleProps: true,
        elements: [
          {
            inputType: "text",
            label: "Button Text",
            defaultValue: "Get Started",
            updateTo: "buttons[i].alt",
          },
          {
            inputType: "text",
            label: "Url",
            defaultValue:
              "https://freshflowxyz.b-cdn.net/sly-projects/1638092364746-1636304775960-_i_play_store.png",
            updateTo: "buttons[i].href",
          },
          {
            inputType: "text",
            label: "Image",
            defaultValue:
              "https://freshflowxyz.b-cdn.net/sly-projects/1638092364746-1636304775960-_i_play_store.png",
            updateTo: "buttons[i].image_src",
          },
          {
            inputType: "text",
            label: "Image Hight",
            defaultValue: "50px",
            updateTo: "buttons[i].image_style.height",
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Texts",
      hasStyleProps: false,
      updateTo: "texts",
      mockElements: {
        styleElementId: 4,
        hasStyleProps: true,
        elements: [
          {
            inputType: "text",
            label: "text",
            defaultValue: "Know more about the <b>product</b>.",
            updateTo: "texts[i].text",
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Heading",
      elements: [
        {
          inputType: "color",
          defaultValue: "#ff0000",
          label: "Color",
          updateTo: "heading.styleProps.color",
        },
        {
          inputType: "fontsize",
          defaultValue: "14px",
          label: "Font Size",
          updateTo: "heading.styleProps.font-size",
        },
        {
          inputType: "fontweight",
          defaultValue: "bold",
          label: "Font Weight",
          updateTo: "heading.styleProps.font-weight",
        },
        {
          inputType: "padding",
          label: "Padding",
          updateTo: "heading.styleProps.padding",
        },
        {
          inputType: "margin",
          label: "Margin",
          updateTo: "heading.styleProps.margin",
        },
      ],
    },
    {
      styleElementId: 2,
      label: "Description",
      elements: [
        {
          label: "Color",
          inputType: "color",
          updateTo: "description.styleProps.color",
        },
      ],
    },
    {
      styleElementId: 3,
      label: "Button Text",
      defaultValue: "Get Started",
      elements: [
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "buttons[i].styleProps.font-weight",
        },
        {
          inputType: "color",
          label: "Color",
          updateTo: "buttons[i].styleProps.color",
        },
        {
          inputType: "color",
          label: "Background Color",
          updateTo: "buttons[i].styleProps.background-color",
        },
      ],
    },
    {
      styleElementId: 4,
      label: "text",
      defaultValue: "Know more about the <b>product</b>.",
      elements: [
        {
          inputType: "fontstyle",
          label: "Font Style",
          updateTo: "texts[i].styleProps.font-style",
        },
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "texts[i].styleProps.font-weight",
        },
        {
          inputType: "fontsize",
          label: "Font Size",
          updateTo: "texts[i].styleProps.font-size",
        },
        {
          inputType: "color",
          label: "Color",
          updateTo: "texts[i].styleProps.color",
        },
      ],
    },
  ],
}; */

export const HEADER_FORM_01 = {
  sectionName: "Header 01 Section",
  elements: [        
    {
      inputType: "select",
      label: "Position",
      hasStyleProps: false,
      updateTo: "position",
      options: [
        {
          display: "Left",
          value: "left",
        },
        {
          display: "Center",
          value: "center",
        },
        {
          display: "Right",
          value: "right",
        },
      ],
      hideLabel: true,
    },   
    {
      inputType: "select",
      label: "Background Overlay",
      styleElementId: 11,
      hasStyleProps: true,
      updateTo: "bgoverlay.display",
      options: [
        {
          display: "Hide",
          value: "hide",
        },
        {
          display: "Show",
          value: "show",
        }
      ],
      hideLabel: true,
    }, 
    {
      inputType: "list",
      label: "Heading",
      hasStyleProps: true,
      styleElementId: 1,
      updateTo: "headings",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 2,
        elements: [
          {
            inputType: "textarea",
            label: "Headings",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            updateTo: "headings[i].text",
            extraPropUpdateTo: "headings[i]",
            extraPropValues: {
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              styleProps: {
                "font-size": "1.125rem",
                "line-height": "1.75rem",
                color: "#ffffff",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "simplelist",
      label: "Dynamic Text",
      defaultValue: "Build custom apps less time",
      updateTo: "dynamicText.strings",
      hasStyleProps: true,
      styleElementId: 3,
    },
    {
      inputType: "list",
      label: "Descriptions",
      hasStyleProps: true,
      styleElementId: 4,
      updateTo: "descriptions",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 5,
        elements: [
          {
            inputType: "textarea",
            label: "Descriptions",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            updateTo: "descriptions[i].text",
            extraPropUpdateTo: "descriptions[i]",
            extraPropValues: {
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              styleProps: {
                "font-size": "1.125rem",
                "line-height": "1.75rem",
                color: "#ffffff",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Buttons",
      hasStyleProps: true,
      styleElementId: 6,
      updateTo: "buttons",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 7,
        elements: [
          {
            inputType: "text",
            label: "Button",
            defaultValue: "Click Here",
            updateTo: "buttons[i].text",
            extraPropUpdateTo: "buttons[i]",
            extraPropValues: {
              text: "Click Here",
              styleProps: {
                color: "#ffffff",
                "border-color": "#3b82f680",
              },
            },
            hasStyleProps: false,
          },
          {
            inputType: "text",
            label: "Href",
            defaultValue: "#",
            updateTo: "buttons[i].href",
            hasStyleProps: false,
          },          
          {
            inputType: "select",
            label: "Open In",
            defaultValue: "sametab",
            updateTo: "buttons[i].openType",
            hasStyleProps: false,
            options: [
              { value: "sametab", display: "Same Tab" },
              { value: "newtab", display: "New Tab" },
            ],
          },
          {
            inputType: "group",
            label: "Advanced",
            initShow:false,
            elements: [
              {
                inputType: "text",
                label: "Button ID",
                defaultValue: "",
                updateTo: "buttons[i].button_id",
                hasStyleProps: false,
              }, 
              {
                inputType: "text",
                label: "Alt Text",
                defaultValue: "",
                updateTo: "buttons[i].alt",
                hasStyleProps: false,
              },             
            ]
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "componentTag",
      subType: "SubscriptionTag",
      label: "Email Subscription",
      updateTo: "subscription",
      hasStyleProps: true,
      styleElementId: 8,
    },
    {
      inputType: "list",
      label: "Help Text",
      hasStyleProps: true,
      styleElementId: 9,
      updateTo: "texts",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 10,
        elements: [
          {
            inputType: "text",
            label: "Text",
            defaultValue: "textstextstexts",
            updateTo: "texts[i].text",
            extraPropUpdateTo: "texts[i]",
            extraPropValues: {
              text: "textstextstexts",
              styleProps: {
                color: "#ffffff",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundgradient", "backgroundimage", "opacity", "padding", "margin",         
            "editall"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Text Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "headingsStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Text Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "headings[i].styleProps",
          fields: [
            "backgroundcolor",
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
            "textalign",
            "borderwidth",
            "borderradius",
            "bordercolor",         
            "editall"
          ],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Dynamic Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "dynamicText.styleProps",
          fields: [
            "backgroundcolor",
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
            "textalign",
            "borderwidth",
            "borderradius",
            "bordercolor",         
            "editall"
          ],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Descriptions Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "descriptionsStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 5,
      label: "Description",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "descriptions[i].styleProps",
          fields: [
            "backgroundcolor",
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
            "textalign",
            "borderwidth",
            "borderradius",
            "bordercolor",         
            "editall"
          ],
        }),
      ],
    },
    {
      styleElementId: 6,
      label: "Button section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "buttonsStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 7,
      label: "Button",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "buttons[i].styleProps",
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "backgroundcolor",
            "padding",
            "margin",
            "borderwidth",
            "bordercolor",
            "borderradius",         
            "editall"
          ],
        }),
      ],
    },
    {
      styleElementId: 8,
      label: "Email section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "emailSecStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 9,
      label: "Text section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textsStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 10,
      label: "Email section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "texts[i].styleProps",
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "padding",
            "margin",
            "textalign",         
            "editall"
          ],
        }),
      ],
    },
    {
      styleElementId: 11,
      label: "Bg Overlay",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "bgoverlay.styleProps",
          fields: [
            "opacity",
            "backgroundcolor",         
            "editall"           
          ],
        }),
      ],
    },
  ],
};
