import Dropdown from "./Dropdown";
import { get, endsWith, replace, find } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import FaIcons from "../../common/FaIcons";

const FontSize = ({ element, data, updateElementValue }) => {
  const [device, setDevice] = useState("");
  const { label, hideLabel, updateTo } = element;
  const upToWith = replace(updateTo, ".font-size", `${device}.font-size`);

  const value = get(data, upToWith);

  const updateValue = (selectedValue) => {
    updateElementValue(upToWith, selectedValue);
    if (endsWith(upToWith, "font-size")) {
      const updateToLH = replace(upToWith, "font-size", "line-height");
      const lineHeight = find(FONT_SIZE_OPTIONS, ["value", selectedValue]);
      updateElementValue(updateToLH, lineHeight["line-height"]);
    }
  };

  /* useEffect(() => {
    console.log();
  }, [device]); */
  return (
    <div className={twdClass("relative w-full")}>
      <div className={twdClass("absolute flex top-2 right-0 mr-10")}>
        <span
          className={twdClass(
            `mr-1 rounded-md p-2 flex justify-center items-center ${
              device === "" ? `bg-gray-200` : "bg-gray-50"
            }`
          )}
          onClick={() => setDevice("")}
        >
          <FaIcons icon="desktop" className={twdClass("text-xs")} />
        </span>
        <span
          className={twdClass(
            `mr-1 rounded-md p-2 flex justify-center items-center ${
              device === "Tab" ? `bg-gray-200` : "bg-gray-50"
            }`
          )}
          onClick={() => setDevice("Tab")}
        >
          <FaIcons icon="tablet" className={twdClass("text-xs")} />
        </span>
        <span
          className={twdClass(
            `rounded-md p-2 flex justify-center items-center ${
              device === "Mob" ? `bg-gray-200` : "bg-gray-50"
            }`
          )}
          onClick={() => setDevice("Mob")}
        >
          <FaIcons icon="mobile" className={twdClass("text-xs")} />
        </span>
      </div>
      <Dropdown
        options={FONT_SIZE_OPTIONS}
        hideLabel={hideLabel}
        label={label}
        value={value}
        updateValue={updateValue}
      />
    </div>
  );
};

export default FontSize;

const FONT_SIZE_OPTIONS = [
  { display: "xs", value: "0.75rem", "line-height": "1rem" },
  { display: "sm", value: "0.875rem", "line-height": "1.25rem" },
  { display: "base", value: "1rem", "line-height": "1.5rem" },
  { display: "lg", value: "1.125rem", "line-height": "1.75rem" },
  { display: "xl", value: "1.25rem", "line-height": "1.75rem" },
  { display: "2xl", value: "1.5rem", "line-height": "2rem" },
  { display: "3xl", value: "1.875rem", "line-height": "2.25rem" },
  { display: "4xl", value: "2.25rem", "line-height": "2.5rem" },
  { display: "5xl", value: "3rem", "line-height": "1" },
  { display: "6xl", value: "3.75rem", "line-height": "1" },
  { display: "7xl", value: "4.5rem", "line-height": "1" },
  { display: "8xl", value: "6rem", "line-height": "1" },
  { display: "9xl", value: "8rem", "line-height": "1" },
];
