import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";



const BGImageSpan = styled.span`
  background-image: ${(props) => props.color};
  width:100px;
  height:20px;
  border-radius:4px;
  display:inline-block;
  box-shadow:2px 2px 3px rgba(0,0,0,0.2);
`;

const ColorSpan = styled.span`
  background-color: ${(props) => props.color};
  width:20px;
  height:20px;
  border-radius:4px;
  display:inline-block;
  box-shadow:2px 2px 3px rgba(0,0,0,0.2);
`;


const Theme = ({theme={}, isThemeOpen, setThemeOpen}) => {
  const {fonts={}, colors={}, color_combinations=[], background_gradients=[]} = theme
  const {headings={}, sub_headings={}, default_text={}} = fonts
  const {primary='', secondary='', tertiary='', background='', text='', accent=''} = colors
  let fonts2=[headings, sub_headings, default_text]
  let colors2=[primary, secondary, tertiary, background, text, accent]
  let fonts_=fonts2.map((font, fi)=>{
    return(<div key={fi}>
      <div>{font.fontFamily}, {font.fontSize}</div>
    </div>)
  })
  let colors_=colors2.map((color, fi)=>{
    return(<div key={fi} >
      <ColorSpan color={color}></ColorSpan>
    </div>)
  })
  let background_gradients_=background_gradients?.map((grad, ci)=>{
    return(<div key={ci} >
      <div><BGImageSpan color={grad}></BGImageSpan> </div>
    </div>)
  })

  let combinations_=color_combinations?.map((comb, ci)=>{
    return(<div key={ci} >
      <div><ColorSpan color={comb.text_color}></ColorSpan> <ColorSpan color={comb.background_color}></ColorSpan> <BGImageSpan color={comb.background_gradient}></BGImageSpan> </div>
    </div>)
  })

  const Block=({title="",  content=""})=>{
    return(<div className={twdClass("px-2 py-2 rounded-xl bg-white shadow-lg")}>
      <div className={twdClass("font-semibold text-gray-500 mb-4")}>{title}</div>
      <div>{content}</div>
    </div>)
  }
  return(<div className={twdClass("flex items-start bg-gray-200 px-4 py-2 gap-4")}>
      <div onClick={()=>{setThemeOpen(prev => !prev)}}>
        <img src={`/assets/images/${isThemeOpen ? "chevron-up.svg" : "chevron-down.svg"}`}/>
      </div>
      <div>
        {!isThemeOpen && 
        <div className={twdClass("flex gap-1 pt-1")}>
          {colors_}
        </div>}
        {isThemeOpen && 
        <div className={twdClass("flex gap-4 mb-4")}>
          <Block {...{title:"Fonts", content:fonts_}}/>
          <Block {...{title:"Colors", content:colors_}}/>
          <Block {...{title:"Gradients", content:background_gradients_}}/>
          <Block {...{title:"Combinations", content:combinations_}}/>  
        </div>}
      </div>
    </div>)
}



export  default Theme