export const NAVBAR_BLOCK_1 = {
  blockType: "NAVBAR",
  blockDesign: "NavDesign1",
  editFormName: "NAVBAR_FORM_1",
  styleProps: {
    "background-color": "#1b1b31",
  },
  logoType: "text",
  logoImg: {
    src: "https://freshflowxyz.b-cdn.net/assets/logo/FlexBee-Logo-1.jpg",
    altText: "FlexBee",
    styleProps: {
      height: "3rem",
    },
  },
  logoText: {
    text: "FlexBee",
    styleProps: {
      "font-size": "1.875rem",
      color: "#ffffff",
      "font-family": "'Oswald', sans-serif",
      "line-height": "2.25rem",
    },
    stylePropsMob: {},
    stylePropsTab: {},
  },
  logoHTML: {
    html: "FlexBee",
    styleProps: {
      "font-size": "1.875rem",
      color: "#ffffff",
      "font-family": "'Oswald', sans-serif",
      "line-height": "2.25rem",
    },
    stylePropsMob: {},
    stylePropsTab: {},
  },
  links: [
    {
      text: "About Us",
      linkUrl: "/about",
      styleProps: {
        color: "#ffffff",
      },
    },
    {
      text: "Contact Us",
      linkUrl: "/contact",
      styleProps: {
        color: "#ffffff",
      },
    },
    {
      text: "Signup",
      linkUrl: "/signup",
      styleProps: {
        color: "#ffffff",
      },
    },
  ],
  hamburgerStyleProps: {
    color: "#ffffff",
    "border-color": "#ffffff",
  },
};
