
const d3=`
    {
        "position":"",
        "heading":{
            "text":"",
            "styleProps":{}
        },
        "description":{
            "text":"",
            "styleProps":{}
        },  
        "typewriterText":{
            "text":"",
            "styleProps":{}
        }, 
        "button":{
            "text":"",
            "styleProps":{}
        }, 
        "helptext":{
            "text":"",
            "styleProps":{}
        }, 
        "styleProps":{},  
    }

`

export const header_prompt=({opts={}, sdata=false, sample=false})=>{
    if(!sdata) sdata={
        heading:"No-Code Experts",
        description:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.",
    }
    if(!sample){
        sample=d3
    }
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'
    
    //console.log("header_prompt", opts, sdata)
    
    return(`
    
    'SECTION_DATA'=${JSON.stringify(sdata)}    

    'JSON_INPUT'={
        "header":${sample}
    }
    
    'WEBSITE_FOR' = "${opts.websiteFor}"
    
    Create Website Header Sections in JSON for [WEBSITE_FOR]  

    Use 'SECTION_DATA' for 'header' Content  
    
    JSON output schema sholud be [JSON_INPUT]
    
    Give me the output in JSON format and it should be valid JSON data
    
    Just give me the JSON data
    
    `)
}
    

const schema={
    "name": "header_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": { 
            "position": {
                "type": "string"
            },           
            "heading": {
                "$ref": "#/$defs/text_obj"
            },
            "description": {
                "$ref": "#/$defs/text_obj"
            },
            "typewriterText": {
                "$ref": "#/$defs/typewriter_text_obj"
            },
            "button": {
                "$ref": "#/$defs/button_obj"
            },
            "helptext": {
                "$ref": "#/$defs/button_obj"
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_obj"}                    
        },  
        "required": [ 
            "position",          
            "heading",
            "description",
            "typewriterText",
            "button",
            "helptext",
            "styleProps"       
        ],
        "additionalProperties": false,
        "$defs": {
            "text_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "typewriter_text_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "strings": { 
                        "type": "array",
                        "description": "type writer text",
                        "items":{
                            "type":"string"
                        }
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "button_obj":{
                "type": "object",
                "description": "button", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}             
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },            
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                         
        }
    }
}
export const header_format = {
    "type":"json_schema",
    "json_schema": schema
}