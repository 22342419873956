import { useRef, useState, useEffect } from "react";
import { Controller, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { twdClass } from "../constants/tailwind.constants";
import { INPUT_TEXT, BTN_NORMAL, BTN_CLS } from "../constants/var.constants";


// Import styles
import 'swiper/swiper-bundle.css';
import styles from "./login.module.css";

function Layout({children, title, desc, action}) {  
  const [isEnd, setEnd] = useState(null);
  const [isStart, setStart] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const NavButton = ({type}) => {
    let c=''
    let c2=''
    let img='arrow-left-sm.svg'
    if(type=='next'){
      c= isEnd ? 'opacity-25' : ''
      img='arrow-right-sm.svg'
    }else{
      c= isStart ? 'opacity-25' : ''
      c2='mr-4'
    }  
    const control = () => {
      if(type=='next'){
        controlledSwiper.slideNext()
      }else{
        controlledSwiper.slidePrev()
      }
    } 
    return(
      <div onClick={control} 
      className={
        twdClass(`${c} ${c2} w-16 h-16 flex items-center justify-center border rounded-full border-opacity-10 border-gray-900 transform hover:-translate-y-px active:-translate-y-0 cursor-pointer`)
      }
      >
        <img src={`/assets/images/${img}`}/>        
      </div>
    )
  }  


  return (
    <div
      className={` ${styles.layout} login-screen ${twdClass(
        "w-screen h-screen bg-gray-100"
      )}`}
    >
      <div
        className={`  ${twdClass(
          "container flex items-center mx-auto h-screen sm:py-24 sm:px-12 md:px-6"
        )}`}
      >
        <div
          className={`${styles.layout_content}  ${twdClass(
            "flex w-full h-full bg-white rounded-2xl overflow-hidden items-stretch"
          )}`}
        >        
          <div
            className={`${styles.layout_left} ${twdClass(
              "flex justify-center relative h-full"
            )}`}
          >
            <div className={`${twdClass("w-full flex flex-col justify-center text-center px-20 xl:px-36 py-12")}`}>
              <div className={`${twdClass("absolute top-0 left-0 right-0 flex justify-between p-8")}`}>
                <div><img width={"36px"} src={"/assets/images/Logo_Black_Outline.svg"}/></div>
                <div>{action}</div>
              </div>
              <div className={twdClass("relative z-10 mb-12")}>
                <h5
                  className={twdClass("text-3xl font-bold pb-4 tracking-tight")}
                >
                  {title}
                </h5>
                <p className={twdClass("")}>
                  {desc}
                </p>
              </div>
              <div>
                {children}
              </div>
            </div>
          </div>
          <div
            className={twdClass(
              "flex-grow hidden md:flex justify-center items-center p-2 pl-0"
            )}
          >
            <div
              className={twdClass(
                "relative text-center h-full w-full rounded-xl py-32 px-2 flex items-center bg-blue-200 bg-cover bg-center"
              )}
              style={{backgroundImage:`url(/assets/images/loginBg.jpg)`}}
            >
              <div  className={twdClass("absolute top-0 left-0 right-0 bottom-0 flex items-center px-12 lg:px-24 xl:px-44 text-left")}>
                <Swiper
                  modules={[Controller, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={(i) => {
                    setEnd(i.isEnd)
                    setStart(i.isBeginning)
                    console.log('slide change', i)
                  }}
                  onSwiper2={(swiper) => console.log(swiper)}
                  className={twdClass("pb-24")} 
                  onSwiper={setControlledSwiper}
                  controller={{ control: controlledSwiper }}              
                  
                >                  
                  <SwiperSlide><Slide>Build Powerful Data-Driven Dynamic Websites Without Coding.</Slide></SwiperSlide>
                  <SwiperSlide><Slide>Just plug in your Google Sheets and start publishing your website.</Slide></SwiperSlide>   
                  <SwiperSlide><Slide>Visually map the data to variables and generate thousands of pages dynamically. Pure magic!</Slide></SwiperSlide>   
                  <SwiperSlide><Slide>Drag-and-Drop editor with built-in blocks to design a beautiful and responsive UI.</Slide></SwiperSlide>                           
                </Swiper>                
              </div>
              <div className={twdClass("absolute bottom-0 left-0 mb-12 px-12 lg:px-24 xl:px-44 flex items-center")}>
                  <NavButton />
                  <NavButton type={'next'}/> 
                </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Error = ({error}) => {
  return(
    <div className={twdClass("h-12 ")}>
      {error && (
        <p className={twdClass("mt-8 bg-red-50 rounded-2xl py-4 text-red-400 text-sm font-semibold px-4")}>
          {error}
        </p>
      )}
    </div>
  )
}


export {Layout, Error}

const Slide = ({children}) => {
  return(
    <div className={twdClass("text-2xl xl:text-3xl")}>
    {children}
    </div>
  )
}
