import { useState } from "react";

const { twdClass } = require("../../constants/tailwind.constants");
const { INPUT_TEXT } = require("../../constants/var.constants");

const CustomSelectBox = ({ value, options, updateValue }) => {
  const [csValue, setCSValue] = useState(value);
  return (
    <select
      className={twdClass(INPUT_TEXT)}
      onChange={(e) => {
        setCSValue(e.target.value);
        updateValue(e.target.value);
      }}
      value={csValue}
    >
      <option value="">Select</option>
      {options.map((item) => (
        <option value={item.key}>{item.value}</option>
      ))}
    </select>
  );
};

export default CustomSelectBox;
