import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  UPDATE_PAGE_SETTINGS,
  RESET_GENERATED
} from "../../constants/action.constants";

import { useState, useEffect } from "react";
import { INPUT_TEXT, BTN_CLS } from "../../constants/var.constants";
import { saveProject, getProjectInfo, getGenerateDesign, promptChatGPTMini } from "../../api/services";
import { getSheetInfo, getSheetColumns, getCards } from "../../api/services";
import { toast } from "react-toastify";
import { Button} from "../ui/com_ui";
import ToggleBtn from "../common/ToggleBtn";
import DynamicConnectionType from '../studio/pages/parts/PagesSettingsPanel'
import PagesGoogleSheetInfo from "../studio/pages/PagesGoogleSheetInfo";
import Preview from "./Preview";
import Generating from "./Generating";
import {theme_prompt, theme_format} from './prompts/theme_prompt'
import {structure_prompt, structure_format} from './prompts/structure_prompt'
import {navbar_prompt, navbar_format} from './prompts/navbar_prompt'
import {header_prompt, header_format} from './prompts/header_prompt'
import {footer_prompt, footer_format} from './prompts/footer_prompt'
import {cards_prompt, cards_format} from './prompts/cards_prompt'
import {cards_sample_prompt, cards_sample_format} from './prompts/cards_sample_prompt'

import {structureDyn_prompt, structureDyn_format} from './prompts/structureDyn_prompt'
import {textElement_prompt, textElement_format} from './prompts/textElement_prompt'
import {buttonElement_prompt, buttonElement_format} from './prompts/buttonElement_prompt'
import {imageElement_prompt, imageElement_format} from './prompts/imageElement_prompt'
import {headerDyn_prompt, headerDyn_format} from './prompts/headerDyn_prompt'

import {dataTypes_prompt, dataTypes_format} from './prompts/dataTypes_prompt'

import {pg_cards} from './processGen/pg_cards'
import {pg_textElement} from './processGen/pg_textElement'
import {pg_buttonElement} from './processGen/pg_buttonElement'


import { getTimeForUniqID, getTimestamp, getTemplateSample, getParams, styles_, links_} from "./transform/t_util";



import Apply from './Apply'
import MapColumns from './form/MapColumns'
import CreateGoogleSheetConnection from './form/CreateGoogleSheetConnection'
import {get, set, find, cloneDeep, forEach} from 'lodash'
import textElement from "./TestDesign/data/textElement";
import buttonElement from "./TestDesign/data/buttonElement";
import imageElement from "./TestDesign/data/imageElement";


const GenerateForm = (props={}) => {
  const {regenerate=false, type='all', cards_block={}} = props
  const dispatch = useDispatch();
  const {
    pageInfo,
    generated={},
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);
  //console.log("pageInfo", pageInfo)
  let suggestName = "New Page";
  let suggestSlug = "";

  if (pages.length > 0) {
    suggestName = `New Page - ${pages.length + 1}`;
    suggestSlug = `/new-page-${pages.length + 1}`;
  }
  const {settings={}} = generated
  const {cards_data=[], sheetColumns=[]} = settings
  const [pageType, setPageType] = useState(settings.pageType ? settings.pageType : pageInfo.settings.pageType);
  const [connectionType, setConnectionType] = useState(settings.connectionType ? settings.connectionType : pageInfo.settings.googleSheetName ? 'googleSheet' : '');
  
  let sD={
    websiteFor:settings.websiteFor ? settings.websiteFor : pageInfo.settings.websiteFor ? pageInfo.settings.websiteFor : '',
    googleSheetName:settings.googleSheetName ? settings.googleSheetName : pageInfo.settings.googleSheetName ? pageInfo.settings.googleSheetName : '',   
    connectionType:settings.connectionType ? settings.connectionType : pageInfo.settings.connectionType ? pageInfo.settings.connectionType : '',
    connectionId:settings.connectionId ? settings.connectionId : pageInfo.settings.connectionId ? pageInfo.settings.connectionId : '',
    slugParamColumn:settings.slugParamColumn ? settings.slugParamColumn : pageInfo.settings.slugParamColumn ? pageInfo.settings.slugParamColumn : '',    
    sheetColumns:settings.sheetColumns ? settings.sheetColumns : pageInfo.settings.sheetColumns ? pageInfo.settings.sheetColumns : [],    
    filters:settings.filters ? settings.filters : [],
    detailsPageParam:settings.detailsPageParam ? settings.detailsPageParam : '',    
    layout:settings.layout ? settings.layout : [],
  }
  const [settingsData, setSettingsData] = useState({...sD});  
  const [isForm, setForm] = useState({spreadsheetId:''});
  const [pageName, setPageName] = useState(pageInfo.settings.pageName ? pageInfo.settings.pageName : suggestName);
  const [pageSlug, setPageSlug] = useState(pageInfo.settings.pageSlug ? pageInfo.settings.pageSlug : suggestSlug);
  const [isGenerating, setGenerating] = useState(false);
  const [isGeneratingDone, setGeneratingDone] = useState(false);
  const [isExecute, setExecute] = useState(false);
  const [isExeId, setExeId] = useState(-1);

  let googleSheetConnectionFound=connections.find(con => con.connection_type=='googleSheet')

  const getStaticPagePrompts = ({prompts=[], genType='', opts={}}) => {     
    if(genType=='cards') {
      //console.log("cards_prompt opts", opts)
      prompts = [      
        {name:'cards', prompt:cards_prompt({opts, sdata:generated?.structure?.cards, data:cards_data, params:sheetColumns}), format:cards_format, 
        sdata:generated?.structure?.cards, system_fingerprint:`fp_${getTimestamp()}`},
      ]
    }else{
      prompts = [
        {name:'structure', prompt:structure_prompt({opts}), format:structure_format},
        {name:'theme', prompt:theme_prompt({opts}), format:theme_format},

        {name:'navbar', prompt:navbar_prompt({opts, sdata:generated?.structure?.navbar}), format:navbar_format, 
        sdata:generated?.structure?.navbar},
        {name:'header', prompt:header_prompt({opts, sdata:generated?.structure?.header}), format:header_format, 
        sdata:generated?.structure?.header},
        {name:'cards', prompt:cards_prompt({opts, sdata:generated?.structure?.cards, data:cards_data, params:sheetColumns}), format:cards_format, 
        sdata:generated?.structure?.cards, system_fingerprint:`fp_${getTimestamp()}`},
        /*{name:'cards_sample', prompt:cards_sample_prompt({opts, sdata:false, data:cards_data[0], template:generated?.cards?.card_template}), 
        sdata:false},*/
        {name:'footer', prompt:footer_prompt({opts, sdata:generated?.structure?.footer}), format:footer_format, 
        sdata:generated?.structure?.footer},
      ]
    }  
    return prompts
  }
  const getDynamicPagePrompts = ({prompts=[], genType='', opts={}}) => {     
    if(genType=='textElement') {
      prompts = [      
        {name:'textElement', prompt:textElement_prompt({opts, sdata:generated?.structure?.textElement, data:cards_data, params:sheetColumns}), format:textElement_format, 
        sdata:generated?.structure?.textElement}
      ]
    }else{
      prompts = [
        {name:'structure', prompt:structureDyn_prompt({opts, data:cards_data, params:sheetColumns}), format:structureDyn_format},
        {name:'theme', prompt:theme_prompt({opts}), format:theme_format},

        {name:'headerDyn', prompt:headerDyn_prompt({opts, sdata:generated?.structure?.headerDyn, data:cards_data}), format:headerDyn_format, 
        sdata:generated?.structure?.headerDyn},   
        {name:'textElement', prompt:textElement_prompt({opts, sdata:generated?.structure?.textElement, data:cards_data}), format:textElement_format, 
        sdata:generated?.structure?.textElement},    
        {name:'imageElement', prompt:imageElement_prompt({opts, sdata:generated?.structure?.imageElement, data:cards_data}), format:imageElement_format, 
        sdata:generated?.structure?.imageElement},
        {name:'buttonElement', prompt:buttonElement_prompt({opts, sdata:generated?.structure?.buttonElement, data:cards_data}), format:buttonElement_format, 
        sdata:generated?.structure?.buttonElement}       
      ]
    }    
    return prompts
  }

  const getPrompts = ({genType=''}) => {
    let prompts = []
    let opts={}
    if(settingsData.websiteFor) opts.websiteFor=settingsData.websiteFor
    if(settingsData.detailsPageParam) opts.detailsPageParam=settingsData.detailsPageParam
    if(settingsData.pageType) opts.pageType=settingsData.pageType
    if(settingsData.layout) opts.layout=settingsData.layout
    //console.log("cards_data", cards_data, settings)
    if(pageType=="dynamic"){
      prompts=getDynamicPagePrompts({prompts, genType, opts})
    }else{
      prompts=getStaticPagePrompts({prompts, genType, opts})
    }
    
    //console.log("prompts", opts, prompts)
    return prompts
  }

  const [isPrompts, setPrompts] = useState(getPrompts({genType:type}));

 
  useEffect(() => {
     /*
    const {
      project_id,
      sheetName,
      pagination,
      whereLogic='AND',
      whereCriteria={},
      criteria,
      connectionId,
      connectionTable,
      columnNames,
      sortBy,
      searchValue,
      searchConfig,
    } = req.body;
     */
    //console.log("settingsData", settingsData)
    if(settingsData.triggerFrom=='googleSheetName_field' || settingsData.triggerFrom=='createGoogleSheetConnection'){
      if(settings.connectionId && settings.googleSheetName ){    

        getCards({
          project_id,
          sheetName:settings.googleSheetName,
          connectionId: settings.connectionId,  
          pagination:{start:0, limit:2}     
        }).then((resp) => {
          //console.log("cards resp", resp)
          if (resp.success) {
            //setSheetColumns(resp.data);
            //setColumnNames(resp.data);
            
            let rd=resp.data.map((r, ri)=>{
              let r_={...r}
              forEach(r, function(value, key) {
                if(value.length > 100) {
                  let slice=value.slice(0, 100);
                  //console.log("slice", key, slice);
                  r_[key]=slice
                }
                //console.log("r", key, value);
                
              });
              return r_
            })
            
            setSettingsData(prev=>{
                return({...prev, cards_data:rd})
            })
          }
        });
        /*
        const {
          connection_details: { spreadsheetId },
          connection_id,
        } = find(connections, [
          "connection_id",
          settings.connectionId,
        ]);
        //console.log("spreadsheetId", spreadsheetId)
        getSheetColumns({
          project_id,
          sheet_name:settings.googleSheetName,
          spreadsheetId,
          connectionId: settings.connectionId,
          ranges: [`${settings.googleSheetName}!A1:Z3`],
        }).then((resp) => {
          console.log("resp", resp)
          if (resp.success) {
            //setSheetColumns(resp.data);
            //setColumnNames(resp.data);
          }
        });
        */
      } 
      //console.log("settings.googleSheetName", "generated")
    }  else{
      //console.log("settings.googleSheetName", "not generated")
    } 
    
  }, [settings.googleSheetName]);

  const generateDataTypes = async () => {

    let prompt=dataTypes_prompt({params:sheetColumns, data:cards_data})
    console.log("settings.sheetColumns prompt_", prompt)

    const {data} = await promptChatGPTMini({promptMini:prompt, format:dataTypes_format})
    console.log("generateDataTypes", pageType, prompt, dataTypes_format) 

    if(data && data.generated){
      let gen
      if(gen=JSON.parse(data.generated)){
        console.log("generated DataTypes ", gen) 
        if(gen.params){
          let sheetColumns_=[...sheetColumns]
          sheetColumns_.map((param, index)=>{
            let found=gen.params.find(el=>el.key==param.key)
            if(found) param.type=found.type
            return param
          })
          console.log("sheetColumns_ ", sheetColumns_) 
          updateSettingsData("sheetColumns", sheetColumns_) 
        }
        
       /* dispatch({
          type: UPDATE_GENERATED,
          data: {[name]: gen}
        });  
        if(name=='theme'){       
          updateSettingsData('theme',gen)
        }*/
      }else{
        alert("Error in Generating Design. Please Try Again.")
      }    
    }else{
      alert("Error in Generating Design. Please Try Again.")
    }
  }

  const [isView, setView] = useState('');
  const [isViewOpts, setViewOpts] = useState({message:""});

  useEffect(() => {
    if (pageType === 'dynamic') {
      
    }
  }, [pageType]);

  useEffect(() => {
    /*if (pageInfo.settings.pageName === pageName) {
      //console.log("save for Create_new_page", pageName, pageSlug)
      saveProject({ id: project_id, pageInfo }).then(async (resp) => {
        if (resp.success) {
          toast.success("Page successfully saved");
          props.fetchPageData({ project_id, page_id: resp.data[0].page_id });
          const { data } = await getProjectInfo(project_id);
          dispatch({ type: UPDATE_PROJECT_INFO, data });
          dispatch({ type: SELECT_PAGE_ID, id: resp.data[0].page_id });
          dispatch({ type: RESET_POPUP });
        }
      });
    }*/
  }, [pageInfo.settings.pageName]);

  useEffect(() => {
    if (pageName) {
      updateSettingsData("pageName", pageName)
      dispatch({
        type: UPDATE_PAGE_SETTINGS,
        data: {...pageInfo.settings, pageName},
      });
    }    
  }, [pageName]);

  useEffect(() => {
    if (pageSlug) {
      updateSettingsData("pageSlug", pageSlug)
      dispatch({
        type: UPDATE_PAGE_SETTINGS,
        data: {...pageInfo.settings, pageSlug},
      });
    }
  }, [pageSlug]);


  useEffect(() => {
    //console.log("websiteFor", settingsData.websiteFor)
    setPrompts(getPrompts({genType:type}))  
  }, [settingsData.websiteFor, settingsData.layout, generated?.structure]);
  

  useEffect(() => {
    updateSettingsData("connectionType", connectionType)
  }, [connectionType]);

  useEffect(() => {
    if(settingsData.connectionId){
      //updateSettingsData("googleSheetName", '')
    }
  }, [settingsData.connectionId]);
  

  const forCardsBlock = () => {
    if(cards_block?.generated?.settings){
      let cards_settings = cards_block.generated.settings
      //console.log("cards_settings", cards_settings)
      if(cards_settings.connectionType) setConnectionType(cards_settings.connectionType)
      if(cards_settings.theme){
        dispatch({
          type: UPDATE_GENERATED,
          data: {theme:cards_settings.theme}
        }); 
      }
      if(cards_settings.section_data){
        dispatch({
          type: UPDATE_GENERATED,
          data: {structure:{cards:cards_settings.section_data}}
        }); 
      }
      if(cards_settings.sheetColumns){
        
       // updateSettingsData("sheetColumns", cards_settings.sheetColumns)  
        setTimeout(()=>{
          console.log("cards_settings sheetColumns", cards_settings.sheetColumns)
          updateSettingsData("sheetColumns", cards_settings.sheetColumns)
        }, 1000) 
      }
      if(cards_settings.cards_data){
        updateSettingsData("cards_data", cards_settings.cards_data)       
      }
      if(cards_settings.filters){
        updateSettingsData("filters", cards_settings.filters)       
      }
      if(cards_settings.layout){
        updateSettingsData("layout", cards_settings.layout)       
      }
      if(cards_settings.websiteFor){
        updateSettingsData("websiteFor", cards_settings.websiteFor)       
      }
      if(cards_settings.connectionId) {
        setSettingsData(prev=>{
            return({
              ...prev, 
              ...cards_settings
            })
          }
        )
      }
    }
  }
  
  useEffect(() => {
    updateSettingsData("pageType", pageType)     
  }, [pageType]);


  useEffect(() => {
    //updateSettingsData("triggerFrom", "blockCards")
    if(type=='cards'){
      updateSettingsData("triggerFrom", "blockCards")
      forCardsBlock()
    }
  }, [type]);


  useEffect(() => {
    if(settingsData.triggerFrom=='createGoogleSheetConnection'){      
      //updateSettingsData("connectionId", isForm.connectionId)   
      //console.log("settingsData.triggerFrom", settingsData.triggerFrom)
    }
  }, [settingsData.triggerFrom]);

  useEffect(() => {
    //console.log("settingsData.sheetColumns", settingsData.sheetColumns)
    //console.log("settings.sheetColumns", sheetColumns)
  }, [settingsData.sheetColumns]);
  
  
  const updateForm = (k, v) => {    
    setForm(prev=>{
      let next={...prev, [k]:v}
      //console.log("settings", k, v, next)
      /*dispatch({
        type: UPDATE_GENERATED,
        data: {settings:next}
      }); */
      return next
    })
  }  
  const updateSettingsData = (k, v) => {
    //console.log("updateSettingsData", k, v)
    setSettingsData(prev=>{
      let next={...prev, [k]:v}
      //console.log("settings", k, v, next)
      dispatch({
        type: UPDATE_GENERATED,
        data: {settings:next}
      }); 
      return next
    })
  }  
  
  function testJSON(text) {
      if (typeof text !== "string") {
          return false;
      }
      try {
          JSON.parse(text);
          return true;
      } catch (error) {
          return false;
      }
  }



  const generatePrompt = async ({name, prompt, format=false}) => {
    const {data} = await promptChatGPTMini({promptMini:prompt, format})
    console.log("generated started "+name, pageType, prompt, format) 
    if(data && data.generated){
      let gen
      if(data.generated && testJSON(data.generated)){
        gen=JSON.parse(data.generated)
        //console.log("generated for "+name, gen) 
        if(name=='theme'){       
          updateSettingsData('theme',gen)
        }else if(name=='cards' && gen.card_template && cards_data){
          gen = pg_cards({obj:gen, cards_data})
          //gen.card_sample=JSON.parse(getTemplateSample({template:JSON.stringify(gen.card_template), data:cards_data[0],  captureFrom:"box_brackets"}))
          //gen.card_items=[gen.card_sample, gen.card_sample]
          //console.log("Modified Cards", gen)
          //import { getTimeForUniqID, getTimestamp, getTemplateSample, getParams, styles_, links_} from "./t_util";
        }else if(name=='textElement'){
          gen = pg_textElement({obj:gen})          
        }else if(name=='buttonElement'){
          gen = pg_buttonElement({obj:gen})          
        }

        dispatch({
          type: UPDATE_GENERATED,
          data: {[name]: gen}
        });         
      }else{
        //alert("Error in Generating Design. Please Try Again.")       
        setViewOpts({message:'Error in Generating Design. '})
      }    
    }else{
      //alert("Error in Generating Design. Please Try Again.")     
      setViewOpts({message:'Error in Generating Design. '})
    }
  }
  
  
  const generateDesign = async () => {   
    setView("generated")   
    setViewOpts({message:''})  
    /*dispatch({
      type: RESET_GENERATED,
      data: {},
    });*/  
    let local_args={commandFrom:'preview',  navbar:false, header:false, cards:false, footer:false, headerDyn:false, textElement:false, buttonElement:false, imageElement:false}
    if(type=='all') local_args.theme=false
    dispatch({
      type: UPDATE_GENERATED,
      data: local_args
    }); 
    //setExecute(true)
    setExeId(0)
  }

  const execute = async()=>{     
    setGeneratingDone(false)
    setGenerating(true)   
    if (isExeId < isPrompts.length) {   
      let obj= isPrompts[isExeId]
      //console.log("generated.theme", isExeId, obj.name, generated)
      if(obj.name=='navbar'){        
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='header'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='cards'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='footer'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='headerDyn'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='textElement'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='buttonElement'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }else if(obj.name=='imageElement'){
        obj.prompt=`\n\r 'THEME': ${JSON.stringify(generated.theme)} \n\r \n\r ${obj.prompt}`
      }
      
      await generatePrompt(obj)
      setExeId(prev=>(prev+1)) 
    }else{
      setExeId(-1)
      setGenerating(false)
      setGeneratingDone(true)    
    }
    
      
  } 
  useEffect(() => {
    if (isExeId!=-1){ 
      console.log("execute",  isExeId)
      execute()
     // setExecute(false)
    }
  }, [isExeId]);

  useEffect(() => {
    console.log("generated",  generated)
  }, [generated]);

  useEffect(() => {
    console.log("generated.commandFrom", generated.commandFrom) 
    if(generated.commandFrom=='section'){       
      setPrompts(getPrompts({genType:generated.regenerate}))   
      dispatch({
        type: UPDATE_GENERATED,
        data: {regenerate:'', commandFrom:''}
      });          
      setExeId(0)
    }
  }, [generated.commandFrom]);

  const SmBtn=({className, active, ...props})=>{
    return(<div 
      {...props}
      className={twdClass(`cursor-pointer px-2 ${active? 'text-blue-500 bg-blue-100' :'text-gray-500 bg-gray-100'}  hover:bg-white rounded-md`)}
      />)
  }
  const IconBtn=({className, active, ...props})=>{
    return(<div 
      {...props}
      className={twdClass(`cursor-pointer px-2 py-2 ${active? 'text-blue-500 bg-blue-100 hover:bg-blue-100' :'text-gray-500 bg-gray-100 hover:bg-blue-50'}   rounded-md`)}
      />)
  }

  const Layout = ()=> {
    let layouts=[     
      {label:'3 Columns', name:'3cols', img:'3cols.png', value:{desktop:3, tab:2, mobile:1}},
      {label:'2 Columns', name:'2cols', img:'2cols.png', value:{desktop:2, tab:1, mobile:1}},
      {label:'1 Column', name:'1col', img:'1col.png', value:{desktop:1, tab:1, mobile:1}}
    ]
    let key="layout"
    return(<div className={twdClass("flex flex-wrap gap-2")}>
       {layouts?.map((layout, ci)=>{
          let param_=settingsData[key] ? settingsData[key] : {}         
          let active=param_ && param_.name==layout.name ? true : false
          return(<IconBtn {...{active}} onClick={()=>{  
            updateSettingsData(key, layout);
            console.log(key, layout.label, layout.value)
          }}>
            <div><img src={`/assets/images/generate/layouts/${layout.img}`} alt={layout.label}/></div>
          </IconBtn>)
        }) }
    </div>)
  }

  const DetailsPage = ()=> {
    let key="detailsPageParam"
    return(<div className={twdClass("flex flex-wrap gap-2")}>
       {settingsData.sheetColumns?.map((column, ci)=>{
          let param_=settingsData[key] ? settingsData[key] : ''         
          let active=param_ && param_==column.value ? true : false
          return(<SmBtn {...{active}} onClick={()=>{  
            updateSettingsData(key, column.value);
            console.log(key, cards_data, column.value)
          }}>{column.value}</SmBtn>)
        }) }
    </div>)
  }

  const Filters = ()=> {
    let key="filters"
    return(<div className={twdClass("flex flex-wrap gap-2")}>
       {settingsData.sheetColumns?.map((column, ci)=>{
          let filter_=[...settingsData[key]]
          let ffound=filter_.find(el=>el.label==column.value)
          let active=ffound ? true : false
          return(<SmBtn {...{active}} onClick={()=>{           
            if(!ffound) {
              let values_=cards_data.map((card)=>{
                return(card[column.value] ? card[column.value] : false)
              })
              filter_.push({label:column.value, values:values_})
            }else{
              filter_=filter_.filter(el=>{
                if(el.label!=column.value) return true ; else return false
              })
            }
            updateSettingsData(key, filter_);
            console.log(key, cards_data, filter_)
          }}>{column.value}</SmBtn>)
        }) }
    </div>)
  }

  let sample_prompts=["AI Tools Listing", "Jobs Listing", "Architecture Listing" ]
  let sampleDyn_prompts=["AI Tool Details", "Job Details", "Property Details" ]
  let sample_prompts_=pageType=='dynamic' ? sampleDyn_prompts : sample_prompts
  return (
    <div  className={twdClass("h-full")}>
      {<div className={`generate-page-layout ${twdClass(`${isView !='generated' ? 'block' : 'hidden'}  flex flex-col items-center w-full mx-auto`)}`} style={{maxWidth:'1200px'}}>
        <div className={twdClass("flex w-full items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>Website For</label>
          <div className={twdClass("flex-1")}>
            <input
              type="text"
              placeholder="Generative AI Developer Profile"
              value={settingsData.websiteFor}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                updateSettingsData('websiteFor', e.target.value);
              }}
            />
            <div className={twdClass("flex flex-wrap mt-2 gap-x-2 text-sm px-2")}>
              <span className={twdClass("pl-2 opacity-75 text-secondary-500 bg-secondary-100 rounded-lg")}>Sample text: </span>
              {sample_prompts_.map((sp, spi)=>{
                return(<SmBtn                   
                  onClick={()=>{updateSettingsData('websiteFor', sp);}}>{sp}</SmBtn>)
              })}
            </div>
          </div>
        </div>
        <div className={twdClass(`${settingsData.websiteFor ? '' : 'hidden'} w-full mt-4`)}>
          <div className={twdClass("flex flex-col px-4 py-4  rounded-xl border border-gray-200 bg-white mb-2")}>
            <div className={twdClass("text-md font-seminbold mb-2 uppercase text-secondary-500")}>Page</div>
            <div className={twdClass("flex w-full items-center mb-2")}>
              <label className={twdClass("w-40 text-sm")}>Page Type</label>
              <div className={twdClass("flex-1")}>
                <ToggleBtn
                  value={pageType === 'dynamic'}
                  falseLabel="Static Page"
                  trueLabel="Dynamic Page"
                  updateValue={(value) => {
                    console.log("value", value)
                    setPageType(value ? 'static' : 'dynamic');
                  }}
                />
              </div>
            </div>           
            <div className={twdClass("flex items-center mb-2")}>
              <label className={twdClass("w-40 text-sm")}>Page Name</label>
              <div className={twdClass("flex-1")}>
                <input
                  type="text"
                  placeholder="About"
                  value={pageName}
                  className={twdClass(INPUT_TEXT)}
                  onChange={(e) => {
                    setPageName(e.target.value);
                    updateSettingsData("pageName", e.target.value)
                  }}
                />
              </div>
            </div>
            <div className={twdClass("flex items-center mb-2")}>
              <label className={twdClass("w-40 text-sm")}>
                Slug
                <div className={twdClass("text-xs text-gray-400")}>
                  (Ex: {pageType=='dynamic' ? '/somepath/:id or /:id':'/about'})
                </div>
              </label>
              <div className={twdClass("flex-1")}>
                <input
                  type="text"
                  placeholder={`${pageType=='dynamic' ? '/somepath/:id or /:id':'/about'}`}
                  className={twdClass(INPUT_TEXT)}
                  value={pageSlug}
                  onChange={(e) => {
                    setPageSlug(e.target.value);
                    updateSettingsData("pageSlug", e.target.value)
                  }}
                />
              </div>
            </div>
          </div>          
          <div className={twdClass("flex flex-col px-4 py-4  rounded-xl border border-gray-200 bg-white  mb-2")}>
            <div className={twdClass("text-md font-seminbold mb-2 uppercase text-secondary-500")}>Connection</div>
            <div className={twdClass("w-full")}>
              <DynamicConnectionType
                connectionType={connectionType}
                setConnectionType={setConnectionType}
              />
            </div>
            {(connectionType=='googleSheet' && googleSheetConnectionFound) && <div className={twdClass("flex w-full items-center mb-2")}>
              <label className={twdClass("w-40 text-sm")}>Google Sheet ID</label>            
              <div className={twdClass("flex-1")}>
                <CreateGoogleSheetConnection
                  settingsData={settingsData}
                  isForm={isForm}
                  updateForm={updateForm}
                  updateSettingsData={updateSettingsData}
                  setConnectionType={setConnectionType}
                />
              </div>
            </div>} 
            <div className={twdClass("w-full")}>
              {connectionType === "googleSheet" && (
                <PagesGoogleSheetInfo
                  project_id={project_id}
                  settingsData={settingsData}
                  pageInfo={pageInfo}
                  connections={connections}
                  updateSettingsData={updateSettingsData}
                  hideParamsMap={true}
                  hideSlugParam={pageType == 'static'}
                  forGenerateDesign={true}
                />
              )}
              {(connectionType && connectionType !== "googleSheet") && <div className={twdClass("px-12 py-12 text-xl flex items-center justify-center bg-gray-100 rounded-2xl")}>
                <span  className={twdClass("font-semibold mr-2")}>GenerateDesign</span> for Supabase is Coming Soon...
              </div>}
            </div>
          </div>
          {(pageType==="static" && connectionType==='googleSheet' && settingsData.googleSheetName) && <div  className={twdClass("w-full mt-4  border bg-white border-gray-200 px-4 py-4 rounded-lg mt-2")}>
            <div className={twdClass("text-md font-seminbold mb-2 uppercase text-secondary-500")}>Cards</div>
            <div className={twdClass("flex w-full items-center mt-2 mb-4 ")}>
              <label className={twdClass("w-40 text-sm")}>Layout</label>
              <div className={twdClass("flex-1")}>
                <div className={twdClass("flex flex-wrap gap-2 text-sm ")}>
                  <Layout/>
                </div>
              </div>
            </div>   
            <div className={twdClass("flex w-full items-center mb-4 border-t border-gray-300 pt-4 ")}>
              <label className={twdClass("w-40 text-sm")}>Filter Params</label>
              <div className={twdClass("flex-1")}>
                <div className={twdClass("flex flex-wrap gap-2 text-sm ")}>
                <Filters/>
                </div>
              </div>
            </div>  
            <div className={twdClass("flex w-full items-center mb-4 border-t border-gray-300 pt-4")}>
              <label className={twdClass("w-40 text-sm")}>Details Page Param</label>
              <div className={twdClass("flex-1")}>
                <div className={twdClass("flex flex-wrap gap-2 text-sm ")}>
                  <DetailsPage/>
                </div>
              </div>
            </div>                 
          </div>}  
        </div>
        {(connectionType==='googleSheet' && settingsData.googleSheetName) && <div className={twdClass(`relative w-full mb-4 ${settingsData.websiteFor ? '' : 'hidden'}`)}>
          <MapColumns {...{          
            settingsData,
            setSettingsData,
            updateSettingsData
          }}/>
          <div  className={twdClass(`flex justify-center`)}><Button 
            type={'default'} 
            cType={'text'}             
            color={'black'}
            size={'md'}         
            iconImg={'link-external.svg'}          
            className={''}  
            onClick={async() => {               
            await generateDataTypes()
            }}       
          >AutoMap Column Types</Button>  </div> 
          
        </div>}        
        <div className={twdClass("mt-4 text-right flex")}>
          <div className={twdClass("flex-grow")}></div>
          <Button 
            type={'default'} 
            cType={'text'}             
            color={'primary'}
            size={'md'}         
            iconImg={'link-external.svg'}          
            className={''}  
            onClick={async() => { 
              /*          
              const {data} = await getGenerateDesign({pageInfo, props:{pageType, page_id:pageInfo.page_id, ...settingsData}})
              console.log("generateDesign",  data)
              if(data && data.pageInfo){
                data.pageInfo= process_blocks(data.pageInfo)
                dispatch({ type: UPDATE_PROJECT_PAGE,  data: {...data.pageInfo}});
              }else{
                alert("Error in Generating Design. Please Try Again.")
              }
              */            
            await generateDesign()
           
              /*dispatch({
                type: CREATE_NEW_PAGE,
                data: { pageName, pageSlug },
              });*/
            }}       
          >Preview</Button>          
        </div>
      </div>}
      
      {<div className={twdClass(`${isView =='generated' ? 'flex' : 'hidden'} h-full flex-col relative `)}>   
        <div  className={twdClass("flex-grow overflow-auto bg-white rounded-xl relative")}>    
            <div className={twdClass("absolute top-0 left-0 right-0 bottom-0 overflow-auto")}>
              {!isViewOpts.message && <div>        
                <Generating {...{isGenerating}}/>
                <Preview {...{isGenerating, isGeneratingDone}}/>
              </div>}
              {isViewOpts.message && <div  className={twdClass("flex flex-col items-center justify-center w-full h-full")}>
                <div className={twdClass("text-2xl mb-8")}>{isViewOpts.message}</div>
                <Button
                  type={'default'}  
                  cType={'iconText'}        
                  color={'white'}
                  size={'md'}         
                  iconImg={'arrow-left.svg'}         
                  className={''}   
                  onClick={()=>{setView(''); setViewOpts({message:''})  }}
                >              
                  Try Again
                </Button>  
              </div>}  
            </div>  
        </div>
        {(isGeneratingDone && !isViewOpts.message) && <div  className={twdClass("flex p-4")}>  
          <div  className={twdClass("flex-grow")}></div>  
          <div  className={twdClass("flex")}>
            <Button
              type={'default'}  
              cType={'iconText'}        
              color={'white'}
              size={'md'}         
              iconImg={'arrow-left.svg'}         
              className={''}   
              onClick={()=>{setView(''); setViewOpts({message:''})  }}
            >              
              Back
            </Button> 
            <Button
              type={'default'}  
              cType={'iconText'}        
              color={'white'}
              size={'md'}         
              iconImg={'stars.svg'}         
              className={'ml-2'}   
              onClick={async()=>{await generateDesign() }}
            >              
              Try Again
            </Button>    
            <Apply {...props}/>
            </div>
        </div>}
      </div>}      
    </div>
  );
};

export default GenerateForm;

