export const LOGOS_BLOCK_1 = {
  blockType: "LOGOS",
  blockDesign: "LogoDesign1",
  editFormName: "LOGOS_FORM_1",
  styleProps: {},
  headings: [
    {
      text: "Industry Associations",
      styleProps: {
        "text-align": "center",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
        padding: "10px 0px 10px 0px",
      },
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      styleProps: {
        "text-align": "center",
        padding: "10px 0px 10px 0px",
      },
    },
  ],
  logos: [
    {
      src:
        "https://www.brandingmag.com/wp-content/uploads/2012/08/microsoftlogotransparent-01.png",
      styleProps: {
        width: "150px",
        height: "50px",
      },
    },
  ],
};
