import CustomSelectBox from "../../common/CustomSelectBox";
import { twdClass } from "../../../constants/tailwind.constants";
import { trim } from "lodash";

const PagesGoogleParams = ({ item, sheetColumns, updateParamsValues }) => {
  const updateValues = (value) => {
    updateParamsValues({ replaceTo: item.key, columnValue: value });
  };
  return (
    <>
      <div className={twdClass("pt-2 text-sm")}>{trim(item.value)}</div>
      <CustomSelectBox
        value={item.columnValue}
        options={sheetColumns}
        updateValue={updateValues}
      />
    </>
  );
};

export default PagesGoogleParams;
