import { useState } from "react";
import { twdClass } from "../../../constants/tailwind.constants";
import { find, replace, cloneDeep, set, get } from "lodash";
import FaIcons from "../../common/FaIcons";

const getImageValue = (value) =>{
  return replace(value, /(url)|[()'"]/g, "")
}
const setImageValue = ({obj, prop}) =>{    
  return prop == 'background-image' ? `url('${obj[prop]}')` : obj[prop] 
} 

const getUpToWith= ({prop, updateTo, device}) => {
  const el = updateTo.split(".")   
  //el[el.length-1]=prop
  let prop2= el[el.length-1]
  let pro_updateTo = el.join(".")
  let upToWith = replace(pro_updateTo, `.${prop2}`, `${device}.${prop2}`);
  //console.log("with",prop, updateTo, updateTo_safe, el, device)
  //console.log("getUpToWith", prop, prop2, el, updateTo, upToWith);
  return upToWith
}

const getPropValues= ({data, values, updateTo, device}) => {
  const out = cloneDeep(values);
  Object.keys(out).forEach((itm, i) => {
    let upToWith=getUpToWith({prop:itm, updateTo, device})
    let upToWith_value = get(data, upToWith);
    if(itm=='background-image') upToWith_value=getImageValue(upToWith_value)
    out[itm] = upToWith_value;    
    //console.log("PropValue", i, itm, out[itm])
  });
  return out
}

const States = ({
  device,
  setDevice,
  value,
  type
}) => { 
  let value_= ''
  if(type=='color') value_= (<span className={twdClass("inline-block w-6 h-6 rounded-md shadow-sm mr-2 border border-white")} style={{backgroundColor:value}}></span>)
  return (
    <div className={twdClass("w-full flex items-center mb-2 text-sm")}>
      <div className={twdClass("px-2 py-2 bg-gray-100 flex items-center flex-grow rounded-md mr-2 ")}>
        {value_}
        <span className={twdClass("text-xs ")}>
          {value}
        </span>
      </div>
      <span
        className={twdClass(
          `mr-1 rounded-md p-2 flex justify-center items-center cursor-pointer ${
            device === "" ? `bg-gray-200` : "bg-gray-50"
          }`
        )}
        onClick={() => setDevice("")}
      >
        Normal
      </span>
      <span
        className={twdClass(
          `mr-1 rounded-md p-2 flex justify-center items-center cursor-pointer ${
            device === "Hover" ? `bg-gray-200` : "bg-gray-50"
          }`
        )}
        onClick={() => setDevice("Hover")}
      >
        Hover
      </span>
      <span
        className={twdClass(
          `mr-6 rounded-md p-2 flex justify-center items-center cursor-pointer ${
            device === "Active" ? `bg-gray-200` : "bg-gray-50"
          }`
        )}
        onClick={() => setDevice("Active")}
      >
        Active
      </span>
    </div>
  );
};

const Device = ({
  device,
  setDevice
}) => { 
  return (
    <div className={twdClass("w-full flex justify-end mb-2")}>
      <span
        className={twdClass(
          `mr-1 rounded-md p-2 flex justify-center items-center ${
            device === "" ? `bg-gray-200` : "bg-gray-50"
          }`
        )}
        onClick={() => setDevice("")}
      >
        <FaIcons icon="desktop" className={twdClass("text-xs")} />
      </span>
      <span
        className={twdClass(
          `mr-1 rounded-md p-2 flex justify-center items-center ${
            device === "Tab" ? `bg-gray-200` : "bg-gray-50"
          }`
        )}
        onClick={() => setDevice("Tab")}
      >
        <FaIcons icon="tablet" className={twdClass("text-xs")} />
      </span>
      <span
        className={twdClass(
          `mr-6 rounded-md p-2 flex justify-center items-center ${
            device === "Mob" ? `bg-gray-200` : "bg-gray-50"
          }`
        )}
        onClick={() => setDevice("Mob")}
      >
        <FaIcons icon="mobile" className={twdClass("text-xs")} />
      </span>
    </div>
  );
};

const hideshowElement = ({index=0, type='', data, element}) => {
  const { hideBasedElemVal, showBasedElemVal } = element;
    
    let hideElement = false;
    if (hideBasedElemVal) {
      let { elem, value } = hideBasedElemVal;
      if(type=='list') elem= replace(elem, "[i]", `[${index}]`);
      hideElement = get(data, elem) === value;
    }
    if (showBasedElemVal) {
      let { elem, value } = showBasedElemVal;
      if(type=='list') elem= replace(elem, "[i]", `[${index}]`);
      hideElement = get(data, elem) !== value;      
      //console.log('list generate show', hideElement,  elem, elem_, value )
      //console.log('list generate show data', data)
      //console.log('list generate show selected', get(data, elem_)  )
    }
    return hideElement
}
export {
  Device,
  States,
  getUpToWith,
  getPropValues,
  setImageValue,
  hideshowElement
};

