import { twdClass } from "../../constants/tailwind.constants";
import { useSelector, Provider } from "react-redux";
import LiftSidePanel from "./leftpanel/LeftSidePanel";
import RightSidePanel from "./rightpanel/RightSidePanel";
import SitePanel from "./sitepanel/SitePanel";
import StudioHeader from "./headerpanel/StudioHeader";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { store } from "../../store";

const StudioPanel = () => {
  const { previewType } = useSelector((state) => state.studioView);
  const { openRightPanel } = useSelector((state) => state.studioDetails);
  const showRightPanel = openRightPanel ? `open-panel ${twdClass("border-l")}` : twdClass("w-0");
  const iframeClass = openRightPanel ? "activeRightPanel" : '';

  return (
    <div>
      <div>
        <StudioHeader />
      </div>
      <div className={twdClass("flex")}>
        <div className="left-panel">
          <LiftSidePanel />
        </div>
        <div className={`sly-preview-parent  ${twdClass(`flex-1 mt-16 ${previewType =='desktop' ? "pt-2" : "pt-2"} ml-20`)}`}>
          {/* <SitePanel showPanel={openRightPanel} /> */}
          <SiteIFrameRender className={`${iframeClass}`} showPanel={openRightPanel} />
        </div>
        <div
          className={`right-panel ${showRightPanel} ${twdClass(
            "bg-white transition-all duration-300 "
          )}`}
        >
          <RightSidePanel showPanel={openRightPanel} />
        </div>
      </div>
    </div>
  );
};

export default StudioPanel;

const SiteIFrameRender = ({ className, showPanel }) => {
  const showRightPanel = showPanel ? "open-panel" : twdClass("w-0");
  const { previewType } = useSelector((state) => state.studioView);

  useEffect(() => {
    const settimer = setInterval(() => {
      const element = window.document.querySelector("#sly-preview-iframe");
      const previewEle = element.contentWindow.document.querySelector(
        "#sly-preview"
      );
      if (previewEle) {
        ReactDOM.render(
          <Provider store={store}>
            <SitePanel showPanel={showRightPanel} />
          </Provider>,
          previewEle
        );
        clearInterval(settimer);
      }
    }, 100);
  }, []);

  return (
    <div
      className={`sly-preview-wrapper ${className} ${previewType}`}
      dangerouslySetInnerHTML={{
        __html: `<iframe  id="sly-preview-iframe" src="${process.env.PUBLIC_URL}/html/iframe.html" width="100%" scrolling="auto"></iframe>`,
      }}
    />
  );
};
