import { get } from "lodash";
import Dropdown from "./Dropdown";

const SelectBox = ({ element, data, updateElementValue, inputArgs  }) => {
  const { label, options, hideLabel, updateTo, responsive } = element;
  const value = get(data, updateTo);
  const updateValue = (value) => {
    updateElementValue(updateTo, value);
  };
  return (
    <Dropdown     
      options={options}
      hideLabel={hideLabel}
      label={label}
      value={value}
      updateValue={updateValue}
      inputArgs={inputArgs}
      responsive={responsive}
    />
  );
};

export default SelectBox;
