const d3=`
    {        
        "images":{
            "image":'{{logo}}',
            "styleProps":{}
        },
        "styleProps":{} 
    }

`

export const imageElement_prompt=({opts={}, sdata=false, data=false, sample=false})=>{
    let pagePrompt=''
    if(!sdata) sdata={"logo":"{{Logo}}"}
    if(!sample){
        sample=d3
    }
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'


    //console.log("textElement_prompt", opts, sdata)
    
    return(`
    
    'SECTION_DATA'=${JSON.stringify(sdata)}  

    'JSON_INPUT'={
        "imageElement":${sample}
    }

    Create Website details page 'imageElement' section 
    
    Use [SECTION_DATA]->image data for 'image' Content   
    
    JSON output schema sholud be [JSON_INPUT]
    
    Give me the output in JSON format and it should be valid JSON data
    
    Just give me the JSON data
    
    `)
}
    

const schema={
    "name": "imageElement_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": {            
            "images":{
                "type":"object",
                "description": "", 
                "properties":{
                    "image": {
                        "type": "string",
                        "description": "Text contains dynamic variables in double flower brackets {{}}"                      
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}        
                },
                "required": [ 
                    "image",
                    "styleProps",
                    "stylePropsMob",
                    "stylePropsTab"          
                ],             
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_obj"}                     
        },  
        "required": [ 
            "images",
            "styleProps",
            "stylePropsMob",
            "stylePropsTab"          
        ],
        "additionalProperties": false,
        "$defs": {  
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                            
        }
    }
}
export const imageElement_format = {
    "type":"json_schema",
    "json_schema": schema
}