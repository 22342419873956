import {cloneDeep, find, set, get, replace } from "lodash";
import { useEffect, useState, Fragment } from "react";
import { BTN_CLS } from "../../../constants/var.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import FaIcons from "../../common/FaIcons";
import {Button} from "../../ui/com_ui";
import copy from 'copy-to-clipboard';

//import createEncryptor from 'simple-encryptor'


const Visible = ({ sitesettings, updateSettings }) => {
  const {visibleTo} = sitesettings
  const updateItem = (key, value) => {
    const sst = cloneDeep(sitesettings);
    const data = set(sst, key, value);
    updateSettings(data);
  };
  

  const Input = () => {
    return (
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>
          Visibility          
        </label>
        <div className={twdClass("flex-1")}>
          <select
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => updateItem("visibleTo", e.target.value)}
            value={visibleTo}
          >
            <option value="">Select</option>
            <option value={'guest'}>Visible to Users</option>
            <option value={'not_visible'}>Not visible to users</option>
          </select>
        </div>
      </div>
    );
  };


  return (
    <div className={twdClass("flex flex-col")}>          
      <Input/>
    </div>  
  );
};

export default Visible;
