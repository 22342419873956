import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import { saveProject, getProjectInfo } from "../../../api/services";
import { toast } from "react-toastify";

const CreatePagePanel = (props) => {
  const dispatch = useDispatch();
  const {
    pageInfo,
    projectData: { project_id, pages },
  } = useSelector((state) => state.studioDetails);

  let suggestName = "New Page";
  let suggestSlug = "";

  if (pages.length > 0) {
    suggestName = `New Page - ${pages.length + 1}`;
    suggestSlug = `/new-page-${pages.length + 1}`;
  }

  const [pageName, setPageName] = useState(suggestName);
  const [pageSlug, setPageSlug] = useState(suggestSlug);

  useEffect(() => {
    if (pageInfo.settings.pageName === pageName) {
      //console.log("save for Create_new_page", pageName, pageSlug)
      saveProject({ id: project_id, pageInfo }).then(async (resp) => {
        if (resp.success) {
          toast.success("Page successfully saved");
          props.fetchPageData({ project_id, page_id: resp.data[0].page_id });
          const { data } = await getProjectInfo(project_id);
          dispatch({ type: UPDATE_PROJECT_INFO, data });
          dispatch({ type: SELECT_PAGE_ID, id: resp.data[0].page_id });
          dispatch({ type: RESET_POPUP });
        }
      });
    }
  }, [pageInfo.settings.pageName]);

  return (
    <div>
      <div className={"create-page-layout"}>
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>Page Name</label>
          <div className={twdClass("flex-1")}>
            <input
              type="text"
              placeholder="About"
              value={pageName}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                setPageName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>
            Slug
            <span className={twdClass("ml-2 text-xs text-gray-400")}>
              (Ex: /about)
            </span>
          </label>
          <div className={twdClass("flex-1")}>
            <input
              type="text"
              placeholder={"/about"}
              className={twdClass(INPUT_TEXT)}
              value={pageSlug}
              onChange={(e) => {
                setPageSlug(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={twdClass("mt-4 text-right")}>
        <button
          className={`${twdClass(BTN_CLS)} ${twdClass(
            "bg-yellow-800 hover:bg-yellow-600 text-white"
          )}`}
          onClick={() => {
            //console.log("create Page for Create_new_page", pageName, pageSlug)
            dispatch({
              type: CREATE_NEW_PAGE,
              data: { pageName, pageSlug },
            });
          }}
        >
          Create Page
        </button>
      </div>
    </div>
  );
};

export default CreatePagePanel;
