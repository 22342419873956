import { getDefaultFields } from "../common/common.fields";

export const FOOTER_FORM_1 = {
  sectionName: "Fotter Section",
  elements: [
    {
      inputType: "text",
      updateTo: "logo.text",
      label: "Comapny Name",
      hasStyleProps: true,
      styleElementId: 1,
    },
    {
      inputType: "simplelist",
      updateTo: "description.list",
      label: "Description",
      defaultValue: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ],
      hasStyleProps: true,
      styleElementId: 2,
    },
    {
      inputType: "text",
      updateTo: "copy.text",
      label: "Copyright",
      hasStyleProps: true,
      styleElementId: 3,
    },
    {
      inputType: "text",
      updateTo: "copy.href",
      label: "Copyright link",
      hasStyleProps: false,
    },
    {
      inputType: "text",
      updateTo: "terms.text",
      label: "Terms",
      hasStyleProps: true,
      styleElementId: 4,
    },
    {
      inputType: "text",
      updateTo: "terms.href",
      label: "Terms link",
      hasStyleProps: false,
    },
    {
      inputType: "text",
      updateTo: "privacy.text",
      label: "Privacy",
      hasStyleProps: true,
      styleElementId: 5,
    },
    {
      inputType: "text",
      updateTo: "privacy.href",
      label: "Privacy link",
      hasStyleProps: false,
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Features Body",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Comapny info",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "logo.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Description",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "description.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Copyright",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "copy.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Terms",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "terms.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 5,
      label: "Privacy",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "privacy.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
          ],
        }),
      ],
    },
  ],
};
