export const HERO_BLOCK_1 = {
  blockType: "HERO",
  blockDesign: "HeroDesign1",
  editFormName: "HERO_FORM_1",
  styleProps: {
    "background-image":
      "url('https://images.unsplash.com/photo-1432847712612-926caafaa802?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80')",
  },
  textBlock: {
    styleProps: {
      "background-color": "#012f40",
    },
    heading1: {
      text: "Best Designs,",
      hideUnderline: true,
      underLineStyle: {
        "background-color": "",
        height: "",
      },
      styleProps: {
        color: "#FFF",
        textAlign: "left",
      },
    },
    heading2: {
      text: "Grow Together",
      hideUnderline: false,
      underLineStyle: {
        "background-color": "red",
        height: "",
      },
      styleProps: {
        color: "#ffca28",
        textAlign: "left",
      },
    },
    description: [
      {
        text:
          "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
        styleProps: { color: "red" },
      },
      {
        text:
          "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
        styleProps: { color: "yellow" },
      },
    ],
    button1: {
      text: "Get Started",
      styleProps: {},
    },
    button2: {
      text: "Try signup for Free",
      styleProps: {},
    },
  },
  showImage: "right",
  imageBlock: {
    image: "https://cdn.devdojo.com/images/september2020/grow-together.png",
  },
};
