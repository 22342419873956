import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { set, isEmpty, find } from "lodash";
import { checkSheetAccess, addNewConnection } from "../../../api/services";
import { toast } from "react-toastify";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import { detectGoogleSheetId } from "../../../utils/common.utils";
import {Button} from "../../ui/com_ui";

export const GoogleSheetForm = ({ selectConnect, setCurrentPanel }) => {
  const {
    projectData: { project_id, connections },
  } = useSelector((state) => state.studioDetails);
  const [connection, setConnection] = useState({
    connection_type: "googleSheet",
    connection_details: {
      spreadsheetId: "",
      displayName: "",
    },
  });

  useEffect(() => {
    const val = find(connections, ["connection_id", selectConnect]);
    if (val) {
      setConnection(val);
    }
  }, []);

  const updateConnection = (key, value) => {
    setConnection((data) => {
      const d = set(data, key, value);
      return { ...d };
    });
  };
  const addConnection = async () => {
    const data = { ...connection, project_id };
    const result = await checkSheetAccess({
      spreadsheetId: connection.connection_details.spreadsheetId,
    });
    if (result && result.success) {
      addNewConnection(data).then((resp) => {
        if (resp.success) {
          toast.success("Connection added to project");
          updateConnection("connection_details.spreadsheetId", "");
          updateConnection("connection_details.displayName", "");
          setCurrentPanel("main");
        }
      });
    } else {
      toast.error(
        "Sheet does not have permission to access, Please make it public."
      );
    }
  };

  const btnEnable =
    isEmpty(connection.connection_type) ||
    isEmpty(connection.connection_details.displayName) ||
    isEmpty(connection.connection_details.spreadsheetId);
  return (
    <div className={twdClass("rounded-2xl px-6 py-6 bg-white -mx-4 -mb-1")}>
      <div className={twdClass("w-full flex items-center mb-2")}>
        <div className={twdClass("w-1/3 text-sm font-semibold")}>
          Sheet Display Name <span className={twdClass("text-red-600")}>*</span>
        </div>
        <div className={twdClass("w-2/3")}>
          <input
            className={twdClass(INPUT_TEXT)}
            value={connection.connection_details.displayName}
            placeholder="Display Name"
            onChange={(e) => {
              updateConnection(
                "connection_details.displayName",
                e.target.value
              );
            }}
          />
        </div>
      </div>
      <div className={twdClass("w-full flex items-center mb-2")}>
        <div className={twdClass("w-1/3 text-sm font-semibold")}>
          Sheet Id <span className={twdClass("text-red-600")}>*</span>
        </div>
        <div className={twdClass("w-2/3")}>
          <input
            className={twdClass(INPUT_TEXT)}
            value={connection.connection_details.spreadsheetId}
            placeholder="1U8pjOn9paO--34iYYhdg5fE3NARI0ySNbc4IVy5YKE0"
            onChange={(e) => {
              const id = detectGoogleSheetId(e.target.value);
              updateConnection("connection_details.spreadsheetId", id);
            }}
          />
        </div>
      </div>
      <div className={twdClass("text-right pt-4")}>
        <Button
          type="default"  color="primary" className={'inline-block'}            
          disabled={btnEnable}
          onClick={addConnection}
        >
          {connection.connection_id ? "Save" : "Add"}  Connection
        </Button>
      </div>
    </div>
  );
};
