import { ConnectionList } from "./ConnectionList";
import { AddConnectionPanel } from "./AddConnectionPanel";
import { useState } from "react";
import { BTN_CLS } from "../../../constants/var.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import FaIcons from "../../common/FaIcons";
import {Button} from "../../ui/com_ui";

export const ConnectionPanel = () => {
  const [panel, setCurrentPanel] = useState("main");
  const [selectConnect, setSelectConnect] = useState(null);

  const updateEditId = ({ id, panelType }) => {
    setSelectConnect(id);
    setCurrentPanel(panelType);
  };
  return (
    <div className="connections-cls">
      <div className={twdClass("my-2 mb-4 text-right")}>
        {panel === "addedit" && (
          <Button
            type="default"  cType={"iconText"} iconImg={"arrow-left-sm.svg"} color="white" className={'inline-block'}  
            onClick={() => setCurrentPanel("main")}
          >
            Back to list
          </Button>
        )}
        {panel === "main" && (
          <Button
            type="default"  color="black" className={'inline-block'}            
            onClick={() => {
              setSelectConnect(null);
              setCurrentPanel("addedit");
            }}
          >
            Add New Connection
          </Button>
        )}
      </div>
      {panel === "main" && <ConnectionList updateEditId={updateEditId} />}
      {panel === "addedit" && (
        <AddConnectionPanel
          selectConnect={selectConnect}
          setCurrentPanel={setCurrentPanel}
        />
      )}
    </div>
  );
};
