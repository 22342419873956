import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, getTimestamp, getTemplateSample, getParams, styles_, links_} from "./t_util";

import cards from '../TestDesign/data/cards'


const cardConfig_=({obj={}, d})=>{
  let {cards_config={}, cards_item_outer_styles={}} = d
  let {cards_per_row={}} = cards_config
  let {desktop=3, tab=2, mobile=1} = cards_per_row

  let out=(
    {
      "perRow": {
          "desktop": desktop,
          "tab": tab,
          "mobile": mobile
      },
      "fasty": "test",
      "layout": "l1",
      "alternate": false,
      "cardstyleProps":cards_item_outer_styles.styleProps ? cards_item_outer_styles.styleProps : {}
    }
  )
  if(obj.cardLocations.length>=5){
    out.layout='l4'
  }else if(obj.cardLocations.length>=4){
    out.layout='l3'
  }
  return out
}
const searchConfig_ = ({obj={}, d}) =>{
  let {searchbar={}} = d
  let {search_button={}, clear_button={}, search_input={}} = searchbar
  let out={
    "showSearchBar": true,
    "input": {},
    "searchButton": {  },
    "clearButton": {},
    "searchContainer": {},
    "columns": obj.configs.params.map(({value}, index) => { return value})
  }
  out.searchContainer=styles_({obj:out.searchContainer, el:searchbar})
  out.input=styles_({obj:out.input, el:search_input})
  out.clearButton=styles_({obj:out.clearButton, el:clear_button})
  out.searchButton=styles_({obj:out.searchButton, el:search_button})
  return out
}
const filters_=({obj={}, d, generated={}})=>{
  const {settings={}}=generated
  let filters=settings.filters ? settings.filters : []

  console.log("forFilters", filters)
  let categories=filters.map((item, index)=>{
    const filter_items=item.values.map((v, i)=>{
      return v ? v : `field${i}`
    })
    let uid=  getTimestamp()
    return(
      {
        "label": item.label,
        "styleProps": {
            "margin": "10px",
            "padding": "10px"
        },
        "multiselect": true,
        "filterItems": filter_items,
        "columns": [item.label],
        "id": `c_${uid+index}`,
      }
    )
  })
  return(
    {
      "categories": categories,
      "showFilter": true,
      "align": "top"
    }
  )
}
const params_=()=>{
  return(
    [
      {
          "label": "id",
          "value": "Id"
      },
      {
          "label": "name",
          "value": "Name"
      },
      {
          "label": "role",
          "value": "Role"
      },
      {
          "label": "description",
          "value": "Description"
      },
      {
          "label": "department",
          "value": "Department"
      },
      {
          "label": "status",
          "value": "Status"
      },
      {
          "label": "tags",
          "value": "Tags"
      },
      {
          "label": "color",
          "value": "Color"
      },
      {
          "label": "email",
          "value": "Email"
      },
      {
          "label": "skype",
          "value": "Skype"
      },
      {
          "label": "location",
          "value": "Location"
      },
      {
          "label": "img",
          "value": "Img"
      },
      {
          "label": "details_link",
          "value": "DetailsLink"
      },
      {
          "label": "date",
          "value": "Date"
      }
    ]
  )
}
const configs_=({obj={}, d, generated={}})=>{
  

  const {settings={}} = generated
  let uid=  getTimeForUniqID()
  let uid2=  getTimestamp()

  //const timestamp = new Date().getTime();
  //updateElementValue("configs.connection.timestamp", timestamp);

  console.log("Config", d)
  return(
    {
      "dataType": "dynamic",
      "connection": {
          "spreadsheetId": "spreadsheet_id",
          "sheetName":  settings.googleSheetName,
          "connectionId": settings.connectionId,
          "connectionTable": "",
          "timestamp": uid2,
          "timestamp2": uid2
      },
      "params": obj.configs.params,
      "filters": filters_({obj, d, generated}),
      "searchConfig": searchConfig_({obj, d}),
      "sortBy": {
          "columnName": "Name",
          "ascending": true
      },
      "id": uid
    }
  )
}
const locations_=()=>{
  return(
    [
      {
          "name": "A",
          "styleProps": {
              "margin": "0px 0px 0px 0px",
              "padding": "0px 0px 0px 0px",
              "width": "50%",
              "background-color": "#116dff",
              "border-radius": "1.5rem"
          }
      },
      {
          "name": "B",
          "styleProps": {
              "margin": "00px 0px 0px 0px",
              "padding": "0px 0px 0px 0px",
              "width": "50%",
              "background-color": "#ffa611",
              "border-radius": "1.5rem"
          }
      },
      {
          "name": "outer",
          "styleProps": {
              "margin": "10px",
              "padding": "20px 20px 20px 20px",
              "width": "100%",
              "background-color": "#333333",
              "border-radius": "1.5rem"
          }
      },
      {
          "name": 0
      }
    ]
  )
}

const getParams_=(template)=>{
  let regex = /\[\[(.*?)\]\]/g;
  let out=''
  let match, matches = [];
  // Part 1
  while (match = regex.exec(template)) {
    matches.push({label:match[1], value:match[1]});
  }
  return matches
}

const process_=({obj, d})=>{
 // console.log("d", d)

  let {title={}, description={}}=d
  obj.headings=[title, description]
  //let card_sample=getTemplateSample({template:JSON.stringify(obj.card_template), captureFrom:"box_brackets"})
  //obj.card_sample=card_sample
  return obj
}
const cardElement_=({ele, settings})=>{
  const {detailsPageParam=''} = settings
  let {elementType='', text='', url=''} =ele
  let params=getParams({template:JSON.stringify(ele), captureFrom:"box_brackets"})  
  let openParams={"type": "newtab"}
  let type='', styleKey='styleProps'
  if(elementType=="buttonEl"){
    type="button"
    styleKey="btnStyleProps"
    if(url) openParams.column=url
    if(params[0]) openParams.column=params[0] 
  }else if(elementType=="detailsLinkEl"){
    type="button"
    styleKey="btnStyleProps"
    openParams.detailsPageParam=detailsPageParam
    if(url) openParams.column=url
    if(params[0]) openParams.column=params[0] 
    if(detailsPageParam) openParams.column=detailsPageParam
  }else if(elementType=="linkEl"){
    type="link"
    styleKey="buttonStyleProps"
    if(url) openParams.column=url
    if(params[0]) openParams.column=params[0] 
  }else if(elementType=="dividerEl"){
    type="divider"
    styleKey="divStyleProps"
  }else if(elementType=="imageEl" || elementType=="thumbImageEl"){
    type="image"
    styleKey="imgStyleProps"
    params=params[0] ? params[0] : {}
  }else{
    type="text"
  }
  let uid= getTimeForUniqID()
  
  let out={id:uid, type, text, params, openParams, renderHTML:false}
  out=styles_({obj:out, el:ele, key:styleKey})
  return out
}
const cardElements_=({obj, d, generated={}})=>{
  console.log("d", d)
  const {settings={}, structure={}}=generated
  let {card_template={}, cards_item_styles={}}=d
  let locations_names=["A", "B", "C", "D"]
  let params=[]
  let elements=[]
  let locations=[]
  let locationIndex=0
  card_template?.card_columns.map((column={}, ci)=>{
    if(!column.elements) return
    if(!column.elements.length) return
    
    let location={name:locations_names[locationIndex]}
    location=styles_({obj:location, el:column})
    //location.styleProps.width='100%'
    locations.push(location)
    column.elements?.map((ele, ei)=>{
      let uid=  getTimeForUniqID()
      let element=cardElement_({ele, settings})
      element.location=locations_names[locationIndex]
      if(element.params[0]){
        params.push(...element.params)
      }else{
        params.push(element.params)
      }
      
      elements.push(element)      
    }) 
    locationIndex++   
  })
  if(locations.length==1){
    locations[0].styleProps.width='100%'
  }
  //cards_item_outer_styles.styleProps.backgroundColor='red'
  if(!cards_item_styles.styleProps) cards_item_styles.styleProps={}
  cards_item_styles.styleProps.width='100%'
  cards_item_styles.styleProps.display='flex'
  cards_item_styles.styleProps.flexWrap='wrap'
  let Outer = {name:"outer"}
  Outer=styles_({obj:Outer, el:cards_item_styles})
  locations.push(Outer)

  obj.cardLocations=locations
  obj.cardElements=elements
  obj.configs.params=params
  obj.cardElements2=[
    {
        "id": "9182736458027",
        "type": "text",
        "params": [
            {
                "value": "id",
                "label": "id",
                "processed": "id_processed"
            }
        ],
        "text": "Trainer ID: [[id]]",
        "align": "left",
        "location": "A",
        "styleProps": {
            "font-size": "1rem",
            "margin": "10px",
            "padding": "10px"
        },
        "stylePropsTab": {
            "font-size": "0.875rem",
            "margin": "10px",
            "padding": "10px"
        },
        "stylePropsMob": {
            "font-size": "0.75rem",
            "margin": "10px",
            "padding": "10px"
        },
        "renderHTML": false
    },
    {
        "id": "1283746572948",
        "type": "image",
        "params": {
            "value": "img",
            "label": "img"
        },
        "align": "center",
        "location": "B",
        "styleProps": {
            "margin": "10px",
            "padding": "10px"
        },
        "stylePropsTab": {
            "margin": "10px",
            "padding": "10px"
        },
        "stylePropsMob": {
            "margin": "10px",
            "padding": "10px"
        },
        "openParams": {
            "type": "hyper_link",
            "column": "img"
        },
        "imgStyleProps": {
            "margin": "10px",
            "padding": "10px"
        },
        "imgStylePropsTab": {
            "margin": "10px",
            "padding": "10px"
        },
        "imgStylePropsMob": {
            "margin": "10px",
            "padding": "10px"
        }
    }
  ]
  return obj
}
const cards_ = ({generated={}, obj=false}) => {
  const {settings={}, structure={}}=generated
  settings.section_data=structure.cards ? structure.cards : {}
  let d = obj ? obj : cards.a01.cards
  let uid=  getTimeForUniqID()
  //console.log("navbar", d.styleProps)
  //console.log("getTimeForUniqID", uid)
  let out={
    "isGenerated":true,
    "testId": uid,
    "metaDataId": uid,
    "blockId": uid,
    "blockType": "CARDS",
    "blockDesign": "BYOCards",
    "editFormName": "DESIGN_CARD_PANEL",
    "headings":[],
    "styleProps": {
        "margin": "0px",
        "padding": "20px"
    },
    "stylePropsTab": {
        "margin": "0px",
        "padding": "20px"
    },
    "stylePropsMob": {
        "margin": "0px",
        "padding": "20px"
    },  
    "cardLocations": [
      {
        "name": "outer",
        "styleProps": {
          "margin": "0",
          "padding": "10px"
        }
      },
      {
        "name": "A",
        "styleProps": {
          "margin": "0",
          "padding": "5px"
        }
      },
      {
        "name": "B",
        "styleProps": {
          "margin": "0",
          "padding": "5px"
        }
      },
      {
        "name": "C",
        "styleProps": {
          "margin": "0",
          "padding": "5px"
        }
      }
    ],
    "cardConfig": {},
    "configs": {},
    "generated":{settings}
  }
  out=process_({obj:out, d})
  out=cardElements_({obj:out, d, generated})
  out.cardConfig=cardConfig_({obj:out, d})
  out.configs=configs_({obj:out, d, generated})
  out=styles_({obj:out, el:d})
  console.log("Cards", out)
  return out
};

export default cards_;

