import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, getTimestamp, styles_, links_} from "./t_util";

import footer from '../TestDesign/data/footer'

const process_=({obj, d})=>{
  //console.log("t_footer", d)

  let {logo={}, description={}, copy={}, terms={}, privacy={}}=d
  obj.logo=logo
  obj.description={...description, list:[description.text]} 
  obj.copy=copy
  obj.terms=terms 
  obj.privacy=privacy
  return obj
}

const footer_ = ({generated={}, obj=false}) => {
  let d = obj ? obj : footer.a01.footer
  let uid=  getTimeForUniqID()
  //console.log("footer", d.styleProps)
  //console.log("getTimeForUniqID", uid)
  let out={
    "metaDataId": uid,
    "blockId": uid,   
    "blockType": "FOOTER",
    "blockDesign": "FooterDesign1",
    "editFormName": "FOOTER_FORM_1",    
    "styleProps": d.styleProps,
    "stylePropsTab": d.stylePropsTab ? d.stylePropsTab : {},
    "stylePropsMob": d.stylePropsMob ? d.stylePropsMob : {},  
    "logo": {
      "text": "",
      "href": "#",
      "styleProps": {}
    },
    "description": {
      "list": [],
      "styleProps": {}
    },
    "copy": {
      "text": "",
      "href": "#",
      "styleProps": {}
    },
    "terms": {
      "text": "Terms",
      "href": "#",
      "styleProps": {}
    },
    "privacy": {
      "text": "Privacy",
      "href": "#",
      "styleProps": {}
    }     
  }
  out=process_({obj:out, d}) 
  out=styles_({obj:out, el:d})
  //out.links=links_({links:d.links})
  return out
};

export default footer_;

