import * as FIELDS from "./combine.fields";
import { cloneDeep, replace, reduce } from "lodash";


const expandFields = (fields) =>{
  let out = reduce(fields, function(sum, n) {
    sum.push(n);
    if(n=='backgroundimage') {
      sum.push('backgroundposition')
      sum.push('backgroundsize')
      sum.push('backgroundrepeat')
    }
    return sum;
  }, []);
  return out
}

export const getDefaultFields = ({
  type = "TEXT",
  updateTo = "",
  fields = [],
}) => {  
  let fields2 = expandFields(fields)
    
  let items = [];
  if (type === "TEXT") {
    items = cloneDeep(FIELDS.TEXT_DEFAULT_FIELDS);
  }
  return items
    .filter((rec) => fields2.indexOf(rec.name) !== -1)
    .map((rec) => ({
      ...rec,
      updateTo: replace(rec.updateTo, "UPDATE_TO", updateTo),
    }));
};
