import { twdClass } from "../../constants/tailwind.constants";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { find } from "lodash";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { Button} from "../ui/com_ui";
import { get, replace, isEmpty, cloneDeep } from "lodash";

import {
  UPDATE_PROJECT_PAGE,
  UPDATE_PROJECT_INFO,
  TOGGEL_CONFIRM_POPUP,
  SELECT_PAGE_ID,
  TOGGEL_POPUP,
  UPDATE_PREVIEW_TYPE,
  RESET_AUTOSAVE,
  RESET_POPUP,
  UPDATE_APP_PREF,
} from "../../constants/action.constants";
import Generate from '../generate/Generate'

export const GeneratePanel = ({
  data,
  updateElementValue,  
  columnNames
}) => {
  const dispatch = useDispatch();
  const {generated={}} = data

  const generateDesign = async () => {
    //const {data} = await getGenerateDesign({pageInfo})
    //console.log("generateDesign", data, pageInfo)
    //dispatch({ type: UPDATE_PROJECT_PAGE,  data: {...data.pageInfo}});

    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        full:true,
        title: "Generate Section",
        component: <Generate {...{regenerate:true, type:'cards', cards_block:data}}/>,
      },
    });

  }
  const {
    selectedBlock,
    pageInfo: { blocks },
    projectData: { connections, project_id },
  } = useSelector((state) => state.studioDetails);

  return (<div className={twdClass(" ")}>
    <Button {...{type:'default', cType:'iconText', iconImg:'stars.svg', color:'primary', size:'sm', className:'inline-block'}} onClick={()=>{generateDesign()}}>ReGenerate</Button>
  </div>)  
};
