export const BTN_NORMAL =
  "mx-2 bg-white py-1 px-3 border border-gray-50 shadow-md text-xs leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-200";
export const INPUT_TEXT =
  "text-sm block w-full text-gray-700 border rounded-2xl bg-gray-50 border-gray-100 hover:border-gray-300 py-2 px-4 leading-tight focus:bg-white";
export const LABEL_INPUT_TEXT =
  "text-sm inline-block text-gray-700 border border-gray-500 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white";
export const BTN_CLS =
  "py-1 px-3 shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-200";

export const SELECT_CLS =
  "text-sm block w-full text-gray-700 border border-gray-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white";
