export const PRICING_METADATA = [
  {
    id: "pricing_1",
    planName: "Free",
    planType: "base",
    planIdM: 100000,
    planIdY: 100000,
    planPriceM: "0",
    planPriceY: "0",
    features: ["No Custom domains", "1 Data source connection", "1 Static page", "1 Dynamic page template", "10 Dynamic pages", "100 rows per sheet", "Search", "Filters (Multi-select)", "Image uploader", "Google Analytics integration", "Drift/Crisp chat integration" , "Embed external scripts", "Integration with major email providers", "10 Columns per Sheet/Table"],
    showInList: true,
    max_cust_domain: 0,
    max_sub_domain: 1,
  },
  {
    id: "pricing_2",
    planName: "Lite",
    planType: "lite",
    planIdM: 772646,
    planIdY: 772647,
    planPriceM: "29.00",
    planPriceY: "290.00",
    features: ["1 Custom domain", "1 Data source connection", "10 Static pages", "10 Dynamic page templates", "1000 Dynamic pages", "1000 rows per sheet", "Search", "Filters (Multi-select)", "Image uploader", "Google Analytics integration", "Drift/Crisp chat integration" , "Embed external scripts", "Integration with major email providers", "20 Columns per Sheet/Table"],
    showInList: true,
    max_cust_domain: 1,
    max_sub_domain: 1,
  },
  {
    id: "pricing_3",
    planName: "Pro",
    planType: "pro",
    planIdM: 772648,
    planIdY: 772649,
    planPriceM: "49.00",
    planPriceY: "490.00",
    features: ["3 Custom domains", "3 Data source connections", "50 Static pages", "50 Dynamic page templates", "5000 Dynamic pages", "5000 rows per sheet", "Connect to Supabase", "Search", "Filters (Multi-select)", "Image uploader", "Google Analytics integration", "Drift/Crisp chat integration" , "Embed external scripts", "Integration with major email providers", "100 Columns per Sheet/Table"],
    showInList: true,
    max_cust_domain: 3,
    max_sub_domain: 1,
  },
];
