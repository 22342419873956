export const PRICING_FORM_1 = {
  sectionName: "Header Section",
  elements: [
    {
      inputType: "text",
      label: "Price Info Title",
      updateTo: "title1.text",
      hasStyleProps: false,
      hideLabel: true,
    },
    {
      inputType: "text",
      label: "Price Title",
      updateTo: "title2.text",
      hasStyleProps: false,
      hideLabel: true,
    },
    {
      inputType: "textarea",
      label: "Price Dec",
      updateTo: "title3.text",
      hasStyleProps: false,
      hideLabel: true,
    },
    {
      inputType: "list",
      label: "Pricing Info",
      updateTo: "plansList",
      hasStyleProps: false,
      styleElementId: 2,
      elements: [],
      mockElements: {
        hasStyleProps: true,
        styleElementId: 2,
        elements: [
          {
            inputType: "text",
            label: "Title 1",
            defaultValue: "Free",
            updateTo: "plansList[i].title.text",
            hasStyleProps: false,
          },
          {
            inputType: "text",
            label: "Title 2",
            defaultValue: "Starter Plan",
            updateTo: "plansList[i].subTitle.text",
            hasStyleProps: false,
            styleElementId: 3,
          },
          {
            inputType: "text",
            label: "Title 3",
            defaultValue:
              "All the free features you need to get started creating your project",
            updateTo: "plansList[i].description.text",
            hasStyleProps: false,
            styleElementId: 4,
          },
          {
            inputType: "text",
            label: "Button Name",
            defaultValue: "Subscript",
            updateTo: "plansList[i].button.text",
            hasStyleProps: false,
            styleElementId: 4,
          },
          {
            inputType: "simplelist",
            label: "Title 3",
            defaultValue: [],
            updateTo: "plansList[i].features",
            hasStyleProps: true,
            styleElementId: 4,
          },
        ],
      },
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Header",
      elements: [
        {
          label: "BG Color",
          inputType: "color",
          updateTo: "styleProps.background-color",
        },
      ],
    },
  ],
};
