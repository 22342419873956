let sd01={ 
  texts:[
    "Job title: {{Job title}}",
    "Category: {{category}}",
    "Location: {{location}}",
    "Job Description: {{Job Description}}",
    "Job Requiremnts: {{Job Requiremnts}}",
  ]
}
let a01={
  textElement:{    
    texts:{
      list:[
        {text:"Job title: {{Job title}}", styleProps:{
          "fontSize": "24px",
          "fontWeight": "bold",
          "color": "#333333",
          "text-align": "left",
          "font-size": "3rem",
          "line-height": "1",
          "margin": "0px 0px 50px 0px"
        } },
        {text:"Category: {{category}}", styleProps:{
          "fontSize": "20px",
          "color": "#333333",
          "text-align": "left"
        } },   
        {text:"Location: {{location}}", styleProps:{
          "fontSize": "20px",
          "color": "#7411ff",
          "text-align": "left",
          "margin": "10px 0px 10px 0px"
        } },  
        {text:"Job Description: {{Job Description}}", styleProps:{
          "fontSize": "20px",
          "color": "#333333",
          "text-align": "left"
        } },  
        {text:"Job Requiremnts: {{Job Requiremnts}}", styleProps:{
          "fontSize": "20px",
          "color": "#333333",
          "text-align": "left"
        }},       
      ],
      styleProps:{
        "padding": "80px 80px 80px 80px",
        "textAlign": "center",
        "background-color": "#ffffff",
        "margin": "0px auto"
      }  
    },
    styleProps:{
      "padding": "60px 20px",
      "textAlign": "center",
      "background-color": "#ececec",
      "display": "block",
      "width": "100%",
      "margin": "0px auto"
    }  
  } 
}

let sd02={ 
  texts:[
    "{{name}}",
    "{{date}}",
    "{{location}}",
    "{{skills}}",
    "Department: {{department}}",
    "Job Type: {{job_type}}",
    "Qualifications: {{qualification}}",
  ]
}
let a02={
  textElement:{    
    texts:{
      list:[
        {
          "text": "{{name}}",
          "styleProps": {
            "color": "#333333",
            "fontWeight": "bold",
            "fontSize": "32px",
            "lineHeight": "1.2",
            "padding": "10px 0px 40px 0px",
            "font-size": "3rem",
            "line-height": "1"
          },
        },
        {
          "text": "{{date}}",
          "styleProps": {
            "color": "#333333",
            "fontStyle": "italic",
            "fontSize": "20px",
            "lineHeight": "1.3",
            "padding": "0px 0px 5px 0px",
            "font-size": "1.5rem",
            "line-height": "2rem"
          }
        },   
        {
          "text": "{{location}}",
          "styleProps": {
            "color": "#333333",
            "fontWeight": "100",
            "fontSize": "20px",
            "lineHeight": "1.3",
            "padding": "0px 0px 20px 0px"
          }
        },
        {
          "text": "{{skills}}",
          "styleProps": {
            "color": "#FFFFFF",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "padding": "20px 20px",
            "margin": "20px 0px 100px 0px",
            "backgroundColor": "#333333",
            "borderRadius": "10px"
          }
        },
        {
          "text": "Department: {{department}}",
          "styleProps": {
            "color": "#b64400",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "padding": "0px 0px 10px 0px",
            "margin": "10px 20px",
            "borderBottom": "1px solid #FFFFFF"
          }
        },
        {
          "text": "Job Type: {{job_type}}",
          "styleProps": {
            "color": "#333333",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "margin": "0px 20px",
            "padding": "10px 0px 10px 0px",
            "borderBottom": "1px solid #fff",
            "font-weight": "700"
          }
        },
        {
          "text": "Qualifications: {{qualification}}",
          "styleProps": {
            "color": "#333333",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "margin": "0px 20px",
            "padding": "10px 0px 10px 0px",
            "borderBottom": "1px solid #fff"
          }
        }      
      ],
      styleProps:{
        "padding": "50px 50px 50px 50px",
        "background-color": "#ffffff",
        "margin": "0px auto",
        "border-radius": "1rem",
        "box-shadow": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
      }  
    },
    styleProps:{
      "padding": "50px 50px 50px 50px",
      "background-color": "#f5f5f7",        
      "display": "block",
      "width": "100%",
      "margin": "0px auto"
    }  
  } 
}


let sd03={ 
  texts:[
    "{{name}}",
    "{{date}}",
    "{{location}}",
    "{{skills}}",
    "Department: {{department}}",
    "Job Type: {{job_type}}",
    "Qualifications: {{qualification}}",
  ]
}
let a03={
  textElement:{    
    texts:{
      list:[
        {
          "text": "{{name}}",
          "styleProps": {
            "color": "#f5f5f5",
            "fontWeight": "bold",
            "fontSize": "32px",
            "lineHeight": "1.2",
            "padding": "10px 0px 40px 0px",
            "font-size": "3rem",
            "line-height": "1"
          },
          "stylePropsMob": {
            "padding": "10px 0px 20px 0px",
            "fontSize": "24px"
          }
        },
        {
          "text": "{{date}}",
          "styleProps": {
            "color": "#9fd89f",
            "fontStyle": "italic",
            "fontSize": "20px",
            "lineHeight": "1.3",
            "padding": "0px 0px 5px 0px",
            "font-size": "1.5rem",
            "line-height": "2rem",
            "font-weight": "500"
          }
        },
        {
          "text": "{{location}}, United Kingdom",
          "styleProps": {
            "color": "#f5f5f5",
            "fontWeight": "100",
            "fontSize": "20px",
            "lineHeight": "1.3",
            "padding": "0px 0px 20px 0px"
          }
        },
        {
          "text": "{{skills}}",
          "styleProps": {
            "color": "#f5f5f5",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "padding": "20px 20px",
            "margin": "20px 0px 100px 0px",
            "backgroundColor": "#333333",
            "borderRadius": "10px"
          }
        },
        {
          "text": "Department: {{department}}",
          "styleProps": {
            "color": "#f5f5f5",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "padding": "0px 0px 10px 0px",
            "margin": "10px 20px 0px 0px",
            "borderBottom": "1px solid #FFFFFF",
            "border-width": "0px"
          }
        },
        {
          "text": "Job Type: {{job_type}}",
          "styleProps": {
            "color": "#f5f5f5",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "margin": "20px 20px 0px 0px",
            "padding": "10px 0px 10px 0px",
            "borderBottom": "1px solid #fff",
            "font-weight": "700",
            "border-width": "0px",
            "font-size": "1.5rem",
            "line-height": "2rem"
          }
        },
        {
          "text": "Qualifications: {{qualification}}",
          "styleProps": {
            "color": "#f5f5f5",
            "fontSize": "20px",
            "lineHeight": "1.2",
            "margin": "10px 0px 0px 0px",
            "padding": "10px 0px 10px 0px",
            "borderBottom": "1px solid #fff",
            "border-width": "0px"
          }
        }     
      ],
      styleProps:{
        "padding": "50px 50px 50px 50px",
        "background-color": "",
        "margin": "0px auto",
        "border-radius": "1rem",
        "box-shadow": ""
      }  
    },
    styleProps:{
      "padding": "50px 50px 50px 50px",
      "background-color": "#242424",
      "display": "block",
      "width": "100%",
      "margin": "0px auto"
    }  
  } 
}


export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
