import { twdClass } from "../../constants/tailwind.constants";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { find } from "lodash";
import { getSheetInfo, getSheetColumns } from "../../api/services";
import CustomSelectBox from "../common/CustomSelectBox";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { Button} from "../ui/com_ui";
import ListComponent from "../form/ListComponent";
import SelectBox from "../form/elements/SelectBox";
import ToggleBtn from "../common/ToggleBtn";
import { get, replace, isEmpty, cloneDeep } from "lodash";

export const WhereClausePanel = ({
  data,
  updateElementValue,  
  columnNames
}) => {
  const {
    selectedBlock,
    pageInfo: { blocks },
    projectData: { connections, project_id },
  } = useSelector((state) => state.studioDetails);

  const [isColumnNames, setColumnNames] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);

  useEffect(() => {
    let columnNames2 = columnNames.map(({key='', value=''})=>{
      return ({value:value.trim(), display:value.trim() })
    })
    //console.log("columnNames ", columnNames)
    setColumnNames(columnNames2);
  }, [columnNames]);



  let element={    
    updateTo:'configs.whereClause.items',
    inputType: "list",
    label: "Where Clause",
    hasStyleProps: false,
    mockElements: {
      hasStyleProps: false,
      styleElementId: 0,
      elements: [
        {
          inputType: "select",
          label: "Column",
          updateTo: "configs.whereClause.items[i].column",
          hasStyleProps: false,
          hideLabel: false,
          options:isColumnNames,
          options2: [
            {
              value: "text",
              display: "Text",
            },
            {
              value: "image",
              display: "Image",
            },
            {
              value: "html",
              display: "HTML/SVG",
            },
          ],
        },        
        {
          inputType: "text",
          label: "Value",
          hasStyleProps: false,
          updateTo: "configs.whereClause.items[i].value",
          hideLabel: false,
        },
        
      ],
    },
    elements: [],
  }
  let logicElement={
    inputType: "text",
    label: "Logic",
    hasStyleProps: false,
    updateTo: "configs.whereClause.logic",
    hideLabel: false,
    options: [
      {
        value: "OR",
        display: "OR",
      },
      {
        value: "AND",
        display: "AND",
      },
    ],
  }
  const listContent=(
    <div>
      <div className={twdClass("flex items-center justify-between mb-1 -mx-2")}>       
        <ToggleBtn
          toggleId={`filter_1`}
          value={ get(data, 'configs.whereClause.enable')}
          falseLabel="Off"
          trueLabel="On"
          updateValue={(value) => {
            console.log("Filter_1", value);
            updateElementValue(
              `configs.whereClause.enable`,
              !value
            );
          }}
        />
      </div>
      <div className={twdClass(" bg-white bg-opacity-50 rounded-2xl -mx-2")}>
        <SelectBox
          element={logicElement}
          data={data}
          updateElementValue={updateElementValue}
          inputArgs={{display_label:true}}        
        />
      </div>
    </div>
  )
  return (
    <>       
      <div className={twdClass(" ")}>
        {(isColumnNames && isColumnNames.length)  && <ListComponent
          element={element}
          data={data}
          updateElementValue={updateElementValue}
          inputArgs={{display_label:true}}
          listArgs={{topContent:listContent}}
          expanded={false}
        />}
      </div>
    </>
  );
};
