import { find, set, get, replace } from "lodash";
import { useEffect, useState, Fragment } from "react";
import { BTN_CLS } from "../../../constants/var.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import FaIcons from "../../common/FaIcons";
import { useSelector, useDispatch } from "react-redux";
//import { deletePageById } from "../../../api/services";
//import { TOGGEL_CONFIRM_POPUP, RESET_CONFRIM_POPUP } from "../../../constants/action.constants";
import {Button} from "../../ui/com_ui";
import copy from 'copy-to-clipboard';
//import createEncryptor from 'simple-encryptor'


const EmbedCode = ({
  blockId='',
  advancedAnchorId=''
}) => {
  const dispatch = useDispatch();
  const {
    pageInfo: { settings},
    projectData: { project_id, pages, domain},
  } = useSelector((state) => state.studioDetails);

  const [isCopyText, setCopyText] = useState('Copy');
   //const encryptor = new createEncryptor('flezr-encryption-key')

  //console.log("settings", settings)
  //console.log("blocks", blocks)
  //console.log("sectionData", sectionData)    
  let domain_=`${domain}`
  let blockId_=advancedAnchorId
  let pageSlug = settings.pageSlug ? settings.pageSlug : '/'
  if(!blockId_) blockId_=blockId

  //let en = encryptor.encrypt(domain_) // o2fXhV
  //let de = encryptor.decrypt(domain_) // [1, 2, 3]
  

  //console.log("encryptor",en, de)
  let section_id= blockId ? `data-section-id="${blockId_}"` : ''
  let section_id_display= blockId ? (
    <>
    <span className={twdClass("font-bold ")}>{'data-section-id'}</span>
    {'="'}
    <span className={twdClass("font-bold text-yellow-500")}>{blockId_}</span>
    {'" '}
    </>
  ) : ''

  let embedCode2=`
  <div class="flezr-embed" data-site-id='https://defi-smartcontracts.flezr.co' data-page-id="1" data-block-id="LandingPages" style="height:500px;"></div>
<script type="text/javascript" src="https://defi-smartcontracts.flezr.co/platform.js"></script>
  `
  let embedCode=`<div class="flezr-embed" data-site-id='${domain_}' data-page-slug="${pageSlug}" ${section_id} style="height:500px;"></div>\r
<script type="text/javascript" src="https://app.flezr.com/platform.js"></script>
  `

  let embedCodeDisplay=(
  <span>
    {`<div class="flezr-embed" data-site-id="${domain_}" `}
    <span className={twdClass("font-bold ")}>{'data-page-slug'}</span>
    {'="'}
    <span className={twdClass("font-bold text-yellow-500")}>{pageSlug}</span>
    {'" '}
    {section_id_display}
    {'style="height:500px;"></div> '}
    {`<script type="text/javascript" src="https://app.flezr.com/platform.js"></script>`}    
  </span>)

  return (
    <div className={twdClass("flex flex-col")}>          
      <div className={twdClass("text-sm bg-gray-800 text-gray-300 hover:text-white border border-gray-200 px-4 py-2 rounded-xl")}>            
        {embedCodeDisplay}
      </div>
      <div className={twdClass("mt-2 flex justify-end")}>
        {isCopyText=='Copy' && <Button           
          color={'white'}  
          size={'sm'}  
          onClick={() => {
            //dispatch({ type: RIGHT_PANEL_TOGGEL, data: false });
            console.log("Copy")
            copy(embedCode);
            setCopyText("Copied to clipboard")
            setTimeout(()=>{
              setCopyText("Copy")
            }, 2000)
          }}
        >
          <FaIcons icon="copy" /> Copy
        </Button>}
        {isCopyText=='Copied to clipboard' && <span className={twdClass("bg-green-50 rounded-xl px-2 py-1 text-sm")}>{isCopyText}</span>}
      </div>
    </div>  
  );
};

export default EmbedCode;
