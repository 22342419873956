import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";


const ExternalCSS = ({ sitesettings, updateSettings }) => {
  const updateItem = (value) => {
    const sst = cloneDeep(sitesettings);
    const data = set(sst, "externalCSS", value);
    updateSettings(data);
  };

  return (
    <div className={twdClass("w-full mb-4")}>
      <div className={twdClass("text-md font-semibold mb-4")}>External CSS</div>
      <textarea
        rows={10}
        value={sitesettings.externalCSS}
        className={twdClass(`${INPUT_TEXT} w-full`)}
        onChange={(e) => updateItem(e.target.value)}
        placeholder = {`<style> \n\r\t body{  color:#ffffff; } \n\r\t .nav{  border:1px solid #333333 ; } \n\r</style>`}
      ></textarea>
    </div>
  );
};

export default ExternalCSS;
