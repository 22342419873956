import { PageGenerator } from "sly-react-libs";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SELECT_BLOCK_ONCLICK,
  UPDATE_BLOCK_DATA,
  ADD_BLOCK_TO_PAGE,
  TOGGEL_POPUP,
  UPDATE_SITE_SETTINGS,
} from "../../../constants/action.constants";
import { cloneDeep, find, findIndex } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import { END_POINT_PATH } from "../../../api/endpoint.urlconstants";
import { CopySection } from "./CopySection";
import { toast } from "react-toastify";

import { getSiteSettings, saveSiteSettings } from "../../../api/services";

const TAILWIND_PREFIX = process.env.REACT_APP_TAILWIND_PREFIX;

const SitePanel = () => {
  const {
    openRightPanel,
    pageInfo,
    pageInfo: { blocks },
    sitesettings,
    projectData: { project_id, domain},
  } = useSelector((state) => state.studioDetails);

  useEffect(() => {
    getSiteSettings(project_id).then((resp) => {
      if (resp.success) {
        dispatch({ type: UPDATE_SITE_SETTINGS, data: resp.data });
      }
    });
    //console.log("fasty", project_id)
  }, [project_id]);

  const { previewType } = useSelector((state) => state.studioView);
  const openPanel = classNames({ "open-editor": openRightPanel });
  const dispatch = useDispatch();
  /* useEffect(() => {
    const siteSection = document.getElementsByClassName('site-section')[0];    
    if (siteSection.children.length) {
      siteSection.lastElementChild.scrollIntoView()
    }
  }) */
  //console.log("sitesettings", sitesettings)
  const updateBackToApp = (action, metaDataId) => {
    let mBlocks = cloneDeep(blocks);

    if (action === "REMOVE") {
      const data = mBlocks.filter((item) => item.metaDataId !== metaDataId);
      dispatch({ type: UPDATE_BLOCK_DATA, data });
    } else if (action === "MOVE_UP" || action === "MOVE_DOWN") {
      const blockIndex = findIndex(mBlocks, ["metaDataId", metaDataId]);
      const record = find(mBlocks, ["metaDataId", metaDataId]);
      const totalLen = mBlocks.length;
      const shouldNotPerform =
        (action === "MOVE_UP" && blockIndex === 0) ||
        (action === "MOVE_DOWN" && blockIndex === totalLen);

      if (!shouldNotPerform) {
        mBlocks.splice(blockIndex, 1);
        const moveTo = action === "MOVE_UP" ? blockIndex - 1 : blockIndex + 1;
        mBlocks.splice(moveTo, 0, record);
        dispatch({ type: UPDATE_BLOCK_DATA, data: mBlocks });
      }
    } else if (action === "DUPLICATE") {
      const [selected] = mBlocks.filter(
        (item) => item.metaDataId === metaDataId
      );
      const data = cloneDeep(selected);
      data.metaDataId = new Date().getTime();
      dispatch({ type: ADD_BLOCK_TO_PAGE, data });
    } else if (action === "COPY_SECTION") {
      toast.success("Successfully copyed block");
      const cBlock = mBlocks.filter((item) => item.metaDataId === metaDataId);
      const copyBlock = cloneDeep(cBlock[0]);
      dispatch({
        type: TOGGEL_POPUP,
        data: {
          showPopup: true,
          title: "Copy to page",
          component: <CopySection copyBlock={copyBlock} />,
        },
      });
    } else {
      dispatch({
        type: SELECT_BLOCK_ONCLICK,
        data: null,
      });
      setTimeout(() => {
        dispatch({
          type: SELECT_BLOCK_ONCLICK,
          data: find(mBlocks, ["metaDataId", metaDataId]),
        });
      });
    }
  };
  // console.log(END_POINT_PATH);
  //console.log("pageInfo", pageInfo)
  return (
    <div
      className={`site-section ${previewType} ${openPanel} ${
        blocks.length ? twdClass("shadow-md") : ""
      }`}
    >
      {blocks.length ? (
        <PageGenerator
          tailwindPrefix={TAILWIND_PREFIX}
          page={pageInfo}
          sitesettings={sitesettings}
          updateBackToApp={updateBackToApp}
          apiendpoint={END_POINT_PATH}
        />
      ) : (
        <div>
          <div
            className={twdClass(
              "w-full text-center mt-24 text-4xl font-semibold text-gray-600 mt-12"
            )}
          >
            Click on left side plus icon to start adding sections
          </div>
        </div>
      )}
    </div>
  );
};

export default SitePanel;
