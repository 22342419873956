// import { useState } from "react";
import { useSelector } from "react-redux";
import SettingsDetails from "./SettingsDetails";
import * as FORMS_JSONS from "../../jsons";
import SettingsPropsDetails from "./SettingsPropsDetails";
import AdvancedPropsDetails from "./AdvancedPropsDetails";
import { GenericCardsPanel } from "../cards/GenericCardsPanel";
import { DesignCardPanel } from "../card-design/DesignCardPanel";

const SettingsPanel = () => {
  // const [showDetailPanel, setDetailPanel] = useState(false);

  const {
    selectedBlock,
    styleElementId,
    advancedElementId,
    listIndex,
    pageInfo: { blocks },
  } = useSelector((state) => state.studioDetails);
  const SELECTED_FORN_NAME = selectedBlock?.editFormName;
  let FORM_INFO = SELECTED_FORN_NAME ? FORMS_JSONS[SELECTED_FORN_NAME] : null;

  // console.log(selectedBlock);
  // console.log(FORM_INFO);
  if (SELECTED_FORN_NAME === "DESIGN_CARD_PANEL") {
    if (advancedElementId !== null) {
      return (
        <AdvancedPropsDetails
          advancedElementId={advancedElementId}
          sectionData={selectedBlock}
          sectionElem={FORM_INFO}
          blocks={blocks}
          listIndex={listIndex}
        />
      );
    }
    return <DesignCardPanel />;
  }
  if (SELECTED_FORN_NAME === "BYO_CARDS_PANEL") {
    return <GenericCardsPanel />;
  }

  if (FORM_INFO) {
    if (styleElementId !== null) {
      return (
        <SettingsPropsDetails
          styleElementId={styleElementId}
          sectionData={selectedBlock}
          sectionElem={FORM_INFO}
          blocks={blocks}
          listIndex={listIndex}
        />
      );
    }else if (advancedElementId !== null) {
      return (
        <AdvancedPropsDetails
          advancedElementId={advancedElementId}
          sectionData={selectedBlock}
          sectionElem={FORM_INFO}
          blocks={blocks}
          listIndex={listIndex}
        />
      );
    }
    return (
      <div>
        <SettingsDetails
          sectionData={selectedBlock}
          sectionElem={FORM_INFO}
          blocks={blocks}
        />
      </div>
    );
  }
  return <></>;
};

export default SettingsPanel;
