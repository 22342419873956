import { useRef } from "react";
import Dropdown from "react-multilevel-dropdown";
import { HelpMenu } from "./ui";
import { useAuth } from "../../contexts/Auth";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";

const Header = () => {
  const userMenuRef = useRef(null);
  const { signOut } = useAuth();
  const history = useHistory();
  const { first_name, last_name, email } = useSelector(
    (state) => state.userdetails
  );

  const adminEmails = ["shaik.yasinbasha47@gmail.com", "demo@mailinator.com"];

  const handleSignOut = async () => {
    await signOut();
    history.push("/login");
  };

  const openUserMenu = (e) => {
    if (userMenuRef.current) {
      userMenuRef.current.toggle(e);
    }
  };

  return (
    <div>
      <div
        className={`${twdClass(
          "w-full py-4 px-12 flex items-center shadow-md fixed w-full top-0 z-20 bg-white"
        )}`}
      >
        <div className={twdClass("flex-grow flex items-center")}>
          <Link className={twdClass("flex items-center")} to="/">
            <span className={twdClass("mr-w")}>
              <img alt="" width="150px" src="/assets/images/Logo.svg" />
            </span>
          </Link>
          <div className={twdClass("ml-12 flex items-center")}>
            {adminEmails.indexOf(email) !== -1 && (
              <Dropdown
                openOnHover={false}
                position="right"
                menuClassName={twdClass("mt-4")}
                wrapperClassName={twdClass("")}
                buttonClassName={twdClass("")}
                buttonVariant={false}
                title={
                  <div className={twdClass("flex items-center")}>
                    <span className={twdClass("text-lg mr-1")}>Admin</span>
                    <img
                      alt=""
                      width="24px"
                      src="/assets/images/chevron-down.svg"
                    />
                  </div>
                }
              >
                <div className={twdClass(" py-1 text-md font-semibold")}>
                  <Link
                    to="/template"
                    className={twdClass("flex items-center px-6 py-3 border-b")}
                  >
                    <span className={twdClass("ml-4 flex-grow")}>Template</span>
                  </Link>
                </div>
              </Dropdown>
            )}
            <Dropdown
              openOnHover={false}
              position="right"
              menuClassName={twdClass("mt-4")}
              wrapperClassName={twdClass("")}
              buttonClassName={twdClass("")}
              buttonVariant={false}
              title={
                <div className={twdClass("flex items-center")}>
                  <span className={twdClass("text-lg mr-1")}>Resources</span>
                  <img
                    alt=""
                    width="24px"
                    src="/assets/images/chevron-down.svg"
                  />
                </div>
              }
            >
              <div className={twdClass(" text-md font-semibold")}>
                <Link
                  to="/pricing"
                  className={twdClass(
                    "flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200"
                  )}
                >
                  <span className={twdClass("ml-4 flex-grow")}>Pricing</span>
                </Link>
                <a
                  href="https://help.flezr.com/faq/is-there-a-white-label-option-for-flezr"
                  target="_blank"
                  className={twdClass(
                    "flex items-center px-6 py-3 bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200"
                  )}
                  rel="noreferrer"
                >
                  <span className={twdClass("ml-4 flex-grow")}>FAQ's'</span>
                </a>
              </div>
            </Dropdown>
            <HelpMenu studio={false} />
          </div>
        </div>
        <div className={twdClass("flex items-center")}>
          <div className={twdClass("")} onClick={openUserMenu}>
            <span
              className={twdClass("mx-2 text-sm font-semibold cursor-pointer")}
            >
              {first_name} {last_name}
            </span>
          </div>
          <Dropdown
            ref={userMenuRef}
            menuClassName={twdClass("mt-4")}
            position="left"
            buttonVariant=""
            title={
              <div
                className={twdClass(
                  "px-2 py-2 rounded-full bg-white shadow-md hover:bg-gray-100 hover:shadow-lg active:shadow-sm"
                )}
              >
                <img
                  alt=""
                  width="24px"
                  src="/assets/images/user-profile-02.svg"
                />
              </div>
            }
          >
            <div onClick={() => {}}>
              <div
                className={twdClass(" py-1 text-md font-semibold")}
                style={{ width: "280px" }}
              >
                <Link
                  to="/"
                  className={twdClass(
                    "flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200"
                  )}
                >
                  <img alt="" width="24px" src="/assets/images/home-02.svg" />
                  <span className={twdClass("ml-4 flex-grow")}>
                    My Projects
                  </span>
                </Link>

                <div className={twdClass("flex justify-center pt-4")}>
                  <div
                    className={twdClass(
                      "flex items-center px-6 py-3 rounded-full cursor-pointer bg-white hover:bg-primary-100 active:bg-primary-200 shadow-md hover:shadow-lg active:shadow-sm"
                    )}
                    onClick={handleSignOut}
                  >
                    <span className={twdClass("mr-4")}>Sign out</span>
                    <img
                      alt=""
                      width="24px"
                      src="/assets/images/logout-02.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
