export const FAQ_BLOCK_1 = {
  blockType: "FAQ",
  blockDesign: "FAQDesign1",
  editFormName: "FAQ_FORM_1",
  styleProps: {
    "background-color": "",
    "background-image": "url('')",
    padding: "50px 120px 50px 120px"
  },
  sectionConfigs: {
    headerTextStyleProps: {        
      "font-size": "1.125rem",
      "line-height": "1.75rem",
      "font-weight" : "500",
      padding: "10px 10px 10px 10px",
    },
    bodyTextStyleProps: {
      padding: "10px 10px 10px 10px",
    },   
    sectionStyleProps: {
      backgroundColor:'#f8f8f8',
      'padding':'10px',
      'border-width':'2px',
      'border-radius':'1rem',
      'border-color':'cccccc'
    }
  },
  headings: [
    {
      text: "FAQ",
      styleProps: {
        "font-weight":"500",
        "text-align": "center",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
        padding: "0px 0px 20px 0px",
        "margin":"0px 20px 20px 20px"
      }
    }
  ],
  list: [
    {     
      headerText: "What subscription tiers can I subscribe to with flezr?",
      bodyText:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with"
      
    },
    {     
      headerText: "How much does a subscription to faq cost, and for how long can I subscribe?",
      bodyText:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with"
    
    },
    {     
      headerText: "Can we use the code/art/assets in Flezr Technologies' example projects?",
      bodyText:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with"
      
    }
  ]
};
