import {
  TOGGEL_POPUP,
  RESET_POPUP,
  TOGGEL_CONFIRM_POPUP,
  RESET_CONFRIM_POPUP,
} from "../constants/action.constants";

const initConfirmation = {
  confirmLabel: "",
  showConfirmPopup: false,
  confrimCallBack: null,
  cancelCallBack: null,
  selectProject: null,
  action:null,
};

const initialState = {
  showPopup: false,
  title: "Info",
  desc: null,
  img: null,
  color:null,
  component: null,
  action:null,
  full:false,
  ...initConfirmation,
};

export const modalpopup = (state = initialState, action) => {
  switch (action.type) {
    case TOGGEL_POPUP:
      return { ...state, ...action.data };
    case RESET_POPUP:
      return { ...state, ...initialState };
    case TOGGEL_CONFIRM_POPUP:
      return { ...state, ...action.data };
    case RESET_CONFRIM_POPUP:
      return { ...state, ...initConfirmation, title: "Info" };
    default:
      return state;
  }
};
