import DetailsElemLabel from "./elementprops/DetailsElemLabel";
import DetailsElementsProps from "./elementprops/DetailsElementsProps";

const SettingsPropsDetails = ({
  sectionData,
  sectionElem,
  blocks,
  styleElementId,
  listIndex,
}) => {
  return (
    <>
      <DetailsElemLabel
        sectionElem={sectionElem}
        styleElementId={styleElementId}
      />
      <DetailsElementsProps
        sectionData={sectionData}
        sectionElem={sectionElem}
        styleElementId={styleElementId}
        blocks={blocks}
        listIndex={listIndex}
      />
    </>
  );
};

export default SettingsPropsDetails;
