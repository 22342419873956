import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../constants/var.constants";

const Generating = ({ isGenerating=false }) => {
  const dispatch = useDispatch();
  const {
    generated={},
    pageInfo,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);



  let gen=[]
  for (var key in generated) {
    //console.log("gen", key, generated[key])
    let skip = ["commandFrom", "settings"].find((f, fi)=>{
      //console.log("skip", key, f)
      return key==f ? true : false
    })
    //console.log("skip", skip)
    if(!skip){
      if(generated[key] !=false) gen.push({name:key, generated:typeof generated[key] =='object' ? true : false})
    }
  }

  //console.log("gen", gen)

  let ganerated_=gen.map((g, i)=>{
    return(<div key={i} className={twdClass(`bg-white shadow-sm border border-gray-900 border-opacity-30 rounded-full px-4 py-2 flex items-center gap-2`)}><img src={'/assets/images/check-green.svg'}/> {g.name}</div>)
  })

  return (
    <div style={{backdropFilter:"blur(2px)"}} className={twdClass(`${isGenerating ? 'flex':'hidden'}  flex-col items-center justify-center p-4 rounded-xl absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 backdrop-blur-md z-10`)}>
      <div className={twdClass("flex-col items-center justify-center  bg-white shadow-lg rounded-3xl px-12 py-12")}>
        <div className={twdClass("text-2xl mb-12")}>Generating Design...</div>
        <div className={twdClass("flex flex-col  text-left gap-2 ")}>
          {ganerated_}
        </div>
      </div>
    </div>
  );
};

export default Generating
