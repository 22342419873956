import { useSelector } from "react-redux";
import { find, cloneDeep, isEmpty } from "lodash";
import { twdClass } from "../../constants/tailwind.constants";
import CustomSelectBox from "../common/CustomSelectBox";
import { useState, useEffect } from "react";
import { INPUT_TEXT } from "../../constants/var.constants";
import { getSheetInfo, getSheetColumns } from "../../api/services";
import { processParamValues } from "../../utils/common.utils";

export const DynamicCardsPanel = ({
  data,
  updateElementValue,
  element,
  setActivePanel,
  updateColumnNames,
}) => {
  const {
    projectData: { connections, project_id },
  } = useSelector((state) => state.studioDetails);

  const [sheetNames, setSheetNames] = useState([]);
  const [sheetColumns, setSheetColumns] = useState([]);

  const fetchSheetNames = (spreadsheetId) => {
    getSheetInfo(spreadsheetId).then((resp) => {
      if (resp.success) {
        setSheetNames(resp.data);
      }
    });
  };

  const getColumnNames = (value) => {
    getGoogleSheetColumns(value);
  };

  const getGoogleSheetColumns = (sheet_name) => {
    const {
      connection_details: { spreadsheetId },
    } = find(connections, [
      "connection_id",
      data.configs.connection.connectionId,
    ]);
    getSheetColumns({
      project_id,
      sheet_name,
      spreadsheetId,
      ranges: [`${sheet_name}!A1:Z2`],
    }).then((resp) => {
      if (resp.success) {
        setSheetColumns(resp.data);
        updateColumnNames(resp.data);
      }
    });
  };

  useEffect(() => {
    const { connectionId, sheetName } = data.configs.connection;
    if (connectionId) {
      const {
        connection_details: { spreadsheetId },
      } = find(connections, ["connection_id", connectionId]);

      fetchSheetNames(spreadsheetId);
      if (sheetName) {
        getColumnNames(sheetName);
      }
    }
    updateElementValue("configs.id", project_id);
  }, []);

  const connectionOptions = connections.map((item) => ({
    key: item.connection_id,
    //spreadsheetId: item.connection_details.spreadsheetId,
    value: item.connection_details.displayName,
  }));
  const connectValue = find(connectionOptions, [
    "key",
    data.configs.connection.connectionId,
  ])?.key;

  /* const [paramList, setParamList] = useState([]);
  useEffect(() => { */
  let paramsvalues = processParamValues(data.configs.templateHTML);
  paramsvalues = paramsvalues.map((item) => {
    const val = find(data.configs.params, ["templateKey", item.key]);
    return {
      display: item.value,
      templateKey: item.key,
      propertieName: !isEmpty(val) ? val.propertieName : item.columnValue,
    };
  });
  /* setParamList(paramsvalues);
  }, [JSON.stringify(data.configs.params)]); */

  return (
    <div>
      <div className={twdClass("flex items-center w-full mt-2")}>
        <div className={twdClass("w-48")}>Data Source</div>
        <select className={twdClass(INPUT_TEXT)} value={"googlesheet"}>
          <option value="googlesheet">Google Sheet</option>
        </select>
      </div>
      <div className={twdClass("my-2 font-semibold")}>Connection Info</div>
      <div className={twdClass("items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>Google Sheet</label>
        <div className={twdClass("flex")}>
          <CustomSelectBox
            value={connectValue}
            options={connectionOptions}
            updateValue={(val) => {
              const itm = find(connections, ["connection_id", val]);
              updateElementValue("configs.connection.connectionId", val);
              fetchSheetNames(itm?.connection_details.spreadsheetId);
            }}
          />
        </div>
      </div>
      <div className={twdClass("items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>Sheet Names</label>
        <div className={twdClass("flex")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={data.configs.connection.sheetName}
            onChange={(e) => {
              updateElementValue(
                "configs.connection.sheetName",
                e.target.value
              );
              getColumnNames(e.target.value);
            }}
          >
            <option value="">Select</option>
            {sheetNames &&
              sheetNames.map((item) => <option value={item}>{item}</option>)}
          </select>
        </div>
      </div>
      <div
        className={twdClass(
          "text-sm font-semibold bg-gray-50 p-2 border rounded-md shadow-sm mb-2 cursor-pointer"
        )}
        onClick={() => setActivePanel("filtersettings")}
      >
        <span className={twdClass("flex items-center justify-between")}>
          <span>Filter and Search settings</span>
          <span>
            <img alt="" src="/assets/images/icon_arrow_right.png" />
          </span>
        </span>
      </div>
      <div className={twdClass("bg-gray-50 p-2 border rounded-md shadow-sm")}>
        <div className={twdClass("text-sm font-semibold mb-4")}>
          Variable Mapping
        </div>
        {paramsvalues.map((item, index) => {
          return (
            <div className={twdClass("flex items-center mt-2")}>
              <label className={twdClass("w-24 text-sm")}>{item.display}</label>
              <div className={twdClass("flex-1")}>
                <select
                  className={twdClass(INPUT_TEXT)}
                  value={item.propertieName}
                  onChange={(e) => {
                    const vl = cloneDeep(item);
                    vl.propertieName = e.target.value;
                    updateElementValue(`configs.params[${index}]`, vl);
                  }}
                >
                  <option value="">Select</option>
                  {sheetColumns.map((opt) => (
                    <option value={opt.key}>{opt.value}</option>
                  ))}
                </select>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
