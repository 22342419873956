import { useState, useEffect } from "react";
import { twdClass } from "../../constants/tailwind.constants";
import ToggleBtn from "../common/ToggleBtn";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import { get } from "lodash";
import FaIcons from "../common/FaIcons";
import { Button, BtnStyle, BtnBack } from "../ui/com_ui";
import Collapse from "../studio/sui/Collapse";

const CE1 = twdClass("p-2 clear-both  flex items-center");
const CE2 = twdClass("flex items-center justify-center h-6 w-6 cursor-pointer");
const CE3 = twdClass("text-sm font-semibold cursor-pointer flex-1 capitalize");

export const BaseSettingsPanel = ({
  updateElementValue,
  configs
}) => {
  const limit = get(configs, "loadmore.limit", []);  
  return (
    <Collapse label="Number of Cards To Display" arrowPosition="left">
      <div
        className={twdClass(
          "flex items-center justify-between mb-1 border border-gray-100 hover:border-gray-200 rounded-xl px-2 py-2"
        )}
      >
        <label className={twdClass("text-sm mr-2")}>Limit:</label>
        <div className={twdClass("w-36")}>
          <select
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {
              updateElementValue("configs.loadmore.limit", e.target.value);
            }}
            value={limit}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>  
    </Collapse>
  );
};
