export const HEADER_BLOCK_04 = {
  blockType: "HEADER",
  blockDesign: "HeaderDesign2",
  editFormName: "HEADER_04_FORM",
  styleProps: {
    "background-image": "url('')",
    "background-color": "#1b1b31",
  },
  textBlock: {
    styleProps: {
      "background-color": "",
      "border-radius": "0",
      padding: "10px 10px 10px 10px",
    },
    stylePropsTab: {},
    stylePropsMob: {},
    alingBlock: "center", // start, center, end
    headings: [
      {
        text: "Best Designs,",
        hideUnderline: true,
        underLineStyle: {
          "background-color": "red",
          height: "4px",
        },
        styleProps: {
          color: "#ffffff",
          "font-size": "3.75rem",
          "line-height": "1",
          "font-weight": "bold",
        },
        alignStyleProps: {
          "text-align": "left",
          margin: "10px 0px 10px 0px",
          padding: "10px 0px 10px 0px",
        },
      },
      {
        text:
          "Join our community and thrive with us! We can all help each other, learn together, and grow together. Join our community and thrive with us! We can all help each other, learn together, and grow together.Join our community and thrive with us! We can all help each other, learn together, and grow together. Join our community and thrive with us! We can all help each other, learn together, and grow together.",
        hideUnderline: false,
        underLineStyle: {
          "background-color": "yellow",
          height: "2px",
        },
        styleProps: {
          color: "#ffffff",
          "font-size": "1.125rem",
          "line-height": "1.75rem",
        },
        alignStyleProps: {
          "text-align": "left",
        },
      },
      {
        text: "Grow Together",
        hideUnderline: true,
        underLineStyle: {
          "background-color": "yellow",
          height: "1px",
        },
        styleProps: {
          color: "#ffffff",
          "font-size": "1rem",
          "text-align": "right",
        },
        alignStyleProps: {
          "text-align": "right",
        },
      },
    ],
    dynamicText: {
      styleProps: {
        color: "#ffffff",
        "font-size": "1.875rem",
        "line-height": "2.25rem",
        padding: "10px 0px 10px 0px",
        "text-align": "center",
      },
      stylePropsTab: {
        padding: "10px 0px 10px 0px",
        "font-size": "1.5rem",
        "line-height": "2rem",
      },
      stylePropsMob: {
        padding: "10px 0px 10px 0px",
        "font-size": "1.5rem",
        "line-height": "2rem",
      },
      strings: ["Build custom apps less time"],
    },

    descriptionsStyleProps: {
      padding: "10px 0px 10px 0px",
      "text-align": "center",
    },
    descriptionsStylePropsTab: {
      padding: "10px 0px 10px 0px",
    },
    descriptionsStylePropsMob: {
      padding: "10px 0px 10px 0px",
    },
    descriptions: [
      {
        styleProps: {
          padding: "20px 0px 20px 0px",
        },
        stylePropsTab: {},
        stylePropsMob: {},
        text:
          "If your employee uses Gusto, the free Gusto Wallet app is for you. It's a smart way to track, save, and access your hard earned money.",
      },
    ],

    buttonsStyleProps: {},
    buttonAlign: "end", // start, center, end
    buttons: [
      {
        styleProps: {
          padding: "0px 0px 0px 0px",
          "border-width": "0px",
        },
        alt: "Google Play Store",
        href: "#",
        text:
          "<img src='https://staple-data.s3.amazonaws.com/sly-projects/1636304775960-_i_play_store.png' style='height: 50px' />",
      },
      {
        styleProps: {
          padding: "8px 20px 8px 20px",
          color: "#ffffff",
        },
        alt: "Apple App Store",
        href: "#",
        text: "Click Here",
      },
    ],

    emailSecStyleProps: {},
    subscription: {
      showSection: "show",
      display: "enable",
      subConfigs: {
        configType: "convertkit",
        configUrl: "https://app.convertkit.com/forms/xxxxxx/subscriptions",
        configPlaceholder: "",
      },
      email_placeholder: {
        text: "example@mail.com",
      },
      buttonText: {
        text: "Subscribe",
        styleProps: {
          "background-color": "#1b1b31",
          color: "#ffffff",
        },
      },
      successMessage: {
        text: "Thank you for the signup",
        styleProps: {},
      },
      errorMessage: {
        text: "Something went wrong. Please try again.",
        styleProps: {},
      },
    },
  },
  imageType: "image",
  imageVideoPostion: "left",
  imageBlock: {
    image:
      "https://demo2.bolvo.com/amauthor/wp-content/uploads/sites/24/2016/06/about-me-img1.png",
    styleProps: {
      margin: "20px 20px 20px 20px",
    },
  },
  videoBlock: {
    url: "https://www.youtube.com/watch?v=tKR58TIaJ9k",
    styleProps: {
      margin: "20px 20px 20px 20px",
    },
    videoHeight: {
      height: "400px",
    },
  },
  htmlBlock: {
    value: "",
    styleProps: {},
  },
  metaDataId: 1641554792815,
};
