import { processParamValues } from "../../utils/common.utils";
import { find, isEmpty, cloneDeep, merge } from "lodash";
import { twdClass } from "../../constants/tailwind.constants";
import { INPUT_TEXT, BTN_CLS, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { useState } from "react";
import { toast } from "react-toastify";

export const StaticCardsPanel = ({ data, updateElementValue }) => {
  let paramsvalues = processParamValues(data.configs.templateHTML);
  paramsvalues = paramsvalues.map((item) => {
    const val = find(data.configs.params, ["templateKey", item.key]);
    return {
      display: item.value,
      templateKey: item.key,
      propertieName: !isEmpty(val) ? val.propertieName : item.columnValue,
    };
  });
  const [showMapping, setShowMapping] = useState(true);
  const [showData, setShowData] = useState(true);
  return (
    <>
      <div
        className={twdClass(
          "my-4 font-semibold flex justify-between cursor-pointer"
        )}
        onClick={() => setShowMapping(!showMapping)}
      >
        <span>Static data mapping</span>
        <span>
          <FaIcons icon={`${showMapping ? "angleDown" : "angleUp"}`} />
        </span>
      </div>
      {showMapping && (
        <div>
          {paramsvalues.map((item, index) => {
            return (
              <div className={twdClass("flex items-center mt-2")}>
                <label className={twdClass("w-24 text-sm")}>
                  {item.display}
                </label>
                <div className={twdClass("flex-1")}>
                  <select
                    className={twdClass(INPUT_TEXT)}
                    value={item.propertieName}
                    onChange={(e) => {
                      const vl = cloneDeep(item);
                      vl.propertieName = e.target.value;
                      updateElementValue(`configs.params[${index}]`, vl);
                    }}
                  >
                    <option value="">Select</option>
                    {data.data &&
                      Object.keys(data.data[0]).map((opt) => (
                        <option value={opt}>{opt}</option>
                      ))}
                  </select>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div
        className={twdClass(
          "my-4 font-semibold flex justify-between cursor-pointer"
        )}
        onClick={() => setShowData(!showData)}
      >
        <span>Add static data</span>
        <span>
          <FaIcons icon={`${showData ? "angleDown" : "angleUp"}`} />
        </span>
      </div>
      {showData && (
        <AddStaticData data={data} updateElementValue={updateElementValue} />
      )}
    </>
  );
};

const AddStaticData = ({ data, updateElementValue }) => {
  const [expRow, setExpRow] = useState({});
  const recLength = data.data.length;
  const paramsValues = data.configs.params.map(
    (item) => item && item.propertieName
  );
  // console.log(paramsValues);
  return (
    <>
      <div className={twdClass("m-2")}>
        <button
          className={twdClass(`${BTN_NORMAL} rounded border-gray-400`)}
          onClick={() => {
            if (recLength < 40) {
              updateElementValue(
                `data[${recLength}]`,
                cloneDeep(data.data[recLength - 1])
              );
            } else {
              toast.error("You can add a maximum of only 40 static cards.");
            }
          }}
        >
          Add Data <FaIcons icon="plusN" />
        </button>
      </div>
      <div>
        {data.data.map((item, index) => {
          return (
            <div className={twdClass("bg-gray-50 border-b border-gray-200")}>
              <div
                className={twdClass(
                  "text-xs p-2 font-semibold flex cursor-pointer"
                )}
              >
                <span
                  onClick={() => {
                    const val = !expRow[`row${index}`];
                    const rows = merge(setExpRow, { [`row${index}`]: val });
                    setExpRow((data) => ({ ...data, ...rows }));
                  }}
                  className={twdClass("flex-1")}
                >
                  <FaIcons
                    icon={`${expRow[`row${index}`] ? "angleDown" : "angleUp"}`}
                    className={twdClass("mx-2")}
                  />
                  Card Data {index + 1}
                </span>
                <span
                  className={twdClass("px-2")}
                  onClick={() => {
                    const list = cloneDeep(data.data);
                    list.splice(index, 1);
                    console.log(list);
                    updateElementValue(`data`, list);
                  }}
                >
                  <FaIcons icon="trash" />
                </span>
              </div>
              {expRow[`row${index}`] && (
                <div className={twdClass("p-2 m-2 bg-white")}>
                  {Object.keys(item).map((prms) => (
                    <div className={twdClass("my-2")}>
                      <span className={twdClass("text-xs uppercase")}>
                        {prms}{" "}
                        {paramsValues.indexOf(prms) === -1 && (
                          <button title="Column not used">
                            <FaIcons icon="infoCircle" />
                          </button>
                        )}
                      </span>
                      <input
                        className={twdClass(INPUT_TEXT)}
                        value={item[prms]}
                        onChange={(e) =>
                          updateElementValue(
                            `data[${index}][${prms}]`,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
