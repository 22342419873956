import { getDefaultFields } from "../common/common.fields";

export const HEADER_03_FORM = {
  sectionName: "Header 03 Section",
  elements: [
    {
      inputType: "select",
      label: "Background Overlay",
      styleElementId: 5,
      hasStyleProps: true,
      updateTo: "position",
      options: [
        {
          display: "Hide",
          value: "hide",
        },
        {
          display: "Show",
          value: "show",
        }
      ],
      hideLabel: true,
    },
    {
      inputType: "text",
      label: "Heading",
      hasStyleProps: true,
      styleElementId: 1,
      updateTo: "heading.text",
    },
    {
      inputType: "simplelist",
      label: "Dynamic Text",
      defaultValue: ["Build custom apps for your business without code"],
      updateTo: "subheading.strings",
      hasStyleProps: true,
      styleElementId: 4,
    },
    {
      inputType: "textarea",
      label: "Description",
      hasStyleProps: true,
      styleElementId: 2,
      updateTo: "description.text",
    },
    {
      inputType: "list",
      label: "Text info",
      hasStyleProps: false,
      stylesProps: {},
      updateTo: "textys",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "text",
            defaultValue: "Know more about the <b>product</b>.",
            hasStyleProps: true,
            styleElementId: 0,
            updateTo: "textys[i].text",
          },
        ],
        hasStyleProps: false,
        stylesProps: {},
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Buttons",
      hasStyleProps: false,
      stylesProps: {
        test: "test",
      },
      updateTo: "buttons",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "Title",
            defaultValue: "Get Started",
            hasStyleProps: true,
            styleElementId: 3,
            updateTo: "buttons[i].title.text",
          },
          {
            inputType: "text",
            label: "Href",
            hasStyleProps: false,
            stylesProps: {
              test: "test",
            },
            updateTo: "buttons[i].href.text",
          },
          /*{
            inputType: "text",
            label: "Alt Text",
            hasStyleProps: false,
            stylesProps: {
              test: "test",
            },
            updateTo: "buttons[i].alt.text",
          },*/
          {
            inputType: "select",
            label: "Bordered",
            hasStyleProps: false,
            stylesProps: {
              test: "test",
            },
            updateTo: "buttons[i].bordered",
            options: [
              {
                display: "Enable",
                value: "Enable",
              },
              {
                display: "Disable",
                value: "disable",
              },
            ],
          },
          {
            inputType: "group",
            label: "Advanced",
            initShow:false,
            elements: [
              {
                inputType: "text",
                label: "Button ID",
                defaultValue: "",
                updateTo: "buttons[i].button_id",
                hasStyleProps: false,
              }, 
              {
                inputType: "text",
                label: "Alt Text",
                defaultValue: "",
                updateTo: "buttons[i].alt.text",
                hasStyleProps: false,
              },             
            ]
          },
        ],
        hasStyleProps: false,
        stylesProps: {
          test: "test",
        },
      },
      elements: [],
    },
    {
      inputType: "componentTag",
      subType: "SubscriptionTag",
      label: "Email Subscription",
      updateTo: "subscription",
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "text",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage"],
        }),
      ],
      defaultValue: "Know more about the <b>product</b>.",
    },
    {
      styleElementId: 1,
      label: "Heading",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "heading.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Description",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "description.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Title",
      elements: [
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "buttons[i].title.styleProps.font-weight",
        },
        {
          inputType: "fontstyle",
          label: "Font Style",
          updateTo: "buttons[i].title.styleProps.font-style",
        },
        {
          inputType: "color",
          label: "Color",
          updateTo: "buttons[i].title.styleProps.color",
        },
        {
          inputType: "backgroundcolor",
          label: "Background Color",
          updateTo: "buttons[i].title.styleProps.background-color",
        },
        {
          inputType: "borderradius",
          label: "Border Radius",
          updateTo: "buttons[i].title.styleProps.border-radius",
        },
        {
          inputType: "shadow",
          label: "Shadow",
          updateTo: "buttons[i].title.styleProps.box-shadow",
        },
      ],
      defaultValue: "Get Started",
    },
    {
      styleElementId: 4,
      label: "Dynamic Text",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "subheading.styleProps",
          fields: ["fontsize", "fontweight", "fontfamily", "color"],
        }),
      ],
    },
    {
      styleElementId: 5,
      label: "Bg Overlay",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "bgoverlay.styleProps",
          fields: [
            "opacity",
            "backgroundcolor",            
          ],
        }),
      ],
    },
  ],
};
