import "./App.css";
import '@animxyz/core'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import StudioPage from "./pages/Studio";
import Pricing from "./pages/Pricing";
import Debug from "./pages/Debug";
import Onboarding from "./pages/Onboarding";
import Templates from "./pages/Templates";
import ProjectTemplates from "./pages/ProjectTemplates";
import { Signup } from "./login/Signup";
import { Login } from "./login/Login";
import { ForgotPassword } from "./login/ForgotPassword";
import { UpdatePassword } from "./login/UpdatePassword";
import { AuthProvider } from "./contexts/Auth";
import { PrivateRoute } from "./components/common/PrivateRoute";
import { ToastContainer } from "react-toastify";
import { VisualStepsContainer } from "./components/ui/visual_steps_ui";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/studio/:id" component={StudioPage} />
            <PrivateRoute path="/pricing" component={Pricing} />            
            <PrivateRoute path="/template" component={Templates} />
            <PrivateRoute path="/projecttemplates" component={ProjectTemplates} />
            <Route path="/debug/:id" component={Debug} />
            <Route path="/onboarding/:id" component={Onboarding} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/update-password" component={UpdatePassword} />
            <Redirect to="/" />
          </Switch>
        </AuthProvider>
      </Router>
      <VisualStepsContainer
        isEnable={true}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
