import { getDefaultFields } from "../common/common.fields";

export const PRICING_01_FORM = {
  sectionName: "Pricing 01 Section",
  elements: [
    {
      inputType: "text",
      label: "Heading",
      hasStyleProps: true,
      styleElementId: 1,
      updateTo: "heading.text",
    },
    {
      inputType: "textarea",
      label: "Description",
      hasStyleProps: true,
      styleElementId: 2,
      updateTo: "description.text",
    },
    {
      inputType: "simplelist",
      label: "Test Simple List",
      defaultValue: ["Text 1", "Text 2"],
      hasStyleProps: false,
      stylesProps: {
        test: "test",
      },
      updateTo: "test_sl.value",
    },
    {
      inputType: "list",
      label: "Textys",
      hasStyleProps: false,
      stylesProps: {
        test: "test",
      },
      updateTo: "textys",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "text",
            defaultValue: "Know more about the <b>product</b>.",
            hasStyleProps: true,
            styleElementId: 0,
            updateTo: "textys[i].texty.text",
          },
        ],
        hasStyleProps: false,
        stylesProps: {
          test: "test",
        },
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Pricing",
      hasStyleProps: false,
      stylesProps: {
        test: "test",
      },
      updateTo: "pricing",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "Title",
            defaultValue: "Basic",
            hasStyleProps: true,
            styleElementId: 6,
            updateTo: "pricing[i].title.text",
          },
          {
            inputType: "text",
            label: "Description",
            defaultValue:
              "For smaller teams that need to replace a VPN for remote access",
            hasStyleProps: true,
            styleElementId: 7,
            updateTo: "pricing[i].description.text",
          },
          {
            inputType: "text",
            label: "Per Month : Billing Monthly",
            defaultValue: "$7",
            hasStyleProps: true,
            styleElementId: 8,
            updateTo: "pricing[i].month_price_monthly.text",
          },
          {
            inputType: "text",
            label: "Per Month : Billing Yearly",
            defaultValue: "$5",
            hasStyleProps: true,
            styleElementId: 8,
            updateTo: "pricing[i].month_price_yearly.text",
          },
          {
            inputType: "simplelist",
            label: "Features",
            defaultValue: [
              "Up to 50 users",
              "5 devices per user",
              "5 remote networks",
            ],
            hasStyleProps: true,
            styleElementId: 9,
            updateTo: "pricing[i].features.value",
          },
          {
            inputType: "text",
            label: "CTA Button",
            defaultValue: "Get Started",
            hasStyleProps: true,
            styleElementId: 10,
            updateTo: "pricing[i].cta_button.text",
          },
        ],
        hasStyleProps: false,
        stylesProps: {
          test: "test",
        },
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "text",
      elements: [
        {
          inputType: "opacity",
          label: "Opacity",
          updateTo: "textys[i].texty.styleProps.opacity",
        },
        {
          inputType: "fontstyle",
          label: "Font Style",
          updateTo: "textys[i].texty.styleProps.font-style",
        },
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "textys[i].texty.styleProps.font-weight",
        },
        {
          inputType: "fontsize",
          label: "Font Size",
          updateTo: "textys[i].texty.styleProps.font-size",
        },
        {
          inputType: "color",
          label: "Color",
          updateTo: "textys[i].texty.styleProps.color",
        },
      ],
      defaultValue: "Know more about the <b>product</b>.",
    },
    {
      styleElementId: 1,
      label: "Heading",
      elements: [
        {
          inputType: "color",
          defaultValue: "#ff0000",
          label: "Color",
          updateTo: "heading.styleProps.color",
        },
        {
          inputType: "fontsize",
          defaultValue: "14px",
          label: "Font Size",
          updateTo: "heading.styleProps.font-size",
        },
        {
          inputType: "fontweight",
          defaultValue: "bold",
          label: "Font Weight",
          updateTo: "heading.styleProps.font-weight",
        },
        {
          inputType: "padding",
          label: "Padding",
          updateTo: "heading.styleProps.padding",
        },
        {
          inputType: "margin",
          label: "Margin",
          updateTo: "heading.styleProps.margin",
        },
      ],
    },
    {
      styleElementId: 2,
      label: "Description",
      elements: [
        {
          inputType: "color",
          label: "Color",
          updateTo: "description.styleProps.color",
        },
      ],
    },
    {
      styleElementId: 6,
      label: "Title",
      elements: [
        {
          inputType: "color",
          label: "Color",
          updateTo: "pricing[i].title.styleProps.color",
        },
        {
          inputType: "fontSize",
        },
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "pricing[i].title.styleProps.font-weight",
        },
      ],
      defaultValue: "Basic",
    },
    {
      styleElementId: 7,
      label: "Description",
      elements: [
        {
          inputType: "color",
          label: "Color",
          updateTo: "pricing[i].description.styleProps.color",
        },
        {
          inputType: "fontSize",
        },
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "pricing[i].description.styleProps.font-weight",
        },
        {
          inputType: "fontStyle",
        },
      ],
      defaultValue:
        "For smaller teams that need to replace a VPN for remote access",
    },
    {
      styleElementId: 8,
      label: "Per Month : Billing Yearly",
      elements: [
        {
          inputType: "color",
          label: "Color",
          updateTo: "pricing[i].month_price_yearly.styleProps.color",
        },
        {
          inputType: "fontSize",
        },
        {
          inputType: "fontweight",
          label: "Font Weight",
          updateTo: "pricing[i].month_price_yearly.styleProps.font-weight",
        },
        {
          inputType: "fontStyle",
        },
      ],
      defaultValue: "$5",
    },
    {
      styleElementId: 9,
      label: "Features",
      elements: [
        {
          inputType: "color",
          label: "Color",
          updateTo: "pricing[i].features.styleProps.color",
        },
      ],
      defaultValue: [
        "Up to 50 users",
        "5 devices per user",
        "5 remote networks",
      ],
    },
    {
      styleElementId: 10,
      label: "CTA Button",
      elements: [
        {
          inputType: "color",
          label: "Color",
          updateTo: "pricing[i].cta_button.styleProps.color",
        },
        {
          inputType: "backgroundcolor",
          label: "Background Color",
          updateTo: "pricing[i].cta_button.styleProps.background-color",
        },
        {
          inputType: "borderradius",
          label: "Border Radius",
          updateTo: "pricing[i].cta_button.styleProps.border-radius",
        },
        {
          inputType: "text",
          label: "url",
        },
      ],
      defaultValue: "Get Started",
    },
  ],
};
