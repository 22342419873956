export const HEADER_03_BLOCK = {
  variation: "v2",
  blockType: "HEADER",
  blockDesign: "header_03",
  styleProps: {
    "background-image":
      "url(https://products.ls.graphics/paaatterns/images/018-p-1080.png)",
  },
  position: "left",
  test_sl: ["New Text 1", "New Text 2"],
  heading: {
    styleProps: {},
    text: "Build custom apps for your business without code",
  },
  subheading: {
    styleProps: {
      "font-size": "50px",
      "font-weight": "bold",
      color: "blue",
    },
    strings: ["Build custom apps for your business without code"],
  },
  description: {
    text:
      "Trusted by over 10,000+ creators, founders & operator already building on Pory",
  },
  textys: [
    {
      type: "el",
      sub_type: "html",
      text:
        '<div style="display: flex;justify-content: center;"><span style="margin-right: 10px;">Powered by your data:</span> <img width="100" src="https://freshflowxyz.b-cdn.net/sly-projects/1638092364746-1636304775960-_i_play_store.png"/></div>',
    },
  ],
  buttons: [
    {
      title: {
        text: "Learn more",
        styleProps: {
          position: "relative",
        },
      },
      href: {
        text: "#",
      },
      bordered: "",
      styleProps: {},
    },
    {
      title: {
        text: "Get Started - It's free",
        styleProps: {
          position: "relative",
        },
      },
      href: {
        text: "#",
      },
      alt: {
        text: "",
      },
      bordered: "enable",
      styleProps: {},
    },
  ],
  footer: {
    text: "Want to use a web browser? Log in to Gusto",
  },
  subscription: {
    display: "enable",
    subConfigs: {
      configType: "convertkit",
      configUrl: "https://app.convertkit.com/forms/xxxxxx/subscriptions",
      configPlaceholder: "",
    },
    email_placeholder: {
      text: "example@mail.com",
    },
    buttonText: {
      text: "Subscribe",
    },
    successMessage: {
      text: "Thank you for the signup",
      styleProps: {},
    },
    errorMessage: {
      text: "Something went wrong. Please try again.",
      styleProps: {},
    },
  },
  editFormName: "HEADER_03_FORM",
};
