import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../constants/action.constants";
import { useState, useEffect } from "react";
import { INPUT_TEXT, BTN_CLS } from "../../constants/var.constants";
import { saveProject, getProjectInfo, getGenerateDesign, promptChatGPTMini } from "../../api/services";
import { toast } from "react-toastify";
import { Button} from "../ui/com_ui";
import ToggleBtn from "../common/ToggleBtn";

import GenerateForm from "./GenerateForm";
import Test from "./Test";


const Generate = (props) => {  
  const [isTestMode, setTestMode] = useState(false);

  return (
    <div className={twdClass("relative h-full ")}>
      <div className={twdClass("absolute top-0 left-0 right-0 bottom-0 overflow-auto")}>
       {/*<div className={twdClass("flex justify-center mb-8")}>
          <Button
            type={'default'}  
            cType={'iconText'}        
            color={'white'}
            size={'md'}         
            iconImg={'arrow-refresh-06.svg'}         
            className={''}   
            onClick={()=>{setTestMode(true)}}
          >              
            TestDesign
          </Button>
          <Button
              type={'default'}  
              cType={'iconText'}        
              color={'primary'}
              size={'md'}         
              iconImg={'arrow-refresh-06.svg'}         
              className={'ml-2'}   
              onClick={()=>{setTestMode(false)}}
            >              
              Generate Form
          </Button>
        </div>*/}
        {isTestMode && <Test {...props}/>}
        {!isTestMode && <GenerateForm {...props}/>}
      </div>
    </div>
  );
};

export default Generate;
