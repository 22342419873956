import { useSelector, useDispatch } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import { CloseBtn } from "../ui/presets";
import FaIcons from "./FaIcons";
import { RESET_POPUP } from "../../constants/action.constants";


const MP_CLS_1 = twdClass(
  "fixed z-40 inset-0 flex items-center justify-center"
);
const MP_CLS_2 = twdClass("absolute z-40 inset-0 transition bg-white bg-opacity-20 hover:bg-opacity-10 active:bg-opacity-50 backdrop-filter backdrop-blur-lg");
const MP_CLS_3 = twdClass(
  "absolute z-50 shadow-lg px-6 py-3 rounded-2xl"
);
const MP_CLS_3_FULL = twdClass(
  "w-full h-full flex flex-col"
);
const MP_CLS_4 = twdClass("text-2xl font-semibold");
const MP_CLS_5 = twdClass(
  "flex items-start justify-between py-2 px-2  pr-0 pb-2 mb-3"
);
const MP_CLS_6 = twdClass(
  "flex-grow"
);

const ModalPopup = ({ childern }) => {
  const { showPopup, title, component, desc, img, color='', action, full=false} = useSelector(
    (state) => state.modalpopup
  );
  const dispatch = useDispatch();
  let MP_CLS_3C=twdClass('bg-gray-100 border-gray-200 ')
  if(color=='primary'){
    MP_CLS_3C=twdClass('bg-primary-500 border-primary-500')
  }
  if(full){
    MP_CLS_3C+=` ${MP_CLS_3_FULL}`
  }
  return (
    <div>
      {showPopup && (
        <div className={MP_CLS_1}>
          <div className={MP_CLS_2} 
            onClick={() => {
              dispatch({ type: RESET_POPUP });
            }}          
          ></div>
          <div className={`${MP_CLS_3} ${MP_CLS_3C}`}>
            <div className={MP_CLS_5}>
              <div className={twdClass("flex flex-grow")}>
                {img != null && <div className={twdClass("-mt-16 -ml-2 mr-8 w-40 h-40 rounded-full ovewrflow-hidden bg-cover bg-center")} style={{backgroundImage:`url(/assets/images/${img})`}}>                 
                </div>}
                <div className={twdClass("flex flex-col")}>
                  <h1 className={MP_CLS_4}>{title}</h1>
                  {desc != null && <div className={twdClass("text-sm mt-2")} style={{maxWidth:'350px'}} >{desc}</div>}
                </div>
              </div>
              {/*<button
                onClick={() => {
                  dispatch({ type: RESET_POPUP });
                }}
              >
                <FaIcons icon="cross" />
              </button>*/}
              {action!=null && <div className={twdClass("mr-8 flex items-center")}>{action}</div>}
              <CloseBtn
                onClick={() => {
                  dispatch({ type: RESET_POPUP });
                }}
              />
            </div>
            <div className={`${MP_CLS_6}`}>{component}</div>           
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalPopup;
