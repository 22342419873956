export const getTimeForUniqID = () => {
  const timestamp = new Date().getTime();
  //sconsole.log("PAGE CREATED", timestamp);
  return timestamp + Math.floor(Math.random() * 88) + 11;
};
export const getTimestamp = () => {
    const timestamp = new Date().getTime();
    //sconsole.log("PAGE CREATED", timestamp);
    return timestamp
};
export const regexFrom=(captureFrom="box_brackets")=>{
    let regex = /\[\[(.*?)\]\]/g;
    if(captureFrom=='flower_brackets'){
        regex = /\{\{(.*?)\}\}/g;
    }
    return regex
}
export const getParams=({template='', captureFrom=""})=>{
    let regex = regexFrom(captureFrom)
    let out=''
    let match, matches = [];
    // Part 1
    while (match = regex.exec(template)) {
      matches.push({label:match[1], value:match[1]});
    }    
    return matches
  }
export const getTemplateSample=({template='', data={}, captureFrom=""})=>{
    let regex = regexFrom(captureFrom)
    let out=template.replace(
        regex, // or /{(\w*)}/g for "{this} instead of %this%"
        function( m, key ){
          return data.hasOwnProperty( key ) ? data[ key ] : "";
        }
      )
    return out
}

  
export const title2Slug = (title) =>{ 
    if(!title) return title
    return title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}
  
export const styleTransform = (styleProps) => {
    if(styleProps['backgroundColor']){    styleProps['background-color'] = styleProps['backgroundColor']; delete styleProps['backgroundColor'] }
    if(styleProps['backgroundImage']){    styleProps['background-image'] = styleProps['backgroundImage']; delete styleProps['backgroundImage'] }
    if(styleProps['fontSize']){    styleProps['font-size'] = styleProps['fontSize']; delete styleProps['fontSize'] }
    return styleProps
}
export const styles_ = ({obj, el, key='styleProps'}) => {
    let {styleProps={}, stylePropsTab={}, stylePropsMob={}} = el
    obj={...obj, [key]:styleTransform(styleProps), [key+'Tab']:stylePropsTab, [key+'Mob']:stylePropsMob}
    return obj
}
const link_ = ({link}) => {
    let {text, url=''} = link
    let out={
        "text": text,
        "linkUrl": url,         
    }
    out=styles_({obj:out, el:link})
    return out
}
export const links_ = ({links}) => {
    let out=links.map((link, i)=>{
        return link_({link})
    })
    return out
}

