export const FOOTER_BLOCK_1 = {
  blockType: "FOOTER",
  blockDesign: "FooterDesign1",
  editFormName: "FOOTER_FORM_1",
  styleProps: {
    "background-color": "#1b1b31",
  },
  logo: {
    text: "FlexBee.",
    href: "#",
    styleProps: {},
  },
  description: {
    list: ["The company you can always trust."],
    styleProps: {},
  },
  copy: {
    text: "© Copyright 2022 FlexBee. All Rights Reserved.",
    href: "#",
    styleProps: {},
  },
  terms: {
    text: "Terms",
    href: "#",
    styleProps: {},
  },
  privacy: {
    text: "Privacy",
    href: "#",
    styleProps: {},
  },
};
