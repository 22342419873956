import { twdClass } from "../../../constants/tailwind.constants";
import { BTN_CLS } from "../../../constants/var.constants";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { copyPastNewBlock } from "../../../api/services";
import { toast } from "react-toastify";
import { RESET_POPUP } from "../../../constants/action.constants";
import {Button} from "../../ui/com_ui";

const PBTN = `${twdClass(BTN_CLS)} ${twdClass(
  "bg-blue-600 hover:bg-blue-400 text-sm font-semibold text-white py-2 disabled:opacity-50"
)}`;

export const CopySection = ({ copyBlock }) => {
  const {
    projectData: { project_id, pages },
  } = useSelector((state) => state.studioDetails);
  const [selectPages, setSelectPages] = useState([]);
  const dispatch = useDispatch();

  const handlePast = () => {
    copyPastNewBlock({
      id: project_id,
      pages: selectPages,
      block: copyBlock,
    }).then((resp) => {
      console.log(resp);
      if (resp.success) {
        toast.success("Successfully pasted block in selected pages");
        dispatch({ type: RESET_POPUP });
      }
    });
  };

  return (
    <div className={twdClass("w-96 -mx-4 -mb-4 ")}>
      <h6 className={twdClass("font-semibold text-base pb-4 px-6")}>
        Select from below pages to copy
      </h6>
      <div className={twdClass("h-52 overflow-auto leading-loose bg-white rounded-lg px-4")}>
        {pages.map((item) => (
          <div
            className={`${twdClass(
              `${selectPages.indexOf(item.page_id) !== -1 ? "bg-primary-200" : ""}`
            )} ${twdClass("my-1 px-2 cursor-pointer")}`}
            onClick={() => {
              let list = [...selectPages];
              const valItem = list.filter((id) => id !== item.page_id);
              if (valItem.length !== list.length) {
                list = valItem;
              } else {
                list.push(item.page_id);
              }
              setSelectPages(list);
            }}
          >
            {item.page_name}
          </div>
        ))}
      </div>
      <div className={twdClass("w-full my-4 flex justify-end text-right")}>
        <Button
          type={'default'} color={'primary'}           
          disabled={selectPages.length === 0}
          onClick={() => handlePast()}
        >
          Paste in
        </Button>
      </div>
    </div>
  );
};
