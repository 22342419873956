import { twdClass } from "../constants/tailwind.constants";
import { PricingLayout } from "../components/pricing/PricingLayout";

const { default: Header } = require("../components/common/Header");

const Pricing = () => {
  return (
    <div>
      <Header />
      <div className={twdClass("mt-20")}>
        <PricingLayout />
      </div>
    </div>
  );
};

export default Pricing;
