import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { Button} from "../../ui/com_ui";

import { getTimeForUniqID, styles_, links_} from "./t_util";

import navbar from '../TestDesign/data/navbar'

const process_=({obj, d})=>{
  //console.log("t_navbar", d)

  let {logo={}}=d
  obj.logoText=logo 
  return obj
}

const navbar_ = ({generated={}, obj=false}) => {
  let d = obj ? obj : navbar.a01.navbar
  let uid=  getTimeForUniqID()
  //console.log("navbar", d.styleProps)
  //console.log("getTimeForUniqID", uid)
  let out={   
    "metaDataId": uid,
    "blockId": uid,
    "blockType": "NAVBAR",
    "blockDesign": "NavDesign1",
    "editFormName": "NAVBAR_FORM_1",
    "styleProps": d.styleProps,
    "stylePropsTab": d.stylePropsTab ? d.stylePropsTab : {},
    "stylePropsMob": d.stylePropsMob ? d.stylePropsMob : {},
    "logoType": "text",
    "logoText_": {
        "text": "Gym Trainer",
        "altText": "Gym Trainer Logo",
        "styleProps": {
            "margin": "0px",
            "padding": "10px",
            "font-size": "1.875rem",
            "line-height": "2.25rem",
            "font-weight": "700"
        },
        "stylePropsTab": {
            "margin": "0px",
            "padding": "10px"
        },
        "stylePropsMob": {
            "margin": "0px",
            "padding": "10px"
        }
    }
  }
  out=process_({obj:out, d}) 
  out.links=links_({links:d.links})
  return out
};

export default navbar_;

