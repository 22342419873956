import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";


const HeadTags = ({ sitesettings, updateSettings }) => {
  const updateItem = (value) => {
    const sst = cloneDeep(sitesettings);
    const data = set(sst, "headTags", value);
    updateSettings(data);
  };

  return (
    <div className={twdClass("w-full mb-4")}>
      <div className={twdClass("text-md font-semibold mb-4")}>Head Tags</div>
      <textarea
        rows={10}
        value={sitesettings.headTags}
        className={twdClass(`${INPUT_TEXT} w-full`)}
        onChange={(e) => updateItem(e.target.value)}
        placeholder = {`<meta name="robots" content="index, follow, max-imagepreview:large, max-video-preview:-1"/>`}
      ></textarea>
    </div>
  );
};

export default HeadTags;
