import { twdClass } from "../../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import { replace, cloneDeep, set, get, last } from "lodash";
import S3Uploader from "./S3Uploader";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import {setImageValue, getPropValues, getUpToWith, Device} from "./util";



const BGImage = ({ element, data, updateElementValue }) => {
  const [device, setDevice] = useState("");
  const [value, setValue] = useState();
  const [values, setValues] = useState({
    'background-image':'',
    'background-position': "center",
    'background-size': "cover",
  });
  const { updateTo, defaultValue } = element;

  useEffect(() => {  
    const prop_values = getPropValues({data, values, updateTo, device}); 
    setValues(prop_values);  
  }, []);

  useEffect(() => {
    const prop_values = getPropValues({data, values, updateTo, device}); 
    setValues(prop_values);      
  }, [device]);

  const updateValues = (x, y) => {
    const val = y ? y : '';
    const bg = set(cloneDeep(values), x, `${val}`);   
    setValues(bg);  
    let upToWith= getUpToWith({prop:x, updateTo, device})
    let v = setImageValue({obj:bg, prop:x})
    updateElementValue(upToWith, v);    
  };

  const addReplaveValue = (value) => {    
    updateValues('background-image', value)   
  };

  return (
    <div className={twdClass("py-2 flex border border-gray-100 hover:border-gray-300 rounded-lg px-2")}>
      <div className={twdClass("w-16 text-xs text-gray-700 font-semibold")}>
        {element.label}
      </div>
      <div>
        <Device {...{device, setDevice}}/>
        <ImgContainer className={twdClass("flex-auto ml-1 mb-2")}>
          <S3Uploader
            imageUrl={replace(values['background-image'], /(url)|[()'"]/g, "")}
            updateS3Path={addReplaveValue}
          />
          <input
            className={twdClass(`w-full mt-2 bg-white ${INPUT_TEXT}`)}
            value={values['background-image']}
            onChange={(e) => {
              addReplaveValue(e.target.value);
            }}
          />          
        </ImgContainer>
        {/*<div className={twdClass("flex items-center w-full mb-2")}>              
          <div className={twdClass("flex-grow")}><Dropdown
            options={SIZE_OPTIONS}
            hideLabel={false}
            label={'Size'}
            value={values['background-size']}
            updateValue={(value) => {
              updateValues("background-size", value);
            }}
          /> </div>             
        </div>
        <div className={twdClass("flex items-center w-full mb-2")}>              
          <div className={twdClass("flex-grow")}><Dropdown
            options={POSITION_OPTIONS}
            hideLabel={false}
            label={'Position'}
            value={values['background-position']}
            updateValue={(value) => {
              updateValues("background-position", value);
            }}
          /> </div>             
        </div>
        <div className={twdClass("flex items-center w-full mb-2")}>              
          <div className={twdClass("flex-grow")}><Dropdown
            options={REPEAT_OPTIONS}
            hideLabel={false}
            label={'Repeat'}
            value={values['background-repeat']}
            updateValue={(value) => {
              updateValues("background-repeat", value);
            }}
          /> </div>             
          </div>*/}
      </div>
    </div>
  );
};

export default BGImage;

const ImgContainer = styled.div`
  .uppy-DragDrop-inner {
    padding: 0px;
  }
  .uppy-DragDrop-arrow {
    height: 22px;
    width: 20px;
    margin-bottom: 0px;
  }
  .uppy-DragDrop-label {
    font-size: 0.75rem;
  }
`;



const POSITION_OPTIONS = [
  { display: "center", value: "center" },
  { display: "top", value: "top" },
  { display: "bottom", value: "bottom" },
  { display: "left", value: "left" },
  { display: "right", value: "right" },
  { display: "top left", value: "top left" },
  { display: "bottom left", value: "bottom left" },
  { display: "top right", value: "top right" },
  { display: "bottom right", value: "bottom right" }
];


const SIZE_OPTIONS = [
  { display: "auto", value: "auto" },
  { display: "cover", value: "cover" },
  { display: "contain", value: "contain" }
];


const REPEAT_OPTIONS = [
  { display: "Repeat", value: "" },
  { display: "No Repeat", value: "no-repeat" },
  { display: "Repeat X", value: "repeat-x" },
  { display: "Repeat Y", value: "repeat-y" }
];
