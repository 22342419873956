import { getDefaultFields } from "../common/common.fields";

export const CTA_02_FORM = {
  sectionName: "CTA Section",
  elements: [
    {
      inputType: "list",
      label: "Buttons",
      hasStyleProps: true,
      styleElementId: 2,
      updateTo: "buttons",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 3,
        elements: [
          {
            inputType: "text",
            label: "Button",
            defaultValue: "Click Here",
            updateTo: "buttons[i].text",
            extraPropUpdateTo: "buttons[i]",
            extraPropValues: {
              text: "Click Here",
              styleProps: {
                color: "#ffffff",
                "border-color": "#3b82f680",
              },
            },
            hasStyleProps: false,
          },
          {
            inputType: "text",
            label: "Href",
            defaultValue: "#",
            updateTo: "buttons[i].href",
            hasStyleProps: false,
          },         
          {
            inputType: "select",
            label: "Open In",
            defaultValue: "sametab",
            updateTo: "buttons[i].openType",
            hasStyleProps: false,
            options: [
              { value: "sametab", display: "Same Tab" },
              { value: "newtab", display: "New Tab" },
            ],
          },
          {
            inputType: "group",
            label: "Advanced",
            initShow:false,
            elements: [
              {
                inputType: "text",
                label: "Button ID",
                defaultValue: "",
                updateTo: "buttons[i].button_id",
                hasStyleProps: false,
              }, 
              {
                inputType: "text",
                label: "Alt Text",
                defaultValue: "",
                updateTo: "buttons[i].alt",
                hasStyleProps: false,
              },             
            ]
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "CTA section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Button section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "btnSecStyleProps",
          fields: ["padding", "margin"],
        }),
        {
          label: "Align",
          inputType: "select",
          name: "btnSecCenter",
          updateTo: "btnSecStyleProps.justify-content",
          options: [
            {
              value: "left",
              display: "Left",
            },
            {
              value: "center",
              display: "Center",
            },
            {
              value: "right",
              display: "Right",
            },
          ],
        },
      ],
    },
    {
      styleElementId: 3,
      label: "Button",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "buttons[i].styleProps",
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "backgroundcolor",
            "padding",
            "margin",
            "bordercolor",
            "borderradius",
          ],
        }),
      ],
    },
  ],
};
