import { twdClass } from "../constants/tailwind.constants";
import { PricingLayout } from "../components/pricing/PricingLayout";
import { useParams  } from "react-router-dom";
import { debug } from "../api/services";
import { Button} from "../components/ui/com_ui";
const { default: Header } = require("../components/common/Header");



const Debug = (props) => {
  const { id, header } = useParams();   
  if(id!='enable') return (<></>)

  const params = new URLSearchParams(props.location.search);
  const headers = params.get('headers')
  const uuid = params.get('uuid')
  const action = params.get('action')
  const subaction = params.get('subaction')
  console.log("props", props)
  console.log("search", props.location.search)
  console.log("headers", headers)
  console.log("uuid", uuid)
  console.log("action", action)
  console.log("subaction", subaction)
  
  const extra={
    project_name: "PROJECT",
    getHeaders:headers,
    getUUID:uuid,
    action:action,
    subaction:subaction,    
  }
  const debug2 = async () => {
    const { data } = await debug({    
      test:true,
      ...extra     
    });
    console.log("data", data)
  };
  const testEvent = async () => {
    const { data } = await debug({
      test:true,
      ...extra    
    });
    console.log("data", data)
  };
  const eventSignup = async () => {
    const { data } = await debug({
      test:true, 
      actiondata:{},    
      ...extra    
    });
    console.log("data", data)
  };
  const eventFirstLogin = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{},       
      ...extra    
    });
    console.log("data", data)
  };
  const eventFirstProjectCreated = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{},       
      ...extra    
    });
    console.log("data", data)
  };
  const eventFirstProjectPublished = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{},       
      ...extra    
    });
    console.log("data", data)
  };
  const eventPaidSubscriptionStarted = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{},       
      ...extra    
    });
    console.log("data", data)
  };
  const eventShakirTest = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{first_name:'Create', last_name:'Box'},        
      ...extra    
    });
    console.log("data", data)
  };
  const eventOnboardingDisplayed = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{first_name:'Create', last_name:'Box'},        
      ...extra    
    });
    console.log("data", data)
  };
  const eventOnboardingFilled = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{submission:{email:'demos@mailinator.com', first_name:'Create', last_name:'Box'}},        
      ...extra    
    });
    console.log("data", data)
  };
  const eventOnboardingReset = async () => {
    const { data } = await debug({
      test:true,   
      actiondata:{submission:{email:'demos@mailinator.com', first_name:'Create', last_name:'Box'}},        
      ...extra    
    });
    console.log("data", data)
  };

  return (
    <div>
      <Header />
      <div className={twdClass("p-12 mt-20")}>        
        DEBUG {id} {header} <div className={twdClass("text-sm text-gray-600")}>{JSON.stringify(props)}</div>
        
        <div className={twdClass("flex mt-12")}>
          {action=='test' && 
            <Button onClick={() => debug2()} className={'flex'} color={'white'} size={'sm'}     >
              Test Debug                
            </Button> 
          }
          {action=='event' && 
            <>
              <Button onClick={() => testEvent()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                Test Event                
              </Button>
              {subaction=='signup' && 
                <Button onClick={() => eventSignup()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  Signup Event               
                </Button>
              }
              {subaction=='first_login' && 
                <Button onClick={() => eventFirstLogin()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  First Login Event               
                </Button>
              }
              {subaction=='shakir_test' && 
                <Button onClick={() => eventShakirTest()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  Shakir Test Event               
                </Button>
              }
              {subaction=='first_project_created' && 
                <Button onClick={() => eventFirstProjectCreated()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  First Project Created Event               
                </Button>
              }
              {subaction=='first_project_published' && 
                <Button onClick={() => eventFirstProjectPublished()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  First Project Published Event               
                </Button>
              }
              {subaction=='paid_subscription_started' && 
                <Button onClick={() => eventPaidSubscriptionStarted()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  Paid Subscription Started Event               
                </Button>
              }
              {subaction=='onboarding_displayed' && 
                <Button onClick={() => eventOnboardingDisplayed()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  OnBoardingDisplayed Event               
                </Button>
              }
              {subaction=='onboarding_filled' && 
                <Button onClick={() => eventOnboardingFilled()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  OnBoardingFilled Event               
                </Button>
              }
              {subaction=='onboarding_reset' && 
                <Button onClick={() => eventOnboardingReset()} className={'mb-2 mx-2'} color={'white'} size={'sm'}     >
                  OnBoardingReset Event               
                </Button>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Debug;
