import { twdClass } from "../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../constants/var.constants";

export const BYOCPanel = ({ updateElementValue, currentBlock }) => {
  return (
    <>
      <div className={twdClass("my-3")}>
        <label>Template HTML</label>
        <textarea
          className={twdClass(INPUT_TEXT)}
          rows="10"
          value={currentBlock.configs.templateHTML}
          onChange={(e) => {
            updateElementValue("configs.templateHTML", e.target.value);
          }}
        ></textarea>
      </div>
      <div className={twdClass("my-3")}>
        <label>External CSS</label>
        <textarea
          className={twdClass(INPUT_TEXT)}
          rows="4"
          value={currentBlock.configs.externalCSS}
          onChange={(e) => {
            updateElementValue("configs.externalCSS", e.target.value);
          }}
        ></textarea>
      </div>
    </>
  );
};
