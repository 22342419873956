import Header from "../components/common/Header";
import { twdClass } from "../constants/tailwind.constants";
import { BTN_CLS, INPUT_TEXT } from "../constants/var.constants";
import FaIcons from "../components/common/FaIcons";
import { useState } from "react";
import { createProjectWithTemlate, getProjectTemplateIds, createProjectFromTemplate } from "../api/services";
import ProjectTemplateList from "../components/project-templates/ProjectTemplatesList";


const Templates = () => {
  const [template, setTemplate] = useState({
    templateId: "",
    projectId: "",
  });

  const handleCreate = () => {
    if (template.templateId && template.projectId) {
      createProjectWithTemlate({
        ...template,
      }).then((resp) => {
        console.log(resp);
      });
    }
  };

  return (
    <div>
      <Header />
      <ProjectTemplateList/>
      
    </div>
  );
};

export default Templates;
