import { twdClass } from "../../constants/tailwind.constants";
import Select from "react-select";
import { INPUT_TEXT } from "../../constants/var.constants";
import { get } from "lodash";

export const SortConfigPanel = ({
  setActivePanel,
  columnNames,
  updateElementValue,
  currentBlock,
}) => {
  const optionList = columnNames.map((itm) => ({
    value: itm.key,
    label: itm.value,
  }));

  return (
    <div>
      <div className={twdClass("px-6 py-5 flex items-center")}>
        <div
          className={twdClass("cursor-pointer mr-4")}
          onClick={() => setActivePanel("main")}
        >
          <img alt="" src="/assets/images/icon_arrow_left.png" />
        </div>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-blue-500 text-sm")}>Sort Configs</div>
        </div>
      </div>
      <div className={twdClass("px-6 py-5 flex items-center")}>
        <div className={twdClass("w-36 text-sm")}>Sort By:</div>
        <div className={twdClass("w-full text-xs")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={get(currentBlock, "configs.sortBy.columnName")}
            onChange={(e) => {
              updateElementValue("configs.sortBy.columnName", e.target.value);
            }}
          >
            {optionList &&
              optionList.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
          </select>
        </div>
      </div>
      <div className={twdClass("px-6 flex items-center")}>
        <div className={twdClass("w-36 text-sm")}>Order By:</div>
        <div className={twdClass("w-full text-xs")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={get(currentBlock, "configs.sortBy.ascending")}
            onChange={(e) => {
              updateElementValue(
                "configs.sortBy.ascending",
                e.target.value === "true"
              );
            }}
          >
            <option value={true}>Ascending</option>
            <option value={false}>Descending</option>
          </select>
        </div>
      </div>
    </div>
  );
};
