import { getDefaultFields } from "../common/common.fields";

export const LOGOS_FORM_1 = {
  sectionName: "Logos Section",
  elements: [
    {
      inputType: "list",
      label: "Logos Heading",
      hasStyleProps: false,
      updateTo: "headings",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 1,
        elements: [
          {
            inputType: "textarea",
            label: "Headings",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            updateTo: "headings[i].text",
            extraPropUpdateTo: "headings[i]",
            extraPropValues: {
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              styleProps: {
                textAlign: "center",
                "font-size": "1.125rem",
                "line-height": "1.75rem",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Logos Images",
      hasStyleProps: true,
      styleElementId: 2,
      updateTo: "logos",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 3,
        elements: [
          {
            inputType: "image",
            label: "Headings",
            defaultValue:
              "https://www.brandingmag.com/wp-content/uploads/2012/08/microsoftlogotransparent-01.png",
            updateTo: "logos[i].src",
            extraPropUpdateTo: "logos[i]",
            extraPropValues: {
              src:
                "https://www.brandingmag.com/wp-content/uploads/2012/08/microsoftlogotransparent-01.png",
              styleProps: {
                width: "150px",
                height: "50px",
              },
            },
            hasStyleProps: false,
          },
          {
            inputType: "text",
            updateTo: "logos[i].alt",
            label: "Alt",
            defaultValue:"",
            hasStyleProps: false
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Logo section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Heading",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "headings[i].styleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Logo section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "logosstyleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Logos",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "logos[i]styleProps",
          fields: ["padding", "margin", "height2px", "width2px"],
        }),
      ],
    },
  ],
};
