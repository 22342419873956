import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "./Common";
import {navbar_prompt, navbar_format} from "../prompts/navbar_prompt";

const Link = (link) => {
  const {text}= link
  return(<DivTag {...link}>{text}</DivTag>)
}

const Links = ({links}) => {
  let links_=links.map((link, i)=>{
    return(<Link key={i} {...link}/>)
  })
  return(<DivTag className={twdClass("")}>
    <div className={twdClass("flex items-center gap-2")}>{links_}</div>
  </DivTag>)
}

const Design2 = ({obj={}, opts={}, sample='', sdata={}}) => {  
  let user_prompt = navbar_prompt({opts, sample, sdata }) 
  return (<Design {...{type:'navbar', obj, user_prompt, format:navbar_format}}/>)
}


const Navbar = ({navbar={}, opts={}, design=false, sample='', sdata={}}) => {
  let obj=navbar.navbar ? navbar.navbar : navbar
  const {logo={}, links=[], ...obj1} = obj
  if(!links) return

  return(
    <DivTag {...obj1} className={twdClass("relative mb-4 px-4 py-2 ")}>
      {design && <Design2 {...{design,  obj, opts, sample, sdata}}/>}
      <div className={twdClass("flex items-center gap-8 relative mx-auto")} style={{'max-width':'1536px'}}>
        <div >
          <Link {...logo}/>
        </div>
        <div className={twdClass("flex-grow")}></div>
        <div className={twdClass("ml-2")}> 
          <Links {...{links}}/>
        </div>
      </div>
    </DivTag>
  )
}
export  default Navbar