import { twdClass } from "../../constants/tailwind.constants";
import { useHistory, useNavigate, Link } from "react-router-dom";
import {Button} from "../ui/com_ui";
import party from "party-js";
import { getLowerCaseHashId } from "../../utils/hashid.utils";

import {  
  RESET_POPUP,
  RESET_CONFRIM_POPUP,
  TOGGEL_CONFIRM_POPUP,
} from "../../constants/action.constants";

import {  
  getProjectList,
  getProjectTemlateIds,
  createProjectFromTemplate,
} from "../../api/services";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const ProjectTemplatesList = () => { 
  const history = useHistory(); 
  const dispatch = useDispatch();  
  const [templates, setTemplates] = useState([]);
  const [projects, setProjects] = useState([]);
  const { max_cust_domain, max_sub_domain } = useSelector(
    (state) => state.userdetails
  );

  const upgradePopup = () => {
    dispatch({
      type: TOGGEL_CONFIRM_POPUP,
      data: {
        showConfirmPopup: true,
        title: "Upgrade Plan",
        confirmLabel:
          "Please upgrade current plan to create more projects.",
        confrimCallBack: (info) => {
          dispatch({ type: RESET_CONFRIM_POPUP });
          history.push(`/pricing`);
        },
      },
    });
  }

  const goBack = () => {
		history.goBack();
	}

  const onPreview = (url) => {
		history.goBack();
	}

  const doSelect = async (id) => {		
    try {
      const { success, data } = await createProjectFromTemplate({id});
      console.log("duplicate data", success)  
      party.confetti(document.querySelector("body"), {
        count: party.variation.range(20, 40),
      });
      //history.push(`/studio/rvydlwovzmr`);
      history.push(`/studio/${getLowerCaseHashId(data.project_id)}`);
    } catch (error) {
      console.log(error);
    }  
    dispatch({ type: RESET_CONFRIM_POPUP });        
	}

  const onSelect = async (id) => {		
    const maxAllowed = max_cust_domain + max_sub_domain;
    if (projects.length + 1 > maxAllowed) {
      upgradePopup()
    } else {
      dispatch({
        type: TOGGEL_CONFIRM_POPUP,
        data: {
          showConfirmPopup: true,
          confirmLabel: "Are you sure, You want to create project from this template?",
          selectProject: id,
          confrimCallBack:  (info) =>  doSelect(info),
        },
      });
    }
	}

  const getProjectTemplates = async () => {
    const { success, result } = await getProjectTemlateIds();
    if (success) {
      console.log("success", result)
      setTemplates(result);
    }
  };
  const getProjects = async () => {
    const { success, data } = await getProjectList();
    if (success) {
      console.log("success", data)
      setProjects(data);
    }
  };

  useEffect(() => {
    getProjectTemplates();
    getProjects();
  }, []);

  return (
    <div>
      <div className={twdClass("bg-gray-100 lg:h-screen px-8 py-20")}>
        <div className={twdClass("lg:container mx-auto")} style={{maxWidth:"1400px"}}>
          <div className={twdClass("mt-5 flex flex-wrap mb-8")} >
            <div className={twdClass("flex-grow mt-12")}>            
              <div  className={twdClass("flex items-center")}>
                <Button                
                  onClick={() => goBack()}
                  type="default"  cType={"icon"} iconImg={"arrow-left-sm.svg"} color="white" className={'mr-8'}                  
                >                
                </Button>
                <div className={twdClass("text-3xl font-semibold")}>
                  Templates
                </div>              
              </div>            
            </div>


            <div> </div>
          </div>
          <div
            className={twdClass(
              "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  "
            )}           
          >
            {templates.map(({id, name, url}, index) => {
              return (
                <div key={index} className={twdClass(" mx-2 my-2 relative ")}>
                  <div className={twdClass("hover:shadow-sm  bg-white rounded-xl px-2 py-2  ")}>  
                    <div className={twdClass("rounded-tl-xl rounded-tr-xl overflow-hidden ")}>
                      <img src={`/assets/images/templates/${id}.png`} width="100%"/>
                    </div>               
                    <div
                      className={twdClass(
                        "h-full transform px-4 py-4  " 
                      )}
                    >                      
                      <div className={twdClass("mb-8 ")}>
                        <div className={twdClass("text-xl")}>{name}</div>
                      </div>
                      <div className={twdClass("w-full flex justify-between")}>
                        <a
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button 
                            type="default"  color="white" className={'mr-8'}                  
                          >    
                            Preview            
                          </Button>
                        </a>                        
                        <Button                
                          onClick={() => onSelect(id)}
                          type="default" color="primary" className={''}                  
                        >    
                          Select            
                        </Button>
                      </div>                      
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>         
      </div>   
    </div>
  );
};

export default ProjectTemplatesList;
