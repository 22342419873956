export const SLY_CARDS_LIST = [
  /* {
    templateName: "Box Simple 1",
    templateType: "SLYC",
    templateId: "TEMP_1",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-justify-items-center sly-gap-4 sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `
    <div class="sly-w-full">
      <div class="sly-relative sly-h-full sly-ml-0 md:sly-mr-0 sly-block sly--rotate-1 sly-transform hover:sly-scale-105 hover:sly--rotate-2">
        <span class="sly-absolute sly-top-0 sly-left-0 sly-w-full sly-h-full sly-mt-1 sly-ml-1 sly-bg-blue-400 sly-rounded-lg" style="background-color: red;"></span>
        <div class="sly-relative sly-h-full sly-p-5 sly-bg-white sly-transform sly-border-2 sly-border-blue-400 sly-rounded-lg" style="border-color: red;">
            <div class="sly-flex sly-items-center sly--mt-1">
                <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Landing Page Checklist</title>
                    <g id="Landin'-Cool" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Square-Logo" transform="translate(3.000000, 3.000000)">
                            <rect id="Rectangle" fill="#0080FE" x="0" y="0" width="42" height="42" rx="11"></rect>
                            <g id="cool-landing-page-logo" transform="translate(21.000000, 19.762563) rotate(90.000000) translate(-21.000000, -19.762563) translate(7.291667, 6.054230)" fill-rule="nonzero">
                                <path d="M23.4659406,0.145833333 C22.0267932,0.170998017 20.611777,0.520964059 19.3259349,1.16975195 L2.48234976,9.3468798 C-0.651010772,10.8827577 -0.353270631,13.7980816 3.10618624,14.5660205 L11.0742795,16.3720992 L12.8890766,24.3216896 C13.640516,27.7347516 16.561205,28.0902789 18.09244,24.9474176 L26.2448486,8.15230808 C28.4140982,3.35980011 26.9821099,0.216938793 23.4659406,0.145833333 Z" id="Path" fill="#FFFFFF"></path>
                                <path d="M22.9944726,2.82231694 C24.8617568,2.55407638 25.1305326,3.90939708 23.7866538,6.86004318 L15.695089,23.40625 L13.7712204,14.7237268 C13.6497014,14.1972547 13.2377861,13.7861595 12.7102635,13.6648825 L4.01041667,11.7307269 L20.6320752,3.65527445 C21.3804784,3.2776346 22.1744649,2.99768299 22.9944726,2.82231694 Z" id="Path" fill="#6AB5FF"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <h3 class="sly-my-2 sly-ml-3 sly-text-lg sly-font-bold sly-text-gray-800">{{val1}}</h3>
            </div>
            <p style="BX_1" class="sly-mt-3 sly-mb-1 sly-text-xs sly-font-bold sly-text-blue-600 sly-uppercase">{{val4}}</p>
            <p class="sly-mb-2 sly-text-gray-600">A curated library of landing page resources. You'll have a up-to-date landing pages in no time.</p> 
            [REPEATER_START]
              {{val6}}|<span class="sly-block sly-p-2 sly-m-2 sly-w-1/5 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]    
            [REPEATER_START]
              {{val7}}|<span class="sly-block sly-p-2 sly-m-2 sly-w-1/5 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END] 
        </div>
      </div>
    </div>
    `,
    stylesList: [
      {
        label: "Main Text",
        propsNames: ["color", "fontsize", "fontweight", "backgroundcolor"],
        id: "BX_1",
        styleProps: {},
      },
      {
        label: "Card BG",
        propsNames: ["backgroundcolor"],
        id: "BX_2",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  }, */
  {
    templateName: "Box Simple 3",
    templateType: "SLYC",
    templateId: "TEMP_3",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-justify-items-center sly-gap-4 sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `
    <div class="sly-w-full">
      <div class="sly-max-w-sm sly-w-full lg:sly-max-w-full lg:sly-flex m-10 sly-border" style="BX_2">
        <div class="sly-h-48 lg:sly-h-auto lg:sly-w-48 sly-flex-none sly-bg-cover sly-rounded-t lg:sly-rounded-t-none lg:sly-rounded-l sly-text-center sly-overflow-hidden sly-border" style="background-image: url('{{Image}}'); BX_6" title="Woman holding a mug">
        </div>
        <div class="sly-rounded-b lg:sly-rounded-b-none lg:sly-rounded-r sly-p-4 sly-flex sly-flex-col sly-justify-between sly-leading-normal">
          <div class="sly-mb-8">
            <div class="sly-text-gray-900 sly-font-bold sly-text-xl sly-mb-2" style="BX_3">{{Title}}</div>
            <p class="sly-text-gray-700 sly-text-base" style="BX_4">{{Description}}</p>
          </div>
          <div class="sly-flex sly-items-center">
            <img class="sly-w-10 sly-h-10 sly-rounded-full sly-mr-4 {{Image}}sly-invisible" src="{{Image}}" alt="">
            <div class="sly-text-sm">
              <div class="sly-text-gray-900 leading-none" style="BX_5">{{SubTitle}}</div>
              <div class="sly-w-full sly-flex">
              [REPEATER_START]
                {{Tag1}}|<div class="sly-text-gray-600" style="BX_7">PROP_ITEM_VALUE</div>
              [REPEATER_END]
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `,
    stylesList: [
      {
        label: "Card Layout",
        propsNames: [
          "backgroundcolor",
          "padding",
          "margin",
          "bordercolor",
          "borderradius",
        ],
        id: "BX_2",
        styleProps: {
          "background-color": "#ffffff",
        },
      },
      {
        label: "Image Configs",
        propsNames: ["bordercolor", "borderradius"],
        id: "BX_6",
        styleProps: {},
      },
      {
        label: "Title Configs",
        propsNames: ["color", "fontfamily", "fontweight", "padding", "margin"],
        id: "BX_3",
        styleProps: {},
      },
      {
        label: "Description Configs",
        propsNames: ["color", "fontfamily", "fontweight", "padding", "margin"],
        id: "BX_4",
        styleProps: {},
      },
      {
        label: "Sub Title Configs",
        propsNames: ["color", "fontfamily", "fontweight", "padding", "margin"],
        id: "BX_5",
        styleProps: {},
      },
      {
        label: "Tag Configs",
        propsNames: [
          "backgroundcolor",
          "color",
          "fontfamily",
          "fontweight",
          "borderradius",
          "padding",
          "margin",
        ],
        id: "BX_7",
        styleProps: {
          margin: "0px 6px 0px 6px",
          padding: "3px 3px 3px 3px",
        },
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 1",
    templateType: "SLYC",
    templateId: "NEW_CARD_1",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t2a md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class=" TC-1 sly-block sly-w-full">  
      <div class="sly-bg-white sly-mx-4 sly-rounded-lg sly-overflow-hidden  sly-shadow-xl hover:sly-shadow-md sly-text-center sly-transition-all">
        <div class="sly-py-24 sly-rounded-t-lg sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>						
        <div style="BS_Content" class="sly-p-4">
          <div style="BS_Title" class="sly-text-2xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-text-gray-400 sly-uppercase sly-break-words">{{SubTitle}}</p>	
          <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>							  
        </div>		
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Content",
        propsNames: ["backgroundcolor"],
        id: "BS_Content",
        styleProps: {
          "background-color": "#ffffff",
        },
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 1a",
    templateType: "SLYC",
    templateId: "NEW_CARD_1a",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t2b md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-1 sly-block sly-w-full">  
      <div class="sly-bg-white sly-mx-4 sly-rounded-lg sly-overflow-hidden  sly-shadow-xl hover:sly-shadow-md sly-text-center sly-transition-all">
        <div class="sly-rounded-t-lg sly-bg-cover">
          <img class="sly-w-full sly-h-auto" src="{{BackgroundImage}}"/>
        </div>						
        <div style="BS_Content" class=" sly-p-4">
          <div style="BS_Title" class="sly-text-2xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-text-gray-400 sly-uppercase sly-break-words">{{SubTitle}}</p>	
          <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>							  
        </div>		
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Content",
        propsNames: ["backgroundcolor"],
        id: "BS_Content",
        styleProps: {
          "background-color": "#ffffff",
        },
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 2a",
    templateType: "SLYC",
    templateId: "NEW_CARD_2",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t4b md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-2 sly-block sly-text-center">
      <div style="BS_Wrap">
        <div class="sly-w-48 sly-h-48 sly-mx-auto sly-rounded-full sly-shadow-xl hover:sly-shadow-md sly-transition-all sly-bg-cover" style="BS_Image; background-image:url({{BackgroundImage}});"></div>						
        <div style="BS_Content" class="sly-p-4">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_SubTitle" style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-text-gray-500 sly-uppercase sly-break-words">{{SubTitle}}</p>	
          <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>							  
        </div>	
      </div>	
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["padding", "margin", "backgroundcolor", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Content",
        propsNames: ["padding", "margin", "backgroundcolor", "borderradius"],
        id: "BS_Content",
        styleProps: {},
      },
      {
        label: "Image",
        propsNames: ["margin", "borderradius"],
        id: "BS_Image",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 3",
    templateType: "SLYC",
    templateId: "NEW_CARD_3",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1  -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-3 sly-block sly-text-gray-900 sly-text-center">
      <div class="sly-w-48 sly-h-48 sly-mx-auto sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>						
      <div style="BS_Content" class="sly-p-4">
        <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
        <p style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-text-gray-500 sly-uppercase sly-break-words">{{SubTitle}}</p>	
        <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>							  
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 3a",
    templateType: "SLYC",
    templateId: "NEW_CARD_3a",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-3a sly-block sly-text-gray-900 sly-text-center">
      <div class="sly-w-48 sly-mx-auto sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all sly-bg-cover" >
        <img class="sly-w-full sly-height-auto" src="{{BackgroundImage}}"/>      
      </div>						
      <div style="BS_Content" class="sly-p-4">
        <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
        <p style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-text-gray-500 sly-uppercase sly-break-words">{{SubTitle}}</p>	
        <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>							  
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 4",
    templateType: "SLYC",
    templateId: "NEW_CARD_4",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t6 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-4 a sly-block sly-text-center sly-p-4 sly-bg-white sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all  " >
      <div class="sly-w-full sly-h-48 sly-rounded sly-mx-auto sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>						
      <div style="BS_Content" class="sly-p-4">
        <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
        <p style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-text-gray-500 sly-uppercase sly-break-words">{{SubTitle}}</p>	
        <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>							  
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 5",
    templateType: "SLYC",
    templateId: "NEW_CARD_5",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-5 sly-block sly-flex sly-flex-col sly-px-4  sly-py-6 sly-bg-white sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all">
      <div class="sly-mb-4 sly-text-gray-500">{{tag}}</div>
      <div class="sly-flex">
        <div class="sly-w-24 sly-h-24 sly-mr-6 sly-inline-flex sly-items-center sly-justify-center sly-rounded-full">
          <div class="sly-w-24 sly-h-24 sly-mx-auto sly-rounded sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>	
        </div>                                    
        <div class="">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_Description"  class="sly-mt-1 sly-text-xs sly-mb-3 sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>		
          <p style="BS_SubTitle" class="sly-mt-1  sly-text-xs sly-text-gray-500 sly-uppercase sly-break-words">{{SubTitle}}</p>								  
        </div>	
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 6",
    templateType: "SLYC",
    templateId: "NEW_CARD_6o",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t23 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: ` 
      <a href="{{LinkTo}}" class="TC-6 sly-block sly-bg-white sly-mb-12 sly-shadow-xl hover:sly-shadow-md sly-transition-all">
        <div>
          <div class="sly-relative sly-overflow-hidden sly-w-full sly-h-64"><div class="sly-absolute sly-top-0 sly-right-0 sly--mr-12 sly--mt-6 sly-w-64 sly-h-64 sly-rounded-full sly-rotate-45 sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div></div>						
          <div class="sly-p-6 sly--mt-4">
            <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
            <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>	
            <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-1 sly-text-sm sly-text-gray-500 sly-uppercase sly-mt-12 sly-break-words">{{SubTitle}}</p> 
            <div class="sly-mt-3 sly-flex sly-flex-wrap">	
              [REPEATER_START]
                {{ExtraCol1}}|<span style="BS_Extra" class="sly-block sly-px-3 sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
              [REPEATER_END]    
              [REPEATER_START]
                {{ExtraCol2}}|<span style="BS_Extra" class="sly-block sly-px-3  sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
              [REPEATER_END]  
            </div>               
          </div>	
        </div>
      </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "Extra",
        propsNames: ["backgroundcolor", "color", "fontsize", "fontWeight"],
        id: "BS_Extra",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 7",
    templateType: "SLYC",
    templateId: "NEW_CARD_7",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-7 sly-block sly-bg-white hover:sly-bg-gray-100 sly-shadow-sm sly-rounded sly-transition-all">
      <div class="sly-overflow-hidden sly-w-full sly-h-64 sly-rounded sly-rounded-bl-none sly-rounded-br-none sly-overflow-hidden"><div class="sly-absolute sly-top-0 sly-right-0 sly-w-full sly-h-full sly-bg-cover sly-relative" style="background-image:url({{BackgroundImage}});"></div></div>						
      <div style="BS_Title" class="sly-p-4 sly--mt-12  sly-relative sly-z-10">
        <div class="sly-p-4 sly-bg-white sly-rounded-lg sly-shadow-xl ">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>	
          <div class="sly-mt-3 sly-flex sly-flex-wrap">	
            [REPEATER_START]
              {{ExtraCol1}}|<span style="BS_Extra" class="sly-block sly-px-3 sly-py-1 sly-my-2 sly-mr-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]    
            [REPEATER_START]
              {{ExtraCol2}}|<span style="BS_Extra" class="sly-block sly-px-3  sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]  
          </div> 
          <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-1 sly-text-sm sly-text-gray-500 sly-uppercase sly-mt-12 sly-break-words">{{SubTitle}}</p>
        </div>
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "Extra",
        propsNames: ["backgroundcolor", "color", "fontsize", "fontWeight"],
        id: "BS_Extra",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 7a",
    templateType: "SLYC",
    templateId: "NEW_CARD_7as",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-7a sly-block sly-bg-white hover:sly-bg-gray-100 sly-shadow-sm sly-rounded sly-overflow-hidden sly-transition-all">
      <div style="BS_ImageWrap" class="sly-overflow-hidden sly-w-full sly-rounded sly-rounded-bl-none sly-rounded-br-none sly-overflow-hidden">
        <img class="sly-w-full sly-h-auto" src="{{BackgroundImage}}"/>
      </div>						
      <div class="sly-p-4 sly--mt-12  sly-relative sly-z-10">
        <div style="BS_Content" class="sly-p-4 sly-bg-white sly-rounded-lg sly-shadow-xl ">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>	
          <div class="sly-mt-3 sly-flex sly-flex-wrap">	
            [REPEATER_START]
              {{ExtraCol1}}|<span style="BS_Extra" class="sly-block sly-px-3 sly-py-1 sly-my-2 sly-mr-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]    
            [REPEATER_START]
              {{ExtraCol2}}|<span style="BS_Extra" class="sly-block sly-px-3  sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]  
          </div> 
          <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-1 sly-text-sm sly-text-gray-500 sly-uppercase sly-mt-12 sly-break-words">{{SubTitle}}</p>
        </div>
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Image Wrap",
        propsNames: ["borderradius"],
        id: "BS_ImageWrap",
        styleProps: {},
      },
      {
        label: "Content",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Content",
        styleProps: {
          "background-color": "#ffffff",
        },
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "Extra",
        propsNames: ["backgroundcolor", "color", "fontsize", "fontWeight"],
        id: "BS_Extra",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 8",
    templateType: "SLYC",
    templateId: "NEW_CARD_8d",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-8 sly-block sly-bg-white sly-overflow-hidden sly-rounded sly-shadow-xl hover:sly-shadow-md sly-transition-all" >
      <div class="sly-w-full sly-h-64 sly-relative sly-z-10">
        <div class="sly-absolute sly-top-0 sly-right-0 sly-w-full sly-h-full sly-bg-cover sly-relative" style="background-image:url({{BackgroundImage}});"></div>
        <div style="BS_Shape; margin-left:-10rem; margin-bottom:-6rem;" class="sly-bg-gray-300 sly-z-10 sly-absolute sly-transform sly-rotate-45 sly-bottom-0 sly-left-0 sly-w-48 sly-h-48 sly--mb-12" ></div>
      </div>						
      <div style="BS_Content" class="sly-p-4 sly-relative ">
        <div class="sly-flex sly-flex-col sly-items-end sly-justify-end sly-text-right sly-pl-8">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-border-b sly-pb-4 sly-mb-4 sly-break-words">{{Title}}</div>
          <p style="BS_Description" class="sly-mt-1 sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>	
          <div class="sly-mt-3 sly-flex sly-flex-wrap">	
            [REPEATER_START]
              {{ExtraCol1}}|<span style="BS_Extra" class="sly-block sly-px-3 sly-py-1 sly-my-2 sly-mr-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]    
            [REPEATER_START]
              {{ExtraCol2}}|<span style="BS_Extra" class="sly-block sly-px-3  sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]  
          </div> 
          <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-1 sly-text-sm sly-text-gray-500 sly-uppercase sly-mt-12 sly-break-words">{{SubTitle}}</p>
        </div>
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Content",
        propsNames: ["backgroundcolor"],
        id: "BS_Content",
        styleProps: {
          "background-color": "#ffffff",
        },
      },
      {
        label: "Shape",
        propsNames: ["backgroundcolor"],
        id: "BS_Shape",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "Extra",
        propsNames: ["backgroundcolor", "color", "fontsize", "fontWeight"],
        id: "BS_Extra",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 9",
    templateType: "SLYC",
    templateId: "NEW_CARD_9",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="TC-19 sly-block sly-bg-white sly-relative sly-overflow-hidden sly-rounded sly-shadow-xl hover:sly-shadow-md sly-transition-all" >
      <div class="sly-w-full sly-h-64 sly-relative sly-z-10">
        <div class="sly-absolute sly-top-0 sly-right-0 sly-w-full sly-h-full sly-bg-cover sly-relative" style="background-image:url({{BackgroundImage}});"></div>			
        <div class="sly-z-10 sly-absolute sly-transform sly-text-white sly-left-0 sly-bottom-0 sly-w-full sly-h-12" style="">
        <svg fill="inherit" stroke="transparent" version="1.1" class="sly-w-full sly-h-full  sly-fill-current" viewBox="0 0 100 20" preserveAspectRatio="none"><path d="m0 0 100 20h-100z"/></svg>
      </div>
      </div>						
      <div class="sly-p-4 sly-relative ">
        <div class="sly-flex sly-flex-col sly-items-end sly-justify-end sly-text-right sly-pl-8">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-mb-3 sly-break-words">{{Title}}</div>
          <p style="BS_Description" class="sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>	
          <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-1 sly-text-sm sly-text-gray-500 sly-uppercase sly-mt-12 sly-break-words">{{SubTitle}}</p>
        </div>
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 10",
    templateType: "SLYC",
    templateId: "NEW_CARD_10",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t6 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-10 sly-block sly-bg-white sly-relative sly-overflow-hidden sly-rounded sly-shadow-xl hover:sly-shadow-md sly-transition-all">
      <div style="BS_ImageWrap" class="sly-w-full sly-h-64 sly-relative overflow-hidden sly-z-10">
        <div class="sly-absolute sly-top-0 sly-right-0 sly-w-full sly-h-full sly-bg-cover sly-relative" style="background-image:url({{BackgroundImage}});"></div>
        <div style="BS_ImageOverlay"  class="sly-absolute sly-top-0 sly-left-0 sly-w-full sly-h-full sly-bg-black sly-opacity-25"></div>
      </div>						
      <div class="sly-p-4 sly-relative sly-z-10">
        <div class="sly-relative sly-flex sly-justify-center">
          <div class="sly--mt-12 sly-w-16 sly-h-16 sly-rounded-full sly-bg-cover sly-relative sly-shadow-md" style="background-image:url({{BackgroundImage}});"></div>				
        </div>
        <div class="sly-flex sly-flex-col sly-items-center sly-justify-center sly-text-center sly-mt-4">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-mb-3 sly-break-words">{{Title}}</div>
          <p style="BS_Description" class="sly-text-xs sly-text-gray-600 sly-uppercase sly-break-words">{{Description}}</p>	
          <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-1 sly-text-sm sly-text-gray-500 sly-uppercase sly-mt-12 sly-break-words">{{SubTitle}}</p>
        </div>
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Image Wrap",
        propsNames: ["borderradius"],
        id: "BS_ImageWrap",
        styleProps: {},
      },
      {
        label: "Image Overlay",
        propsNames: ["opacity", "backgroundcolor"],
        id: "BS_ImageOverlay",
        styleProps: {
          "background-color": "#000000",
        },
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 11",
    templateType: "SLYC",
    templateId: "NEW_CARD_11",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-11 sly-block sly-relative sly-p-2 sly-rounded hover:sly-shadow-md sly-overflow-hidden sly-transition-all">
      <div class="sly-relative sly-flex sly-justify-center">
        <div class="sly-w-full sly-h-24  sly-bg-cover sly-relative sly-rounded" style="background-image:url({{BackgroundImage}});"></div>				
      </div>
      <div class="sly-flex sly-flex-col sly-items-start sly-justify-center sly-text-left sly-mt-4">
        <div style="BS_Title" class="sly-text-md sly-font-semibold sly-mb-0 sly-break-words">{{Title}}</div>
        <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-text-sm sly-text-gray-500 sly-break-words">{{SubTitle}}</p>
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 12",
    templateType: "SLYC",
    templateId: "NEW_CARD_12",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-12 sly-block sly-relative sly-p-2">
      <div class="sly-relative sly-bg-white sly-w-24 sly-h-24 sly-m-auto sly-flex sly-justify-center sly-relative sly-rounded-full sly-shadow-lg hover:sly-shadow-md sly-overflow-hidden sly-transition-all sly-p-3">
        <div class="sly-w-full sly-h-full  sly-bg-cover sly-relative sly-rounded-full" style="background-image:url({{BackgroundImage}});"></div>				
      </div>
      <div class="sly-flex sly-flex-col sly-items-center sly-justify-center sly-text-center sly-mt-3">
        <div style="BS_Title" class="sly-text-md sly-font-semibold sly-mb-0 sly-break-words">{{Title}}</div>	
        <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-0 sly-text-sm sly-text-gray-500 sly-break-words">{{SubTitle}}</p>			
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 13",
    templateType: "SLYC",
    templateId: "NEW_CARD_13",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5  md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-13 sly-block sly-relative">
      <div class="sly-relative sly-flex sly-justify-center">
        <div class="sly-w-24 sly-h-24  sly-bg-cover sly-relative sly-rounded-full sly-shadow-lg hover:sly-shadow-md focus:sly-shadow-sm sly-overflow-hidden sly-transition-all" style="background-image:url({{BackgroundImage}});"></div>				
      </div>
      <div class="sly-flex sly-flex-col sly-items-center sly-justify-center sly-text-center sly-mt-4">
        <div style="BS_Title" class="sly-text-md sly-mb-0 sly-px-2 sly-break-words">{{Title}}</div>			
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 14",
    templateType: "SLYC",
    templateId: "NEW_CARD_14",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5  md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-14 sly-block sly-relative sly-p-2 sly-rounded-lg hover:sly-bg-white hover:sly-shadow-md sly-overflow-hidden sly-transition-all">
      <div class="sly-relative sly-flex sly-justify-center">
        <div class="sly-w-full sly-h-24  sly-bg-cover sly-relative sly-rounded-lg" style="background-image:url({{BackgroundImage}});"></div>				
      </div>
      <div class="sly-flex sly-flex-col sly-items-center sly-justify-center sly-text-center sly-mt-4">
        <div style="BS_Title" class="sly-text-md sly-font-semibold sly-mb-0 sly-px-2 sly-break-words">{{Title}}</div>			
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 15",
    templateType: "SLYC",
    templateId: "NEW_CARD_15",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-15 sly-block sly-bg-white sly-relative sly-rounded-lg sly-border sly-border-gray-300 sly-shadow-lg hover:sly-shadow-sm" >		
      <div class="sly-flex sly-flex-col sly-items-start sly-justify-start sly-text-left sly-mt-3 sly-p-3 sly-max-w-48">
        <div style="BS_Title" class="sly-text-lg sly-font-semibold sly-mb-0 sly-break-words">{{Title}}</div>	
        <p style="BS_Description" class="sly-text-md sly-text-gray-600 sly-mt-2  sly-break-words">{{Description}}</p>	
        <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-2 sly-text-sm sly-text-gray-500 sly-uppercase sly-break-words">{{SubTitle}}</p>			
      </div>
      <div class="sly-relative sly-w-full sly-h-48 sly--mt-8 sly-flex sly-justify-end sly-overflow-hidden sly-transition-all sly-rounded-lg">
        <div class="sly-w-48 sly-h-48 sly--mr-8 sly-mt-8 sly-bg-cover sly-relative sly-rounded-full" style="background-image:url({{BackgroundImage}});"></div>				
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 16",
    templateType: "SLYC",
    templateId: "NEW_CARD_16",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-16 sly-block sly-bg-white sly-relative sly-p-2"">
      <div style="BS_ImageBG" class="sly-relative sly-bg-gray-200 sly-h-24 sly-flex sly-justify-center sly-relative sly-rounded-lg sly-mx-4">
        <div class="sly-w-24 sly-h-24 sly-mt-12 sly-bg-cover sly-relative sly-rounded-full sly-shadow-xl hover:sly-shadow-md sly-overflow-hidden sly-transition-all " style="background-image:url({{BackgroundImage}});"></div>				
      </div>
      <div class="sly-flex sly-flex-col sly-items-center sly-justify-center sly-text-center sly-mt-16">
        <div style="BS_Title" class="sly-text-md sly-font-semiboldsly- mb-0 sly-break-words">{{Title}}</div>	
        <p style="BS_SubTitle" class="sly-flex sly-justify-end sly-mt-0 sly-text-sm sly-text-gray-500 sly-break-words">{{SubTitle}}</p>			
      </div>
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Image BG",
        propsNames: ["backgroundcolor"],
        id: "BS_ImageBG",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 17",
    templateType: "SLYC",
    templateId: "NEW_CARD_17",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-17 sly-block sly-flex sly-flex-col sly-px-4  sly-py-6 sly-bg-white sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all  ">		
      <div class="sly-flex sly-items-start">			
        <div class="sly-mr-8 sly-flex-grow">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}} <span style="BS_SubTitle" class="ml-4 text-xs text-gray-500 uppercase sly-break-words">{{SubTitle}}</span>	</div>
          <p style="BS_Description" class="sly-mt-1 sly-text-sm sly-mb-3 sly-text-gray-600 sly-break-words">{{Description}}</p>		
                          
        </div>
        <div class="sly-w-24 sly-h-24 sly-inline-flex sly-items-center sly-justify-center sly-rounded-full">
          <div class="sly-w-24 sly-h-24 sly-mx-auto sly-rounded-full sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>	
        </div>			
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 18",
    templateType: "SLYC",
    templateId: "NEW_CARD_18",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t6 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-18 sly-block sly-flex sly-flex-col sly-px-4  sly-py-6 sly-bg-white sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all  ">		
      <div class="sly-flex sly-items-start">			
        <div class="sly-mr-8 sly-flex-grow">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}} <span style="BS_SubTitle" class="ml-4 text-xs text-gray-500 uppercase">{{SubTitle}}</span>	</div>
          <p style="BS_Description" class="sly-mt-1 sly-text-sm sly-mb-3 sly-text-gray-600 sly-break-words">{{Description}}</p>	                          
        </div>
        <div class="sly-w-24 sly-h-24 sly-inline-flex sly-items-center sly-justify-center sly-rounded-lg">
          <div class="sly-w-24 sly-h-24 sly-mx-auto sly-rounded-lg sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>	
        </div>			
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 18a",
    templateType: "SLYC",
    templateId: "NEW_CARD_18a",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="TC-18a sly-block sly-flex sly-flex-col sly-px-4  sly-py-6 sly-bg-white sly-rounded-lg sly-shadow-xl hover:sly-shadow-md sly-transition-all  " >		
      <div class="sly-flex sly-items-start">			
        <div style="BS_Content" class="sly-mr-8 sly-flex-grow">
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}} <span style="BS_SubTitle" class="ml-4 text-xs text-gray-500 uppercase">{{SubTitle}}</span>	</div>
          <p style="BS_Description" class="sly-mt-1 sly-text-sm sly-mb-3 sly-text-gray-600 sly-break-words">{{Description}}</p>		
          <div class="sly-mt-3 sly-flex sly-flex-wrap">	
            [REPEATER_START]
              {{ExtraCol1}}|<span style="BS_Extra" class="sly-block sly-px-3 sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]    
            [REPEATER_START]
              {{ExtraCol2}}|<span style="BS_Extra" class="sly-block sly-px-3  sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
            [REPEATER_END]  
          </div> 	                
        </div>
        <div class="sly-w-24 sly-inline-flex sly-items-center sly-justify-center sly-rounded-lg">
          <div class="sly-w-24 sly-mx-auto sly-rounded-lg sly-overflow-hidden">
            <img class="sly-w-full sly-h-auto" src="{{BackgroundImage}}"/>
          </div>	
        </div>			
      </div>		
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "Extra",
        propsNames: ["backgroundcolor", "color", "fontsize", "fontWeight"],
        id: "BS_Extra",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "New Card 19",
    templateType: "SLYC",
    templateId: "NEW_CARD_19",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5b md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a href="{{LinkTo}}" class="sly-block sly-bg-white sly-relative sly-shadow-xl hover:sly-shadow-md sly-text-left sly-transition-all sly-duration-500 sly-transform sly--skew-x-12 hover:sly--translate-x-2 hover:sly--translate-y-2" style="border-radius:2rem;">		
      <div class="sly-absolute sly-flex sly-w-full sly-h-full sly-overflow-hidden sly-rounded-full" style="border-radius:2rem;" >
        <div class="sly-relative sly-w-full sly-h-full sly-bg-cover" style="background-image:url({{BackgroundImage}});"></div>
        <div style="BS_BgColor" class="sly-absolute sly-top-0 sly-left-0 sly-w-full sly-h-full sly-bg-blue-600 sly-opacity-90" ></div>			
      </div>		
      <div class="sly-relative sly-flex sly-flex-col sly-justify-start sly-items-start sly-text-left sly-pl-12 sly-pr-8 sly-pb-12 sly-pt-12 sly-transform sly-skew-x-12 sly-text-white">
        <div style="BS_Title" class="sly-text-2xl sly-font-semibold sly-leading-tight sly-break-words">{{Title}}</div>			
        <p style="BS_Description" class="sly-mt-6 sly-text-md  sly-break-words">{{Description}}</p>
        <p style="BS_SubTitle" class="sly-mt-4 sly-text-sm sly-font-semibold sly-uppercase sly-pt-8  sly-break-words">{{SubTitle}}</p>				
      </div>	
    </a>
    `,
    stylesList: [
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "BG Color",
        propsNames: ["backgroundcolor"],
        id: "BS_BgColor",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    templateName: "Video Card 1",
    templateType: "SLYC",
    templateId: "Video_CARD_1as",
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 -t5 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-items-start sly-gap-4 sly-container sly-mx-auto sly-p-3",
    parentLayoutStyles: {
      "background-color": "",
    },
    templateHTML: `  
    <a style="BS_Wrap" href="{{LinkTo}}" class="sly-block sly-bg-white sly-overflow-hidden sly-relative sly-shadow-xl hover:sly-shadow-md sly-text-left sly-transition-all sly-duration-500 sly-transform  hover:sly--translate-x-2 hover:sly--translate-y-2" >		
      <div class="sly-text-center">
        <div style="BS_VideoWrap; padding-top:56.25%;" class="sly-overflow-hidden sly-relative" >
          <div class="sly-absolute sly-top-0 sly-left-0 sly-bottom-0 sly-right-0">
            <iframe id="player" type="text/html" width="100%" height="100%" src="{{YoutubeLink}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </div>
        </div>
        <div class="sly-p-4 sly-relative">          
          <div style="BS_Title" class="sly-text-xl sly-font-semibold sly-break-words">{{Title}}</div>
          <p style="BS_SubTitle" class="sly-mt-1 sly-mb-3 sly-text-sm sly-opacity-75 sly-break-words">W{{SubTitle}}</p>
          <p style="BS_Description" class="sly-mt-1 sly-text-xs markdown-card sly-break-words">{{Description}}</p>
          <div class="">
            <div class="sly-mt-3 sly-flex sly-flex-wrap">	
              [REPEATER_START]
                {{ExtraCol1}}|<span style="BS_Extra" class="sly-block sly-px-3 sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
              [REPEATER_END]    
              [REPEATER_START]
                {{ExtraCol2}}|<span style="BS_Extra" class="sly-block sly-px-3  sly-py-1 sly-m-2 sly-bg-gray-50 sly-transform sly-text-sm sly-text-gray-500 sly-text-center sly-rounded-xl" >PROP_ITEM_VALUE</span>
              [REPEATER_END]  
            </div> 	
          </div>
        </div>
      </div>	
    </a>
    `,
    stylesList: [
      {
        label: "Wrap",
        propsNames: ["backgroundcolor", "padding", "borderradius"],
        id: "BS_Wrap",
        styleProps: {},
      },
      {
        label: "Video Wrap",
        propsNames: ["borderradius"],
        id: "BS_VideoWrap",
        styleProps: {},
      },
      {
        label: "Title",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_Title",
        styleProps: {},
      },
      {
        label: "SubTitle",
        propsNames: ["color", "fontsize", "fontweight"],
        id: "BS_SubTitle",
        styleProps: {},
      },
      {
        label: "Description",
        propsNames: ["color", "fontsize", "fontWeight"],
        id: "BS_Description",
        styleProps: {},
      },
      {
        label: "Extra",
        propsNames: ["backgroundcolor", "color", "fontsize", "fontWeight"],
        id: "BS_Extra",
        styleProps: {},
      },
    ],
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
  },
  {
    stylesList: [],
    templateName: "Bring Your Card",
    templateType: "BYOC",
    templateId: "CUSTOM_BYOC",
    templateHTML: ``,
    filters: {
      showFilter: false,
      align: "top",
      categories: [],
    },
    parentLayoutCls:
      "sly-grid sly-grid-cols-1 md:sly-grid-cols-2 lg:sly-grid-cols-3 sly-justify-items-center sly-gap-4 sly-p-3",
  },
];
