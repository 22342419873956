export const processParamValues = (string, rxp = /{{([^}}]+)}}/g) => {
  var found = [],
    curMatch;

  while ((curMatch = rxp.exec(string))) {
    if (found.indexOf(curMatch[1]) === -1) {
      found.push(curMatch[1]);
    }
  }
  return found.map((item) => ({
    key: `{{${item}}}`,
    value: item,
    columnValue: "",
  }));
};

export const processBracesValues = (string) => {
  return processParamValues(string, /\[\[([^\]\]]+)\]\]/g).map((item) => ({
    ...item,
    key: item.key.replace("{{", "[[").replace("}}", "]]"),
  }));
};

export const detectGoogleSheetId = (sheet) => {
  if (sheet && sheet.indexOf("docs.google.com/spreadsheets/") !== -1) {
    return sheet.split("/d/")[1].split("/")[0];
  }
  return sheet !== "" ? sheet : "";
};

export const checkCustomDomainUrl = (cname) => {
  const hasDot = cname.split(".");
  if (hasDot.length === 1) {
    return true;
  }
  let isFail = true;
  hasDot.forEach((element, index) => {
    if (isFail) {
      const firstChar =
        element.length > 0 ? element.charAt(0) : element.charAt(0);
      const lastChar =
        element.length > 0
          ? element.charAt(element.length - 1)
          : element.charAt(0);

      isFail =
        /^([a-zA-Z0-9]+)/.test(firstChar) && /^([a-zA-Z0-9]+)/.test(lastChar);
    }
  });
  return !isFail;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};
