import { twdClass } from "../../../constants/tailwind.constants";
import CustomSelectBox from "../../common/CustomSelectBox";
import { useEffect, useState } from "react";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { find, cloneDeep, merge, set, findIndex } from "lodash";
import PagesGoogleParams from "./PagesGoogleParams";
import { processParamValues } from "../../../utils/common.utils";
//import { useSelector } from "react-redux";

export const PageSupabasePanel = ({
  settingsData,
  connections,
  updateSettingsData,
  pageInfo,
}) => {
  //const { pageInfo } = useSelector((state) => state.studioDetails);

  const connectOptions = connections
    .filter((itm) => itm.connection_type === "supabase")
    .map((itm) => ({
      key: itm.connection_id,
      value: itm.connection_details.displayName,
    }));

  let tableOptions = [];
  let columnsOptions = [];
  if (settingsData.connectionId) {
    const connection = find(connections, [
      "connection_id",
      settingsData.connectionId,
    ]);
    if (connection && connection.connection_details.tables) {
      tableOptions = connection.connection_details.tables.map(
        (item) => item.name
      );
      if (settingsData.connectionTable) {
        const cols = find(connection.connection_details.tables, [
          "name",
          settingsData.connectionTable,
        ]);
        columnsOptions = cols.columns.map((itm) => ({ key: itm, value: itm }));
      }
    }
  }

  const [paramsList, setParamsList] = useState([]);
  useEffect(() => {
    const pageBlocks = cloneDeep(pageInfo);
    pageBlocks.settings = {};
    const pageString = JSON.stringify(pageBlocks);
    const { paramsValues } = settingsData;
    let data = processParamValues(pageString);
    data = data.map((itm) => {
      const hasValue = find(paramsValues, ["replaceTo", itm.key]);
      if (hasValue) {
        set(itm, "columnValue", hasValue.columnValue);
      }
      return itm;
    });
    setParamsList(data);
  }, []);

  const updateParamsValues = (item) => {
    const pageBlocks = cloneDeep(pageInfo);
    pageBlocks.settings = {};
    const pageString = JSON.stringify(pageBlocks);
    let { paramsValues } = settingsData;
    const data = processParamValues(pageString);
    const index = findIndex(paramsValues, ["replaceTo", item.replaceTo]);
    if (index !== -1) {
      set(paramsValues, `[${index}].columnValue`, item.columnValue);
    } else {
      paramsValues.push(item);
    }
    paramsValues = paramsValues.filter((itm) => {
      return find(data, ["key", itm.replaceTo]);
    });
    updateSettingsData("paramsValues", paramsValues);
  };

  return (
    <div>
      <div
        className={twdClass("my-2 py-2 font-2xl font-semibold w-full border-t border-gray-100 ")}
      >
        Supabase Info
      </div>
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>Connections</label>
        <div className={twdClass("flex-1")}>
          <select
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {
              updateSettingsData("connectionId", e.target.value);
            }}
            value={
              settingsData && settingsData.connectionId
                ? settingsData.connectionId
                : ""
            }
          >
            <option value="">Select</option>
            {connectOptions.map((item) => (
              <option value={item.key}>{item.value}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-40 text-sm")}>Table Names</label>
        <div className={twdClass("flex-1")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={settingsData.connectionTable}
            onChange={(e) => {
              updateSettingsData("connectionTable", e.target.value);
            }}
          >
            <option value="">Select</option>
            {tableOptions.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={twdClass("flex items-start mb-2 border-t border-gray-100 pt-2")}>
        <label className={twdClass("w-40 pt-2 text-sm font-semibold")}>
          Params Map
        </label>
        <div className={twdClass("flex-1 mr-2 grid grid-cols-2 gap-4")}>
          <div className={twdClass("pt-2 text-sm")}>
            Slug Params
            <span className={twdClass("ml-2 text-xs text-gray-400")}>
              (Ex: /:id)
            </span>
          </div>
          <div>
            <CustomSelectBox
              value={settingsData.slugParamColumn}
              options={columnsOptions}
              updateValue={(value) => {
                updateSettingsData("slugParamColumn", value);
              }}
            />
          </div>
          {paramsList.map((item) => (
            <PagesGoogleParams
              item={item}
              sheetColumns={columnsOptions}
              updateParamsValues={updateParamsValues}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
