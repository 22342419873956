import { twdClass } from "../../../../constants/tailwind.constants";
import { useState, useEffect } from "react";

const TG_1 = twdClass("bg-gray-50 rounded-2xl border border-gray-100 hover:border-gray-300 px-1");
const TG_2 = twdClass("flex items-center cursor-pointer");
const TG_3 = `${twdClass("sr-only")} toggel-input`;
const TG_4 = twdClass("w-10 h-4 bg-gray-400 rounded-full shadow-inner");
const TG_5 = `dot ${twdClass(
  "absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
)}`;
const TG_6 = twdClass("m-3 text-gray-900 text-sm py-1 px-2 ");
const TG_7 = twdClass("m-3 text-gray-900 text-sm font-semibold bg-white rounded-full py-1 px-2 shadow-md");

const ToggleBtn = ({
  value,
  updateValue,
  falseLabel,
  trueLabel,
  toggleId = new Date().getTime(),
}) => {
  return (
    <div className={TG_1}>
      <label for={`toggle-${toggleId}`} className={TG_2}>
        <div className={value ? TG_6 : TG_7}>{falseLabel}</div>
        <div className={twdClass("relative")}>
          <input
            type="checkbox"
            id={`toggle-${toggleId}`}
            checked={value}
            className={TG_3}
            onChange={(e) => {
              const val = e.target.checked;
              console.log('change', val)
              updateValue(val);              
            }}
          />
          <div className={TG_4}></div>
          <div className={TG_5}></div>
        </div>
        <div className={value ? TG_7 : TG_6}>{trueLabel}</div>
      </label>
    </div>
  );
};

export default ToggleBtn;
