import { useRef } from "react";
import Dropdown from 'react-multilevel-dropdown';
import { BTN_NORMAL } from "../../constants/var.constants";
import { useAuth } from "../../contexts/Auth";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "./FaIcons";

const HelpMenu = ({studio=false}) => {
  let tc_= studio==true ? 'text-md' : 'text-lg'
  let c_= studio==true ? 'mt-2' : 'mt-4'
  

  return (
    <Dropdown
      openOnHover={false}
      menuClassName={twdClass(`${c_}`)}
      position="right"
      buttonVariant=""
      title={<div className={twdClass("flex items-center")}><span className={twdClass(`${tc_} mr-1`)}>Help</span><img alt="" width="24px" src="/assets/images/chevron-down.svg" /></div>}
    >
      <div
        onClick={() => {}}
      >
        <div className={twdClass(" text-md font-semibold")} style={{width:"380px"}}>
          <a target="_blank" href='https://help.flezr.com/how-to/add-custom-domain' 
            className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <span className={twdClass("mr-4 flex-grow")}>Add Custom Domain</span>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
          </a>
          <a target="_blank" href='https://help.flezr.com/building-your-app/making-responsive-design' 
            className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <span className={twdClass("mr-4 flex-grow")}>Making Responsive Design</span>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
          </a>
          <a target="_blank" href='https://help.flezr.com/building-your-app/connecting-data-source-google-sheets-supabase' 
            className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <span className={twdClass("mr-4 flex-grow")}>Connecting Data Source: Google Sheets/Supabase</span>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
          </a>
          <a target="_blank" href='https://help.flezr.com/building-your-app/customising-the-blocks-and-page-elements' 
            className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <span className={twdClass("mr-4 flex-grow")}>Customising the Blocks and Page Elements</span>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
          </a>                  
          <a target="_blank" href='https://help.flezr.com/how-to/how-to-create-cards-and-detailed-pages-from-google-form-submissions' 
            className={twdClass("flex items-center px-6 py-3 bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <span className={twdClass("mr-4 flex-grow")}>How to create Cards and Detailed Pages from Google Form submissions?</span>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
          </a>
        </div>
      </div>            
    </Dropdown>
  );
};

const LogoMenu = ({studio=false}) => {
  let tc_= studio==true ? 'text-md' : 'text-lg'
  let c_= studio==true ? 'mt-2' : 'mt-4'
  

  return (
    <Dropdown
      openOnHover={false}
      menuClassName={twdClass(`${c_}`)}
      position="right"
      buttonVariant=""
      title={<div className={twdClass("flex items-center")}><div className={twdClass("w-11 -mr-3")}><img src="/assets/images/Logo_Black_Outline.svg"/></div><img alt="" width="24px" src="/assets/images/chevron-down.svg" /></div>}
    >
      <div
        onClick={() => {}}
      >

        <Link to="/" className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200 ")}>                  
              <span className={twdClass("ml-4 flex-grow")}>Back to projects</span>                  
        </Link>
        <Link to="/pricing" className={twdClass("flex items-center px-6 py-3  bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>                  
              <span className={twdClass("ml-4 flex-grow")}>Pricing</span>                  
        </Link>
        {/*<Link to="/pricing" className={twdClass("flex items-center px-6 py-3  bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>                  
              <span className={twdClass("ml-4 flex-grow")}>Tutorials</span>                  
        </Link>*/}
        <a href="https://help.flezr.com/faq/is-there-a-white-label-option-for-flezr" target="_blank" className={twdClass("flex items-center px-6 py-3 bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>                  
              <span className={twdClass("ml-4 flex-grow")}>FAQ's'</span>                  
        </a>
      </div>            
    </Dropdown>
  );
};

const UserMenu = ({studio=false}) => {
  const { signOut } = useAuth();
  const history = useHistory();
  const { first_name, last_name, email } = useSelector(
    (state) => state.userdetails
  );
 
  let tc_= studio==true ? 'text-md' : 'text-lg'
  let c_= studio==true ? 'mt-2' : 'mt-4'  

  const handleSignOut = async () => {
    await signOut();
    history.push("/login");
  };
  return (
    <Dropdown      
      menuClassName={twdClass(studio==true ? 'mb-4' : 'mt-4')}
      position={ studio==true ? 'top-right' : 'left'}
      buttonClassName={'Dropdown_button_icon'}
      buttonVariant=""
      title={<div className={twdClass("px-2 py-2 rounded-full bg-white shadow-md hover:bg-gray-100 hover:shadow-lg active:shadow-sm")}><img alt="" width="24px" src="/assets/images/user-profile-02.svg" /></div>}
    >
      <div
        onClick={() => {}}
      >
        <div className={twdClass(" py-1 text-md font-semibold")} style={{width:"280px"}}>
          <Link to="/" className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <img alt="" width="24px" src="/assets/images/home-02.svg" />
            <span className={twdClass("ml-4 flex-grow")}>My Projects</span>                  
          </Link>
          {/*<div className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
            <span className={twdClass("ml-4 flex-grow")}>Settings</span>                  
          </div>
          <div className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
            <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
            <span className={twdClass("ml-4 flex-grow")}>Feedback</span>                  
          </div>*/}
          <div className={twdClass("flex justify-center pt-4")}>
            <div className={twdClass("flex items-center px-6 py-3 rounded-full cursor-pointer bg-white hover:bg-primary-100 active:bg-primary-200 shadow-md hover:shadow-lg active:shadow-sm")}  onClick={handleSignOut}>                  
              <span className={twdClass("mr-4")}>Sign out</span>  
              <img alt="" width="24px" src="/assets/images/logout-02.svg" />                
            </div>
          </div>
        </div>
      </div>            
    </Dropdown>
  );
};


export {
 HelpMenu,
 LogoMenu,
 UserMenu
};
