import { twdClass } from "../../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import { get } from "lodash";

export const RowPerColumns = ({ element, data, updateElementValue }) => {
  const [initvalues, setInitValues] = useState({
    desktop: 1,
    tab: 1,
    mobile: 1,
  });

  useEffect(() => {
    const updateToValue = get(data, element.updateTo);
    if (updateToValue) {
      Object.keys(updateToValue).forEach((item) => {
        if (updateToValue[item]) {
          setInitValues((con) => {
            return { ...con, [item]: updateToValue[item] };
          });
        }
      });
    }
  }, []);

  const updateElementPerRow = (key, value) => {
    updateElementValue(`${element.updateTo}.${key}`, value);
  };

  return (
    <div className={twdClass("justify-between border border-gray-100 hover:border-gray-200 px-1 py-1 rounded-2xl")}>
      <div className={twdClass("cursor-pointer p-2 text-sm font-semibold")}>
        Cards Per Row
      </div>
      <div className={twdClass("p-2")}>
        {Object.keys(initvalues).map((item) => {
          return (
            <>
              <div className={twdClass("flex items-center text-xs my-2")}>
                <label className={twdClass("whitespace-nowrap w-24 capitalize")}>
                  {item} view:
                </label>
                {[...Array(5)].map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={twdClass(
                        `hover:bg-gray-100 cursor-pointer px-2 mx-2 border rounded-md
                        ${+initvalues[item] === index + 1 ? "bg-primary-200 border-primary-500" : ""}
                        `
                      )}
                      onClick={() => {
                        setInitValues((con) => {
                          return { ...con, [item]: index + 1 };
                        });
                        updateElementPerRow([item], index + 1);
                      }}
                    >
                      {index + 1}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
