import { useDispatch } from "react-redux";
import { find } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import { UPDATE_ADVANCED_ID } from "../../../constants/action.constants";
import { BtnBack} from "../../ui/button_ui";

const AdvancedElemLabel = ({ sectionElem, advancedElementId }) => {
  const dispatch = useDispatch();
  //const { styleElements } = sectionElem;
 // const { label } = find(styleElements, ["styleElementId", advancedElementId]);

  return (
    <div className={twdClass("px-3 py-3 flex items-center")}>
      <BtnBack
        className={'mr-4'}
        onClick={() => {
          dispatch({ type: UPDATE_ADVANCED_ID, id: null });
        }}
      >        
      </BtnBack>
      <div className={twdClass("flex-grow")}>
        <div className={twdClass("text-gray-700 text-lg font-semibold")}>Section</div>
        <div className={twdClass("text-xs")}>Advanced Settings</div>
      </div>     
    </div>
  );
};

export default AdvancedElemLabel;
