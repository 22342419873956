const initialState = {
  email: null,
  first_name: null,
  last_name: null,
  max_cust_domain: 0,
  max_sub_domain: 0,
  plan_id: null,
};

export const userdetails = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LOGINUSER":
      return { ...state, ...action.data };
    default:
      return state;
  }
};
