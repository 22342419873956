import { useSelector, useDispatch } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "./FaIcons";
import { RESET_CONFRIM_POPUP } from "../../constants/action.constants";
import { BTN_CLS } from "../../constants/var.constants";
import { cloneDeep } from "lodash";

const MP_CLS_1 = twdClass(
  "fixed z-40 inset-0 flex items-center justify-center"
);
const MP_CLS_2 = twdClass("absolute z-40 inset-0 bg-gray-600 opacity-20");
const MP_CLS_3 = twdClass(
  "absolute z-50 border border-gray-200 shadow-lg px-6 py-3 bg-white"
);
const MP_CLS_4 = twdClass("text-xl font-semibold");
const MP_CLS_5 = twdClass(
  "flex items-center justify-between border-b-2 pb-4 mb-3"
);
const MP_CLS_BODY = twdClass("relative flex-auto p-4");
const MP_CLS_FOOTER = twdClass(
  "flex flex-wrap flex-shrink-0 items-center justify-end p-3"
);

const CofirmPopup = () => {
  const {
    confirmLabel = "",
    showConfirmPopup = false,
    confrimCallBack = null,
    cancelCallBack = null,
    action = null,
    selectProject,
    title,
  } = useSelector((state) => state.modalpopup);

  const data = cloneDeep(selectProject);
  // console.log(data);
  const dispatch = useDispatch();
  return (
    <div>
      {showConfirmPopup && (
        <div className={MP_CLS_1}>
          <div className={MP_CLS_2}></div>
          <div className={MP_CLS_3}>
            <div className={MP_CLS_5}>
              <h1 className={MP_CLS_4}>{title ? title : "Confirm"} </h1>
              <button
                onClick={() => {
                  dispatch({ type: RESET_CONFRIM_POPUP });
                }}
              >
                <FaIcons icon="cross" />
              </button>
            </div>
            <div className={MP_CLS_BODY}>{confirmLabel}</div>
            <div className={MP_CLS_FOOTER}>
              {action && <div className={twdClass('flex-grow')}>
                {action}
              </div>}
              <button
                type="button"
                className={twdClass(BTN_CLS)}
                onClick={() => {
                  if(cancelCallBack) cancelCallBack(data)
                  dispatch({ type: RESET_CONFRIM_POPUP });
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  confrimCallBack(data);
                }}
                className={twdClass(
                  `${BTN_CLS} ml-4 bg-blue-600 hover:bg-blue-800 text-white px-6`
                )}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CofirmPopup;
