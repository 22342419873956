import { find, set, replace } from "lodash";
import RenderElements from "../RenderElements";
import { twdClass } from "../../../constants/tailwind.constants";
import { useDispatch, useSelector} from "react-redux";
import { UPDATE_BLOCK_DATA } from "../../../constants/action.constants";
import { useEffect, useState, Fragment } from "react";



const DetailsElementsProps = ({
  sectionElem,
  styleElementId,
  sectionData,
  blocks,
  listIndex,
}) => {
  const { styleElements } = sectionElem;
  const [isStyleStates, setStyleStates] = useState(false);
  const [elementsProp, setElementProp] = useState([]);

  let {
    styleElementPart= ''
  } = useSelector((state) => state.studioDetails);

  useEffect(() => {
    let { elements, styleStates=false } = find(styleElements, ["styleElementId", styleElementId]);
    //console.log("styleStates", styleStates)
    elements = elements.map((item) => ({
      ...item,
      updateTo: replace(item.updateTo, "[i]", `[${listIndex}]`),
    }));
    setStyleStates(styleStates)
    setElementProp(elements);
  }, []);

  const { metaDataId } = sectionData;
  const dispatch = useDispatch();

  const updateElementValue = (to, value) => {
    let blockData = set(sectionData, to, value);
    blocks = blocks.map((item) => {
      if (item.metaDataId === metaDataId) {
        return { ...blockData };
      }
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks,
    });
  };

  let editallElement=elementsProp?.find((el)=>{
    return el.inputType=='editall' ? true : false
  })
  //console.log("elementsProp", elementsProp, editallElement)

  //console.log("elementsProp", isStyleStates, elementsProp)
  return (
    <div className={twdClass("px-3 py-3")}>
      {!styleElementPart && <div>
        {elementsProp.map((element, index) => (
          <Fragment key={index}>
            <div  className={twdClass("mb-1")}>
            <RenderElements            
              element={{...element, styleStates:isStyleStates}}
              data={sectionData}
              updateElementValue={updateElementValue}
            />
            </div>
          </Fragment>
        ))}  
      </div>}   
      {styleElementPart && <div>
        <Fragment>
          <div  className={twdClass("mb-1")}>
          <RenderElements            
            element={{...editallElement, styleStates:isStyleStates}}
            data={sectionData}
            updateElementValue={updateElementValue}
          />
          </div>
        </Fragment>
      </div>}   
    </div>
  );
};

export default DetailsElementsProps;
