const { REACT_APP_AGENCY_CODE, REACT_APP_API_BASE_URL } = process.env;

export const END_POINT_PATH = `${REACT_APP_API_BASE_URL}/${REACT_APP_AGENCY_CODE}`;
const BASE_PATH = `${END_POINT_PATH}/system`;
const CLIENT_PATH = `${END_POINT_PATH}/client`

export const DEBUG = `${BASE_PATH}/debug`;
export const LOGIN_USER = `${BASE_PATH}/user/info`;
export const CREATE_USER = `${BASE_PATH}/user/create`;
export const USER_PLAN = `${BASE_PATH}/user/plan`;
export const SAVE_PROJECT = `${BASE_PATH}/project/save`;
export const DUPLICATE_PROJECT = `${BASE_PATH}/project/duplicate`;
export const CREATE_PROJECT = `${BASE_PATH}/project/create`;
export const UPDATE_PROJECT = `${BASE_PATH}/project/update`;
export const PROJECTS_LIST = `${BASE_PATH}/projects`;
export const PROJECT_OPTS = `${BASE_PATH}/project`;
export const PROJECT_READ = `${BASE_PATH}/project/read`;
export const PROJECT_SITE_SETTINGS = `${BASE_PATH}/project/settings`;
export const GOOGLE_SHEET_INFO = `${BASE_PATH}/project/sheets`;
export const GOOGLE_SHEET_COLUMNS = `${BASE_PATH}/project/sheets/columns`;
export const ADD_CONNECTION = `${BASE_PATH}/project/connection`;
export const CONNECTIONS_LIST = `${BASE_PATH}/connections`;
export const DELETE_CONNECTION = `${BASE_PATH}/connection`;
export const DELETE_PAGE = `${BASE_PATH}/project/page`;
export const GET_ALL_PAGES = `${BASE_PATH}/project/pages`;
export const UPLOADTO_S3 = `${BASE_PATH}/image/uploader`;
export const CHECK_AVAILBILITY = `${BASE_PATH}/checkdomain`;
export const CHECK_GOOGLE_SHEET_ACCESS = `${BASE_PATH}/sheet/access`;
export const ADD_NEW_BLOCK = `${BASE_PATH}/project/section/add`;
export const SUPABASE_CONNECTION = `${BASE_PATH}/connections/supabase/columns`;
export const CREATE_TEMPLATES = `${BASE_PATH}/createtemplate`;
export const CREATE_PROJECT_FROM_TEMPLATE = `${BASE_PATH}/createprojectfromtemplate`;
export const GET_PROJECT_TEMPLATE_IDS = `${BASE_PATH}/getprojecttemplateids`;


export const GET_USER_CHECK = `${BASE_PATH}/user/getcheck`;
export const UPDATE_ONBOARDING = `${BASE_PATH}/user/onboarding`;

export const GENERATE_DESIGN = `${BASE_PATH}/project/page/generatedesign`;
export const PROMPT_CHATGPT_MINI = `${BASE_PATH}/project/page/promptChatGPTMini`;

export const GET_CARDS = `${CLIENT_PATH}/cards`;



