import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../constants/var.constants";
import  TestDesign  from "./TestDesign";
import styled from "styled-components";

const Test = (props) => {
  const [isThemeOpen, setThemeOpen] = useState(false)
  const dispatch = useDispatch();
  const {
    generated={},
    pageInfo,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);
  
  const {theme=false, navbar=false, header=false, cards=false} = generated 
  return (
    <div  className={twdClass("relative")} >
      <TestDesign {...props}/>     
    </div>
  );
};

export default Test
