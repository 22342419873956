import { twdClass } from "../../constants/tailwind.constants";
import RenderElements from "../form/RenderElements";
import { getDefaultFields } from "../../jsons/common/common.fields";
import { findIndex } from "lodash";
import { Button, BtnStyle, BtnBack } from "../ui/com_ui";




export const FilterStyles = ({
  setActivePanel,
  activeCatId,
  data,
  updateElementValue,
  activeElementId,
}) => {
  const {
    configs: {
      filters: { categories },
    },
  } = data;
  
  const indexCatgory = findIndex(categories, ["id", activeCatId]);
  // console.log(indexCatgory);

  const PROPS_ITEMS = {
    label:[
      ...getDefaultFields({
        type: "TEXT",
        updateTo: `configs.filters.categories[${indexCatgory}].styleProps`,
        fields: [
          "color",
          "fontsize",
          "fontfamily",
          "fontweight",
          "padding",
          "margin",
        ],
      })
    ],
    filterItems: [    
      ...getDefaultFields({
        type: "TEXT",
        //updateTo: `configs.searchConfig.elements[${indexCatgory}].styleProps`,
        updateTo: `configs.filters.categories[${indexCatgory}].styles.filterItems.styleProps`,
        fields: [  
          "color",
          "fontsize",
          "fontfamily",
          "fontweight",     
          "backgroundcolor",
          "borderwidth",
          "borderradius",
          "bordercolor",
          "padding",
          "margin",    
          'shadow',     
        ],
      }),
    ],  
  };

  const ELEMENTS = PROPS_ITEMS[activeElementId];
  const ELEMENTS2 = getDefaultFields({
    type: "TEXT",
    updateTo: `configs.filters.categories[${indexCatgory}].styleProps`,
    fields: [
      "color",
      "fontsize",
      "fontfamily",
      "fontweight",
      "padding",
      "margin",
    ],
  });
  return (
    <div>
      <div className={twdClass("px-2 py-5 flex items-center")}>
        {/*<div
          className={twdClass("cursor-pointer mr-4")}
          onClick={() => setActivePanel("filtersettings")}
        >
          <img alt="" src="/assets/images/icon_arrow_left.png" />
  </div>*/}
        <BtnBack
          className={"mr-4"}
          onClick={() => setActivePanel("filtersettings")}
        ></BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-sm")}>
            Filter <span className={twdClass("font-semibold ")}>{activeElementId}</span> styles 
          </div>
        </div>
      </div>
      <div className={twdClass("px-3 py-5")}>
        {ELEMENTS.map((item) => (
          <RenderElements
            element={item}
            data={data}
            updateElementValue={updateElementValue}
          />
        ))}
      </div>
    </div>
  );
};
