import Dropdown from "./Dropdown";
import { useState, useEffect } from "react";
import { get, replace } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import styled from "styled-components";

const CI_1 = twdClass("border-2 border-gray-300 hover:border-gray-500  pb-2 rounded-lg px-2");
const CI_1b = twdClass("border border-gray-100  hover:border-gray-300 rounded-lg px-2");
const CI_2 = twdClass("flex items-center py-2 cursor-pointer");
const CI_3 = twdClass("w-16 text-xs text-gray-900 font-semibold ");
const CI_4 = twdClass("flex-grow");
const CI_5 = twdClass("flex items-center");
const CI_6 = twdClass("w-8 h-4 mr-2 rounded-sm transform scale-125");

const CI_13 = twdClass("flex items-center relative pl-2 border border-gray-100 hover:border-gray-300 rounded-lg py-1 px-2 mt-1");

const CI_15 = twdClass(
  "rounded-md px-2 rounded-md border-2 border-transparent bg-white  appearance-none focus:border-blue-500 focus:outline-none"
);

const FontFamily = ({ element, data, updateElementValue }) => {
  const { label, hideLabel, updateTo } = element;
  const [expandCol, setExpandCol] = useState(false);
  const [dispValue, setDispValue] = useState("");
  const [custValue, setCustValue] = useState("");

  const updateToBk = replace(updateTo, "font-family", "font-family-bk");
  const updateToCust = replace(updateTo, "font-family", "font-family-cust");

  useEffect(() => {
    //console.log(updateTo);
    //console.log(data);
    const value = get(data, updateTo);
    const valueBk = get(data, updateToBk);
    const valueCust = get(data, updateToCust);
    //console.log(value);
    setDispValue(valueCust ? valueCust :  valueBk ? valueBk : value);
    setCustValue(valueCust);
  }, []);

  

  let value = get(data, updateTo);
  const valueBk = get(data, updateToBk);
  const valueCust = get(data, updateToCust);

  value = valueBk ? valueBk : value
  const updateValue = (value) => {    
    updateElementValue(updateToBk, value);
    updateElementValue(updateTo,  valueCust ? valueCust : value);    
    setDispValue(valueCust ? valueCust :  valueBk ? valueBk : value);
  };
  const updateCustValue = (v) => {    
    const fonts = get(data, 'fonts');
    console.log('fonts', fonts)
    let font = v
    //font = replace(v, "{{", "");
    //font = replace(font, "}}", "");
    let el = updateTo.split(".")  
    //let ele = replace(el[0], "[0]", ""); 
    //console.log('el', el)
    //v=processValue(v)
    updateElementValue(`fonts.${el[0]}`, font);   
    updateElementValue(updateToCust, v);
    updateElementValue(updateTo, processValue(v)); 
    setCustValue(v);
    setDispValue(v ? v :  valueBk ? valueBk : value);
  };

  const processValue = (v) => {    
    v=v
    .split(',')    
    .map((name) => {
      let test=name.trim();
      //name=name.replace(/\s+/g, ' ');
      let name_=name
      if(test && name.includes(' ') && test.split(' ')[1] && !name.includes("'")){
        name_=name.replace(/\s+/g, ' ').trim()
        name_=`'${name_}'`;
        console.log('SPACE',  name, name_)      
      }
      return name_
    })
    .join(',');
    return v
  };
  
  const imgSrc = `/assets/images/chevron-${expandCol ? "up" : "down"}.svg`;
  return (
    <>
      <div className={expandCol ? CI_1 : CI_1b}>
        <div className={CI_2} onClick={() => setExpandCol(!expandCol)}>
          {!hideLabel && <div className={CI_3}  >{label}</div>}
          <div className={CI_4}>
            <div className={CI_5}>
              <div className={twdClass("flex-grow text-xs font-semibold")}>{dispValue}</div>              
            </div>
          </div>
          <div
            className={twdClass("cursor-pointer")}
            onClick={() => setExpandCol(!expandCol)}
          >
            <img alt="" src={imgSrc} />
          </div>
        </div>
        <div className={`${!expandCol ? twdClass("hidden py-2") : "py-2"}`}>
          <Dropdown
            options={FONT_SIZE_OPTIONS}
            hideLabel={hideLabel}
            label={'Font Name'}
            value={value}
            updateValue={updateValue}
          />
          <div className={CI_13}>
            <div className={twdClass("text-xs opacity-50 mr-2")}>Custom Name</div>
            <input
              className={CI_15}
              value={custValue}
              onChange={(e) => {  
                updateCustValue(e.target.value)                
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FontFamily;

const BGDiv = styled.div`
  background-color: ${(props) => props.color};
`;


const FontList = [
  "'Abril Fatface', cursive",
  "'Allura', cursive",
  "'Anton', sans-serif",
  "'Fjalla One', sans-serif",
  "'Fredoka One', cursive",
  "'Heebo', sans-serif",
  "'Homemade Apple', cursive",
  "'Indie Flower', cursive",
  "'Julius Sans One', sans-serif",
  "'Lato', sans-serif",
  "'Lobster', cursive",
  "'Nunito', sans-serif",
  "'Nunito Sans', sans-serif",
  "'Open Sans', sans-serif",
  "'Oswald', sans-serif",
  "'Pacifico', cursive",
  "'Permanent Marker', cursive",
  "'PT Sans', sans-serif",
  "'Quicksand', sans-serif",
  "'Roboto Condensed', sans-serif",
  "'Roboto Mono', monospace",
  "'Rubik Mono One', sans-serif",
  "'Square Peg', cursive",
  "'Yanone Kaffeesatz', sans-serif",
];

const FONT_SIZE_OPTIONS = FontList.map((item) => {
  return {
    value: item,
    display: `<span style="font-family: ${item};">${replace(
      item,
      /'/g,
      ""
    )}</span>`,
  };
});

/* [
  { value: "inherit", display: `<span >Default</span>` },
  {
    value: "'Oswald', sans-serif",
    display: `<span style="font-family: 'Oswald', sans-serif;">Oswald</span>`,
  },
  {
    value: "'Roboto Mono', monospace",
    display: `<span style="font-family: 'Roboto Mono', monospace;">Roboto Mono</span>`,
  },
  {
    value: "'Yanone Kaffeesatz', sans-serif",
    display: `<span style="font-family: 'Yanone Kaffeesatz', sans-serif;">Yanone Kaffeesatz</span>`,
  },
  {
    value: "'Anton', sans-serif",
    display: `<span style="font-family: 'Anton', sans-serif;">Anton</span>`,
  },
  {
    value: "'Lobster', cursive",
    display: `<span style="font-family: 'Lobster', cursive;">Lobster</span>`,
  },
  {
    value: "'Fjalla One', sans-serif",
    display: `<span style="font-family: 'Fjalla One', sans-serif;">Fjalla One</span>`,
  },
  {
    value: "'Abril Fatface', cursive",
    display: `<span style="font-family: 'Abril Fatface', cursive;">Abril Fatface</span>`,
  },
]; */
