import { getTimeForUniqID, getTimestamp, getTemplateSample, getParams, styles_, links_} from "../transform/t_util";

export const pg_cards=({obj={}, cards_data})=>{
    obj.card_sample=JSON.parse(getTemplateSample({template:JSON.stringify(obj.card_template), data:cards_data[0],  captureFrom:"box_brackets"}))
    obj.card_items=[obj.card_sample, obj.card_sample]    
    if(obj.searchbar?.styleProps) {
        obj.searchbar.styleProps.display='flex'
        console.log("pg_cards searchBar", obj)
    }
    return obj
}
