import { useDispatch, useSelector} from "react-redux";
import { find } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import { UPDATE_STYLE_ID, UPDATE_STYLE_PART } from "../../../constants/action.constants";
import { StyleBackBtn, EditBtn} from "../../ui/presets";

const DetailsElemLabel = ({ sectionElem, styleElementId }) => {
  const dispatch = useDispatch();
  const { styleElements } = sectionElem;
  const sE = find(styleElements, ["styleElementId", styleElementId])
  const {elements, label } = sE ? sE : "Sd";
  //console.log("styleElementId", styleElementId)

  let {
    styleElementPart= ''
  } = useSelector((state) => state.studioDetails);  

  let editallElement=elements?.find((el)=>{
    return el.inputType=='editall' ? true : false
  })

  return (
    <div className={twdClass("px-3 py-3 flex items-center")}>
      <StyleBackBtn
        className={'mr-4'}
        onClick={() => {
          dispatch({ type: UPDATE_STYLE_ID, id: null });
          dispatch({ type: UPDATE_STYLE_PART, part: null });
        }}
      >        
      </StyleBackBtn>
      <div className={twdClass("flex-grow")}>
        <div className={twdClass("text-gray-700 text-lg font-semibold")}>{label}</div>
        <div className={twdClass("text-xs")}>Style</div>
      </div>      
      {editallElement && <EditBtn
        className={'ml-4'}
        onClick={() => {
          dispatch({ type: UPDATE_STYLE_PART, part: styleElementPart ? '' : 'editall' });
        }}
      >      
        {styleElementPart ? 'Close' : 'Edit All '} 
      </EditBtn>}
      {/*<div className={twdClass("flex items-center")}>
        <div className={twdClass("ml-4 cursor-pointer")}>
          <img alt="" src="/assets/images/icon_copy.png?v=1" />
        </div>
        <div className={twdClass("ml-4 cursor-pointer")}>
          <img alt="" src="/assets/images/icon_reset.png?v=1" />
        </div>
      </div>*/}
    </div>
  );
};

export default DetailsElemLabel;
