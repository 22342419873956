import { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { twdClass } from "../constants/tailwind.constants";
import { INPUT_TEXT, BTN_NORMAL, BTN_CLS } from "../constants/var.constants";
import {Button} from "../components/ui/com_ui";
import {Layout, Error} from "./Layout";
import styles from "./login.module.css";

export function ForgotPassword() {
  const emailRef = useRef();

  const { resetPassowrd } = useAuth();

  const history = useHistory();
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    // Get email and password input values
    const email = emailRef.current.value;
    let origin = window ? window.location.origin : ''
    let redirectTo = (origin && origin.search("localhost") )? origin+'/update-password' : ''
    //let redirectTo = window.location.replace(/forgot/g, "update");
    //console.log("window.location", window.location.origin);
    
    // Calls `resetPassword` function from the context
    const { data, error } = await resetPassowrd({email, redirectTo});
    //console.log("data", data);
    //console.log("redirectTo", redirectTo);
    //console.log(error);
    if (error) {
      setError(error.message);
    } else {
      // Redirect user to Dashboard
      //history.push("/");
      setSent(true)
    }
  }


  useEffect(() => {
    if(error){
      setTimeout(()=>{
        setError("")
      }, 2000)
    }
  }, [error]);

  const LoginLink = (<Link to="/login" className={twdClass("text-gray-700 font-semibold")}>Login</Link>)
  const ResendLink = (<Button onClick={()=> setSent(false)} type={"defualt"} color={"primary"} size="lg">Resend</Button>)
  return (
    <Layout
      title={sent ? "Reset email sent" : "Forgot Password"}
      desc={sent ? "An Email has been sent to your email address. and click the reset password link provided." : "Enter your email address to receive a reset password link."}
      action={LoginLink}
    >
      {!sent && 
        <form onSubmit={handleSubmit}>       
          <input
            id="input-email"
            type="email"
            ref={emailRef}
            className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
            placeholder={"Email"}
          />

          <button
            type="submit"
            className={twdClass("block w-full")}          
          >
            <Button type={"defualt"} color={"primary"} size="lg">Send</Button>
          </button>        

          <Error error={error}/>
        </form> 
      } 
      {sent && <div>
        {ResendLink}
      </div>}   
    </Layout>
  );
}
