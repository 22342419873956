import { twdClass } from "../../constants/tailwind.constants";
import RenderElements from "../form/RenderElements";
import { getDefaultFields } from "../../jsons/common/common.fields";
import { findIndex } from "lodash";
import { Button, BtnStyle, BtnBack } from "../ui/com_ui";

const PROPS_ITEMS = {
  searchContainer: [    
    ...getDefaultFields({
      type: "TEXT",
      //updateTo: `configs.searchConfig.elements[${indexCatgory}].styleProps`,
      updateTo: `configs.searchConfig.searchContainer.styleProps`,
      fields: [       
        "backgroundcolor",
        "borderwidth",
        "borderradius",
        "bordercolor",
        "padding",
        "margin",
        "width-percent",
      ],
    }),
  ], 
  input: [
    ...getDefaultFields({
      type: "TEXT",
      //updateTo: `configs.searchConfig.elements[${indexCatgory}].styleProps`,
      updateTo: `configs.searchConfig.input.styleProps`,
      fields: [
        "color",
        "backgroundcolor",
        "fontsize",
        "fontfamily",
        "fontweight",
        "borderwidth",
        "borderradius",
        "bordercolor",
        "padding",
        "margin",
        "width-percent",
      ],
    }),
  ], 
  searchButton: [
    ...getDefaultFields({
      type: "TEXT",
      //updateTo: `configs.searchConfig.elements[${indexCatgory}].styleProps`,
      updateTo: `configs.searchConfig.searchButton.styleProps`,
      fields: [
        "color",
        "backgroundcolor",
        "fontsize",
        "fontfamily",
        "fontweight",
        "borderwidth",
        "borderradius",
        "bordercolor",
        "padding",
        "margin",
        "width-percent",
      ],
    }),
  ], 
  clearButton: [
    ...getDefaultFields({
      type: "TEXT",
      //updateTo: `configs.searchConfig.elements[${indexCatgory}].styleProps`,
      updateTo: `configs.searchConfig.clearButton.styleProps`,
      fields: [
        "color",
        "backgroundcolor",
        "fontsize",
        "fontfamily",
        "fontweight",
        "borderwidth",
        "borderradius",
        "bordercolor",
        "padding",
        "margin",
        "width-percent",
      ],
    }),
  ], 
};

export const SearchStyles = ({
  setActivePanel,
  activeElementId,
  data,
  updateElementValue,
}) => {
  const {
    configs: {
      searchConfig,
    },
  } = data;

  //const activeElement = find(searchConfig, ["id", activeElementId]);
  //const indexOf = findIndex(searchConfig, ["id", activeElementId]);

  const ELEMENTS = PROPS_ITEMS[activeElementId];

  return (
    <div>
      <div className={twdClass("px-2 py-5 flex items-center")}>       
        <BtnBack
          className={"mr-4"}
          onClick={() => setActivePanel("filtersettings")}
        ></BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-sm")}>
            Search <span className={twdClass("font-semibold ")}>{activeElementId}</span> styles
          </div>
        </div>
      </div>
      <div className={twdClass("px-3 py-5")}>
        {ELEMENTS.map((item) => (
          <RenderElements
            element={item}
            data={data}
            updateElementValue={updateElementValue}
          />
        ))}
      </div>
    </div>
  );
};
