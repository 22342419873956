import { get } from "lodash";
import { useState, useEffect } from "react";
import { twdClass } from "../../../constants/tailwind.constants";

const TextareaInput = ({ element, data, updateElementValue, inputArgs }) => {
  if (!inputArgs) inputArgs = { action: "", inputStyle: 1 };
  const [value, setValue] = useState();
  const { updateTo, defaultValue, label } = element;
  const { action, display_label, display_row=false, inputStyle } = inputArgs;

  const [isFocus, setFocus] = useState(0);

  useEffect(() => {
    const dataValue = get(data, updateTo);
    const value = dataValue ? dataValue : defaultValue;
    setValue(value);
  }, [get(data, updateTo)]);

  let CL_WRAP = display_row ? ` flex flex-row ` : ` flex flex-col `;
  CL_WRAP += isFocus
    ? " border-2 border-gray-500"
    : " border-2 border-gray-100 hover:border-gray-200 ";
  CL_WRAP += inputStyle == 2 ? " p-1" : " ";

  let CL_LABEL_WRAP = `flex items-center`;
  let CL_INPUT_wrap= display_row ? " flex-grow  " : "  ";
  let CL_INPUT =
    " text-sm block w-full py-2 px-1 text-gray-700 leading-tight focus:outline-none bg-gray-50  active:bg-gray-200";
  let CL_INPUT2 = isFocus ? " bg-gray-200  " : " hover:bg-gray-100  ";  
  let CL_LABEL = " pb-1 px-1 flex-grow";
  CL_LABEL += isFocus ? " text-gray-900 " : " text-gray-900 ";
  CL_LABEL += display_row ? " w-20" : " ";
  let CL_ACTION = " text-sm";

  return (
    <div className={twdClass(`${CL_WRAP} rounded-lg w-full`)}>
      <div className={twdClass(`${CL_LABEL_WRAP}`)}>
        <div className={twdClass(`${CL_LABEL} font-semibold text-xs`)}>
          {display_label && label}
        </div>
        <div className={twdClass(CL_ACTION)}>{action && action}</div>
      </div>
      <div  className={twdClass(`${CL_INPUT_wrap}`)}>
        <textarea
          className={twdClass(`w-full ${CL_INPUT} ${CL_INPUT2} rounded-md`)}
          value={value}     
          onFocus={(e) => {
            setFocus(1);
          }}
          onBlur={(e) => {
            setFocus(0);
          }}     
          onChange={(e) => {
            setValue(e.target.value);
            updateElementValue(updateTo, e.target.value);
          }}
          
        ></textarea>
      </div>
    </div>    
  );
};

export default TextareaInput;
