import { useState, useEffect } from "react";
import { get, clone } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import { AddItemBtn, DeleteItemBtn} from "../../ui/presets";
import FaIcons from "../../common/FaIcons";
import { useDispatch } from "react-redux";
import {
  UPDATE_LIST_INDEX,
  UPDATE_STYLE_ID,
} from "../../../constants/action.constants";

const SimpleList = ({ element, data, updateElementValue, inputArgs }) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let listValues = get(data, element.updateTo);
    listValues = listValues ? listValues : element.defaultValue;
    if (listValues && listValues.length > 0) {
      setList(listValues);
    }
  }, []);

  const updateListValue = (index, value) => {
    setList((state) => {
      state[index] = value;
      return [...state];
    });
  };

  useEffect(() => {
    updateElementValue(element.updateTo, list);
  }, [list]);

  const addNewElement = () => {
    const value = element.defaultValue ? element.defaultValue : [];
    updateListValue(list.length, value);
  };

  const removeElment = (index) => {
    setList((state) => {
      const cList = clone(state);
      cList.splice(index, 1);
      return cList;
    });
  };
  return (
    <div  className={twdClass("border border-transparent  px-1 py-1 rounded-lg hover:border-gray-500")}>
      <div
        className={twdClass(
          "w-full text-sm text-left flex justify-between items-center px-1 py-1 mb-2 "
        )}
      >
        <span className={twdClass("text-xs font-semibold")}>{element.label}</span>
        <span>
          {/*<span onClick={() => addNewElement()} className={twdClass("mr-2")}>
            <FaIcons icon="plus" />
          </span>*/}
          <div className={twdClass(``)}>
            <AddItemBtn type={'icon'} cType={""} size={'xs'} onClick={() => {addNewElement()}} />
          </div> 
          {element && element.showListStyleProps && (
            <span
              className={twdClass("")}
              onClick={() => {
                if (inputArgs && inputArgs.listIndex !== undefined) {
                  dispatch({
                    type: UPDATE_LIST_INDEX,
                    id: inputArgs.listIndex,
                  });
                  dispatch({
                    type: UPDATE_STYLE_ID,
                    id: element.styleElementId,
                  });
                }
              }}
            >
              <FaIcons icon="arrowRight" />
            </span>            
          )}
        </span>
      </div>           
      {list.map((item, index) => {
        return (
          <div className={twdClass("flex items-center")}>
            <div className={twdClass(" mb-1 mr-1 w-full")}>
              <input
                className={twdClass(
                  "w-full text-sm block w-full py-1 px-2 bg-gray-50  hover:border-gray-300 hover:bg-gray-100 focus:bg-gray-200 border rounded-md  text-gray-700 leading-tight focus:outline-none"
                )}
                value={item}
                onChange={(e) => {
                  updateListValue(index, e.target.value);
                }}
              />
            </div>
            <div className={twdClass("opacity-25 hover:opacity-100")}>
              <DeleteItemBtn 
                onClick={() => removeElment(index)}
              />
            </div>
            {/*<span
              className={twdClass(
                "p-2 text-xs text-red-300 hover:text-red-400 flex items-center cursor-pointer"
              )}
              onClick={() => removeElment(index)}
            >
              <FaIcons icon="trashalt" />
            </span>*/}
          </div>
        );
      })}
    </div>
  );
};

export default SimpleList;
