import { get, isString, isEmpty } from "lodash";
import { React, useState, useEffect } from "react";
import { twdClass } from "../../../constants/tailwind.constants";
import ReactQuill from "react-quill";
import QuillToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";


const RichTextEditor = ({ element, data, updateElementValue }) => {
    const [state, setState] = useState({ value: null });
    const { updateTo, defaultValue } = element;
    useEffect(() => {
        const dataValue = get(data, updateTo);
        const value = dataValue ? dataValue : defaultValue;
        setState({value});
    }, []);
    const handleChange = value => {
        setState({ value });
        updateElementValue(updateTo, value);
    };
  
    return (
        <div className={twdClass("w-full")}>
            <QuillToolbar />
            <ReactQuill
                theme="snow"
                value={state.value}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
        </div>
    );
};

export default RichTextEditor;
