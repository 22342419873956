import { twdClass } from "../../constants/tailwind.constants";
import ToggleBtn from "../common/ToggleBtn";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { Button, BtnStyle, BtnBack } from "../ui/com_ui";
import { SearchBarPanel } from "./SearchBarPanel";




export const FilterSettings = ({
  data,
  updateElementValue,
  setActivePanel,
  columnNames,
  setactiveCatId,
  setActiveElementId,
  currentConectType,
}) => {
  const { configs } = data;
  return (
    <div>
      <div className={twdClass("px-2 py-5 flex items-center")}>
        <BtnBack
          className={"mr-4"}
          onClick={() => setActivePanel("main")}
        ></BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-gray-800 text-sm font-semibold")}>
            Filter & Search settings
          </div>
        </div>
      </div>
      <div className={twdClass("px-3 pt-1 pb-1 ")}>        
        <SearchBarPanel
          updateElementValue={updateElementValue}
          configs={configs}
          columnNames={columnNames}
          setActivePanel={setActivePanel}
          setActiveElementId={setActiveElementId}
          currentConectType={currentConectType}
        />
        <div
          className={twdClass(
            "flex items-center justify-between mb-1 border border-gray-100 hover:border-gray-200 rounded-xl px-2 py-2"
          )}
        >
          <label className={twdClass("text-sm mr-2")}>Filter Panel:</label>
          <ToggleBtn
            toggleId={"showfilter"}
            value={configs.filters.showFilter}
            falseLabel={`Hide`}
            trueLabel="Show"
            updateValue={(value) => {
              //console.log("showfilter", value);
              updateElementValue("configs.filters.showFilter", !value);
            }}
          />
        </div>
        <div
          className={twdClass(
            "flex items-center justify-between mb-1 border border-gray-100 hover:border-gray-200 rounded-xl px-2 py-2"
          )}
        >
          <label className={twdClass("text-sm mr-2")}>Position:</label>
          <div className={twdClass("w-36")}>
            <select
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                updateElementValue("configs.filters.align", e.target.value);
              }}
              value={configs.filters.align}
            >
              <option value="top">Top</option>
              <option value="left">Left</option>
              <option value="right">Right</option>
            </select>
          </div>
        </div>

        
      </div>
      <FilterCategories
        updateElementValue={updateElementValue}
        configs={configs}
        columnNames={columnNames}
        setActivePanel={setActivePanel}
        setactiveCatId={setactiveCatId}
        currentConectType={currentConectType}
        setActiveElementId={setActiveElementId}
      />
    </div>
  );
};

const FilterCategories = ({
  updateElementValue,
  configs,
  columnNames,
  setActivePanel,
  setactiveCatId,
  setActiveElementId,
  currentConectType,
}) => {
  const addNewCategory = () => {
    const data = cloneDeep(configs.filters.categories);
    data.push({
      label: "Category Name",
      styleProps: {
        color: "#ffffff",
        "font-size": "1.5rem",
        "line-height": "2rem",
        padding: "20px 0px 10px 0px",
      },
      id: `c_${new Date().getTime()}`,
      multiselect: false,
      filterItems: ["Filter Tag 1", "Filter Tag 2", "Filter Tag 3"],
      columns: [],
    });
    updateElementValue("configs.filters.categories", data);
  };
  const updateCategories = (id) => {
    const data = cloneDeep(configs.filters.categories);
    const value = data.filter((item) => item.id !== id);
    updateElementValue("configs.filters.categories", value);
  };
  return (
    <div className={twdClass("px-3 ")}>
      <div
        className={twdClass(
          "flex flex-col justify-between border-gray-100 border border-gray-100 hover:border-gray-200 rounded-xl px-1 py-1"
        )}
      >
        <div
          className={twdClass("flex items-center justify-between px-1 py-1")}
        >
          <span className={twdClass("text-sm")}>Categories</span>
          <span>
            {/* <FaIcons icon="plus" /> */}
            <Button
              type={"default"}
              cType={"iconText"}
              iconImg={"plus-01-w.svg"}
              color={"black"}
              className={"whitespace-nowrap"}
              size={"sm"}
              onClick={() => addNewCategory()}
            >
              Add Category
            </Button>
          </span>
        </div>
        {configs.filters.categories.map((item, index) => (
          <FilterCategory
            updateElementValue={updateElementValue}
            data={item}
            index={index}
            columnNames={columnNames}
            updateCategories={updateCategories}
            setActivePanel={setActivePanel}
            setactiveCatId={setactiveCatId}
            currentConectType={currentConectType}
            setActiveElementId={setActiveElementId}
          />
        ))}
      </div>
    </div>
  );
};

const FilterCategory = ({
  updateElementValue,
  data,
  index,
  columnNames,
  updateCategories,
  setActivePanel,
  setactiveCatId,
  currentConectType,
  setActiveElementId
}) => {
  const [expand, setExpand] = useState(true);
  return (
    <div
      className={twdClass(
        "mt-2 bg-gray-50 border-gray-100 border border-gray-100 hover:border-gray-200 rounded-xl px-1 py-1"
      )}
    >
      <div className={twdClass("py-2 pr-1 clear-both flex items-center ")}>
        <span
          className={twdClass(
            "flex items-center justify-center h-6 w-6 cursor-pointer"
          )}
          onClick={() => setExpand(!expand)}
        >
          <FaIcons
            icon={`${expand ? "angleUp" : "angleDown"}`}
            className={twdClass("text-sm")}
          />
        </span>
        <span
          className={twdClass("text-sm font-semibold cursor-pointer flex-1")}
          onClick={() => setExpand(!expand)}
        >
          {data.label ? data.label : `Category ${index + 1}`}
        </span>
        <span className={twdClass("flex")}>
          <Button
            type={"icon"}
            iconImg={"trash-04-red.svg"}
            className={"bg-red-100 p-2"}
            size="sm"
            onClick={() => {
              updateCategories(data.id);
              // updateElementValue("configs.filters.categories");
            }}
          ></Button>
        </span>
      </div>
      {expand && (
        <div className={twdClass("p-2 bg-white rounded-md")}>
          <div
            className={twdClass("items-center justify-between clear-both mb-1")}
          >
            <div className={twdClass("flex justify-between items-center mb-1")}>
              <label className={twdClass("text-sm font-semibold w-36")}>
                Label:
              </label>
              <BtnStyle
                onClick={() => {
                  console.log("data", data)
                  setactiveCatId(data.id);
                  setActiveElementId('label');
                  setActivePanel("filterstyles");
                }}
              />
              {/*<span
                onClick={() => {
                  setactiveCatId(data.id);
                  setActivePanel("filterstyles");
                }}
                className={twdClass("cursor-pointer")}
              >
                <img alt="" src="/assets/images/icon_arrow_right.png" />
              </span>*/}
            </div>
            <input
              className={twdClass(INPUT_TEXT)}
              value={data.label}
              onChange={(e) => {
                updateElementValue(
                  `configs.filters.categories[${index}].label`,
                  e.target.value
                );
              }}
            />
          </div>
          <div className={twdClass("flex items-center justify-between mb-1")}>
            <label className={twdClass("text-sm font-semibold")}>Select:</label>
            <ToggleBtn
              toggleId={`filter_${data.id}`}
              value={data.multiselect ? data.multiselect : false}
              falseLabel="Single"
              trueLabel="Multi"
              updateValue={(value) => {
                console.log("Filter_1", value);
                updateElementValue(
                  `configs.filters.categories[${index}].multiselect`,
                  !value
                );
              }}
            />
          </div>
          <div className={twdClass("mb-2")}>
            <div className={twdClass("flex justify-between items-center mb-1")}>
              <label className={twdClass("text-sm font-semibold")}>
                Filter values{" "}
                <span className={twdClass("text-xs font-normal")}>
                  (comma separated)
                </span>
                :
              </label>
              <BtnStyle
                onClick={() => {
                  setactiveCatId(data.id);
                  setActiveElementId('filterItems');
                  setActivePanel("filterstyles");
                }}
              />
            </div>
            <textarea
              className={twdClass(INPUT_TEXT)}
              rows="3"
              value={data.filterItems.join(",")}
              onChange={(e) => {
                const items = e.target.value.split(",");
                updateElementValue(
                  `configs.filters.categories[${index}].filterItems`,
                  items
                );
              }}
            ></textarea>
          </div>
          <div>
            <label className={twdClass("text-sm font-semibold")}>
              Column Names{" "}
              <span className={twdClass("text-xs font-normal")}>
                (Click on select)
              </span>
              :
            </label>
            <div>
              {columnNames &&
                columnNames.map((item) => {
                  const selectd = data.columns.indexOf(item.key) !== -1;
                  return (
                    <button
                      className={twdClass(
                        `m-px px-1 text-xs border rounded-md border-primary-200 ${
                          selectd ? "bg-primary-500" : "bg-primary-200"
                        }`
                      )}
                      onClick={() => {
                        // const hasItem = data.columns.indexOf(item.key) !== -1;
                        let values = data.columns;
                        if (currentConectType === "supabase") {
                          values = [item.key];
                        } else {
                          if (selectd) {
                            values = data.columns.filter(
                              (itm) => itm !== item.key
                            );
                          } else {
                            values.push(item.key);
                          }
                        }
                        updateElementValue(
                          `configs.filters.categories[${index}].columns`,
                          values
                        );
                      }}
                    >
                      {item.value}
                      <span className={twdClass("w-3 inline-block ml-1")}>
                        {selectd && (
                          <FaIcons
                            icon="cross"
                            className={twdClass("font-thin")}
                          />
                        )}
                      </span>
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
