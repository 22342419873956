import { twdClass } from "../../constants/tailwind.constants";
import Select from "react-select";
import { INPUT_TEXT } from "../../constants/var.constants";

export const OpenLinkIn = ({
  columnNames = [],
  section,
  updateValues,
  updateStylePanel,
  popup_option=false
}) => {
  const optionList = columnNames.map((itm) => ({
    value: itm.key,
    label: itm.value,
  }));
  const updateOpenWith = (key, val) => {
    const data = section.openParams ? section.openParams : {};
    updateValues(section.id, { openParams: { ...data, [key]: val } });
  };
  return (
    <div>
      <div className={twdClass("w-36 text-sm mb-2")}>Open Link:</div>
      <div className={twdClass("items-start mb-4")}>
        <div className={twdClass("flex grid grid-cols-2 gap-4 w-full text-xs")}>
          <select
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {
              updateOpenWith("type", e.target.value);
            }}
          >
            <option value="sametab">Same Tab</option>
            <option value="newtab">New Tab</option>
            {popup_option && <option value="popup">Popup</option>}
          </select>
          <Select
            name="image-element"
            defaultValue={optionList.filter(
              (itm) => itm.value === section?.openParams?.column
            )}
            options={optionList}
            onChange={(value) => {
              updateOpenWith("column", value.value);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius:'20px',                
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
