const d3=`
    {
        "logo":{
            "text":"",
            "styleProps":{}
        },
        "links":[
            {
                "text":"",
                "styleProps":{}
            }
        ],
        "styleProps":{},  
    }

`

export const navbar_prompt=({opts={}, sdata=false, sample=false})=>{
    if(!sdata) sdata={
        logo:{text:"No-Code Experts"},
        links:[
            {text:"Home"},
            {text:"About"},
            {text:"Services"},
            {text:"Get Started"}
        ]
    }
    if(!sample){
        sample=d3
    }
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'
    
    //console.log("navbar_prompt", opts, sdata)
    
    return(`
    
    'SECTION_DATA'=${JSON.stringify(sdata)}    

    'JSON_INPUT'={
        "navbar":${sample}
    }
    
    'WEBSITE_FOR' = "${opts.websiteFor}"
    
    Create Website navbar in JSON for [WEBSITE_FOR]   
    
    Use 'SECTION_DATA' for 'navbar' Content  
    
    JSON output schema sholud be [JSON_INPUT]
    
    Give me the output in JSON format and it should be valid JSON data
    
    Just give me the JSON data
    
    `)
}
    

const schema={
    "name": "navbar_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": {    
            "logo": {
                "$ref": "#/$defs/logo_obj"
            },        
            "links": {
                "type":"array",
                "description" : "website links",
                "items":{
                    "$ref": "#/$defs/link_obj"
                }                
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_obj"}                     
        },  
        "required": [           
            "logo",
            "links",
            "styleProps",
            "stylePropsMob",
            "stylePropsTab"          
        ],
        "additionalProperties": false,
        "$defs": {
            "logo_obj":{
                "type": "object",
                "description": "Logo", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "link_obj":{
                "type": "object",
                "description": "Logo", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "url": { 
                        "type": "string",
                        "description": "link url"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "url",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "text_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "button_obj":{
                "type": "object",
                "description": "button", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}             
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },            
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                            
        }
    }
}
export const navbar_format = {
    "type":"json_schema",
    "json_schema": schema
}