import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RIGHT_PANEL_TOGGEL } from "../../constants/action.constants";
import { twdClass } from "../../constants/tailwind.constants";
import { Button } from "./button_ui";

const StyleBtn = (props) => { 
  let init={type:'icon', color:'primary', iconImg:'color-swatch-01.svg', size:'xs', ...props} 
  return (
    <Button  {...init}/>
  );
};
const SortBtn = (props) => { 
  let init={type:'icon', color:'white', iconImg:'Sort.svg', size:'xs', ...props} 
  return (
    <Button  {...init}/>
  );
};
const SettingsBtn = (props) => { 
  let init={type:'icon', color:'white', iconImg:'settings.svg', size:'xs', ...props} 
  return (
    <Button  {...init}/>
  );
};
const CollapseBtn = ({isOpen, ...props}) => { 
  let init={type:'icon', color:'transparent', iconImg:isOpen ? 'chevron-up.svg': 'chevron-down.svg', size:'md', ...props} 
  return (
    <Button  {...init}/>
  );
};
const EditBtn = ({children, ...props}) => { 
  let init={type:'default', cType:'iconText', color:'white', iconImg:'pencil-01.svg', size:'sm', className:'inline-block', ...props} 
  return (
    <Button  {...init}>{children}</Button>
  );
};
const AddItemBtn = (props) => { 
  let init={type:'default', cType:'iconText', color:'black', iconImg:'plus-01-w.svg', size:'sm', className:'inline-block', ...props} 
  return (
    <Button  {...init}>Add Item</Button>
  );
};
const DeleteItemBtn = (props) => { 
  let init={type:'icon', color:'white', iconImg:'trash-04-red.svg', size:'xs', className:'bg-red-100', ...props} 
  return (
    <Button  {...init}></Button>
  );
};
const StyleBackBtn = (props) => { 
  let init={type:'icon', color:'primary', iconImg:'arrow-left.svg', size:'sm', ...props} 
  return (
    <Button  {...init}></Button>
  );
};
const CloseBtn = (props) => { 
  let init={type:'icon', color:'white', iconImg:'x-02.svg', size:'md', ...props} 
  return (
    <Button  {...init}></Button>
  );
};



export {
  SettingsBtn,
  StyleBtn,
  CollapseBtn,
  AddItemBtn,
  DeleteItemBtn,
  StyleBackBtn,
  EditBtn,
  CloseBtn,
  SortBtn
}
