import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { useState, useEffect } from "react";
import { cloneDeep, set, isEmpty, get } from "lodash";
import S3Uploader from "../../form/elements/S3Uploader";
import {
  StyleBtn,
  CollapseBtn,
  AddItemBtn,
  DeleteItemBtn,
  SettingsBtn,
} from "../../ui/presets";

const activeCls = "border-b-2 font-semibold border-gray-600";

const FONTWEIGHTS = [
  { name:'400', label:'400 : Normal'},
  { name:'100', label:'100 : Thin'},
  { name:'200', label:'200 : Extra Thin'},
  { name:'300', label:'300 : Light'},
  { name:'500', label:'500 : Medium'},
  { name:'600', label:'600 : Semi Bold'},
  { name:'700', label:'700 : Bold'},
  { name:'800', label:'800 : Extra Bold'},
  { name:'900', label:'900 : Black'},
]  

const FONTTYPES = [
  { name:'opentype', label:'opentype (otf)'},
  { name:'truetype', label:'truetype (ttf)'}, 
]  

const FontUrls = ({ sitesettings, updateSettings }) => {
  let fontUrls = sitesettings.fontUrls ? sitesettings.fontUrls :[{name:'', url:''}]
  const [listElements, setListElements] = useState(fontUrls);
  const updateTo='fontUrls'
  //const { elements } = isFontUrls 

  useEffect(() => {
    const ss = cloneDeep(sitesettings);
    let data = set(ss, updateTo,  listElements);      
    updateSettings(data);
  }, [JSON.stringify(listElements)]);

  const addElement = () => {
    setListElements((state) => {      
      const updatedElms = [...state, {name:'', url:''}]
      return updatedElms;
    });
  };
  const removeElement = (index) => {
    setListElements((state) => {      
      const updatedElms = [...state]
      //const lst = get(data, listElement.updateTo);
      //console.log("delete", listElement.updateTo, lst)
      const updatedElms_ = updatedElms.filter((_, i) => i !== index);
      return updatedElms_;
    });
  };
  let Item=({item, index})=>{
    //const indexStr=`${updateTo}.[${index}]`
    const indexStr=`[${index}]`
    const updateField = (key, value) => {
      let keyStr=`${indexStr}.${key}`     
      const le = cloneDeep(listElements);
      let data = set(le, keyStr,  value);      
      //updateSettings(data);
      setListElements(data)
      //console.log("updateTo",  indexStr, keyStr, data)
    };
    return(
      <div className={twdClass('border border-gray-200 hover:border-gray-300 rounded-2xl hover:bg-gray-50 mb-2 px-4 py-4 ')}>
        <div className={twdClass("w-full flex items-center mb-2")}>
          <Valid {...item} {...{key:'type', value:item.type}}/>
          <div className={twdClass("w-1/4 text-sm ")}>Font Type:</div>
          <div className={twdClass("w-3/4")}>
            <select
              value={item.type}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {               
                updateField("type", e.target.value);
              }}
              
            >             
              <option value="">Select</option>
              {FONTTYPES.map((i)=>{
                return(<option value={i.name}>{i.label}</option>)
              })}                
            </select>            
          </div>
        </div>
        <div className={twdClass("w-full flex items-center mb-2")}>
          <Valid {...item} {...{key:'name', value:item.name}}/>
          <div className={twdClass("w-1/4 text-sm ")}>Font Name:</div>
          <div className={twdClass("w-3/4")}>
            <input
              type="text"
              value={item.name}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {              
                updateField("name", e.target.value);
              }}
            />
          </div>
        </div>
        <div className={twdClass("w-full flex items-center mb-2")}>
          <Valid {...item} {...{key:'url', value:item.url}}/>
          <div className={twdClass("w-1/4 text-sm ")}>Font Url:</div>
          <div className={twdClass("w-3/4")}>
            <textarea
              rows={4}
              type="text"
              value={item.url}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {               
                updateField("url", e.target.value);
              }}
            />
          </div>
        </div>        
        <div className={twdClass("w-full flex items-center mb-2")}>
          <Valid {...item} {...{key:'weight', value:item.weight}}/>
          <div className={twdClass("w-1/4 text-sm ")}>Font Weight:</div>
          <div className={twdClass("w-3/4")}>
            <select
              value={item.weight}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {               
                updateField("weight", e.target.value);
              }}
              
            >
              <option value="">Select</option>
              {FONTWEIGHTS.map((i)=>{
                return(<option value={i.name}>{i.label}</option>)
              })}                
            </select>            
          </div>
        </div>        
        <div className={twdClass("flex")}><DeleteItemBtn onClick={() => removeElement(index)} /></div>
      </div>
    )
  }
  let ListElements_=listElements.map((item, index) => {    
    return <div  className={twdClass('w-full mb-2')} key={index}>{Item({item, index})}</div>;          
  })
  return(<div className={twdClass("w-full flex flex-col mb-4")}>
    {/*<textarea
      rows={10}
      value={sitesettings.fontUrls}
      placeholder="http://domain-name.com/fonts/open-sans.otf"
      className={twdClass(`${INPUT_TEXT} w-full`)}
      onChange={(e) => updateFontUrls(e.target.value)}
></textarea>*/}
    <div className={twdClass(`mb-2`)}><AddItemBtn onClick={addElement}/></div>   
    <div className={twdClass('flex flex-col overflow-auto')} style={{maxHeight:'400px'}}>{ListElements_}</div>    
  </div>)

}
const Valid = ({key, name, value}) => {
  let ret_=(value) ? <img src="/assets/images/check-green.svg"/> : '' 
  return (<div className={twdClass('w-8')}>{ret_}</div>)
}
const FontsSettings = ({ sitesettings, updateSettings }) => {
  const [activeTab, setActiveTab] = useState("google-fonts");
 

  const processGoogleFonts = (data, value) => {
    let fonts=value.split(",")
    const fonts2 = fonts.map((itm) => {
      itm=itm.trim().replace(new RegExp(" ", "g"), '+')
      itm="family="+itm
      return itm
    }).join("&");
    
    return set(data, "googleFonts_processed", fonts2);   
  }
  
  const updateGoogleFonts = (value) => {
    const sst = cloneDeep(sitesettings);
    let data = set(sst, 'googleFonts',  value);
    data = processGoogleFonts(data, value);
    updateSettings(data);
  };

  

  const googleFonts = twdClass(`mr-4 py-2 ${activeTab === "google-fonts" ? activeCls : ""}`);
  const fontUrls = twdClass(`mr-4 py-2 ${activeTab === "font-urls" ? activeCls : ""}`); 
  
  

  return (
    <div className={twdClass("w-full ")}>
      <div className={twdClass("inline-block text-sm font-semibold mb-4 bg-gray-100 rounded-xl px-4")}>
        <button className={googleFonts} onClick={() => setActiveTab("google-fonts")}>
          Google Fonts
        </button>
        <button className={fontUrls} onClick={() => setActiveTab("font-urls")}>
          Font Urls
        </button>        
      </div>

      {activeTab === "google-fonts" && (
        <div className={twdClass("w-full flex items-start mb-4")}>          
          <textarea
            rows={10}
            value={sitesettings.googleFonts}
            placeholder="Ubuntu, Roboto Mono"
            className={twdClass(`${INPUT_TEXT} w-full`)}
            onChange={(e) => updateGoogleFonts( e.target.value)}
          ></textarea>
        </div>
      )}
      {activeTab === "font-urls" && (
        <FontUrls {...{ sitesettings, updateSettings }}/>
      )}      
    </div>
  );
};

export default FontsSettings;
