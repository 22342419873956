import { twdClass } from "../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import { INPUT_TEXT } from "../../constants/var.constants";
import { SLY_CARDS_LIST } from "../../jsons/cards/cards.details";
import { useSelector, useDispatch } from "react-redux";
import { find, merge, cloneDeep, set, isEmpty } from "lodash";
import { UPDATE_BLOCK_DATA } from "../../constants/action.constants";
import { DynamicCardsPanel } from "../form/custom";
import { StaticCardsPanel } from "./StaticCardsPanel";
import { CardStyleElements } from "./CardStyleElements";
import { BYOCPanel } from "./BYOCPanel";
import { CardContainerStyles } from "./CardContainerStyles";
import { FilterSettings } from "./FilterSettings";
import { GeneralSettings } from "./GeneralSettings";
import { FilterStyles } from "./FilterStyles";
import { HeadingText } from "./HeadingText";

export const GenericCardsPanel = () => {
  const [activePanel, setActivePanel] = useState("main");
  const [columnNames, setColumnNames] = useState([]);
  const [activeCatId, setactiveCatId] = useState("");
  const {
    selectedBlock,
    pageInfo: { blocks },
  } = useSelector((state) => state.studioDetails);
  const dispatch = useDispatch();
  const currentBlock = find(blocks, ["metaDataId", selectedBlock.metaDataId]);
  const [savedCard, setSavedCard] = useState(currentBlock);

  const templateUpdate = (value, reFrom) => {
    const temp =
      savedCard.configs && savedCard.configs.templateId === value
        ? savedCard.configs
        : find(SLY_CARDS_LIST, ["templateId", value]);

    //console.log(temp);

    let block = cloneDeep(currentBlock);
    if (!isEmpty(reFrom) && temp.templateId === "CUSTOM_BYOC") {
      if (block.configs.templateHTML) {
        temp.templateHTML = block.configs.templateHTML;
      }
      if (block.configs.externalCSS) {
        temp.externalCSS = block.configs.externalCSS;
      }
    }

    block.configs.stylesList = [];
    block.configs.filters = {
      showFilter: false,
      align: "top",
      categories: [],
    };

    block.configs = merge(block.configs, temp);
    block.configs.data = temp.data;

    const mBlocks = blocks.map((item) => {
      if (item.metaDataId === block.metaDataId) {
        return { ...block };
      }
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: mBlocks,
    });
  };

  useEffect(() => {
    setSavedCard(currentBlock);
    templateUpdate(selectedBlock.configs.templateId, "INIT_RENDER");
  }, []);

  const updateElementValue = (key, value) => {
    // let blk = cloneDeep(currentBlock);
    const blk = set(currentBlock, key, value);
    const mBlocks = blocks.map((item) => {
      if (item.metaDataId === blk.metaDataId) {
        return { ...blk };
      }
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: mBlocks,
    });
  };

  return (
    <>
      {activePanel === "main" && (
        <>
          <div className={twdClass("px-6 py-5 flex items-center")}>
            <div className={twdClass("flex-grow")}>
              <div className={twdClass("text-blue-500 text-sm")}>
                Cards Section
              </div>
              <div className={twdClass("text-xs")}>Section</div>
            </div>
            <div
              className={twdClass("cursor-pointer")}
              onClick={() => setActivePanel("styles")}
            >
              <img alt="" src="/assets/images/icon_arrow_right.png" />
            </div>
          </div>
          {!isEmpty(currentBlock) && (
            <CardMainPanel
              templateUpdate={templateUpdate}
              updateElementValue={updateElementValue}
              currentBlock={currentBlock}
              setActivePanel={setActivePanel}
              setColumnNames={setColumnNames}
            />
          )}
        </>
      )}
      {activePanel === "styles" && (
        <>
          <div className={twdClass("px-6 py-5 flex items-center")}>
            <div
              className={twdClass("cursor-pointer mr-4")}
              onClick={() => setActivePanel("main")}
            >
              <img alt="" src="/assets/images/icon_arrow_left.png" />
            </div>
            <div className={twdClass("flex-grow")}>
              <div className={twdClass("text-blue-500 text-sm")}>
                Card Styles
              </div>
            </div>
          </div>
          {!isEmpty(currentBlock) && (
            <>
              <CardContainerStyles
                updateElementValue={updateElementValue}
                currentBlock={currentBlock}
              />
              <CardStyleElements
                updateElementValue={updateElementValue}
                currentBlock={currentBlock}
              />
            </>
          )}
        </>
      )}
      {activePanel === "generalsettings" && !isEmpty(currentBlock) && (
        <>
          <GeneralSettings
            updateElementValue={updateElementValue}
            data={currentBlock}
            setActivePanel={setActivePanel}
            columnNames={columnNames}
            setactiveCatId={setactiveCatId}
          />
        </>
      )}
      {activePanel === "filtersettings" && !isEmpty(currentBlock) && (
        <>
          <FilterSettings
            updateElementValue={updateElementValue}
            data={currentBlock}
            setActivePanel={setActivePanel}
            columnNames={columnNames}
            setactiveCatId={setactiveCatId}
          />
        </>
      )}
      {activePanel === "filterstyles" && !isEmpty(currentBlock) && (
        <FilterStyles
          setActivePanel={setActivePanel}
          activeCatId={activeCatId}
          data={currentBlock}
          updateElementValue={updateElementValue}
        />
      )}
      {activePanel === "headingtext" && !isEmpty(currentBlock) && (
        <HeadingText
          updateElementValue={updateElementValue}
          data={currentBlock}
          setActivePanel={setActivePanel}
        />
      )}
    </>
  );
};

const CardMainPanel = ({
  currentBlock,
  templateUpdate,
  updateElementValue,
  setActivePanel,
  setColumnNames,
}) => {
  return (
    <div className={twdClass("px-6 py-5")}>
      <div
        className={twdClass(
          "text-sm font-semibold bg-gray-50 p-2 border rounded-md shadow-sm mb-3 cursor-pointer"
        )}
        onClick={() => setActivePanel("headingtext")}
      >
        <span className={twdClass("flex items-center justify-between")}>
          <span>Configure Headings</span>
          <span>
            <img alt="" src="/assets/images/icon_arrow_right.png" />
          </span>
        </span>
      </div>
      <div className={twdClass("flex items-center w-full")}>
        <div className={twdClass("w-48")}>Templates</div>
        <select
          value={
            currentBlock.configs.templateId
              ? currentBlock.configs.templateId
              : ""
          }
          className={twdClass(INPUT_TEXT)}
          onChange={(e) => templateUpdate(e.target.value)}
        >
          {SLY_CARDS_LIST.map((item) => (
            <option value={item.templateId}>{item.templateName}</option>
          ))}
        </select>
      </div>
      <div className={twdClass("flex items-center w-full mt-2")}>
        <div className={twdClass("w-48")}>Data Type</div>
        <select
          className={twdClass(INPUT_TEXT)}
          onChange={(e) => {
            updateElementValue("configs.dataType", e.target.value);
          }}
          value={currentBlock.configs.dataType}
        >
          <option value="static">Static</option>
          <option value="dynamic">Dynamic</option>
        </select>
      </div>

      {currentBlock.configs.templateId === "CUSTOM_BYOC" && (
        <BYOCPanel
          updateElementValue={updateElementValue}
          currentBlock={currentBlock}
        />
      )}
      {currentBlock.configs.dataType === "static" && (
        <StaticCardsPanel
          data={currentBlock}
          updateElementValue={updateElementValue}
        />
      )}
      {currentBlock.configs.dataType === "dynamic" && (
        <DynamicCardsPanel
          data={currentBlock}
          updateElementValue={updateElementValue}
          setActivePanel={setActivePanel}
          updateColumnNames={setColumnNames}
        />
      )}
    </div>
  );
};
