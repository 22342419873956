import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";
import ToggleBtn from "./ui/ToggleBtn";
import {Button} from "../../ui/com_ui";
import { useState, useEffect } from "react";


const Advanced = ({ sitesettings, updateSettings }) => {
  const {isEnable, password='', user='site' } = sitesettings.passwordProtected ? sitesettings.passwordProtected : {}
  const [isViewPW, setViewPW] = useState(false)
  const updateItem = (value) => {
   // const sst = cloneDeep(sitesettings);
   // const data = set(sst, `manifest.${field}`, value);
    //updateSettings(data);
  };
  const updateField = (field, value) => {    
    const sst = cloneDeep(sitesettings);
    const data = set(sst, `passwordProtected.${field}`, value);
    //console.log("field", field, value, data)
    updateSettings(data);
  };

  return (
    <div>
      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("mb-4")}>
          <p className={twdClass("text-md font-semibold")}>Advanced Settings</p>
          <p className={twdClass("text-sm text-gray-400 lg:pr-24")}></p>
        </div>  
      </div>
      {/*<div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md font-semibold mb-4")}>Register Script</div>
        <textarea
          rows={10}
          value={sitesettings.serviceWorker.register_script}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateField('register_script', e.target.value)}
          placeholder = {`{ \n\t"background_color": "purple",\n\t"display": "fullscreen"\n}`}
        ></textarea>
  </div>*/}


      <div className={twdClass("w-full mb-4 border border-gray-200 px-2 py-2 rounded-xl")}>
        <div className={twdClass("text-sm font-semibold mb-4")}>Password Protected</div>

        <div className={twdClass("w-full flex items-center mb-2")}>
          <div className={twdClass("w-1/4 text-sm ")}>Enable</div>
          <div className={twdClass("w-3/4")}>
            <ToggleBtn
              toggleId={"passwordProtected"}
              value={isEnable}              
              falseLabel={`Off`}
              trueLabel="On"
              updateValue={(value) => {
                console.log("isENable", value)
                updateField("isEnable", value);
              }}
            />  
          </div>
        </div>
        <div className={twdClass("w-full flex items-center mb-2")}>
          <div className={twdClass("w-1/4 text-sm ")}>User</div>
          <div className={twdClass("w-3/4")}>
            <input
              type="text"
              disabled={true}
              value={user}
              className={twdClass(INPUT_TEXT)}
              placeholder='admin'
              onChange={(e) => {
                //updateSettings("title", e.target.value);
                updateField("user", e.target.value);
              }}
            />
          </div>
        </div>
        <div className={twdClass("w-full flex items-center mb-2")}>
          <div className={twdClass("w-1/4 text-sm ")}>Password</div>
          <div className={twdClass("w-3/4 flex justify-end items-center")}>
            <input
              type={isViewPW ? "text" : "password"}
              value={password}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                //updateSettings("title", e.target.value);
                updateField("password", e.target.value);
              }}
            />
            
            <Button                
              type={"default"} color={"white"} className={"inline-block ml-2"} size={'sm'}
              onClick={(e) => {                
                setViewPW(!isViewPW);
              }} 
            >
              View                
            </Button>  
          </div>          
        </div>       
        {/*<textarea
          rows={10}
          value={sitesettings?.serviceWorker?.script}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateField('script', e.target.value)}
          placeholder = {`self.addEventListener('install', event => { \n\t event.waitUntil( \n\t\t ... \n\t); \n });`}
        ></textarea>*/}
      </div>      
    </div> 
  );
};

export default Advanced;

