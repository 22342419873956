
const d3=`
    {
        "position":"",
        "heading":{
            "text":"",
            "styleProps":{}
        },
        "description":{
            "text":"",
            "styleProps":{}
        },
        "styleProps":{},  
    }

`

export const headerDyn_prompt=({opts={}, sdata=false, data=false, sample=false})=>{
    if(!sdata) sdata={
        heading:"New Openings for {{Title}}",
        description:"Job Location: {{Location}}",
    }
    if(!data) {
        data=[
            {"Title":"Generative AI Developer", 'Location':'Remote'},
            {"Title":"UI Developer", 'Location':'Remote'},
        ]
    }
    if(!sample){
        sample=d3
    }
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'
    
    //console.log("header_prompt", opts, sdata)
    
    return(`
    
    'SECTION_DATA'=${JSON.stringify(sdata)}    

    'JSON_INPUT'={
        "header":${sample}
    }

    Create Website details page 'imageElement' section 
    
    Use [SECTION_DATA] data for 'header' Content   

    JSON output schema sholud be [JSON_INPUT]
    
    Give me the output in JSON format and it should be valid JSON data
    
    Just give me the JSON data
    
    `)
}
    

const schema={
    "name": "headerDyn_response",
    "strict": false,
    "schema": {
        "type": "object",        
        "properties": { 
            "position": {
                "type": "string",
                "enum": ["left", "right", "center"]
            },           
            "heading": {
                "$ref": "#/$defs/text_obj"
            },
            "description": {
                "$ref": "#/$defs/text_obj"
            },           
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_obj"}                    
        },  
        "required": [ 
            "position",          
            "heading",
            "description",
            "styleProps"       
        ],
        "additionalProperties": false,
        "$defs": {
            "text_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "Text contains dynamic variables in double flower brackets {{}}"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },       
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                         
        }
    }
}
export const headerDyn_format = {
    "type":"json_schema",
    "json_schema": schema
}