import { getDefaultFields } from "../common/common.fields";

export const FEATURES_FORM_2 = {
  sectionName: "Header Section",
  elements: [
    {
      inputType: "list",
      label: "Heading",
      hasStyleProps: false,
      updateTo: "headings",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 1,
        elements: [
          {
            inputType: "textarea",
            label: "Headings",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            updateTo: "headings[i].text",
            extraPropUpdateTo: "headings[i]",
            extraPropValues: {
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              styleProps: {
                "text-align": "center",
                "font-size": "1.125rem",
                "line-height": "1.75rem",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Features",
      hasStyleProps: true,
      styleElementId: 2,
      updateTo: "list",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 3,
        elements: [
          {
            inputType: "image",
            label: "Image",
            defaultValue:
              "https://d11lx8wl9i3fvs.cloudfront.net/1637339991272/1670751794116.png",
            updateTo: "list[i].imageSrc",
          },
          {
            inputType: "text",
            updateTo: "list[i].imageAlt",
            label: "Alt",
            defaultValue:"",
            hasStyleProps: false
          },
          {
            inputType: "textarea",
            label: "Header Text",
            defaultValue: "Lorem Ipsum is simply dummy text industry",
            updateTo: "list[i].headerText",
          },
          {
            inputType: "textarea",
            label: "Header Text",
            defaultValue:
              "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with",
            updateTo: "list[i].bodyText",
          },
          {
            inputType: "textarea",
            label: "Header Text",
            defaultValue: "Lorem Ipsum is simply dummy text industry",
            updateTo: "list[i].footerText",
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Features Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Heading",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "headings[i].styleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Section",
      elements: [
        { inputType: "section", label: "Section" },
        {
          inputType: "columns",
          label: "Columns",
          updateTo: "sectionPerRow",
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "sectionConfigs.sectionStyleProps",
          fields: [
            "backgroundcolor",
            "backgroundimage",
            "borderwidth",
            "borderradius",
            "bordercolor",
            "padding",
            "margin",
          ],
        }),
        { inputType: "section", label: "Image Section Configurations" },
        {
          inputType: "select",
          label: "Postions",
          updateTo: "sectionConfigs.imagePosition",
          options: [
            {
              value: "top",
              display: "Top",
            },
            {
              value: "left",
              display: "Left",
            },
            {
              value: "right",
              display: "Right",
            },
            {
              value: "bottom",
              display: "Bottom",
            },
          ],
        },
        {
          inputType: "select",
          label: "Aligment",
          updateTo: "sectionConfigs.imageAlign",
          options: [
            {
              value: "start",
              display: "Start",
            },
            {
              value: "center",
              display: "Center",
            },
            {
              value: "end",
              display: "End",
            },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "sectionConfigs.imageSectionStyleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Image/Text",
      elements: [
        { inputType: "section", label: "Image Configurations" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "sectionConfigs.imageStyleProps",
          fields: [
            "backgroundcolor",
            "borderwidth",
            "borderradius",
            "bordercolor",
            "padding",
            "margin",
            "width2px",
          ],
        }),
        { inputType: "section", label: "Header Text" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "sectionConfigs.headerTextStyleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
        { inputType: "section", label: "Body Text" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "sectionConfigs.bodyTextStyleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
        { inputType: "section", label: "Footer Text" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "sectionConfigs.footerTextStyleProps",
          fields: [
            "color",
            "fontsize",
            "fontfamily",
            "textalign",
            "fontweight",
            "padding",
            "margin",
          ],
        }),
      ],
    },
  ],
};
