const d3=`
    {        
        "buttons":{
            "list":[
                {
                    "text":"Email",
                    "url":"mailto:{{email}}",
                    "styleProps":{}
                },
                {
                    "text":"Skype",
                    "url":"skype:{{skype}}?chat",
                    "styleProps":{}
                },
                {
                    "text":"Explore properties",
                    "url":"{{details_link}}",
                    "styleProps":{}
                }
            ],
            "styleProps":{
                "padding":"20px"
            },  
        },
        "styleProps":{
            "padding":"20px"
        },  
    }

`

export const buttonElement_prompt=({opts={}, sdata=false, data=false, sample=false})=>{
    let pagePrompt=''
    if(!sdata) sdata={        
        buttons:[
            {"text":"Email", "url":"mailto:{{email}}"},
            {"text":"Skype", "url":"skype:{{skype}}?chat"},
            {"text":"Explore properties", "url":"{{details_link}}"}
        ]
    }

    if(!sample){
        sample=d3
    }
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'


    //console.log("textElement_prompt", opts, sdata)
    
    return(`
    
    'SECTION_DATA'=${JSON.stringify(sdata)}   

    'JSON_INPUT'={
        "buttonElement":${sample}
    }
    
    'WEBSITE_FOR' = "${opts.websiteFor}"
    
    Create Website details page for 'buttons' 
    
    Use [SECTION_DATA]->buttons data for 'buttons' Content  
    
    JSON output schema sholud be [JSON_INPUT]
    
    Give me the output in JSON format and it should be valid JSON data
    
    Just give me the JSON data
    
    `)
}
    

const schema={
    "name": "buttonElement_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": { 
            "buttons": {
                "type":"object",
                "description": "", 
                "properties":{
                    "list": {
                        "type":"array",
                        "description": "", 
                        "items":{
                            "$ref": "#/$defs/button_obj"
                        }                
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}        
                },
                "required": [ 
                    "list",
                    "styleProps",
                    "stylePropsMob",
                    "stylePropsTab"          
                ],                   
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_obj"}                     
        },  
        "required": [ 
            "buttons",
            "styleProps",
            "stylePropsMob",
            "stylePropsTab"          
        ],
        "additionalProperties": false,
        "$defs": {           
            "button_obj":{
                "type": "object",
                "description": "button", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "url": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "url",
                    "styleProps"
                ],
                "additionalProperties": false
            },           
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                            
        }
    }
}
export const buttonElement_format = {
    "type":"json_schema",
    "json_schema": schema
}