import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "./Common";
import {header_prompt, header_format} from "../prompts/header_prompt";

const Text = ({textEl}) => {
  const {text}= textEl
  return(<DivTag {...textEl}>{text}</DivTag>)
}

const Button = ({buttonEl}) => {
  const {text}= buttonEl
  return(<DivTag {...buttonEl}>{text}</DivTag>)
}

const Design2 = ({obj={}, opts={}, sample='', sdata={}}) => {  
  let user_prompt = header_prompt({opts, sample, sdata }) 
  return (<Design {...{type:'header', obj, user_prompt, format:header_format}}/>)
}

const Header = ({header={}, opts={}, design=false, sample='', sdata={}}) => {
  let obj=header.header ? header.header : header

  const {position='left', heading={}, description={}, typewriterText={}, button={}, helptext={}, ...obj1} = obj
 
  let c='w-full ', c2='w-full', c3=''
  if(position=='center'){
    c+=' md:w-11/12 lg:w-10/12 mx-auto'
    c2+=' hidden '
    c3+=' justify-center'
  }else if(position=='right'){
    c+=' md:w-8/12 lg:w-6/12 md:order-1'
    c2+=' md:w-4/12 lg:w-6/12 md:order-0'
    c3+=' justify-end'
  }else if(position=='left'){
    c+=' md:w-8/12 lg:w-6/12 md:order-0'
    c2+=' md:w-4/12 lg:w-6/12 md:order-1'
    c3+=' justify-start'
  }
  return(
    <DivTag {...obj1} className={twdClass("relative mb-4 px-4 py-2 ")}>
        {design && <Design2 {...{ design, obj, opts, sample, sdata}}/>}
        <div className={twdClass("relative mx-auto ")} style={{'max-width':'1536px'}}>
          <div className={twdClass(`flex flex-wrap`)}>
            <div className={twdClass(`w-full ${c}`)}>
              <Text {...{textEl:heading}}/>
              <Text {...{textEl:typewriterText}}/>
              <Text {...{textEl:description}}/>
              <div className={twdClass(`flex ${c3}`)}><Button {...{buttonEl:button}} /></div>
              <Text {...{textEl:helptext}}/>           
            </div>
            <div className={twdClass(`w-full ${c2}`)}></div> 
          </div>           
        </div>      
    </DivTag>
  )
}
export  default Header