import axios from "axios";
import { getUUID } from "../contexts/Auth";
import { showLoading } from "./../components/common/LoadSpinner";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    showLoading(true);
    config = {
      ...config,
      headers: {
        ...config.headers,
        UUID: getUUID(),
      },
    };
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    showLoading(false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    showLoading(false);
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    showLoading(false);
    return Promise.reject(error);
  }
);
