let sd01={ 
  list:[
    {"image":"{{image}}", "image_sample":"https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},    
  ]
}
let a01={
  imageElement:{ 
    images:{
      image:'{{image}}',
      image_sample:'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      styleProps:{       
        "width": "100%",
        "margin": "0px autopx 0px autopx",
        "background-color": "#ffffff",
        "padding": "50px 50px 50px 50px"
      }
    },    
    styleProps:{
      "padding": "50px 20px",      
      "display": "block",
      "width": "100%",
      "margin": "0px auto",
      "background-color": "#ececec"
    },
  } 
}

let sd02={ 
  list:[
    {"image":"{{image}}", "image_sample":"https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},    
  ]
}
let a02={
  imageElement:{ 
    images:{
      image:'{{image}}',
      image_sample:'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      styleProps:{       
        "width": "100%",
        "border-radius": "1rem"
      }
    },    
    styleProps:{
      "padding": "50px 50px 50px 50px",
      "color": "#000000",
      "text-align": "center",
      "display": "block",
      "width": "100%",
      "margin": "0px auto",
      "background-color": "#f5f5f7"
    },
  } 
}

let sd03={ 
  list:[
    {"image":"{{image}}", "image_sample":"https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},    
  ]
}
let a03={
  imageElement:{ 
    images:{
      image:'{{image}}',
      image_sample:'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      styleProps:{       
        "margin": "0px auto",
        "border-radius": "1.5rem",
        "padding": "00px 0px 0px 0px",
      }
    },    
    styleProps:{
      "padding": "50px 50px 50px 50px",
      "color": "#000000",
      "text-align": "center",
      "display": "block",
      "width": "100%",
      "margin": "0px auto",
      "background-color": "#242424"
    },
  } 
}



export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
