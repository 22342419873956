let a=
`{
        "title":{
            "text":"Best In Class",
            "styleProps":{'font-size':'34px', 'margin-bottom':'10px'}
        },
        "description":{
            "text":"",
            "styleProps":{'font-size':'22px', 'margin-bottom':'10px'}
        },  
        "cards_config":{
            "cards_per_row":{
                "desktop":1,
                "tab":1,
                "mobile": 1
            }
        },
        "card_template":{
            "card_columns":[
                {
                    "elements":[
                        {"elementType":"imageEl", "src":"/assets/image.jpg", "styleProps":{"width":"100%", "border-radius":"20px"}},                                            
                    ] , 
                    "styleProps":{} 
                }
                {
                    "elements":[
                        {"elementType":"headingEl", "text":"[[title]]", "styleProps":{"font-size":"22px", "color":"#333333" | "#4fccf2", "margin-bottom": "20px"}},
                        {"elementType":"textEl", "text":"[[sub_title]]", "styleProps":{"font-size":"20px", "line-height":" 1.2", "color":"#333333" | "#4fccf2", "margin-bottom": "20px"}},
                        {"elementType":"textEl", "text":"[[description]]", "styleProps":{"font-size":"18px", "color":"#666666" | "#4fccf2", "margin-bottom": "20px"}}                       
                        {"elementType":"textEl", "text":"[[status]]", "styleProps":{"font-size":"14px", "background-color":"#e7cece", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},
                        {"elementType":"textEl", "text":"[[location]]", "styleProps":{"font-size":"14px", "background-color":"#cccccc", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},                    
                        {"elementType":"categoryEl", "text":"[[department]]", "styleProps":{"font-size":"14px", "background-color":"#cccccc", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},                    
                        {"elementType":"detailsLinkEl", "text":"View Details",  "url":"[[details_url]]", "styleProps":{ "background-color": "#f2ffc6" | "#c6fff6",  "border": "2px solid rgba(0, 0, 0, 0.3)", "font-size": "12px", "padding": "0px 8px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},  
                    ] , 
                    "styleProps":{'padding':'10px 20px 10px 10px '},
                    "stylePropsMob":{'padding':'10px 10px 10px 10px '}   
                },                    
            ],                                             
        },
        "card_items":[
            {
                "card_columns":[
                    {
                        "elements":[
                            {"elementType":"imageEl", "src":"/assets/image.jpg", "styleProps":{"width":"100%", "border-radius":"20px"}},                                            
                        ] , 
                        "styleProps":{} 
                    }
                    {
                        "elements":[
                            {"elementType":ELEMENT_TYPES, "text":"Project 1", "styleProps":{"font-size":"22px", "color":"#333333" | "#4fccf2", "margin-bottom": "20px"}},
                            {"elementType":"textEl", "text":"Project 1 Sub title", "styleProps":{"font-size":"20px", "line-height":" 1.2", "color":"#333333" | "#4fccf2", "margin-bottom": "20px"}},
                            {"elementType":"textEl", "text":"Project 1 description", "styleProps":{"font-size":"18px", "color":"#666666" | "#4fccf2", "margin-bottom": "20px"}}                       
                            {"elementType":"textEl", "text":"completed", "styleProps":{"font-size":"14px", "background-color":"#e7cece", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},
                            {"elementType":"textEl", "text":"London", "styleProps":{"font-size":"14px", "background-color":"#cccccc", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},                    
                            {"elementType":"categoryEl", "text":"Marketing", "styleProps":{ "background-color": "#f2ffc6" | "#c6fff6",  "border": "2px solid rgba(0, 0, 0, 0.3)", "font-size": "12px", "padding": "0px 8px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},  
                            {"elementType":"detailsLinkEl", "text":"View Details",  "url":"/project/1", "styleProps":{ "background-color": "#f2ffc6" | "#c6fff6",  "border": "2px solid rgba(0, 0, 0, 0.3)", "font-size": "12px", "padding": "0px 8px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},  
                        ] , 
                        "styleProps":{'padding':'10px 20px 10px 10px '},
                        "stylePropsMob":{'padding':'10px 10px 10px 10px '}   
                    },                    
                ]                                            
            }          
        ],
        "styleProps":{"background-color":"#e0eeee", 'padding':'40px 15px'},   
        "cards_holder_styles":{
            "styleProps":{"margin-top":"20px"}      
        },     
        "cards_outer_styles":{
            "styleProps":{"display":"grid", "grid-template-columns": "repeat(1, minmax(0, 1fr))", "gap":"20px", }    
        },        
        "cards_item_styles":{
            "styleProps":{"display":"grid", "grid-template-columns": "repeat(1, minmax(0, 1fr))", "gap":"20px", "align-items": "center",  "padding":"40px" | "20px", "box-shadow":"4px 4px 4px rgba(0,0,0,0.3)" | "4px 8px 8px -4px rgba(0,0,0,0.1)", "border-radius":"20px" | "40px", "background-color":"#ffffff" | "#eeeeee";},     
        }
    }
`



export default {
  a
}
