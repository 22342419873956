
const d3=`
    {
        "logo":{
            "text":"",
            "styleProps":{}
        },
        "description":{
            "text":"",
            "styleProps":{}
        },  
        "copy":{
            "text":"",
            "styleProps":{}
        }, 
        "terms":{
            "text":"",
            "styleProps":{}
        }, 
        "privacy":{
            "text":"",
            "styleProps":{}
        }, 
        "styleProps":{},  
    }

`

export const footer_prompt=({opts={}, sdata=false, sample=false})=>{
    if(!sdata) sdata={
        title:"No-Code Experts",
        description:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.",
    }
    if(!sample){
        sample=d3
    }
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'
    
    //console.log("footer_prompt", opts, sdata)
    
    return(`
    
    'SECTION_DATA'=${JSON.stringify(sdata)}    

    'JSON_INPUT'={
        "footer":${sample}
    }
    
    'WEBSITE_FOR' = "${opts.websiteFor}"
    
    Create Website Footer Sections in JSON for [WEBSITE_FOR]  
    
    Use 'SECTION_DATA' for 'footer' Content  
    
    JSON output schema sholud be [JSON_INPUT]
    
    Give me the output in JSON format and it should be valid JSON data
    
    Just give me the JSON data
    
    `)
}

const schema={
    "name": "footer_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": {            
            "logo": {
                "$ref": "#/$defs/link_obj"
            },
            "description": {
                "$ref": "#/$defs/text_obj"
            },
            "copy": {
                "$ref": "#/$defs/link_obj"
            },
            "terms": {
                "$ref": "#/$defs/link_obj"
            },
            "privacy": {
                "$ref": "#/$defs/link_obj"
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"},
            "stylePropsMob": {"$ref": "#/$defs/styles_tab_obj"},
            "stylePropsTab": {"$ref": "#/$defs/styles_mobile_obj"}                       
        },  
        "required": [           
            "logo",
            "description",
            "copy",
            "terms",
            "privacy",
            "styleProps",
            "stylePropsTab",
            "stylePropsMob"        
        ],
        "additionalProperties": false,
        "$defs": {
            "text_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_tab_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_mobile_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },  
            "link_obj":{
                "type": "object",
                "description": "Logo", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "href": { 
                        "type": "string",
                        "description": "href"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_tab_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_mobile_obj"}           
                },
                "required": [
                    "text",
                    "url",
                    "styleProps"
                ],
                "additionalProperties": false
            },        
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            },  
            "styles_tab_obj":{
                "type": "object",
                "description": "css styles for tab device", 
                "properties": {},                  
                "additionalProperties": true
            }, 
            "styles_mobile_obj":{
                "type": "object",
                "description": "css styles for mobile device", 
                "properties": {},                  
                "additionalProperties": true
            }                           
        }
    }
}
export const footer_format = {
    "type":"json_schema",
    "json_schema": schema
}