import { twdClass } from "../../constants/tailwind.constants";
import { SHOW_ERRORMESSAGE_SCREEN } from "../../constants/message.constants";
import StudioPanel from "./StudioPanel";

const StudioLayout = () => {
  return (
    <div>
      <div className={twdClass("hidden lg:block bg-gray-100")}>
        <StudioPanel />
      </div>
      <div className={twdClass("lg:hidden flex justify-center items-center h-screen")}>
        <h1 className={twdClass("uppercase text-2xl font-bold")}>{SHOW_ERRORMESSAGE_SCREEN}</h1>
      </div>
    </div>
  );
};

export default StudioLayout;
