import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "./Common";
import {textElement_prompt, textElement_format} from "../prompts/textElement_prompt";

const Text = (args) => {
  const {text, text_sample}= args
  return(<DivTag {...args} alt={text}>{text}</DivTag>)
}


const Texts = ({texts}) => {
  const { list=[], ...obj1} = texts
  let list_=list.map((text, i)=>{
    return(<Text key={i} {...text}/>)
  })
  return(<DivTag {...obj1} className={twdClass("")}>
    <div className={twdClass("flex flex-col gap-2")}>{list_}</div>
  </DivTag>)
}

const Design2 = ({obj={}, opts={}, sample='', sdata={}}) => {  
  let user_prompt = textElement_prompt({opts, sample, sdata }) 
  return (<Design {...{type:'textElement', obj, user_prompt, format:textElement_format}}/>)
}


const TextElement = ({textElement={}, opts={}, design=false, sample='', sdata={}}) => {
  let obj=textElement.textElement ? textElement.textElement : textElement
  const { texts=[], ...obj1} = obj
  return(
    <DivTag {...obj1} className={twdClass("relative mb-4 px-4 py-2 ")}>
      {design && <Design2 {...{design,  obj, opts, sample, sdata}}/>}
      <div className={twdClass("flex flex-col gap-8 relative mx-auto")} style={{'max-width':'1536px'}}>  
        <div className={twdClass("ml-2")}> 
          <Texts {...{texts}}/>
        </div>
      </div>
    </DivTag>
  )
}
export  default TextElement