import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import { Dropdown } from "./dropdown_ui";
import ReactTooltip from "react-tooltip";


const ToolTip = ({ title='', desc='', more='', position='right', steps=[],  options=false, children, expand='', related_links=[], visualSteps=''}) => {  
  const dispatch = useDispatch();
  const [toggleExpand, setToggleExpand] = useState(false);
  const [level, setLevel] = useState(0);

  const handleExpand = (e) => {
    e.preventDefault();    
    setToggleExpand((prevState) => !prevState);
    //console.log('open')
  };
  const closeExpand = () => {
    setToggleExpand(false);
    //console.log("close")
  };
  const updateVisualSteps = async () => {
     dispatch({
      type: 'UPDATE_VISUAL_STEPS',
      data: {
        isActive: true,
        current: visualSteps, 
        title, 
        desc,
        steps ,
        step:1,
        options,
        oBack:false,
      },
    });
    //console.log("STEPS", 'tutorials')
  }
  let minWidth='300px'
  let backC='hidden'
  let viewC='hidden'
  let titleC=''
  let viewInteractiveC='hidden'  
  if (level==1) minWidth='400px'
  if (level==1) backC=''
  if (level==1) titleC='border-b border-gray-200 mb-4'
  if (level==1) viewInteractiveC=''
  if (level==0) viewC=''
  let related_links_=related_links.map(({t, l}, index)=>{
    return (
      <div key={index} className={twdClass("py-1 hover:underline")}><a target="_blank" href={l} >{t}</a></div>
    )
  })  
  let steps_=steps.map(({t}, index)=>{
    return (
      <div key={index} className={twdClass("py-3 flex")}>
        <div className={twdClass("w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 mr-4 font-semibold ")} style={{minWidth:'30px'}}>{index+1}</div>
        <div>{t}</div>
      </div>
    )
  })  
  let img=toggleExpand ? 'chevron-up.svg' : 'chevron-down.svg'
  return (
    <Dropdown 
      position={position}
      title={<div className={twdClass("transform hover:-translate-y-px active:translate-y-0")} ><img alt="" width="24px" src="/assets/images/question.svg" /></div>}
    >        
      <div className={twdClass(`${titleC} flex items-center px-8 pt-8 pb-4 `)} style={{minWidth: minWidth}}>
        <div className={twdClass(`${backC} p-2 -ml-2 cursor-pointer transform hover:-translate-y-px active:translate-y-0`)} onClick={()=> {setLevel(0)}}>
          <img style={{minWidth:'24px'}} alt="" width="24px" src={`/assets/images/arrow-left-sm.svg`} />
        </div>
        <div></div>
        {(level==0 && title) && <div className={twdClass("font-semibold")} >{title}</div>}
        {level==1 && <div className={twdClass("font-semibold flex-grow text-center -ml-8")} >Steps</div>}
        {level==1 && <div id="play_tutorial" className={twdClass(`${viewInteractiveC} flex p-2 items-center cursor-pointer transform hover:-translate-y-px active:translate-y-0`)} onClick={()=> {updateVisualSteps()}}>
           
          <div data-tip="Play&nbsp;Tutorial" className={twdClass("relative")} >
            <img alt="" width="20px" className={twdClass("")} src="/assets/images/play-03.svg" />
            <ReactTooltip place="top" effect="solid" />
          </div>
        </div>}
      </div>
      {level==0 && <div className={twdClass("px-8 pb-8 text-sm")}>
        {desc}
      </div>}
      {level==1 && <div className={twdClass("px-8 pb-4 text-sm overflow-auto")} style={{maxHeight:'300px'}}>
        {steps_}
      </div>}      
      <div className={twdClass("px-8 py-4 text-sm flex items-center border-t")}>        
        <div className={twdClass("cursor-pointer transform hover:-translate-y-px active:translate-y-0")} onClick={handleExpand}>
          <img alt="" width="24px" src={`/assets/images/${img}`} />
        </div>
        <div className={twdClass("flex-grow")}></div>
        <div className={twdClass(`${viewC} flex p-2 items-center cursor-pointer transform hover:-translate-y-px active:translate-y-0`)} onClick={()=> {setLevel(1)}}>
          View More <img alt="" width="24px" src="/assets/images/arrow-right-sm.svg" />
        </div>        
      </div>
      {toggleExpand && <div className={twdClass("px-8 py-4 bg-gray-100 text-sm")}>
        {related_links && related_links_}
        {expand!='' && expand}
      </div>}      
    </Dropdown>
  );
};

export {ToolTip}
