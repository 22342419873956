import { find, findIndex } from "lodash";
import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "../common/FaIcons";
import { useState, useRef, useEffect } from "react";
import { INPUT_TEXT } from "../../constants/var.constants";
import { TextElementPanel } from "./TextElementPanel";
import { ImageElementPanel } from "./ImageElementPanel";
import { VideoElementPanel } from "./VideoElementPanel";
import { ButtonElementPanel } from "./ButtonElementPanel";
import { DividerElementPanel } from "./DividerElementPanel";
import { LinkElementPanel } from "./LinkElementPanel";
import { Button, BtnStyle, BtnBottom, BtnTop, BtnDelete} from "../ui/com_ui";
import ToggleBtn from "../common/ToggleBtn";

const CE1 = twdClass("p-2 clear-both  flex items-center");
const CE2 = twdClass("flex items-center justify-center h-6 w-6 cursor-pointer");
const CE3 = twdClass("text-sm font-semibold cursor-pointer flex-1 capitalize");
const CE4 = twdClass(
  "ml-1 flex items-center justify-center rounded-full h-6 w-6 bg-gray-300 hover:bg-gray-100 cursor-pointer"
);
const CE5 = twdClass("text-sm text-red-400 hover:text-red-600");
const CE6 = twdClass("text-sm text-blue-400 hover:text-blue-600");

const LAYOUTS = [  
  {
    name:'l2', label:'2 rows',
    items:[
      { name:'A', label:'A', location:true,},
      { name:'B', label:'B', location:true,}
    ]
  },
  {
    name:'l5', label:'3 rows',
    items:[
      { name:'A', label:'A', location:true,},
      { name:'B', label:'B', location:true,},
      { name:'C', label:'C', location:true,}
    ]
  },
  {
    name:'l1', label:'2 columns',
    items:[
      { name:'A', label:'A', location:true,},
      { name:'B', label:'B', location:true,}
    ]
  },
  {
    name:'l3', label:'3 columns',
    items:[
      { name:'A', label:'A', location:true,},
      { name:'B', label:'B', location:true,},
      { name:'C', label:'C', location:true,}
    ]
  },
  {
    name:'l4', label:'4 columns',
    items:[
      { name:'A', label:'A', location:true,},
      { name:'B', label:'B', location:true,},
      { name:'C', label:'C', location:true,},
      { name:'D', label:'D', location:true,}
    ]
  }
]


const TextSearch = () => {
  const [search, setSearch] = useState(''); 
  const [searching, setSearching] = useState(false); 
  const timer = useRef(0);
  const running = useRef(false);

  const doSearch=()=>{
    setSearching(true)
    console.log("search Start", search)  
    setTimeout(()=>{
      setSearching(false)
      console.log("search Stop", search)  
    },1000) 
  }

  const increaseTimer = () => { 
    if(timer.current >=3) {
      if(search.length >=3) {
        doSearch(true)          
      }    
      running.current=false    
    }else{       
      timer.current++;
      console.log("timer", timer.current)  
      setTimeout(()=>{
        increaseTimer() 
      },100)  
    }       
  };
  const runTimer = () => {   
    timer.current = 0;       
    if(!running.current) {
      increaseTimer()  
      running.current=true  
      setSearching(false)
      console.log("run", timer.current) 
    }else{
      console.log("already running") 
    }
  };
  return (
    <div>
      <textarea
        rows="2"
        className={twdClass(INPUT_TEXT)}
        value={search}
        onKeyUp={()=>{
          runTimer()
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      ></textarea>
      {timer.current}
      {searching && <div>Searching...</div>}
    </div>
  )
}


export const CardLayoutPanel = ({
  cardConfig,
  activeLayout,
  setActiveLayout,  
  updateElementValue,
  updateLocationStylePanel
}) => {
  const [expand, setExpand] = useState(true); 
  const updateLocation = (e) => {
    
  };
  const updateLayout = (e) => {
    setActiveLayout(e.target.value)      
    updateElementValue("cardConfig.layout", e.target.value);
    //updateElementValue("cardLocations", [{name:'outer', styleProps:{padding:'10px'}}, {name:'A', styleProps:{padding:'10px'}}, {name:'B', styleProps:{padding:'10px'}}]);
  };

  

  return (
    <div className={twdClass("my-3 bg-gray-50 border border-gray-100  hover:border-gray-200 rounded-2xl px-1 py-1")}>
      <div className={CE1}>
        <span className={CE2} onClick={() => setExpand(!expand)}>
          <FaIcons
            icon={`${expand ? "angleUp" : "angleDown"}`}
            className={twdClass("text-sm")}
          />
        </span>
        <span className={CE3} onClick={() => setExpand(!expand)}>
          Layout
        </span>
        <span className={twdClass("flex items-center")}>          
          
        </span>
      </div>
      {expand && (
        <div className={twdClass("p-2 bg-white border border-gray-100 hover:border-gray-200 rounded-xl")}>
          {/*<TextSearch/>*/}
          <div className={twdClass("flex items-center mb-2")}>
            <div className={twdClass("w-36 text-sm mb-1")}>Type:</div>
            <div className={twdClass("w-full")}>
              <select
                className={twdClass(INPUT_TEXT)}
                onChange={(e) => updateLayout(e)}
                value={activeLayout}
              >
                <option value="">Select</option>
                {LAYOUTS.map((i)=>{
                  return(<option value={i.name}>{i.label}</option>)
                })}                
              </select>
            </div>
          </div>
          <div className={twdClass(`flex items-center justify-between mb-2 ${activeLayout =='l1' ? '' : 'hidden'}`)}>
            <label className={twdClass("text-sm mr-2")}>Alternate:</label>
            <ToggleBtn
              toggleId={"showfilter"}
              value={cardConfig.alternate}
              falseLabel={`Hide`}
              trueLabel="Show"
              updateValue={(value) => {
                updateElementValue("cardConfig.alternate", !value);
              }}
            />
          </div>  
          <RenderLayout 
            activeLayout={activeLayout}
            updateLocationStylePanel={updateLocationStylePanel}
          />     
                       
        </div>
      )}
    </div>
  );
};


function _item(item){
  //let items_=_items(item.items, _item2)
  //console.log("items_", items_)
  return (<div className={twdClass("bg-primary-10 border border-primary-400  hover:border-primary-500 rounded-lg px-1 py-1")}>      
    {item['label']} 
    {item.items.map((it, index)=>{     
      return (<div className={twdClass("bg-primary-10 border border-primary-400  hover:border-primary-500 rounded-lg px-1 py-1")}>      
        {it['label']}
        {it.items.map((it2, index)=>{     
          return (<div className={twdClass("bg-primary-10 border border-primary-400  hover:border-primary-500 rounded-lg px-1 py-1")}>      
            {it2['label']}            
          </div>)
        })}
      </div>)
    })}
  </div>)
}


const RenderLayout = ({
  activeLayout,
  updateLocationStylePanel
}) => {
  //let layout=LAYOUTS[activeLayout]
  let layout = find(LAYOUTS, ["name", activeLayout]);
  console.log("layout", layout)
  if(!layout) return <></>

  const RItem = ({name, label, children}) => {
    //let items_=_items(item.items, _item2)
    //console.log("items_", items_)
    return (
      <div  className={twdClass("w-full")}>
        <div className={twdClass("bg-primary-500 bg-opacity-25 border border-primary-100 group hover:border-primary-500 rounded-lg px-1 py-1 m-1")}>  
          <div className={twdClass("flex justify-between py-1")}>
            <div className={twdClass("text-sm opacity-50 group-hover:opacity-100")}>{label} </div>
            <BtnStyle              
              title="Style"
              onClick={() => {
                updateLocationStylePanel({ location: name, showStyles: true });
              }}
            >              
            </BtnStyle>
          </div> 
          {children}           
        </div>
      </div>
      )
  }

  const ROuter = ({label, children}) => {
    //let items_=_items(item.items, _item2)
    //console.log("items_", items_)
    return (
      <div className={twdClass("bg-primary-100 border border-primary-500  rounded-lg px-1 py-1")}> 
        <div className={twdClass("flex justify-between py-1")}>
          <div className={twdClass("text-sm opacity-50")}>Outer</div>
          <BtnStyle              
            title="Style"
            onClick={() => {
              updateLocationStylePanel({ location: 'outer', showStyles: true });
            }}
          >              
          </BtnStyle>
        </div>   
        {children}            
      </div>
      )
  }

  function L1(layout){ 
    return (
      <ROuter {...layout}>
        <div className={twdClass("flex")}>
          <RItem {...layout.items[0]}></RItem>
          <RItem {...layout.items[1]}></RItem>
        </div>
      </ROuter>
    )
  }
  function L2(layout){ 
    return (
      <ROuter {...layout}>
        <div className={twdClass("")}>
          <RItem {...layout.items[0]}></RItem>
          <RItem {...layout.items[1]}></RItem>
        </div>
      </ROuter>
    )
  }
  function L3(layout){ 
    return (
      <ROuter {...layout}>
        <div className={twdClass("flex")}>
          <RItem {...layout.items[0]}></RItem>
          <RItem {...layout.items[1]}></RItem>
          <RItem {...layout.items[2]}></RItem>
        </div>
      </ROuter>
    )
  }
  function L4(layout){ 
    return (
      <ROuter {...layout}>
        <div className={twdClass("flex")}>
          <RItem {...layout.items[0]}></RItem>
          <RItem {...layout.items[1]}></RItem>
          <RItem {...layout.items[2]}></RItem>
          <RItem {...layout.items[3]}></RItem>
        </div>
      </ROuter>
    )
  }
  function L5(layout){ 
    return (
      <ROuter {...layout}>
        <div className={twdClass("")}>
          <RItem {...layout.items[0]}></RItem>
          <RItem {...layout.items[1]}></RItem>
          <RItem {...layout.items[2]}></RItem>
        </div>
      </ROuter>
    )
  }

  
  return (
    <div className={twdClass("p1")}> 
      {activeLayout=='l1' && <L1 {...layout}/>}
      {activeLayout=='l2' && <L2 {...layout}/>}
      {activeLayout=='l3' && <L3 {...layout}/>}
      {activeLayout=='l4' && <L4 {...layout}/>}
      {activeLayout=='l5' && <L5 {...layout}/>}
    </div>   
  );
};





