import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { cloneDeep, set } from "lodash";

const GoogleFonts = ({ sitesettings, updateSettings }) => {
  const process = (data, value) => {
    let fonts=value.split(",")
    const fonts2 = fonts.map((itm) => {
      itm=itm.trim().replace(new RegExp(" ", "g"), '+')
      itm="family="+itm
      return itm
    }).join("&");
    
    return set(data, "googleFonts_processed", fonts2);   
  }
  const updateItem = (value) => {
    const sst = cloneDeep(sitesettings);
    let data = set(sst, "googleFonts", value);
    data = process(data, value);
    updateSettings(data);
  };

  return (
    <div className={twdClass("w-full mb-4")}>
      <div className={twdClass("text-md font-semibold mb-4")}>Google Fonts</div>
      <textarea
        rows={10}
        value={sitesettings.googleFonts}
        placeholder="Ubuntu, Roboto Mono"
        className={twdClass(`${INPUT_TEXT} w-full`)}
        onChange={(e) => updateItem(e.target.value)}
      ></textarea>
    </div>
  );
};

export default GoogleFonts;
