import { getDefaultFields } from "../common/common.fields";

export const HERO_FORM_3 = {
  sectionName: "Hero Section",
  elements: [
    {
      inputType: "select",
      label: "Image/Video Position",
      updateTo: "imageVideoPostion",
      hasStyleProps: false,
      hideLabel: true,
      options: [
        {
          value: "left",
          display: "Left",
        },
        {
          value: "right",
          display: "Right",
        },
      ],
    },
    {
      inputType: "select",
      label: "Image/Video",
      updateTo: "imageType",
      hasStyleProps: false,
      hideLabel: true,
      options: [
        {
          value: "image",
          display: "Image",
        },
        {
          value: "video",
          display: "Video",
        },
      ],
    },
    {
      inputType: "text",
      label: "Image",
      updateTo: "imageBlock.image",
      hasStyleProps: true,
      styleElementId: 4,
      hideLabel: true,
      hideBasedElemVal: {
        elem: "imageType",
        value: "video",
      },
    },
    {
      inputType: "text",
      label: "Video Link",
      updateTo: "videoBlock.url",
      hasStyleProps: true,
      styleElementId: 3,
      hideLabel: true,
      hideBasedElemVal: {
        elem: "imageType",
        value: "image",
      },
    },
    {
      inputType: "list",
      label: "Hero Text",
      hasStyleProps: true,
      styleElementId: 1,
      updateTo: "textBlock.headings",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 2,
        elements: [
          {
            inputType: "textarea",
            label: "Headings",
            defaultValue:
              "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
            updateTo: "textBlock.headings[i].text",
            extraPropUpdateTo: "textBlock.headings[i]",
            extraPropValues: {
              text:
                "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
              hideUnderline: false,
              underLineStyle: {
                "background-color": "yellow",
                height: "2px",
              },
              styleProps: {
                color: "#000000",
                textAlign: "left",
                "font-size": "1.125rem",
                "line-height": "1.75rem",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Hero",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Hero Text Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.styleProps",
          fields: [
            "backgroundcolor",
            "borderradius",
            "padding",
            "margin",
            "fontfamily",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Hero Text Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.headings[i].styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "textalign",
            "padding",
            "margin",
            "fontfamily",
          ],
        }),
        {
          inputType: "select",
          label: "Underline",
          updateTo: "textBlock.headings[i].hideUnderline",
          hideLabel: false,
          options: [
            {
              value: true,
              display: "Display",
            },
            {
              value: false,
              display: "Hide",
            },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.headings[i].underLineStyle",
          fields: ["backgroundcolor", "height"],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Video Block",
      elements: [
        {
          inputType: "select",
          label: "Height",
          updateTo: "videoBlock.videoHeight.height",
          options: [...Array(20)].map((_, index) => {
            return {
              value: index * 50 + "px",
              display: index * 50 + " Px",
            };
          }),
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "videoBlock.styleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Image Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "imageBlock.styleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
  ],
};
