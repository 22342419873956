import { getDefaultFields } from "../../jsons/common/common.fields";
import RenderElements from "../form/RenderElements";
import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "../common/FaIcons";
import { useState, useEffect } from "react";
import { INPUT_TEXT } from "../../constants/var.constants";
import { replace, cloneDeep } from "lodash";

export const CardContainerStyles = ({ updateElementValue, currentBlock }) => {
  const [show, setShow] = useState(true);
  const list = getDefaultFields({
    type: "TEXT",
    updateTo: `styleProps`,
    fields: ["backgroundcolor", "padding", "margin", "backgroundimage"],
  });
  return (
    <div className={twdClass("px-6")}>
      <CardsViewSettings
        currentBlock={currentBlock}
        updateElementValue={updateElementValue}
      />
      <div
        className={twdClass(
          "flex justify-between p-2 bg-blue-100 cursor-pointer"
        )}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span>Container Styles</span>
        <span>
          <FaIcons icon={`${show ? "angleDown" : "angleUp"}`} />
        </span>
      </div>
      {show &&
        list.map((item) => (
          <RenderElements
            element={item}
            data={currentBlock}
            updateElementValue={updateElementValue}
          />
        ))}
    </div>
  );
};

const CardsViewSettings = ({ updateElementValue, currentBlock }) => {
  const initvalues = {
    desktop: 0,
    tab: 0,
    mobile: 0,
  };
  // const { parentLayoutCls } = currentBlock.configs;
  const [params, setParams] = useState(cloneDeep(initvalues));
  useEffect(() => {
    const paramdata = cloneDeep(params);
    currentBlock.configs.parentLayoutCls.split(" ").forEach((item) => {
      if (item.indexOf("md:sly-grid-cols-") !== -1)
        paramdata.tab = +replace(item, "md:sly-grid-cols-", "");
      else if (item.indexOf("lg:sly-grid-cols-") !== -1)
        paramdata.desktop = +replace(item, "lg:sly-grid-cols-", "");
      else if (item.indexOf("sly-grid-cols-") !== -1)
        paramdata.mobile = +replace(item, "sly-grid-cols-", "");
    });
    console.log(paramdata);
    setParams((data) => ({ ...data, ...paramdata }));
  }, [currentBlock.configs.parentLayoutCls]);

  const updateParams = (type, value) => {
    let cls = currentBlock.configs.parentLayoutCls;
    if (type === "tab") {
      const tb = "md:sly-grid-cols-";
      cls = replace(cls, `${tb}${params.tab}`, `${tb}${value}`);
    } else if (type === "mobile") {
      const tb = " sly-grid-cols-";
      cls = replace(cls, `${tb}${params.mobile}`, `${tb}${value}`);
    } else if (type === "desktop") {
      const tb = "lg:sly-grid-cols-";
      cls = replace(cls, `${tb}${params.desktop}`, `${tb}${value}`);
    }

    updateElementValue("configs.parentLayoutCls", cls);
  };

  return (
    <div className={twdClass("justify-between")}>
      <div className={twdClass("bg-blue-100 cursor-pointer p-2")}>
        Cards Per Row
      </div>
      {Object.keys(initvalues).map((item) => {
        return (
          <>
            <div className={twdClass("flex items-center text-xs my-2")}>
              <label className={twdClass("whitespace-nowrap w-24 capitalize")}>
                {item} view:
              </label>
              {[...Array(5)].map((_, index) => {
                return (
                  <div
                    className={twdClass(
                      `hover:bg-gray-100 cursor-pointer px-2 mx-2 border rounded-md ${
                        +params[item] === index + 1
                          ? "bg-gray-200 font-semibold"
                          : ""
                      }`
                    )}
                    onClick={() => {
                      updateParams(item, index + 1);
                    }}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
          </>
        );
      })}
    </div>
  );
};
