/* export const HEADER_BLOCK_01 = {
  blockType: "HEADER",
  blockDesign: "header_01",
  styleProps: {
    "background-image":
      "url(https://images.pexels.com/photos/8390286/pexels-photo-8390286.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260)",
  },
  position: "left",
  test_sl: ["New Text 1", "New Text 2"],
  heading: {
    styleProps: {},
    text: "Make your paycheck work harder with Gusto Wallet.",
  },
  description: {
    text:
      "If your employee uses Gusto, the free Gusto Wallet app is for you. It's a smart way to track, save, and access your hard earned money.",
  },
  buttonsStyleProps: {},
  buttons: [
    {
      styleProps: {},
      sub_type: "imageButton",
      alt: "Google Play Store",
      href: "#",
      image_src:
        "https://staple-data.s3.amazonaws.com/sly-projects/1636304775960-_i_play_store.png",
      image_style: {
        height: "50px",
      },
    },
    {
      styleProps: {},
      sub_type: "imageButton",
      alt: "Apple App Store",
      href: "#",
      image_src:
        "https://staple-data.s3.amazonaws.com/sly-projects/1636304533684-_i_app_store.png",
      image_style: {
        height: "50px",
      },
    },
  ],
  textsStyleProps: {},
  texts: [
    {
      type: "el",
      sub_type: "html",
      text: "Want to use a web browser? <a href='#'>Log in to Gusto</a>",
    },
  ],
  footer: {
    text: "Want to use a web browser? Log in to Gusto",
  },
  editFormName: "HEADER_FORM_01",
}; */

export const HEADER_BLOCK_01 = {
  blockType: "HEADER",
  blockDesign: "header_01",
  styleProps: {
    "background-image":
      "url(https://images.pexels.com/photos/8390286/pexels-photo-8390286.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260)",
  },
  position: "left",
  headingsStyleProps: {
    padding: "120px 0px 10px 0px",
  },
  headingsStylePropsTab: {
    padding: "50px 0px 10px 0px",
  },
  headingsStylePropsMob: {
    padding: "20px 0px 10px 0px",
  },
  headings: [
    {
      styleProps: {
        "font-size": "3rem",
        "line-height": "1",
        padding: "20px 0px 20px 0px",
        "font-weight": "600",
      },
      stylePropsTab: {
        "font-size": "1.875rem",
        "line-height": "2.25rem",
        padding: "20px 0px 20px 0px",
      },
      stylePropsMob: {
        "font-size": "1.875rem",
        "line-height": "2.25rem",
        padding: "20px 0px 20px 0px",
      },
      text: "Make your paycheck work harder with Gusto Wallet.",
    },
  ],
  dynamicText: {
    styleProps: {
      "font-size": "1.875rem",
      "line-height": "2.25rem",
      padding: "10px 0px 10px 0px",
    },
    stylePropsTab: { padding: "10px 0px 10px 0px" },
    stylePropsMob: { padding: "10px 0px 10px 0px" },
    strings: ["Build custom apps less time"],
  },
  descriptionsStyleProps: {
    padding: "10px 0px 10px 0px",
  },
  descriptionsStylePropsTab: {
    padding: "10px 0px 10px 0px",
  },
  descriptionsStylePropsMob: {
    padding: "10px 0px 10px 0px",
  },
  descriptions: [
    {
      styleProps: {
        padding: "20px 0px 20px 0px",
      },
      stylePropsTab: {},
      stylePropsMob: {},
      text:
        "If your employee uses Gusto, the free Gusto Wallet app is for you. It's a smart way to track, save, and access your hard earned money.",
    },
  ],
  buttonsStyleProps: {},
  buttons: [
    {
      styleProps: {
        padding: "0px 0px 0px 0px",
        "border-width": "0px",
      },
      alt: "Google Play Store",
      href: "#",
      text:
        "<img src='https://staple-data.s3.amazonaws.com/sly-projects/1636304775960-_i_play_store.png' style='height: 50px' />",
    },
    {
      styleProps: {
        padding: "8px 20px 8px 20px",
      },
      alt: "Apple App Store",
      href: "#",
      text: "Click Here",
      /* image_src:
        "https://staple-data.s3.amazonaws.com/sly-projects/1636304533684-_i_app_store.png",
      image_style: {
        height: "50px",
      }, */
    },
  ],
  emailSecStyleProps: {},
  subscription: {
    showSection: "show",
    display: "enable",
    subConfigs: {
      configType: "convertkit",
      configUrl: "https://app.convertkit.com/forms/xxxxxx/subscriptions",
      configPlaceholder: "",
    },
    email_placeholder: {
      text: "example@mail.com",
    },
    buttonText: {
      text: "Subscribe",
      styleProps: {
        "background-color": "#1b1b31",
        color: "#ffffff",
      },
    },
    successMessage: {
      text: "Thank you for the signup",
      styleProps: {},
    },
    errorMessage: {
      text: "Something went wrong. Please try again.",
      styleProps: {},
    },
  },
  textsStyleProps: {},
  texts: [
    {
      type: "el",
      sub_type: "html",
      text: "Want to use a web browser? <a href='#'>Log in to Gusto</a>",
    },
  ],
  editFormName: "HEADER_FORM_01",
  metaDataId: 1641310954045,
};
