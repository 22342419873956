export const HERO_BLOCK_3 = {
  blockType: "HERO",
  blockDesign: "HeroDesign3",
  editFormName: "HERO_FORM_3",
  styleProps: {
    "background-image":
      "url('https://images.unsplash.com/photo-1432847712612-926caafaa802?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80')",
  },
  textBlock: {
    styleProps: {
      "background-color": "blue",
      "border-radius": "0",
    },
    headings: [
      {
        text: "Best Designs,",
        hideUnderline: true,
        underLineStyle: {
          "background-color": "red",
          height: "",
        },
        styleProps: {
          color: "#000000",
          "text-align": "left",
          "font-size": "3.75rem",
          "line-height": "1",
          "font-weight": "bold",
        },
      },
      {
        text:
          "Join our community and thrive with us! We can all help each other, learn together, and grow together. Join our community and thrive with us! We can all help each other, learn together, and grow together.Join our community and thrive with us! We can all help each other, learn together, and grow together. Join our community and thrive with us! We can all help each other, learn together, and grow together.",
        hideUnderline: false,
        underLineStyle: {
          "background-color": "yellow",
          height: "2px",
        },
        styleProps: {
          color: "#000000",
          "text-align": "left",
          "font-size": "1.125rem",
          "line-height": "1.75rem",
        },
      },
      {
        text: "Grow Together",
        hideUnderline: true,
        underLineStyle: {
          "background-color": "yellow",
          height: "2px",
        },
        styleProps: {
          color: "#000000",
          "text-align": "right",
          "font-size": "1rem",
        },
      },
    ],
  },
  imageType: "video",
  imageVideoPostion: "right",
  imageBlock: {
    image:
      "https://www.iamabiker.com/wp-content/uploads/2021/01/Honda-Highness-CB350-HD-wallpaper-1.jpg",
    styleProps: {},
  },
  videoBlock: {
    url: `https://www.youtube.com/embed/1ozmyl1ZEyY`,
    styleProps: {},
    videoHeight: {
      height: "400px",
    },
  },
};
