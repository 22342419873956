import { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { twdClass } from "../constants/tailwind.constants";
import { INPUT_TEXT, BTN_NORMAL, BTN_CLS } from "../constants/var.constants";
import {Button} from "../components/ui/com_ui";
import {Layout, Error} from "./Layout";
import styles from "./login.module.css";

export function UpdatePassword() {
  const passwordRef = useRef();
  const password2Ref = useRef();

  const { updatePassowrd } = useAuth();

  const history = useHistory();
  const [error, setError] = useState(null);
  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    // Get email and password input values
    const password = passwordRef.current.value;
    const password2 = password2Ref.current.value;    
    if (password == password2) {
      // Calls `resetPassword` function from the context
      const { data, error } = await updatePassowrd({password});
      //console.log("data", data);
      //console.log(error);
      if (error) {
        setError(error.message);
      } else {
        // Redirect user to Dashboard
        history.push("/");
      }
    } else {
      setError('Password & Confirm Password should match.');
    }

    
  }


  useEffect(() => {
    if(error){
      setTimeout(()=>{
        setError("")
      }, 2000)
    }
  }, [error]);

  const LoginLink = (<Link to="/login" className={twdClass("text-gray-700 font-semibold")}>Login</Link>)
  return (
    <Layout
      title={"Update Password"}
      desc={"Type and confirm a secure new password for the account."}
      action={LoginLink}
    >
      <form onSubmit={handleSubmit}>  
        <input
          id="input-password"
          type="password"
          required
          ref={passwordRef}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Password"}
        />
        <input
          id="input-password"
          type="password"
          required
          ref={password2Ref}
          className={`${styles.input} ${twdClass(INPUT_TEXT)}`}
          placeholder={"Confirm Password"}
        />

        <button
          type="submit"
          className={twdClass("block w-full")}          
        >
          <Button type={"defualt"} color={"primary"} size="lg">Update</Button>
        </button>        

        <Error error={error}/>
      </form>     
    </Layout>
  );
}
