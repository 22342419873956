import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";
import { Button} from "../../ui/com_ui";
import { find, merge, cloneDeep, set, isEmpty } from "lodash";

import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED,
  UPDATE_BLOCK_DATA
} from "../../../constants/action.constants";



import t_header from "../transform/t_header";
import t_footer from "../transform/t_footer";
import t_navbar from "../transform/t_navbar";
import t_cards from "../transform/t_cards";

import t_headerDyn from "../transform/t_headerDyn";
import t_imageElement from "../transform/t_imageElement";
import t_buttonElement from "../transform/t_buttonElement";
import t_textElement from "../transform/t_textElement";


export const DivTag = styled.div`
${(props) => {
  if (props && props.styleProps) {
    return props.styleProps;
  }
  return "";
}}
@media (min-width: 700px) and (max-width: 1024px) {
  ${(props) => {
    if (props && props.stylePropsTab) {
      return props.stylePropsTab;
    }
    return "";
  }}
}
@media (max-width: 600px) {
  ${(props) => {
    if (props && props.stylePropsMob) {
      return props.stylePropsMob;
    }
    return "";
  }}
}
`;

export const InputTag = styled.input`
${(props) => {
  if (props && props.styleProps) {
    return props.styleProps;
  }
  return "";
}}
@media (min-width: 700px) and (max-width: 1024px) {
  ${(props) => {
    if (props && props.stylePropsTab) {
      return props.stylePropsTab;
    }
    return "";
  }}
}
@media (max-width: 600px) {
  ${(props) => {
    if (props && props.stylePropsMob) {
      return props.stylePropsMob;
    }
    return "";
  }}
}
`;

export const ImgTag = styled.img`
${(props) => {
  if (props && props.styleProps) {
    return props.styleProps;
  }
  return "";
}}
@media (min-width: 700px) and (max-width: 1024px) {
  ${(props) => {
    if (props && props.stylePropsTab) {
      return props.stylePropsTab;
    }
    return "";
  }}
}
@media (max-width: 600px) {
  ${(props) => {
    if (props && props.stylePropsMob) {
      return props.stylePropsMob;
    }
    return "";
  }}
}
`;

export const Link = (link) => {
  const {text}= link
  return(<DivTag {...link}>{text}</DivTag>)
}

export const Links = ({links}) => {
  let links_=links.map((link, i)=>{
    return(<Link key={i} {...link}/>)
  })
  return(<DivTag className={twdClass("")}>
    <div className={twdClass("flex items-center gap-2")}>{links_}</div>
  </DivTag>)
}



export const Design = ({ design=false, type='', obj={}, user_prompt='', format=false}) => {  
  const dispatch = useDispatch();
  const {
    pageInfo: { blocks },
    generated={},
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);

  const apply = () => {
   /* let tNavbar=t_navabar({generated})
    let tCards=t_cards({generated})
    pageInfo.blocks.unshift(tNavbar)
    pageInfo.blocks.unshift(tCards)
    console.log("pageInfo", project_id, pageInfo)
    dispatch({ type: UPDATE_PROJECT_PAGE, data:pageInfo });
    dispatch({ type: RESET_POPUP });*/  
    let apply_obj  
    if(type=='cards'){
      apply_obj=t_cards({obj, generated})
    }else if(type=='navbar'){
      apply_obj=t_navbar({obj, generated})
    }else if(type=='header'){
      apply_obj=t_header({obj, generated})
    }else if(type=='footer'){
      apply_obj=t_footer({obj, generated})
    }else if(type=='headerDyn'){
      apply_obj=t_headerDyn({obj, generated})
    }else if(type=='imageElement'){
      apply_obj=t_imageElement({obj, generated})
    }else if(type=='buttonElement'){
      apply_obj=t_buttonElement({obj, generated})
    }else if(type=='textElement'){
      apply_obj=t_textElement({obj, generated})
    }


    console.log("Apply", apply_obj, obj)
    let blocks2 = cloneDeep(blocks);
    blocks2.unshift(apply_obj)
    console.log("blocks2", blocks2)
    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks2,
    });
    dispatch({ type: RESET_POPUP });
    /*
    pageInfo.blocks.unshift(apply_obj)
    console.log("pageInfo", project_id, pageInfo)
    dispatch({ type: UPDATE_PROJECT_PAGE, data:pageInfo });
    dispatch({ type: RESET_POPUP });
    */
    // let blk = cloneDeep(currentBlock);
    /*const blk = set(currentBlock, key, value);
    const mBlocks = blocks.map((item) => {
      if (item.metaDataId === blk.metaDataId) {
        return { ...blk };
      }
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: mBlocks,
    });
*/
}
const regenerate2 = () => {  
  dispatch({
    type: UPDATE_GENERATED,
    data: {regenerate:type, commandFrom:'section', [type]:false}
  });    
 }
    
 

  const [isView, setView] = useState(false)

  let code=JSON.stringify(obj)
  //code=code.replaceAll('\\"', '---');
  //code=code.replaceAll('"', '\\"');
  //code=code.replaceAll('---', "'");
  let d={messages:[]}
  d.messages[0]={role:'system', content:'You are a website builder which can build websites from JSON format'}  
  d.messages[1]={}
  d.messages[1].role='user'
  d.messages[1].content=user_prompt
  d.messages[2]={}
  d.messages[2].role='assistant'
  d.messages[2].content=code
  return(<div className={twdClass("group")}>
    <div className={twdClass("z-10 absolute top-0 right-0 left-0 px-4 py-4 -mt-14 opacity-0 group-hover:opacity-100")}>
      <div  className={twdClass("flex items-center gap-2")}>
        <div className={twdClass("bg-black text-white px-4 py-2 inline-block cursor-pointer")} onClick={()=>{setView(prev => prev=='code'? '' : 'code')}}>JSONL</div>
        <div className={twdClass("bg-black text-white px-4 py-2 inline-block cursor-pointer")} onClick={()=>{setView(prev => prev=='prompt'? '' : 'prompt'); console.log(user_prompt)}}>user_prompt</div>
        {format && <div className={twdClass("bg-black text-white px-4 py-2 inline-block cursor-pointer")} onClick={()=>{setView(prev => prev=='format'? '' : 'format'); console.log(format)}}>format</div>}
        <div className={twdClass("bg-black text-white px-4 py-2 inline-block cursor-pointer")} onClick={()=>{regenerate2()}}>Regenerate</div>
        {design && <div className={twdClass("bg-black text-white px-4 py-2 inline-block cursor-pointer")} onClick={()=>{apply()}}>Apply this Design to Page</div>}
      </div>
      {isView == "code" && <textarea className={twdClass("text-black bg-white px-4 py-4 w-full")}>{JSON.stringify(d)}</textarea>}
      {isView == "prompt" && <textarea className={twdClass("text-black bg-white px-4 py-4 w-full")}>{JSON.stringify(user_prompt)}</textarea>}
      {isView == "format" && <textarea className={twdClass("text-black bg-white px-4 py-4 w-full")}>{JSON.stringify(format.json_schema)}</textarea>}
    </div>
  </div>)
}
