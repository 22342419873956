import { combineReducers } from "redux";
import { appdetails } from "./details";
import { studioDetails, studioView } from "./studio";
import { modalpopup } from "./modal";
import { userdetails } from "./user";

export const rootReducer = combineReducers({
  appdetails,
  studioDetails,
  studioView,
  modalpopup,
  userdetails,
});
