import { getDefaultFields } from "../../jsons/common/common.fields";
import { twdClass } from "../../constants/tailwind.constants";
import RenderElements from "../form/RenderElements";
import { Button, BtnStyle, BtnBack, BtnEdit} from "../ui/com_ui";

export const BlockStylePanel = ({
  currentBlock,
  setActivePanel,
  updateElementValue,
}) => {
  const list = getDefaultFields({
    type: "TEXT",
    updateTo: `styleProps`,
    fields: ["backgroundcolor", "padding", "margin", "backgroundimage", "backgroundgradient"],
  });
  const overlay = getDefaultFields({
    type: "TEXT",
    updateTo: `overlay.styleProps`,
    fields: ["backgroundcolor", "backgroundimage", "backgroundgradient", "opacity"],
  });
  const columns = getDefaultFields({
    type: "TEXT",
    updateTo: `cardConfig.perRow`,
    fields: ["columns"],
  });

  const bg_section = { inputType: "section", label: "Bacground Style" }
  const overlay_section = { inputType: "section", label: "Overlay Style" }
  return (
    <div className={twdClass("px-3")}>
      <div className={twdClass("py-3 flex items-center")}>
        <BtnBack
          className={"mr-4"}
          onClick={() => setActivePanel("main")}
        >          
        </BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-gray-800 text-sm font-semibold")}>Section Styles</div>
        </div>
      </div>
      <div>
        {[...columns, bg_section, ...list, overlay_section, ...overlay].map((item) => (
          <div  className={twdClass("mb-1")}>
            <RenderElements
              element={item}
              data={currentBlock}
              updateElementValue={updateElementValue}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const CardStylePanel = ({
  setActivePanel,
  updateElementValue,
  currentBlock,
}) => {
  const list = getDefaultFields({
    type: "TEXT",
    updateTo: `cardConfig.cardstyleProps`,
    fields: [
      "backgroundcolor",
      "padding",
      "margin",
      "borderwidth",
      "borderradius",
      "bordercolor",
      "shadow",
    ],
  });

  return (
    <div className={twdClass("px-3")}>
      <div className={twdClass("py-3 flex items-center")}>
        <BtnBack
          className={"mr-4"}
          onClick={() => setActivePanel("designcard")}
        >
          <img alt="" src="/assets/images/icon_arrow_left.png" />
        </BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-gray-800 text-sm font-semibold")}>Card Styles</div>
        </div>
      </div>
      <div>
        {[...list].map((item) => (
          <div  className={twdClass("mb-1")}>
            <RenderElements
              element={item}
              data={currentBlock}
              updateElementValue={updateElementValue}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
