import { twdClass } from "../../constants/tailwind.constants";
import { useState } from "react";
import FaIcons from "../common/FaIcons";
import RenderElements from "./../form/RenderElements";
import { getDefaultFields } from "../../jsons/common/common.fields";

export const CardStyleElements = ({ currentBlock, updateElementValue }) => {
  const { stylesList } = currentBlock.configs;
  return (
    <div className={twdClass("px-6")}>
      {stylesList.map((item, index) => {
        return (
          <CardStyleElement
            elementData={item}
            data={currentBlock}
            updateElementValue={updateElementValue}
            stlyesPropsIndex={index}
          />
        );
      })}
    </div>
  );
};

const CardStyleElement = ({
  elementData,
  data,
  updateElementValue,
  stlyesPropsIndex,
}) => {
  const [showPanel, setShowPanel] = useState(false);
  return (
    <>
      <div
        className={twdClass(
          "flex justify-between p-2 bg-blue-100 my-2 cursor-pointer"
        )}
        onClick={() => setShowPanel(!showPanel)}
      >
        <span>{elementData.label}</span>
        <span>
          <FaIcons icon={`${showPanel ? "angleDown" : "angleUp"}`} />
        </span>
      </div>
      {showPanel && (
        <div>
          {/* {elementData.propsNames.map((item) => ( */}
          <GenerateElementToRender
            elementData={elementData}
            data={data}
            updateElementValue={updateElementValue}
            stlyesPropsIndex={stlyesPropsIndex}
          />
          {/* ))} */}
        </div>
      )}
    </>
  );
};

const GenerateElementToRender = ({
  elementData,
  data,
  updateElementValue,
  stlyesPropsIndex,
}) => {
  const list = getDefaultFields({
    type: "TEXT",
    updateTo: `configs.stylesList[${stlyesPropsIndex}].styleProps`,
    fields: elementData.propsNames,
  });
  return (
    <>
      {list.map((item) => (
        <RenderElements
          element={item}
          data={data}
          updateElementValue={updateElementValue}
        />
      ))}
    </>
  );
};
