
import  ctest  from "./ctest";
import  csample  from "./csample";
import  c05  from "./c05";
import  c06  from "./c06";
import  c07  from "./c07";
import  c08  from "./c08";
import  c09  from "./c09";
import  c10  from "./c10";
import  c11  from "./c11";
import  c12  from "./c12";
import  c13  from "./c13";
import  c14  from "./c14";
import  c15  from "./c15";

let d01=[
  {
    title:"Lee Hawlkings",
    role:"Bubble",
    desc:"An innovative problem-solver with a proven track record in creating dynamic and complex web applications using Bubble",
    rate:"$100 per hour",
    email:"lee._hawlkings@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527389070.jpg",
    details_link:"/expert/1"
  },
  {
    title:"Adam Grey",
    role:"Webflow, Zapier",
    desc:"A seasoned Webflow designer who excels in automating workflows with Zapier to create seamless user experiences",
    rate:"$200 per hour",
    email:"adam._grey@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527401764.jpg",
    details_link:"/expert/2"
  },
  {
    title:"Samra Chan",
    role:"Zapier, Flezr",
    desc:"A versatile no-code expert with a knack for integrating apps via Zapier and building robust dynamic websites with Flezr",
    rate:"$250 per hour",
    email:"samra._chan@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527330757.jpg",
    details_link:"/expert/3"
  }
]



let sd01={
  title:"No-Code Experts",
  description:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.",
}

let a01={
  cards:{
    title:{text:"No-Code Experts", styleProps:{textAlign: 'center', color:' #ececec', fontSize: '2.25rem', lineHeight: '2.5rem', margin: '40px 0px 20px'}},
    description:{text:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.", styleProps:{color: 'rgb(236, 236, 236)', fontSize: '1.25rem', lineHeight: '1.75rem',  margin: '10px 100px', textAlign: 'center'}},
    searchbar:{
      styleProps:{ display:'flex', backgroundColor: 'rgb(97, 179, 194)', borderRadius: '1.5rem', padding: '10px 20px 10px 10px', margin: '30px 0px'},
      search_input:{ 
        styleProps:{borderRadius: '9999px',  padding: '5px 20px', color: 'rgb(51, 51, 51)', fontWeight: 100, backgroundColor: 'rgb(255, 255, 255)', width: 'auto'}
      },
      search_button:{
        styleProps:{color: 'rgb(51, 51, 51)', padding: '5px 20px', backgroundColor: 'rgb(207, 187, 134)', borderRadius: '1.5rem', margin: '0px 10px',        fontWeight: 'bold', width: 'auto'}
      },
      clear_button:{
        styleProps:{backgroundColor: 'rgb(236, 236, 236)',  color: 'rgb(51, 51, 51)', padding: '5px 20px', borderRadius: '9999px'}
      }
    },
    card_template:{
      card_columns:[
        {
          styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps:{width:'200px', height:'200px', borderRadius:'100px'} },
            {elementType:"headingEl", text:"[[title]]", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'5px 10px'} },
            {elementType:"textEl", text:"[[role]]", styleProps:{textAlign: 'center', fontSize: '1.25rem',lineHeight: '1.75rem',color: 'rgb(97, 179, 194)',    margin: '0px 0px 20px'} }, 
            {elementType:"textEl", text:"[[desc]]", styleProps:{textAlign: 'center', padding: '0px 20px 20px', color: 'rgb(51, 51, 51)'} }, 
            {elementType:"textEl", text:"[[rate]]", styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
            {elementType:"textEl", text:"Contact: [[email]]", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            {elementType:"buttonEl", text:"View More", url:"[[details_link]]", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} }
          ]
        }
      ],
    },
    card_items:[
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
            elements:[              
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527389070.jpg", styleProps:{width:'200px', height:'200px', borderRadius:'100px'} },
              {elementType:"headingEl", text:"Lee Hawlkings", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'5px 10px'} },
              {elementType:"textEl", text:"Bubble", styleProps:{textAlign: 'center', fontSize: '1.25rem',lineHeight: '1.75rem',color: 'rgb(97, 179, 194)',    margin: '0px 0px 20px'} }, 
              {elementType:"textEl", text:"An innovative problem-solver with a proven track record in creating dynamic and complex web applications using Bubble", styleProps:{textAlign: 'center', padding: '0px 20px 20px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$100 per hour", styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: lee._hawlkings@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }
        ],
      },
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
            elements:[
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527401764.jpg", styleProps:{width:'200px', height:'200px', borderRadius:'100px'} },
              {elementType:"headingEl", text:"Adam Grey", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'5px 10px'} },
              {elementType:"textEl", text:"Webflow, Zapier",  styleProps:{textAlign: 'center', fontSize: '1.25rem',lineHeight: '1.75rem',color: 'rgb(97, 179, 194)',    margin: '0px 0px 20px'} }, 
              {elementType:"textEl", text:"A seasoned Webflow designer who excels in automating workflows with Zapier to create seamless user experiences", styleProps:{textAlign: 'center', padding: '0px 20px 20px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$200 per hour",styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: adam._grey@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }
        ],
      }, 
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
            elements:[
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527330757.jpg", styleProps:{width:'200px', height:'200px', borderRadius:'100px'} },
              {elementType:"headingEl", text:"Samra Chan", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'5px 10px'} },
              {elementType:"textEl", text:"Zapier, Flezr",  styleProps:{textAlign: 'center', fontSize: '1.25rem',lineHeight: '1.75rem',color: 'rgb(97, 179, 194)',    margin: '0px 0px 20px'} }, 
              {elementType:"textEl", text:"A versatile no-code expert with a knack for integrating apps via Zapier and building robust dynamic websites with Flezr", styleProps:{textAlign: 'center', padding: '0px 20px 20px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$250 per hour",styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: samra._chan@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }
        ],
      }    
    ],
    styleProps:{backgroundColor:'#081113', color:'#ececec', padding:'80px 20px'},
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{padding:'20px', backgroundColor: 'rgb(246, 255, 255)', borderRadius: '1rem', color:'#000000', boxShadow:'4px 4px 2px rgba(0,0,0,0.1)'} 
    }
  }
}



let d02=[
  {
    title:"International Food Festival",
    location:"Sydney, Australia | July 18, 2023",
    desc:"Join us for a celebration of international cuisine at Sydney. A myriad of food stalls showcasing dishes from around the world.",
    rate:"$100 per hour",
    email:"lee._hawlkings@gmail.com",
    image:"https://i.imgur.com/vdGUz6Q.jpg",
    details_link:"/event/1"
  },
  {
    title:"Music in the City Festiva",
    location:"Vienna, Austria | July 24, 2023",
    desc:"Enjoy a musical extravaganza in the music capital of the world. Features performances by world-renowned musicians and up-and-coming talents",
    rate:"$200 per hour",
    email:"adam._grey@gmail.com",
    image:"https://i.imgur.com/69Hj7Ri.jpg",
    details_link:"/event/2"
  },
  {
    title:"Global Business Conference",
    location:"Dubai, UAE | July 26, 20",
    desc:"Meet business leaders and influencers from around the globe. Exchange ideas and discuss strategies for global business growth.",
    rate:"$250 per hour",
    email:"samra._chan@gmail.com",
    image:"https://i.imgur.com/NTiUgEf.jpg",
    details_link:"/event/3"
  }
]

let sd02={
  title:"Upcoming Events",
  description:"Discover what's on the horizon with our detailed directory of global events, from food festivals to business conferences.",
}


let a02={
  cards:{
    title:{text:"Upcoming Events", styleProps:{textAlign: 'center', color: 'rgb(204, 204, 204)', fontSize: '3rem', lineHeight: 1, padding: '50px 0px 0px'}},
    description:{text:"Discover what's on the horizon with our detailed directory of global events, from food festivals to business conferences.", styleProps:{color:' rgb(204, 204, 204)', fontSize: '1.5rem', lineHeight: '2rem', margin: '10px 0px', textAlign: 'center', padding: '30px 50px'}},
    searchbar:{
      styleProps:{ display:'flex', borderRadius:' 1.5rem', padding: '10px 20px', margin: '30px 0px', width: 'auto', borderColor: 'rgb(120, 94, 94)', borderWidth: '2px'},
      search_input:{ 
        styleProps:{color: 'rgb(36, 36, 36)',  padding: '5px 20px', backgroundColor: '#ffffff', borderRadius: '1.5rem', margin: '0px 10px',      fontWeight: 'bold', width: 'auto'}
      },
      search_button:{
        styleProps:{color: 'rgb(51, 51, 51)', padding: '5px 20px', backgroundColor: 'rgb(207, 187, 134)', borderRadius: '1.5rem', margin: '0px 10px',        fontWeight: 'bold', width: 'auto'}
      },
      clear_button:{
        styleProps:{backgroundColor: 'rgb(236, 236, 236)',  color: 'rgb(51, 51, 51)', padding: '5px 20px', borderRadius: '9999px'}
      }
    },
    card_template:{
      card_columns:[
        {
          styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width:"100%"} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps:{borderRadius: '50%',  margin:' -100px 0px 0px'} },             
          ]
        },
        {
          styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: '30px 0px 0px', width:"100%"} ,
          elements:[  
            {elementType:"headingEl", text:"[[title]]", styleProps:{fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 700, margin: '0px',
              color: 'rgb(36, 36, 36)'} },
            {elementType:"textEl", text:"[[location]]", styleProps:{padding: '20px 10px 0px', color: 'rgb(120, 94, 94)', fontWeight: 700} }, 
            {elementType:"textEl", text:"[[desc]]", styleProps:{padding: '30px', color: 'rgb(36, 36, 36)'} }, 
            {elementType:"textEl", text:"[[rate]]", styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
            {elementType:"textEl", text:"Contact: [[email]]", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            {elementType:"buttonEl", text:"View More", url:"[[details_link]]", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} }
          ]
        }
      ],
    },
    card_items:[
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
            elements:[              
              {elementType:"imageEl", src:"https://i.imgur.com/vdGUz6Q.jpg", styleProps:{borderRadius: '50%',  margin:' -100px 0px 0px'} },             
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: '30px 0px 0px'} ,
            elements:[  
              {elementType:"headingEl", text:"International Food Festival", styleProps:{fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 700, margin: '0px',
                color: 'rgb(36, 36, 36)'} },
              {elementType:"textEl", text:"Sydney, Australia | July 18, 2023", styleProps:{padding: '20px 10px 0px', color: 'rgb(120, 94, 94)', fontWeight: 700} }, 
              {elementType:"textEl", text:"Join us for a celebration of international cuisine at Sydney. A myriad of food stalls showcasing dishes from around the world.", styleProps:{padding: '30px', color: 'rgb(36, 36, 36)'} }, 
              {elementType:"textEl", text:"$100 per hour", styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: lee._hawlkings@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
              {elementType:"buttonEl", text:"View More", url:"[[details_link]]", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} }
            ]
          }
        ],
      },
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
            elements:[              
              {elementType:"imageEl", src:"https://i.imgur.com/69Hj7Ri.jpg", styleProps:{borderRadius: '50%', margin:' -100px 0px 0px'} },             
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: '30px 0px 0px'} ,
            elements:[              
              {elementType:"headingEl", text:"Music in the City Festival", styleProps:{fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 700, margin: '0px',
                color: 'rgb(36, 36, 36)'} },
              {elementType:"textEl", text:"Vienna, Austria | July 24, 2023",  styleProps:{padding: '20px 10px 0px', color: 'rgb(120, 94, 94)', fontWeight: 700}  }, 
              {elementType:"textEl", text:"Enjoy a musical extravaganza in the music capital of the world. Features performances by world-renowned musicians and up-and-coming talents.", styleProps:{padding: '30px', color: 'rgb(36, 36, 36)'} }, 
              {elementType:"textEl", text:"$200 per hour",styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: adam._grey@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }
        ],
      },
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'} ,
            elements:[              
              {elementType:"imageEl", src:"https://i.imgur.com/NTiUgEf.jpg", styleProps:{borderRadius: '50%',  margin:' -100px 0px 0px'} },             
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: '30px 0px 0px'} ,
            elements:[              
              {elementType:"headingEl", text:"Global Business Conference",styleProps:{fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 700, margin: '0px',
                color: 'rgb(36, 36, 36)'} },
              {elementType:"textEl", text:"Dubai, UAE | July 26, 2023",  styleProps:{padding: '20px 10px 0px', color: 'rgb(120, 94, 94)', fontWeight: 700}  }, 
              {elementType:"textEl", text:"Meet business leaders and influencers from around the globe. Exchange ideas and discuss strategies for global business growth.", styleProps:{padding: '30px', color: 'rgb(36, 36, 36)'} }, 
              {elementType:"textEl", text:"$250 per hour",styleProps:{textAlign: 'center', fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: samra._chan@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: 'rgb(207, 187, 134)', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          }
        ],
      }    
    ],
    styleProps:{backgroundColor: 'rgb(36, 36, 36)', padding: '10px 20px'},
    cards_holder_styles:{padding:''},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },    
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{ backgroundColor: 'rgb(255, 255, 255)', textAlign: 'center', margin: '100px 30px 50px', borderRadius: '1rem', padding: '0px 10px 30px'} 
    },
    cards_item_cols_styles:[
      {
        styleProps:{padding:' 30px 0px 0px'}       
      }
    ]
  }
}

let d03=[
  {
    title:"Jasper Art",
    rating:'⭐⭐⭐⭐⭐',
    price:"₹ 3,999.00",
    desc:"Create Any Art Or Image In Seconds With Jasper Art. Try Our AI Image Generator Now! Turn Your Imagination Into Unique Images And Amazing Art In Seconds.",
    image:"https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&w=1600",
    details_url:"/jasper-art"
  },
  {
    title:"Midjourney",
    rating:'⭐⭐⭐⭐⭐',
    price:"Game Pass",
    desc:"Turn words into stunning AI-generated Art & Drawings instantly. Unlock your creativity. Discover The Magic Of AI-Generated Art: See What Our Text-To-Art Generator Can Create.",
    image:"https://images.pexels.com/photos/17485740/pexels-photo-17485740/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-visualises-the-flow-of-information-within-large-language-models-it-was-created-by-tim-west-as-part-of-the-visualising.png?auto=compress&cs=tinysrgb&w=1600",
    details_url:"/midjourney"
  },
  {
    title:"Dall-E 3",
    rating:'⭐⭐⭐⭐⭐',
    price:"Free",
    desc:"DALL·E 3 is built natively on ChatGPT, which lets you use ChatGPT as a brainstorming partner and refiner of your prompts. Just ask ChatGPT what you want to see",
    image:"https://images.pexels.com/photos/17485742/pexels-photo-17485742/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-represents-how-ai-can-be-applied-invisibly-and-beneficially-to-fields-as-diverse-as-medicine-transport-urban-planning.png?auto=compress&cs=tinysrgb&w=1600",
    details_url:"/dall-e-3"
  }
]

let sd03={
  title:"Explore the top AI Image Generators",
  description:"These AI image generator tools will get you the best AI-generated images using text-to-image AI.",
}

let a03={
  cards:{
    title:{text:"Explore the top AI Image Generators", styleProps:{textAlign: 'center', color: 'rgb(255, 255, 255)', fontSize: '4.5rem', lineHeight: 1,
      fontWeight: 700, fontFamily: '"Indie Flower", cursive'}},
    description:{text:"These AI image generator tools will get you the best AI-generated images using text-to-image AI.", styleProps:{color: 'rgba(255, 255, 255, 0.6)', fontSize: '3rem', lineHeight: 1, margin: '10px 0px', textAlign: 'center', padding: '50px 220px 80px', fontFamily: '"Indie Flower", cursive'}},
    searchbar:{
      styleProps:{ display:'flex', borderRadius:' 1.5rem', padding: '10px 20px', margin: '30px 0px', width: 'auto', borderColor: 'rgb(120, 94, 94)', borderWidth: '2px'},
      search_input:{ 
        styleProps:{color: 'rgb(36, 36, 36)',  padding: '5px 20px', backgroundColor: '#ffffff', borderRadius: '1.5rem', margin: '0px 10px',      fontWeight: 'bold', width: 'auto'}
      },
      search_button:{
        styleProps:{color: 'rgb(51, 51, 51)', padding: '5px 20px', backgroundColor: 'rgb(207, 187, 134)', borderRadius: '1.5rem', margin: '0px 10px',        fontWeight: 'bold', width: 'auto'}
      },
      clear_button:{
        styleProps:{backgroundColor: 'rgb(236, 236, 236)',  color: 'rgb(51, 51, 51)', padding: '5px 20px', borderRadius: '9999px'}
      }
    },
    cards_per_row:{
      desktop:3,
      tab:2,
      mobile:1
    },
    card_template:{
      card_columns:[
        {
          styleProps:{} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps:{borderRadius: '1rem', width:'100%'} },             
          ]
        },
        {
          styleProps:{ padding: '50px 50px 20px', width: '100%'} ,
          elements:[  
            {elementType:"headingEl", text:"[[title]]", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 20px 0px',
              color: 'rgb(255, 255, 255)'} },
            {elementType:"textEl", text:"[[rating]]", styleProps:{} }, 
            {elementType:"textEl", text:"[[price]]", styleProps:{padding: '10px 0px 30px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(36, 174, 187)', fontWeight: 500} }, 
            {elementType:"textEl", text:"[[desc]]", styleProps:{color: 'rgba(255, 255, 255, 0.4)', fontSize: '1.25rem', lineHeight: '1.75rem',
              padding: '0px 0px 10px'} },   
          ]
        },
        {
          styleProps:{width: '100%', padding: '20px 80px 40px', textAlign: 'center'} ,
          elements:[              
            {elementType:"buttonEl", text:"View More", url:"[[details_url]]", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} },            
          ]
        },
      ],
    },
    card_items:[
      {
        card_columns:[
          {
            styleProps:{} ,
            elements:[              
              {elementType:"imageEl", src:"https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&w=1600", styleProps:{borderRadius: '1rem', width:'100%'} },             
            ]
          },
          {
            styleProps:{ padding: '50px 50px 20px', width: '100%'} ,
            elements:[  
              {elementType:"headingEl", text:"Jasper Art", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 20px 0px',
                color: 'rgb(255, 255, 255)'} },
              {elementType:"textEl", text:"⭐⭐⭐⭐⭐", styleProps:{} }, 
              {elementType:"textEl", text:"₹ 3,999.00", styleProps:{padding: '10px 0px 30px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(36, 174, 187)', fontWeight: 500} }, 
              {elementType:"textEl", text:"Create Any Art Or Image In Seconds With Jasper Art. Try Our AI Image Generator Now! Turn Your Imagination Into Unique Images And Amazing Art In Seconds.", styleProps:{color: 'rgba(255, 255, 255, 0.4)', fontSize: '1.25rem', lineHeight: '1.75rem',
                padding: '0px 0px 10px'} },   
            ]
          },
          {
            styleProps:{width: '100%', padding: '20px 80px 40px', textAlign: 'center'} ,
            elements:[              
              {elementType:"buttonEl", text:"View More", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} },            
            ]
          },
        ],
      },
      {
        card_columns:[
          {
            styleProps:{} ,
            elements:[              
              {elementType:"imageEl", src:"https://images.pexels.com/photos/17485740/pexels-photo-17485740/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-visualises-the-flow-of-information-within-large-language-models-it-was-created-by-tim-west-as-part-of-the-visualising.png?auto=compress&cs=tinysrgb&w=1600", styleProps:{borderRadius: '1rem', width:'100%'} },             
            ]
          },
          {
            styleProps:{ padding: '50px 50px 20px', width: '100%'} ,
            elements:[  
              {elementType:"headingEl", text:"Midjourney", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 20px 0px',
                color: 'rgb(255, 255, 255)'} },
              {elementType:"textEl", text:"⭐⭐⭐⭐⭐", styleProps:{} }, 
              {elementType:"textEl", text:"Game Pass", styleProps:{padding: '10px 0px 30px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(36, 174, 187)', fontWeight: 500} }, 
              {elementType:"textEl", text:"Turn words into stunning AI-generated Art & Drawings instantly. Unlock your creativity. Discover The Magic Of AI-Generated Art: See What Our Text-To-Art Generator Can Create.", styleProps:{color: 'rgba(255, 255, 255, 0.4)', fontSize: '1.25rem', lineHeight: '1.75rem',
                padding: '0px 0px 10px'} },   
            ]
          },
          {
            styleProps:{width: '100%', padding: '20px 80px 40px', textAlign: 'center'} ,
            elements:[              
              {elementType:"buttonEl", text:"View More", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} },            
            ]
          },
        ],
      },
      {
        card_columns:[
          {
            styleProps:{} ,
            elements:[              
              {elementType:"imageEl", src:"https://images.pexels.com/photos/17485742/pexels-photo-17485742/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-represents-how-ai-can-be-applied-invisibly-and-beneficially-to-fields-as-diverse-as-medicine-transport-urban-planning.png?auto=compress&cs=tinysrgb&w=1600", styleProps:{borderRadius: '1rem', width:'100%'} },             
            ]
          },
          {
            styleProps:{ width: '100%', padding: '50px 50px 20px'} ,
            elements:[  
              {elementType:"headingEl", text:"Dall-E 3", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 20px 0px',
                color: 'rgb(255, 255, 255)'} },
              {elementType:"textEl", text:"⭐⭐⭐⭐⭐", styleProps:{} }, 
              {elementType:"textEl", text:"Free", styleProps:{padding: '10px 0px 30px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(36, 174, 187)', fontWeight: 500} }, 
              {elementType:"textEl", text:"DALL·E 3 is built natively on ChatGPT, which lets you use ChatGPT as a brainstorming partner and refiner of your prompts. Just ask ChatGPT what you want to see", styleProps:{color: 'rgba(255, 255, 255, 0.4)', fontSize: '1.25rem', lineHeight: '1.75rem',
                padding: '0px 0px 10px'} },   
            ]
          },
          {
            styleProps:{width: '100%', padding: '20px 80px 40px', textAlign: 'center'} ,
            elements:[              
              {elementType:"buttonEl", text:"View More", styleProps:{ margin: '20px 0px 0px', color: 'rgb(255, 255, 255)', fontSize: '1.5rem', lineHeight: '2rem',  fontWeight: 500, width: '100%', backgroundColor:' rgb(34, 170, 182)', borderWidth: '0px', padding: '14px 0px', borderRadius: '9999px'} },            
            ]
          },
        ],
      },   
    ],
    styleProps:{backgroundColor: 'rgb(47, 56, 61)', padding: '180px 20px'},
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },    
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{ borderRadius:' 1.5rem',  backgroundColor:' rgb(24, 29, 32)', padding: '6px'} 
    },   
  }
}

let d04=[
  {
    title:"THE 4 BEST HOSTELS IN SINGAPORE",
    location:"Singapore",
    desc:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the",
    image:"https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1600",
    details_url:'/details/1',
  },
  {
    title:"WHERE TO STAY IN SINGAPOR",
    location:"Singapore",
    desc:"Dedicated to a truly sustainable and sincerely responsible travel & tourism industry",
    image:"https://images.pexels.com/photos/3152126/pexels-photo-3152126.jpeg?auto=compress&cs=tinysrgb&w=1600",
    details_url:'/details/2',
  },
  {
    title:"HOW TO TRAVEL SINGAPORE WITH A BABY",
    location:"Singapore",
    desc:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the",
    image:"https://images.pexels.com/photos/1907049/pexels-photo-1907049.jpeg?auto=compress&cs=tinysrgb&w=1600",
    details_url:'/details/3',
  }
]

let sd04={
  title:"Explore the top Best Hotels",
  description:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the world.",
}

let a04={
  cards:{
    title:{text:"Explore the top Best Hotels", 
      styleProps:{textAlign: 'center', color: 'rgb(51, 51, 51)', fontSize: '4.5rem', lineHeight: 1, fontWeight: 500,  padding: '0px 20px'},
      stylePropsMob:{fontSize: '2.5rem', padding: '0px 20px'}
    },
    description:{text:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the world.", 
      styleProps:{color: 'rgb(51, 51, 51)', fontSize: '1.875rem', lineHeight: '2.25rem', margin: '10px 0px', textAlign: 'center',
      padding: '50px 120px'},
      stylePropsMob:{fontSize: '1.4rem', lineHeight: '1.6rem', margin: '10px 0px', padding: '50px 20px'},
    },
    searchbar:{
      styleProps:{ display:'flex', flexWrap:'wrap', gap:'10px', borderRadius:' 1.5rem', padding: '10px 20px', margin: '30px 0px', width: 'auto', borderColor: 'rgb(51 51 51)', borderWidth: '2px'},
      search_input:{ 
        styleProps:{color: 'rgb(36, 36, 36)',  padding: '5px 20px', backgroundColor: '#ffffff', borderRadius: '1.5rem', margin: '0px 10px',      fontWeight: 'bold', width: 'auto'}
      },
      search_button:{
        styleProps:{color: 'rgb(255, 255, 255)', padding: '5px 20px', backgroundColor: 'rgb(51 51 51)', borderRadius: '1.5rem', margin: '0px 10px',        fontWeight: 'bold', width: 'auto'}
      },
      clear_button:{
        styleProps:{backgroundColor: 'rgb(236, 236, 236)',  color: 'rgb(51, 51, 51)', padding: '5px 20px', borderRadius: '9999px'}
      }
    },
    cards_per_row:{
      desktop:3,
      tab:2,
      mobile:1
    },
    card_template:{
      card_columns:[
        {
          styleProps:{} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps:{borderRadius: '1rem', width:'100%'} },             
          ]
        },
        {
          styleProps:{ padding: '50px 20px 20px', width: '100%'} ,
          elements:[  
            {elementType:"headingEl", text:"[[title]]", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 50px 0px'} },   
            {elementType:"textEl", text:"[[desc]]", styleProps:{color: 'rgb(51, 51, 51)', fontSize: '1.25rem', lineHeight: '1.75rem', padding: '0px 0px 10px'} }, 
          ]
        },
        {
          styleProps:{padding: '50px 20px 20px', width: '50%'} ,
          elements:[              
            {elementType:"textEl", text:"[[location]]", styleProps:{padding: '0px 0px 0px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(17, 109, 255)',  fontWeight: 500} },           
          ]
        },
        {
          styleProps:{padding: '50px 20px 20px', width: '50%'} ,
          elements:[              
            {elementType:"buttonEl", text:"More details ➜", url:"[[details_url]]", styleProps:{ margin: '0px 0px 0px', color: 'rgb(255, 166, 17)', fontSize: '1.25rem', lineHeight: '1.75rem',fontWeight: 500} },            
          ]
        },
      ],
    },
    card_items:[
      {
        card_columns:[
          {
            styleProps:{} ,
            elements:[              
              {elementType:"imageEl", src:"https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1600", styleProps:{borderRadius: '1rem', width:'100%'} },             
            ]
          },
          {
            styleProps:{ padding: '50px 20px 20px', width: '100%'} ,
            elements:[  
              {elementType:"headingEl", text:"THE 4 BEST HOSTELS IN SINGAPORE", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 50px 0px'} },   
              {elementType:"textEl", text:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the", styleProps:{color: 'rgb(51, 51, 51)', fontSize: '1.25rem', lineHeight: '1.75rem', padding: '0px 0px 10px'} }, 
            ]
          },
          {
            styleProps:{padding: '50px 20px 20px', width: '50%'} ,
            elements:[              
              {elementType:"textEl", text:"Singapore", styleProps:{padding: '0px 0px 0px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(17, 109, 255)',  fontWeight: 500} },           
            ]
          },
          {
            styleProps:{padding: '50px 20px 20px', width: '50%'} ,
            elements:[              
              {elementType:"textEl", text:"More details ➜", styleProps:{ margin: '0px 0px 0px', color: 'rgb(255, 166, 17)', fontSize: '1.25rem', lineHeight: '1.75rem',fontWeight: 500} },            
            ]
          },
        ],
      },      
      {
        card_columns:[
          {
            styleProps:{} ,
            elements:[              
              {elementType:"imageEl", src:"https://images.pexels.com/photos/3152126/pexels-photo-3152126.jpeg?auto=compress&cs=tinysrgb&w=1600", styleProps:{borderRadius: '1rem', width:'100%'} },             
            ]
          },
          {
            styleProps:{ padding: '50px 20px 20px', width: '100%'} ,
            elements:[  
              {elementType:"headingEl", text:"WHERE TO STAY IN SINGAPORE", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 50px 0px'} },   
              {elementType:"textEl", text:"Dedicated to a truly sustainable and sincerely responsible travel & tourism industry", styleProps:{color: 'rgb(51, 51, 51)', fontSize: '1.25rem', lineHeight: '1.75rem', padding: '0px 0px 10px'} }, 
            ]
          },
          {
            styleProps:{padding: '50px 20px 20px', width: '50%'} ,
            elements:[              
              {elementType:"textEl", text:"Singapore", styleProps:{padding: '0px 0px 0px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(17, 109, 255)',  fontWeight: 500} },           
            ]
          },
          {
            styleProps:{padding: '50px 20px 20px', width: '50%'} ,
            elements:[              
              {elementType:"textEl", text:"More details ➜", styleProps:{ margin: '0px 0px 0px', color: 'rgb(255, 166, 17)', fontSize: '1.25rem', lineHeight: '1.75rem',fontWeight: 500} },            
            ]
          },
        ],
      },  
      {
        card_columns:[
          {
            styleProps:{} ,
            elements:[              
              {elementType:"imageEl", src:"https://images.pexels.com/photos/1907049/pexels-photo-1907049.jpeg?auto=compress&cs=tinysrgb&w=1600", styleProps:{borderRadius: '1rem', width:'100%'} },             
            ]
          },
          {
            styleProps:{ padding: '50px 20px 20px', width: '100%'} ,
            elements:[  
              {elementType:"headingEl", text:"HOW TO TRAVEL SINGAPORE WITH A BABY", styleProps:{fontSize: '2.25rem', lineHeight: '2.5rem', fontWeight: 500, padding: '0px 60px 50px 0px'} },   
              {elementType:"textEl", text:"Make your vacations unforgettable! The ultimate guide to adventure travel! Get expert tips for travel, cheap deals, travel prizes & adventures around the", styleProps:{color: 'rgb(51, 51, 51)', fontSize: '1.25rem', lineHeight: '1.75rem', padding: '0px 0px 10px'} }, 
            ]
          },
          {
            styleProps:{padding: '50px 20px 20px', width: '50%'} ,
            elements:[              
              {elementType:"textEl", text:"Singapore", styleProps:{padding: '0px 0px 0px', fontSize: '1.25rem', lineHeight: '1.75rem', color: 'rgb(17, 109, 255)',  fontWeight: 500} },           
            ]
          },
          {
            styleProps:{padding: '50px 20px 20px', width: '50%'} ,
            elements:[              
              {elementType:"buttonEl", text:"More details ➜", styleProps:{ margin: '0px 0px 0px', color: 'rgb(255, 166, 17)', fontSize: '1.25rem', lineHeight: '1.75rem',fontWeight: 500} },            
            ]
          },
        ],
      },      
    ],
    styleProps:{ backgroundColor: 'rgb(247, 160, 114)', padding: '110px 20px'},
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },    
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{ borderRadius: '1.5rem', backgroundColor: 'rgb(255, 255, 255)', padding: '20px 20px 40px', display:'flex', flexWrap:'wrap'} 
    },   
  }
}




export default {
  a01, d01, sd01, 
  a02, d02, sd02, 
  a03, d03, sd03, 
  a04, d04, sd04, 
  a05:c05.a, d05:c05.d, sd05:c05.sd,
  a06:c06.a, d06:c06.d, sd06:c06.sd,
  atest:ctest.a, dtest:ctest.d, sdtest:ctest.sd,
  asample:csample.a,
  c07,
  c08,
  c09,
  c10,
  c11,
  c12,
  c13,
  c14,
  c15
}
