export const CTA_01_BLOCK = {
  blockType: "CTA",
  blockDesign: "CTADesign1",
  editFormName: "CTA_01_FORM",
  styleProps: {
    "background-color": "#1b1b31",
  },
  headings: [
    {
      text: "Industry Associations",
      styleProps: {
        "text-align": "center",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
        padding: "50px 0px 10px 0px",
        color: "#ffffff",
      },
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      styleProps: {
        "text-align": "center",
        padding: "10px 0px 10px 0px",
        color: "#ffffff",
      },
    },
  ],
  showBtnSec: true,
  btnSecStyleProps: {},
  btnSecStylePropsTab: {},
  btnSecStylePropsMob: {},
  buttons: [
    {
      text: "Click Here",
      alt: "Click Here",
      href: "https://yahoo.com",
      styleProps: {
        "background-color": "#1b1b31",
        color: "#ffffff",
        "border-color": "#3b82f680",
      },
    },
    {
      text: "Click Here",
      alt: "Click Here",
      href: "https://yahoo.com",
      styleProps: {
        "background-color": "#1b1b31",
        color: "#ffffff",
        "border-color": "#3b82f680",
      },
    },
  ],
  showEmailSec: true,
  emailSecStyleProps: {},
  subscription: {
    showSection: "show",
    display: "enable",
    subConfigs: {
      configType: "convertkit",
      configUrl: "https://app.convertkit.com/forms/xxxxxx/subscriptions",
      configPlaceholder: "",
    },
    email_placeholder: {
      text: "example@mail.com",
    },
    buttonText: {
      text: "Subscribe",
      styleProps: {
        "background-color": "#1b1b31",
        color: "#ffffff",
      },
    },
    successMessage: {
      text: "Thank you for the signup",
      styleProps: {},
    },
    errorMessage: {
      text: "Something went wrong. Please try again.",
      styleProps: {},
    },
  },
};
