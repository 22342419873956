import { twdClass } from "../../constants/tailwind.constants";
import ToggleBtn from "../common/ToggleBtn";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { Button, BtnStyle, BtnBack } from "../ui/com_ui";
import { SearchBarPanel } from "./SearchBarPanel";
import { BaseSettingsPanel } from "./BaseSettingsPanel";



export const GeneralSettings = ({
  data,
  updateElementValue,
  setActivePanel,
  columnNames,
  setactiveCatId,
  setActiveElementId,
  currentConectType,
}) => {
  const { configs } = data;
  return (
    <div>
      <div className={twdClass("px-2 py-5 flex items-center")}>
        <BtnBack
          className={"mr-4"}
          onClick={() => setActivePanel("main")}
        ></BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-gray-800 text-sm font-semibold")}>
            General settings
          </div>
        </div>
      </div>
      <div className={twdClass("px-3 pt-1 pb-1 ")}>
        <BaseSettingsPanel
          updateElementValue={updateElementValue}
          configs={configs}          
        />
      </div>
      
    </div>
  );
};
