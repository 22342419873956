import { twdClass } from "../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import ToggleBtn from "../common/ToggleBtn";
import { PricingBlocks } from "./PricingBlocks";
import { useSelector, useDispatch } from "react-redux";
import { updatePlanInfo } from "../../api/services";
import { PRICING_METADATA } from "./pricing.metadata";

export const PricingLayout = () => {
  const [myPlan, setMyPlan] = useState("M");
  const { email, plan_id } = useSelector((state) => state.userdetails);
  const dispatch = useDispatch();
  const updatePlan = (plan) => {
    const planId = plan[`planId${myPlan}`];
    const Paddle = window.Paddle;
    // console.log(email, parseInt(planId));
    Paddle.Checkout.open({
      email,
      product: parseInt(planId),
      successCallback: function (data) {
        updatePlanInfo({
          plan_id: planId,
          max_cust_domain: plan.max_cust_domain,
          max_sub_domain: plan.max_sub_domain,
        }).then((resp) => {
          if (resp.success) {
            dispatch({ type: "UPDATE_LOGINUSER", data: resp.data });
          }
        });
      },
      closeCallback: function () {},
    });
  };

  useEffect(() => {
    const currentPlan = PRICING_METADATA.filter(
      (item) =>
        item.planIdM === parseInt(plan_id) || item.planIdY === parseInt(plan_id)
    )[0];
    if (currentPlan) {
      Object.keys(currentPlan).forEach((item) => {
        if (currentPlan[item] === parseInt(plan_id)) {
          setMyPlan(item.replace("planId", ""));
        }
      });
    }
  }, [plan_id]);

  return (
    <div>
      <div>
        <h2 className={twdClass("text-center text-3xl font-bold")}>
          Choose Your Plan
        </h2>
        <div className={twdClass("flex justify-center my-2")}>
          <ToggleBtn
            toggleId="pricing"
            value={myPlan === "M"}
            falseLabel="Yearly"
            trueLabel="Monthly"
            updateValue={(value) => {
              setMyPlan(value ? "Y" : "M");
            }}
          />
        </div>
      </div>
      <PricingBlocks
        myPlan={myPlan}
        updatePlan={updatePlan}
        plan_id={plan_id}
      />
    </div>
  );
};
