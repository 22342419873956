import { getDefaultFields } from "../common/common.fields";

export const CAROUSEL_ELEMENT_FORM = {
  sectionName: "Carousel Section",
  elements: [
    {
      inputType: "list",
      label: "Image info",
      hasStyleProps: false,
      styleElementId: 1,
      stylesProps: {},
      updateTo: "list",
      mockElements: {
        elements: [
          {
            inputType: "image",
            label: "Image Link",
            defaultValue:
              "https://images.unsplash.com/photo-1590602391165-edb096edb9de?auto=format&fit=crop&w=1488&q=80",
            updateTo: "list[i].image",
            extraPropUpdateTo: "list[i]",
            extraPropValues: {
              image:
                "https://images.unsplash.com/photo-1590602391165-edb096edb9de?auto=format&fit=crop&w=1488&q=80",
            },            
          },
          {
            inputType: "text",
            updateTo: "list[i].imageAlt",
            label: "Alt",
            defaultValue:"",
            hasStyleProps: false
          },
        ],
        hasStyleProps: false,
        styleElementId: 2,
        stylesProps: {},
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Carousel section",
      elements: [
        { inputType: "section", label: "Section" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
        { inputType: "section", label: "Inner Section" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "stylePropsDiv",
          fields: ["padding", "margin", "width-percent"],
        }),
      ],
    },
  ],
};
