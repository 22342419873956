import { twdClass } from "../../constants/tailwind.constants";
import CustomSelectBox from "../common/CustomSelectBox";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { useEffect } from "react";
import { INPUT_TEXT, BTN_NORMAL } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { Button} from "../ui/com_ui";

export const SupabaseConnectionInfo = ({
  updateElementValue,
  setColumnNames,
  connectionType,
}) => {
  const {
    selectedBlock,
    pageInfo: { blocks },
    projectData: { connections, project_id },
  } = useSelector((state) => state.studioDetails);

  const currentBlock = find(blocks, ["metaDataId", selectedBlock.metaDataId]);
  const selectConnection = find(connections, [
    "connection_id",
    currentBlock.configs.connection.connectionId,
  ]);

  const connectionOptions = connections
    .filter((itm) => itm.connection_type === connectionType)
    .map((item) => ({
      key: item.connection_id,
      value: item.connection_details.displayName,
    }));

  const connectValue = find(connectionOptions, [
    "key",
    currentBlock.configs.connection.connectionId,
  ])?.key;

  const tabelsList = selectConnection?.connection_details?.tables;

  const updateColumnsList = (val) => {
    if (val) {
      const columnsList = find(selectConnection?.connection_details?.tables, [
        "name",
        currentBlock.configs.connection.connectionTable,
      ])?.columns?.map((itm) => ({
        key: itm,
        value: itm,
      }));
      setColumnNames(columnsList ? columnsList : []);
    }
  };

  useEffect(() => {
    updateElementValue("configs.id", project_id);
    updateColumnsList(currentBlock.configs?.connection?.connectionTable);
  }, []);

  return (
    <div>
      <div className={twdClass("flex items-center mt-2 mb-2")}>
        <label className={twdClass("w-48 text-sm")}>Supabase DB's</label>
        <div className={twdClass("w-full")}>
          <CustomSelectBox
            value={connectValue}
            options={connectionOptions}
            updateValue={(val) => {
              updateElementValue("configs.connection.connectionId", val);
              updateElementValue("configs.connection.connectionTable", "");
            }}
          />
        </div>
      </div>
      <div className={twdClass("flex items-center mb-2")}>
        <label className={twdClass("w-48 text-sm")}>Table Names</label>
        <div className={twdClass("w-full")}>
          <select
            className={twdClass(INPUT_TEXT)}
            value={currentBlock.configs?.connection?.connectionTable}
            onChange={(e) => {
              updateElementValue(
                "configs.connection.connectionTable",
                e.target.value
              );
              updateColumnsList(e.target.value);
            }}
          >
            <option value="">Select</option>
            {tabelsList &&
              tabelsList.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
          </select>
        </div>
      </div>
      <div className={twdClass("flex justify-center mb-2 mt-4 bg-gray-50  px-2 py-2 rounded-2xl -mx-3 -mb-1")}>
        <Button
          type={"default"} cType={"iconText"} color={'white'} iconImg={'arrow-refresh-06.svg'}    
          disabled={!currentBlock.configs.connection.connectionTable}
          onClick={() => {
            const timestamp = new Date().getTime();
            updateElementValue("configs.connection.timestamp", timestamp);
          }}
        >          
          Sync/Refresh Data
        </Button>
      </div>
    </div>
  );
};
