import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import ToggleBtn from"./ui/ToggleBtn";
import { cloneDeep, set } from "lodash";


const Sitemap = ({ sitesettings, updateSettings }) => {
  const updateItem = (key, value) => {
    const sst = cloneDeep(sitesettings);
    const data = set(sst, key, value);
    updateSettings(data);
  };
  let convertText2Xml=sitesettings.convertText2Xml 
  return (
    <div>
      <div className={twdClass("w-full mb-4")}>
        <div className={twdClass("text-md font-semibold mb-4")}>Sitemap</div>
        <textarea
          rows={10}
          value={sitesettings.sitemap}
          className={twdClass(`${INPUT_TEXT} w-full`)}
          onChange={(e) => updateItem("sitemap", e.target.value)}
          placeholder = {`https://www.awesome.flezr.com/\nhttps://www.awesome.flezr.com/about/`}
        ></textarea>
      </div>
      <div className={twdClass("w-full flex items-center mb-2")}>        
        <div className={twdClass("w-1/4 text-sm ")}>Convert Text2Xml:</div>        
        <div className={twdClass("w-3/4")}>
          <ToggleBtn
            toggleId={"showfilter"}
            value={sitesettings?.sitemapData?.convertText2Xml}
            falseLabel={`Off`}
            trueLabel="On"
            updateValue={(value) => {
              //console.log("manifest", value)
              updateItem("sitemapData.convertText2Xml", value);
            }}
          />  
        </div>
      </div>
      <div className={twdClass("w-full flex items-center mb-2")}>        
        <div className={twdClass("w-1/4 text-sm ")}>File Type:</div>
        <div className={twdClass("w-3/4")}>
          <select
            value={sitesettings?.sitemapType}
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {               
              updateItem("sitemapType", e.target.value);
            }}
            
          >
            <option value="">Default</option>
            <option value="xml">xml</option>
            <option value="txt">txt</option>                      
          </select>            
        </div>
      </div>   
      
    </div>
    
  );
};

export default Sitemap;
