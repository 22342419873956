import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import { useState, useEffect } from "react";
import { cloneDeep, set, isEmpty, get } from "lodash";
import S3Uploader from "../../form/elements/S3Uploader";

const activeCls = "border-b-2 font-semibold border-gray-600";

const MetadataSettings = ({ sitesettings, updateSettings }) => {
  const [activeTab, setActiveTab] = useState("standard");

  const activeMDS =
    isEmpty(sitesettings.metadata) || isEmpty(sitesettings.metadata[activeTab])
      ? {}
      : sitesettings.metadata[activeTab];

  const updateItems = (key, value) => {
    const keyStr = `metadata.${activeTab}.${key}`;
    const sts = cloneDeep(sitesettings);
    const data = set(sts, keyStr, value);
    updateSettings(data);
  };
  const updateImagePath = (url, type) => {
    if (activeTab === "standard") {
      const opengraphKey = `metadata.opengraph.${type}`;
      const twitterKey = `metadata.twitter.${type}`;
      const statndardKey = `metadata.standard.${type}`;
      const sts = cloneDeep(sitesettings);
      const opengraphValue = get(sts, opengraphKey);
      const twitterValue = get(sts, twitterKey);
      const data = set(sts, opengraphKey, opengraphValue || url);
      const updated = set(data, twitterKey, twitterValue || url);
      const latestData = set(data, statndardKey, url);
      updateSettings(latestData);
    } else {
      updateItems(type, url);
    }
  };

  const titleValue = isEmpty(activeMDS.title) ? "" : activeMDS.title;
  const decValue = isEmpty(activeMDS.description) ? "" : activeMDS.description;
  const faiValue = isEmpty(activeMDS.favicon) ? "" : activeMDS.favicon;
  const share = isEmpty(activeMDS.favicon) ? "" : activeMDS.share;

  const STND = twdClass(`mr-4 py-2 ${activeTab === "standard" ? activeCls : ""}`);
  const OGRH = twdClass(`mr-4 py-2 ${activeTab === "opengraph" ? activeCls : ""}`);
  const TWR = twdClass(`mr-4 py-2 ${activeTab === "twitter" ? activeCls : ""}`);
  return (
    <div className={twdClass("w-full mx-2")}>
      <div className={twdClass("inline-block text-sm font-semibold mb-4 bg-gray-100 rounded-xl px-4 -mx-4")}>
        <button className={STND} onClick={() => setActiveTab("standard")}>
          Standard
        </button>
        <button className={OGRH} onClick={() => setActiveTab("opengraph")}>
          Open Graph
        </button>
        <button className={TWR} onClick={() => setActiveTab("twitter")}>
          Twitter
        </button>
      </div>

      <div className={twdClass("w-full flex items-center mb-2")}>
        <div className={twdClass("w-1/4 text-sm ")}>Title:</div>
        <div className={twdClass("w-3/4")}>
          <input
            type="text"
            value={titleValue}
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {
              //updateSettings("title", e.target.value);
              updateItems("title", e.target.value);
            }}
          />
        </div>
      </div>
      <div className={twdClass("w-full flex items-top mb-2")}>
        <div className={twdClass("w-1/4 text-sm ")}>
          Description:
        </div>
        <div className={twdClass("w-3/4")}>
          <textarea
            rows={4}
            value={decValue}
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {
              //updateSettings("description", e.target.value);
              updateItems("description", e.target.value);
            }}
          />
        </div>
      </div>
      {activeTab === "standard" && (
        <div className={twdClass("w-full flex items-start mb-4")}>
          <div className={twdClass("w-1/4 text-sm ")}>
            Favicon:
          </div>
          <div className={twdClass("w-3/4 border border-gray-100 hover:border-gray-300 rounded-xl px-2 py-2")}>
            <div className={`favicon-cls ${twdClass("w-full h-18 mb-2")}`}>
              <S3Uploader
                imageUrl={faiValue}
                updateS3Path={(url) => {
                  updateImagePath(url, "favicon");
                }}
                maxFileSize={10000}
              />
            </div>
            <input
              type="text"
              value={faiValue}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                updateItems("favicon", e.target.value);
              }}
            />
          </div>
        </div>
      )}
      <div className={twdClass("w-full flex items-start mb-4")}>
        <div className={twdClass("w-1/4 text-sm ")}>
          Social Share:
        </div>
        <div className={twdClass("w-3/4 border border-gray-100 hover:border-gray-300 rounded-xl px-2 py-2")}>
          <div className={`favicon-cls ${twdClass("w-full h-18  mb-2")}`}>
            <S3Uploader
              imageUrl={share}
              updateS3Path={(url) => {
                updateImagePath(url, "share");
              }}
              maxFileSize={200000}
            />
          </div>
          <input
            type="text"
            value={share}
            className={twdClass(INPUT_TEXT)}
            onChange={(e) => {
              //updateSettings("favicon", e.target.value);
              updateItems("share", e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MetadataSettings;
