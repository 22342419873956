
const get_google_sheet_id_vs= { 
  id:5,
  title:"Get Google Sheet ID" ,
  desc:"Open the Google Sheets and then. Get data from 'Share' -> 'Anyone with the Link' -> 'Copy Link'.",
  steps:[
    {t:"To get Sheet ID, Open your Google Sheet and then. Click 'Share' from the navigation bar.", 
      img:'5_cds_01.png', lx:'92%', ly:'3%'}, 
    {t:"Select 'Anyone with the Link'.", 
      img:'5_cds_02.png', lx:'38%', ly:'62%'},  
    {t:"Click 'Copy Link'.", 
      img:'5_cds_02.png', lx:'28%', ly:'75%'},             
  ]  
}
const get_supabase_anon_vs= { 
  id:7,
  title:"Get Supabase Anon key & Url" ,
  desc:"Open Supabase app and then. Get data from 'Project' -> 'Settings' -> 'API' -> 'Project URL' & 'Project API keys'.",
  steps:[
    {t:"Open the Supabase App.", 
      img:'7_gsb_00.png', lx:'50%', ly:'50%'}, 
    {t:"Click 'Settings' from the left navigation bar.", 
      img:'7_gsb_01.png', lx:'4%', ly:'84%'}, 
    {t:"Click 'API'.", 
      img:'7_gsb_02.png', lx:'6%', ly:'19%'},  
    {t:"Copy URL.", 
      img:'7_gsb_03.png', lx:'85%', ly:'28%'}, 
    {t:"Copy Anon Key.", 
      img:'7_gsb_03.png', lx:'85%', ly:'82%'},            
  ]  
}
const connect_google_vs= { 
  id:4,
  title:"Connecting Google Sheet" ,
  desc:"To customise the design of an individual card, click the card section in the main canvas.",
  steps:[
    {t:'Click \'Connections\' from the left navigation bar.', 
      img:'4_cds_01.png', lx:'6%', ly:'39%',
      links_bk:[
        {t:'Support', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
        {t:'Connect', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'}
      ]},
    {t:"Click 'Add New Connection'", 
      img:'4_cds_02.png', lx:'85%', ly:'38%' },
    {t:"Select 'Google Spreadsheet' from the 'Connection Type'", 
      img:'4_cds_03.png', lx:'48%', ly:'38%'}, 
    {t:"Enter any name for your datasource in the 'Sheet Display Name' field.", 
      img:'4_cds_04.png', lx:'58%', ly:'49%'}, 
    {t:"To get Sheet ID, Open your Google Sheet and then. Click 'Share' from the navigation bar.", 
      img:'4_cds_04.png', lx:'48%', ly:'57%',
      vs:get_google_sheet_id_vs
    },    
    {t:"Paste the copied link in the 'Sheet ID' field and press 'Save Connection'", 
      img:'4_cds_07.png', lx:'48%', ly:'59%'},                  
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}

const connect_supabase_vs= { 
  id:6,
  title:"Connecting Supabase" ,
  desc:"To customise the design of an individual card, click the card section in the main canvas.",
  steps:[
    {t:'Click \'Connections\' from the left navigation bar.', 
      img:'4_cds_01.png', lx:'6%', ly:'39%',
      links_bk:[
        {t:'Support', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
        {t:'Connect', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'}
      ]},
    {t:"Click 'Add New Connection'", 
      img:'4_cds_02.png', lx:'85%', ly:'38%' },
    {t:"Select 'Supabase DB' from the 'Connection Type'", 
      img:'6_csb_01.png', lx:'48%', ly:'30%'}, 
    {t:"Enter any name for your datasource in the 'Sheet Display Name' field.", 
      img:'6_csb_02.png', lx:'43%', ly:'39%'}, 
    {t:"To get Supabase Anon key, Open your Supabase App and then. get required data.", 
      img:'6_csb_01.png', lx:'48%', ly:'47%',
      vs:get_supabase_anon_vs
    },    
    {t:"Paste the copied link in the 'Sheet ID' field and press 'Save Connection'", 
      img:'4_cds_07.png', lx:'48%', ly:'59%'},                  
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}
export {
  get_google_sheet_id_vs,
  get_supabase_anon_vs,
  connect_supabase_vs,
  connect_google_vs
}
