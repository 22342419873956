import { find, set, get, replace } from "lodash";
import { useEffect, useState } from "react";
import { BTN_CLS } from "../../../constants/var.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import FaIcons from "../../common/FaIcons";
import { useSelector, useDispatch } from "react-redux";
import {Button} from "../../ui/com_ui";


const Collapse = ({label, desc, children, arrowPosition='right'}) => {
  const [isOpen, setOpen] = useState(true);
  let arrow=<FaIcons  icon={`${isOpen ? "angleUp" : "angleDown"}`}></FaIcons>
  return(
    <div className={twdClass("mb-1 border border-gray-100 hover:border-gray-200 rounded-xl px-2 py-2")}>
        <div className={twdClass("flex  cursor-pointer px-2")} onClick={() => setOpen(!isOpen)}>
          {arrowPosition=='left' && <div className={twdClass("mr-2")}>{arrow}</div>}
          <div  className={twdClass("mr-12")}>
            <div className={twdClass("text-sm font-semibold ")}>{label}</div>
            {desc && <div className={twdClass(`text-sm mt-2 ${isOpen ? " " : "hidden"}`)}>{desc}</div>}
          </div>
          <div className={twdClass("flex-grow")}></div>
          {arrowPosition=='right' && arrow}
        </div>
        <div className={twdClass(`${isOpen ? "pl-2 pt-4" : "hidden"}`)}>          
          {children}
        </div>        
      </div>    
  )
}

export default Collapse;
