let sd01={
  logo_text:"No-Code Experts",
  links:[
    "About",
    "Services",
    "Contact",
    "Get Started"
  ]
}
let a01={
  navbar:{
    logo:{text:"No-Code Experts", styleProps:{fontSize:'20px', fontWeight:'bold'}},
    links:[
      {text:"Home", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"About", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"Services", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"Products", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"Contact", styleProps:{backgroundColor:'#ff0000', color:'#ffffff', padding:'5px 10px',  marginLeft:'10px', borderRadius:'10px'} }
    ],
    styleProps:{backgroundColor:'#ffffff', padding:'20px'}  
  } 
}

let sd02={
  logo_text:"Explore Singapore",
  links:[
    "About",
    "Services",
    "Contact",
    "Get Started"
  ]
}
let a02={
  navbar:{
    logo:{text:"Explore Singapore", styleProps:{fontSize:'20px', fontWeight:'bold'}},
    links:[
      {text:"Home", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"About", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"Services", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"Products", styleProps:{padding:'5px 10px', color:"#000000"} },
      {text:"Join", styleProps:{backgroundColor:'#000000', color:'#ffffff', padding:'5px 10px',  marginLeft:'10px', borderRadius:'10px'} }
    ],
    styleProps:{backgroundColor:'#ffcccc', padding:'20px'}  
  } 
}


let sd03={
  logo_text:"Top Events",
  links:[
    "About",
    "Services",
    "Contact",
    "Get Started"
  ]
}
let a03={
  navbar:{
    logo:{text:"Top Events", styleProps:{backgroundColor:'#ffcccc', color:'#000000', fontSize:'20px', fontWeight:'bold', padding:'5px 20px', borderRadius:'10px'}},
    links:[
      {text:"Home", styleProps:{padding:'5px 10px', color:'#ffffff'} },
      {text:"About", styleProps:{padding:'5px 10px', color:'#ffffff'} },
      {text:"Services", styleProps:{padding:'5px 10px', color:'#ffffff'} },
      {text:"Products", styleProps:{padding:'5px 10px', color:'#ffffff'} },
      {text:"Get Started", styleProps:{color:'#ffffff', padding:'5px 10px', marginLeft:'10px', border:'2px solid #ffffff', borderRadius:'10px'} }
    ],
    styleProps:{backgroundColor:'#000000', padding:'20px', color:'#ffffff'}  
  } 
}



export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
