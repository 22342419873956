

let o={ 
  layout:{
    value:{
      desktop:4,
      tab:2,
      mobile:1
    }
  }
}

let d=[
  {
    title:"Senior Full Stack Developer",
    company:"ForwardThink",
    location:"Remote - USA",
    requirement:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.    Aliquam vel maximus enim, non semper dui.    Vivamus et hendrerit metus. Morbi vehicula porta massa, sed pellentesque est pretium et.",
    type:"Full Time",
    apply:"/job/1",
    image:"https://iili.io/HxOyhHQ.png"
  },
  {
    title:"Head of Marketing",
    company:"BrightSpark",
    location:"Lisbon",
    requirement:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.    Aliquam vel maximus enim, non semper dui.    Vivamus et hendrerit metus. Morbi vehicula porta massa, sed pellentesque est pretium et.",
    type:"Contract",
    apply:"/job/2",
    image:"https://iili.io/HxOyjRV.png"
  }
]



let sd={
  title:"Explore New Roles",
  description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam egestas lectus risus, ac porttitor metus tempus eu. Phasellus varius, ipsum et vehicula feugiat.",
}

let a={
  cards:{
    title:{text:"Explore New Roless", styleProps:{textAlign: 'center', color:' #333333', fontSize: '2.25rem', lineHeight: '2.5rem', margin: '40px 0px 20px'}},
    description:{text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam egestas lectus risus, ac porttitor metus tempus eu. Phasellus varius, ipsum et vehicula feugiat.", styleProps:{color: '#333333', fontSize: '1.25rem', lineHeight: '1.75rem',  margin: '10px 100px', textAlign: 'center'}},
    searchbar:{
      styleProps:{
        "backgroundColor": "#000000",
        "padding": "20px 20px 20px 20px",
        "border-radius": "1rem"
      },
      search_input:{ 
        styleProps:{
          "color": "#000000",
          "backgroundColor": "#ffffff",
          "padding": "8px 20px",
          "margin": "0px 10px 0px 10px",
          "width": "auto",
          "borderRadius": "20px"
        }
      },
      search_button:{
        styleProps:{
          "color": "#000000",
          "backgroundColor": "#FFD700",
          "padding": "8px 20px",
          "borderRadius": "20px",
          "marginRight": "10px"
        }
      },
      clear_button:{
        styleProps: {
          "color": "#000000",
          "backgroundColor": "#FFD700",
          "padding": "8px 20px",
          "borderRadius": "20px"
        }
      }
    },
    cards_per_row:{
      desktop:4,
      tab:2,
      mobile:1
    },
    card_template:{
      card_columns:[
        {
          styleProps:{
            "width": "100%",
            "padding": "0px 20px 0px 20px",
             display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'
          } ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps: {
              "borderRadius": "20px",
              "width": "50%"
            }},
          ]
        },
        {
          styleProps:{
            "padding": "20px",
            "width": "100%",
            display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', borderRadius:"20px"
          } ,
          stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
          elements:[ 
            {elementType:"headingEl", text:"[[title]]", styleProps:{
              "fontSize": "24px",
              "color": "#000000",
              "fontWeight": "bold",
              "margin": "0px 0px 10px"
            }},
            {elementType:"textEl", text:"[[company]]", styleProps:{
              "fontSize": "18px",
              "color": "#000000",
              "margin": "0px 0px 5px"
            }}, 
            {elementType:"textEl", text:"[[location]]", styleProps:{
              "fontSize": "18px",
              "color": "#000000",
              "margin": "0px 0px 5px"
            }}, 
            {elementType:"textEl", text:"[[type]]", styleProps:{
              "fontSize": "18px",
              "color": "#000000",
              "margin": "0px 0px 15px 0px"
            } }, 
            {elementType:"buttonEl", text:"Apply", url:"[[apply]]", styleProps:{
              "backgroundColor": "#FFD700",
              "color": "#000000",
              "padding": "10px 20px",
              "borderRadius": "10px",
              "margin": "0px 5px 0px 0px"
            }},
          ]
        }     
      ],
    },
    card_items:[        
      {        
        card_columns:[
          {
            styleProps:{
              "width": "100%",
              "padding": "0px 20px 0px 20px",
               display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'
            } ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[              
              {elementType:"imageEl", src:"https://iili.io/HxOyhHQ.png", styleProps: {
                "borderRadius": "20px",
                "width": "50%"
              }},
            ]
          },
          {
            styleProps:{
              "padding": "20px",
              "width": "100%",
              display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', borderRadius:"20px"
            } ,
            stylePropsMob:{width:'100%', padding:'0px 20px 0px 20px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Senior Full Stack Developer", styleProps:{
                "fontSize": "24px",
                "color": "#000000",
                "fontWeight": "bold",
                "margin": "0px 0px 10px"
              }},
              {elementType:"textEl", text:"ForwardThink", styleProps:{
                "fontSize": "18px",
                "color": "#000000",
                "margin": "0px 0px 5px"
              }}, 
              {elementType:"textEl", text:"Remote - USA", styleProps:{
                "fontSize": "18px",
                "color": "#000000",
                "margin": "0px 0px 5px"
              }}, 
              {elementType:"textEl", text:"Full Time", styleProps:{
                "fontSize": "18px",
                "color": "#000000",
                "margin": "0px 0px 15px 0px"
              } }, 
              {elementType:"buttonEl", text:"Apply", url:"/job/1", styleProps:{
                "backgroundColor": "#FFD700",
                "color": "#000000",
                "padding": "10px 20px",
                "borderRadius": "10px",
                "margin": "0px 5px 0px 0px"
              }},
            ]
          }     
        ],
      },              
    ],
    styleProps:{backgroundColor:'#eeeeee', color:'#333333', padding:'80px 20px'},
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{display:'flex', flexWrap:"wrap", padding:'20px', backgroundColor: '#ffffff', borderRadius: '1rem', color:'#000000', boxShadow:'4px 4px 2px rgba(0,0,0,0.1)'} 
    }
  }
}


export default {
  o, a, d, sd,
}
