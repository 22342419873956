const d3=`
    {
        "title":{
            "text":"Best In Class",
            "styleProps":{'font-size':'34px', 'margin-bottom':'10px'}
        },
        "description":{
            "text":"",
            "styleProps":{'font-size':'22px', 'margin-bottom':'10px'}
        },  
        "cards_config":{
            "cards_per_row":{
                "desktop":1,
                "tab":1,
                "mobile": 1
            }
        },
        "card_template":{
            "card_columns":[
                {
                    "elements":[
                        {"elementType":"imageEl", "src":"/assets/image.jpg", "styleProps":{"width":"100%", "border-radius":"20px"}},                                            
                    ] , 
                    "styleProps":{} 
                }
                {
                    "elements":[
                        {"elementType":"headingEl", "text":"[[title]]", "styleProps":{"font-size":"22px", "color":"#333333" | "#4fccf2", "margin-bottom": "20px"}},
                        {"elementType":"textEl", "text":"[[sub_title]]", "styleProps":{"font-size":"20px", "line-height":" 1.2", "color":"#333333" | "#4fccf2", "margin-bottom": "20px"}},
                        {"elementType":"textEl", "text":"[[description]]", "styleProps":{"font-size":"18px", "color":"#666666" | "#4fccf2", "margin-bottom": "20px"}}                       
                        {"elementType":"textEl", "text":"[[status]]", "styleProps":{"font-size":"14px", "background-color":"#e7cece", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},
                        {"elementType":"textEl", "text":"[[location]]", "styleProps":{"font-size":"14px", "background-color":"#cccccc", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},                    
                        {"elementType":"categoryEl", "text":"[[department]]", "styleProps":{"font-size":"14px", "background-color":"#cccccc", "padding":"4px 14px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},                    
                        {"elementType":"detailsLinkEl", "text":"View Details",  "url":"[[details_url]]", "styleProps":{ "background-color": "#f2ffc6" | "#c6fff6",  "border": "2px solid rgba(0, 0, 0, 0.3)", "font-size": "12px", "padding": "0px 8px", "display": "inline-block", "border-radius":"20px", "margin-bottom": "20px"}},  
                    ] , 
                    "styleProps":{'padding':'10px 20px 10px 10px '},
                    "stylePropsMob":{'padding':'10px 10px 10px 10px '}   
                },                    
            ],                                             
        },       
        "styleProps":{"background-color":"#e0eeee", 'padding':'40px 15px'},   
        "cards_holder_styles":{
            "styleProps":{"margin-top":"20px"}      
        },     
        "cards_outer_styles":{
            "styleProps":{"display":"grid", "grid-template-columns": "repeat(1, minmax(0, 1fr))", "gap":"20px", }    
        },        
        "cards_item_styles":{
            "styleProps":{"display":"grid", "grid-template-columns": "repeat(1, minmax(0, 1fr))", "gap":"20px", "align-items": "center",  "padding":"40px" | "20px", "box-shadow":"4px 4px 4px rgba(0,0,0,0.3)" | "4px 8px 8px -4px rgba(0,0,0,0.1)", "border-radius":"20px" | "40px", "background-color":"#ffffff" | "#eeeeee";},     
        }
    }

`
export const cards_prompt=({opts={}, sdata=false, params=[], data=false, sample=false})=>{
    let {detailsPageParam='', layout={}} = opts

if(!sdata) sdata={
    title:"No-Code Experts",
    description:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.",
}
if(!data) {
    data=[
        {"title":"International Food Festival",  "image":"https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg","date":"24-4-2024",  "status":"Processings", "status_color":"#0000ff", "category":"Technology",  "location":"Sydney, Australia | July 18, 2023", "description":"Join us for a celebration of international cuisine at Sydney. A myriad of food stalls showcasing dishes from around the world."},
        {"title":"Music in the City Festival",  "image":"https://images.pexels.com/photos/147411/italy-mountains-dawn-daybreak-147411.jpeg","date":"21-4-2024",  "status":"Failed", "status_color":"#ff0000", "category":"Design", "location":"Vienna, Austria | July 24, 2023", "description":"Enjoy a musical extravaganza in the Join us for a celebration of international cuisine at Sydney. A myriad of food stalls showcasing dishes from around the world."},
    ]
}
if(!sample){
    sample=d3
}
if(!detailsPageParam) detailsPageParam=''
if(!layout.value) layout={name:'3cols', value:{desktop:3, tab:2, mobile:1}}

//console.log("cards_prompt", opts, data, sdata)
if(!params) params=[]

let f_params = params?.filter((param)=>{
    return param.type=='hide' ? false : true
})

return(`

[SECTION_DATA]=${JSON.stringify(sdata)}

[PARAMS]=${JSON.stringify(f_params)}

[DETAILS_PAGE_PARAM]=${detailsPageParam}

[LAYOUT]=${JSON.stringify(layout.value)}

[JSON_INPUT]={
    "cards":${sample}
}

'ELEMENT_TYPES'=["idEl", "headingEl", "subHeadingEl", "categoryEl", "tagsEl", "typeEl", "textEl", "statusEl", "thumbImageEl", "imageEl", "linkEl", "buttonEl", "phoneEl", "emailEl", "styleProperty", "detailsLinkEl"]

Create Website Cards Sections in JSON

Use [PARAMS] data for 'elementType'

use [LAYOUT] data for 'cards_config'->'cards_per_row'

Use [SECTION_DATA] for 'title' and 'description'  

Use [PARAMS] data for creating 'card_template'

Must Include elementType 'detailsLinkEl'

Use [DETAILS_PAGE_PARAM] as 'url' if elementType is 'detailsLinkEl'

must set styleProps.width for each column in 'cards_columns'

maintain some 'padding' for each column in 'cards_columns'


JSON output schema sholud be [JSON_INPUT]

`)
}

const schema={
    "name": "cards_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": {    
            "title": {
                "$ref": "#/$defs/text_obj"
            },  
            "description": {
                "$ref": "#/$defs/text_obj"
            }, 
            "searchbar":{
                "type":"object",
                "properties": {    
                    "search_input": {
                        "$ref": "#/$defs/responsive_styles_obj"
                    },
                    "search_button": {
                        "$ref": "#/$defs/button_obj"
                    },
                    "clear_button": {
                        "$ref": "#/$defs/button_obj"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"}  
                },
                "required": [
                    "search_input",
                    "search_button",
                    "clear_button",
                    "styleProps"
                ]
            },
            "cards_config":{
                "type":"object",
                "properties": {    
                    "cards_per_row": {
                        "type":"object",
                        "properties": {    
                            "desktop": {
                                "type":"number",
                                "enum": [1, 2, 3]
                            },
                            "tab": {
                                "type":"number",
                                "enum": [1, 2]
                            },
                            "mobile": {
                                "type":"number",
                                "enum": [1]
                            }
                        },
                        "required": [
                            "desktop",
                            "tab",
                            "mobile"
                        ]
                    }
                },
                "required": [
                    "cards_per_row"
                ]
            },
            "card_template":{
                "$ref": "#/$defs/card_template_obj"
            },             
            "cards_outer_styles":{
                "$ref": "#/$defs/responsive_styles_obj"
            },
            "cards_item_outer_styles":{
                "$ref": "#/$defs/responsive_styles_obj"
            },
            "cards_item_styles":{
                "$ref": "#/$defs/responsive_styles_obj"
            },
            "styleProps": {"$ref": "#/$defs/styles_obj"}               
        },  
        "required": [           
            "title",
            "description",
            "searchbar",
            "cards_config",
            "card_template",           
            "cards_outer_styles",
            "cards_item_outer_styles",
            "cards_item_styles",
            "styleProps",
            "stylePropsMob",
            "stylePropsTab"          
        ],
        "additionalProperties": false,
        "$defs": {
            "card_template_obj":{
                "type":"object",
                "properties": {    
                    "card_columns": {
                        "type":"array",
                        "description":"it should have max 4 columns",
                        "items":{
                            "type":"object",
                            "properties": {    
                                "elements": {
                                    "type":"array",
                                    "items":{
                                        "$ref": "#/$defs/element_template_obj"
                                    }
                                },
                                "styleProps": {"$ref": "#/$defs/styles_obj"}     
                            },
                            "required": [
                                "elements",
                                "styleProps"
                            ]
                        }                        
                    }
                },
                "required": [
                    "card_columns"
                ]
            },            
            "element_template_obj":{
                "type": "object",
                "description": "Card Dynamic Element contains dynamic variables in double box brackets [[]]", 
                "properties": {
                    "elementType": { 
                        "type": "string",
                        "description": "element type",
                        "enum": ["idEl", "headingEl",  "subHeadingEl", "categoryEl", "tagsEl", "typeEl", "textEl", "statusEl", "thumbImageEl", "imageEl", "linkEl", "buttonEl", "phoneEl", "emailEl", "styleProperty", "detailsLinkEl"]
                    },
                    "text": { 
                        "type": "string",
                        "description": "text contains dynamic variables in double box brackets [[]]"
                    },
                    "url": { 
                        "type": "string",
                        "description": "add this required property if elementType is detailsLinkEl"
                    },
                    "src": { 
                        "type": "string",
                        "description": "add this required property if elementType is imageEl"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "elementType",
                    "text",
                    "styleProps",
                    "stylePropsMob",
                    "stylePropsTab"
                ],
                "additionalProperties": false
            }, 
            "link_obj":{
                "type": "object",
                "description": "Logo", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "url": { 
                        "type": "string",
                        "description": "link url"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "url",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "text_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}           
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            },
            "button_obj":{
                "type": "object",
                "description": "button", 
                "properties": {
                    "text": { 
                        "type": "string",
                        "description": "text"
                    },
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}             
                },
                "required": [
                    "text",
                    "styleProps"
                ],
                "additionalProperties": false
            }, 
            "responsive_styles_obj":{
                "type": "object",
                "description": "button", 
                "properties": {                   
                    "styleProps": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsMob": {"$ref": "#/$defs/styles_obj"},
                    "stylePropsTab": {"$ref": "#/$defs/styles_obj"}             
                },
                "required": [                    
                    "styleProps"
                ],
                "additionalProperties": false
            },           
            "styles_obj":{
                "type": "object",
                "description": "css styles", 
                "properties": {},                  
                "additionalProperties": true
            }                            
        }
    }
}


export const cards_format = {
    "type":"json_schema",
    "json_schema": schema
}

/*
dataTypes: 902 + 204 = 1106
cards: 3949 + 3115 = 7064


*/
