import { 
  connect_supabase_vs,
  connect_google_vs 
} from "./vs_data_com";

const dynamic_page_vs= { 
  id:8,
  title:"Dynamic Page" ,
  desc:"Simply connect data source and create dynamic pages. The cards can have dynamic as well as static data and that can be customised.",
  steps:[
    {t:"Build dynamic pages using data source", 
      img:'9_dp_00.jpg', lx:'16%', ly:'19%',
      note:'First you need to connect data source.',
      vs:[connect_google_vs, connect_supabase_vs],
      links:[
        {t:'Sample Google Spreadsheet', l:'https://docs.google.com/spreadsheets/d/14fnGqvzQOpkNK8uYxXdJgqvfCm2LCVUCaMI85bdXjlU/edit?usp=sharing'}
      ]},
    {t:"Click on 'Add Page'.", 
      img:'9_dp_01.jpg', lx:'22%', ly:'30%',
    },
    {t:"Change the 'Page Name' -> Place Details", 
      img:'9_dp_01a.jpg', lx:'60%', ly:'38%',
    },
    {t:"Click on 'Page Type' -> Dynamic Page", 
      img:'9_dp_01b.jpg', lx:'75%', ly:'45%',
    },
    {t:"Enter 'Slug Name' -> /page_details/:slug", 
      img:'9_dp_01c.jpg', lx:'65%', ly:'51%', 
      note:'Use any word in place of page_details and slug.',
    },
    {t:"Select 'Connection Type' -> Google Spreadsheet", 
      img:'9_dp_01c.jpg', lx:'65%', ly:'55%',       
    },
    {t:"Select 'Connections' -> Places", 
      img:'9_dp_01d.jpg', lx:'64%', ly:'67%',
      note:"'Places' is the Google Spreadsheet Connection name.",
      vs:[connect_google_vs, connect_supabase_vs],
    },
    {t:"Click on 'Fetch Sheet' button to load all sheets from the Google Spreadsheet.", 
      img:'9_dp_01d.jpg', lx:'86%', ly:'65%',      
    },
    {t:"Select 'Sheet Name' -> Locations", 
      img:'9_dp_01e.jpg', lx:'55%', ly:'72%',
      note:"'Locations' is the Sheet name from Places Google Spreadsheet.",
    },
    {t:"Select 'Slug Param' -> Slug.", 
      img:'9_dp_01f.jpg', lx:'70%', ly:'55%',
      note:"'Slug' is to identify of the page row in Google Sheet. Use any word in place of Slug.",
    },
    {t:"Click on 'Update Page' to save the dynamic page details.", 
      img:'9_dp_01g.jpg', lx:'80%', ly:'72%',   
      note:"After every update/change in the page, save the project.",  
    },
    {t:"Click on 'Add Section Blocks' icon", 
      img:'9_dp_02.jpg', lx:'5%', ly:'16%',
    },
    {t:"Click on 'Elements' -> Text Element", 
      img:'9_dp_02a.jpg', lx:'50%', ly:'78%',
      hint:"It will add Text Element block to the page.",
    },
    {t:"Click on 'Text Element Block'", 
      img:'9_dp_02b.jpg', lx:'65%', ly:'13%',
    },
    {t:"Enter dynamic variable in field 'text' -> {{Name}}", 
      img:'9_dp_02c.jpg', lx:'55%', ly:'55%',
      note:"Variable 'Name' should be enclosed in flower brackets.",
    },
    {t:"Click on 'Pages' icon.", 
      img:'9_dp_02d.jpg', lx:'5%', ly:'23%',
    },  
    {t:"Select param 'Name' -> Name", 
      img:'9_dp_02e.jpg', lx:'80%', ly:'76%',
      hint:"This will map variable 'Name' in dynamic page to variable 'Name' in Google Sheet.",
    }, 
    {t:"Preview the project with page /palce_details/2", 
      img:'9_dp_02f.jpg', lx:'47%', ly:'2%',
      hint:"'page_details' is the Dynamic Page name and '2' is the slug name. ",
      note:"Slug name can be string or number without(space & special characters).",
    },  
    {t:"Preview the project with page /palce_details/3", 
      img:'9_dp_02g.jpg', lx:'47%', ly:'2%',
      hint:"'page_details' is the Dynamic Page name and '3' is the slug name. ",      
    },        
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}

    
export {  
  dynamic_page_vs
}
