export const dataTypes_prompt=({opts={}, params={}, data=false, sample=false})=>{
    let pagePrompt=''  
      
    //console.log("dataTypes_prompt", opts, sdata)
    
    return(`
       
    [JSON_INPUT] =${JSON.stringify(params)}

    [CARDS_DATA] =${JSON.stringify(data)}

    Add type value to [JSON_INPUT] based on [CARDS_DATA] values        
    
    Give me the output in JSON format and it should be valid JSON data

    JSON output schema sholud be [JSON_INPUT]
    
    Just give me the JSON data
    
    `)
}
    

const schema={
    "name": "dataTypes_response",
    "strict": false,
    "schema": {
        "type": "object",
        "properties": { 
            "params": {
                "type":"array",
                "description": "this array contains param objects", 
                "items":{
                    "$ref": "#/$defs/param_obj"
                }                
            }                  
        },  
        "required": [ 
            "params"      
        ],
        "additionalProperties": false,
        "$defs": {           
            "param_obj":{
                "type": "object",
                "description": "text", 
                "properties": {
                    "key": { 
                        "type": "string",
                        "description": "this contains param name as key"
                    },                               
                    "type": { 
                        "type": "string",
                        "description": "this contains param data type as value",
                        "enum": ["idEl", "headingEl",  "subHeadingEl",  "categoryEl", "tagsEl", "typeEl", "textEl", "statusEl", "thumbImageEl", "imageEl", "linkEl", "buttonEl", "phoneEl", "emailEl", "styleProperty", "detailsLinkEl"]
                    }        
                },
                "required": [
                    "key",                    
                    "type"
                ],
                "additionalProperties": false
            }                 
        }
    }
}
export const dataTypes_format = {
    "type":"json_schema",
    "json_schema": schema
}