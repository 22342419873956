import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../constants/var.constants";
import  Theme  from "./parts/Theme";
import  Navbar  from "./parts/Navbar";
import  Header  from "./parts/Header";
import  Cards  from "./parts/Cards";
import  Footer  from "./parts/Footer";
import  TextElement  from "./parts/TextElement";
import  ButtonElement  from "./parts/ButtonElement";
import  ImageElement  from "./parts/ImageElement";
import styled from "styled-components";

const Preview = ({isGenerating, isGeneratingDone}) => {
  const [isThemeOpen, setThemeOpen] = useState(false)
  const dispatch = useDispatch();
  const {
    generated={},
    pageInfo,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);
  
  const {settings={}, theme=false, navbar=false, header=false, cards=false, footer=false, headerDyn={}, textElement={}, buttonElement={}, imageElement={}} = generated 
  const {pageType={}} = settings 

  const forStatic=()=>{
    return(
      <div  >        
        {navbar && <Navbar  {...{navbar}}/> }  
        {header && <Header  {...{header}}/> }  
        {cards && <Cards  {...{cards}}/> }  
        {footer && <Footer  {...{footer}}/> } 
      </div>
    )
  }
  const forDynamic=()=>{
    return(
      <div  >   
        {headerDyn && <Header  {...{header:headerDyn}}/> }      
        {imageElement && <ImageElement  {...{imageElement}}/> } 
        {textElement && <TextElement  {...{textElement}}/> }         
        {buttonElement && <ButtonElement  {...{buttonElement}}/> } 
      </div>
    )
  }

  let render=''
  if(pageType=='dynamic'){
    render=forDynamic()
  }else{
    render=forStatic()
  }
  return (
    <div  className={twdClass("relative h-full overflow-auto")} >
      {/*<div>
        {theme && <Theme {...{theme, isThemeOpen, setThemeOpen}}/>}   
      </div>  */}
      <div  >        
        {render}
      </div>  
    </div>
  );
};

export default Preview
