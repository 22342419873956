import { twdClass } from "../../../constants/tailwind.constants";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { replace, cloneDeep, set, get } from "lodash";
import Dropdown from "./Dropdown";

const CI_1 = twdClass("border-2 border-gray-300 hover:border-gray-500  pb-2 rounded-lg px-2");
const CI_1b = twdClass("border border-gray-100  hover:border-gray-300 rounded-lg px-2");
const CI_2 = twdClass("flex items-center py-2 cursor-pointer");
const CI_3 = twdClass("w-16 text-xs text-gray-900 font-semibold ");
const CI_4 = twdClass("flex-grow text-blue-500");
const CI_5 = twdClass("flex items-center");
const CI_6 = twdClass("w-8 h-4 mr-2 rounded-sm transform scale-125");
const CI_7 = twdClass("w-4 h-4 mr-1 rounded-sm cursor-pointer");

const C_GradientDiv = twdClass("w-4 h-4 mr-1 rounded-sm cursor-pointer");
const CI_8 = twdClass("flex flex-wrap bg-gray-100 rounded-md p-2 w-auto mr-5");
const CI_9 = twdClass("flex flex-wrap");
const CI_10 = twdClass("w-8 h-7 mb-1 mr-1 rounded-md cursor-pointer");
const CI_11 = twdClass("flex flex-wrap mt-4 items-end w-full");
const CI_12 = twdClass(
  "w-5/12 h-6 rounded-md border-transparent cursor-pointer"
);
const CI_13 = twdClass("w-7/12 flex flex-col relative pl-2");

const CI_15 = twdClass(
  "w-full rounded-md px-2 rounded-md border-2 border-transparent bg-white  appearance-none focus:border-blue-500 focus:outline-none"
);

const ColorInput = ({ element, data, updateElementValue }) => {
  const { label, updateTo, hideLabel, defaultValue } = element;  
  const [expandCol, setExpandCol] = useState(false);
  const [dispValue, setDispValue] = useState("");
  const [values, setValues] = useState({
    direction: "to right",
    from: "#ff0000",
    to: "#00ff00",
  });

  const process = () => {
    const vals = get(data, updateTo);
    if (vals) {
      const nextvals = replace(vals, /(linear-gradient)|[()]/g, "");
      const lst = nextvals.split(",");
      const grd = cloneDeep(values);
      Object.keys(grd).forEach((itm, i) => {
        grd[itm] = lst[i];
      });
      return grd     
    }
  }
  useEffect(() => {   
    const grd = process()
    if (grd) {     
      setDispValue(grd);
    }
  }, []);

  useEffect(() => {
    const grd = process()
    if (grd) {     
      setValues(grd);
    }
  }, []);

  /*useEffect(() => {
    const dataValue = get(data, updateTo);
    const value = dataValue ? dataValue : defaultValue;
    const nextValue = replace(value, /(linear-gradient)|[()'"]/g, "");
    setValues(nextValue);
  }, []);*/

  const updateValues = (x, y) => {
    const val = y ? y : '';
    const grd = set(cloneDeep(values), x, `${val}`);
    const v = Object.values(grd).join(",");
    let nextval=''
    setValues(grd);   
    if(grd.direction!=''){     
      nextval= `linear-gradient(${v})`
    }
    updateElementValue(element.updateTo, nextval);
    //console.log("kk", element.updateTo, values, grd, v, x, y , nextval)
    //console.log("v", grd, nextval)
  };
  const updateAllVals = (grd) => {
    const v = Object.values(grd).join(",");
    setValues(grd);
    setDispValue(grd);
    updateElementValue(element.updateTo, `linear-gradient(${v})`);
  };  
  //const imgSrc = `/assets/images/icon_arrow_${expandCol ? "up" : "down"}.png`;
  const imgSrc = `/assets/images/chevron-${expandCol ? "up" : "down"}.svg`;
 
  return (
    <div className={expandCol ? CI_1 : CI_1b}>
      <div className={CI_2} onClick={() => setExpandCol(!expandCol)}>
        {!hideLabel && <div className={CI_3}  >{label}</div>}
        <div className={CI_4}>
          <div className={CI_5}>
            <GradientDiv className={CI_6} color={dispValue}></GradientDiv>
            <div className={twdClass("flex")}>
              {GRADIENTS_1.map((item) => (
                <GradientDiv
                  className={C_GradientDiv}
                  color={item}
                  onClick={(e) => {
                    e.stopPropagation();   
                    updateAllVals(item);
                  }}
                ></GradientDiv>
              ))}
            </div>
          </div>
        </div>
        <div
          className={twdClass("cursor-pointer")}
          onClick={() => setExpandCol(!expandCol)}
        >
          <img alt="" src={imgSrc} />
        </div>
      </div>
      <div className={`${!expandCol ? twdClass("hidden py-2") : "py-2"}`}>
        <div className={CI_8}>
          {/*<div className={CI_9}>
            {COLORS_2.map((item) => (
              <BGDiv
                className={CI_10}
                color={item}
                onClick={() => {
                  //updateAllVals(item);
                }}
              ></BGDiv>
            ))}
          </div>
          <div className={CI_11}>
            <InputColorTag
              className={CI_12}
              color={dispValue}
              value={dispValue}
              type="color"
              onChange={(e) => {
                updateColor(e.target.value);
              }}
            ></InputColorTag>
            <div className={CI_13}>
              <div className={twdClass("text-sm opacity-50")}>Hex code</div>
              <input
                className={CI_15}
                value={dispValue}
                onChange={(e) => {
                  updateColor(e.target.value);
                }}
              />
            </div>
          </div>*/}
          <div className={twdClass("w-full")}>
            <div className={twdClass("flex items-center w-full")}>              
              <div className={twdClass("flex-grow")}><Dropdown
                options={DIRECTION_OPTIONS}
                hideLabel={false}
                label={'Direction'}
                value={values.direction}
                updateValue={(value) => {
                  updateValues("direction", value);
                }}
              /> </div>             
            </div>
            <div className={CI_11}>
              <InputColorTag
                className={CI_12}
                color={values.from}
                value={values.from}
                type="color"
                onChange={(e) => {                  
                  updateValues("from", e.target.value);
                }}
              ></InputColorTag>
              <div className={CI_13}>
                <div className={twdClass("text-sm opacity-50")}>From Hex code</div>
                <input
                  value={replace(values.from, "px", "")}
                  onChange={(e) => {
                    updateValues("from", e.target.value);
                  }}
                />
              </div>
            </div>       
            <div className={CI_11}>
              <InputColorTag
                className={CI_12}
                color={values.to}
                value={values.to}
                type="color"
                onChange={(e) => {                  
                  updateValues("to", e.target.value);
                }}
              ></InputColorTag>
              <div className={CI_13}>
                <div className={twdClass("text-sm opacity-50")}>To Hex code</div>
                <input
                  value={replace(values.to, "px", "")}
                  onChange={(e) => {
                    updateValues("to", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorInput;

const BGDiv = styled.div`
  background-color: ${(props) => props.color};
`;
const GradientDiv = styled.div`
  background-image: ${(props) => `linear-gradient(${props.color.direction}, ${props.color.from}, ${props.color.to})`};
`;

const InputColorTag = styled.input`
  background-color: ${(props) => props.color};
`;

const COLORS_1 = [
  "#116dff",
  "#7411ff",
  "#ffa611",
  "#333333",
  "#808080",
  "#cccccc",
];
const GRADIENTS_1 = [
  {direction:"to right", from:'#f2709c', to:'#ff9472'},
  {direction:"to right", from:'#50C9C3', to:'#96DEDA'},
  {direction:"to right", from:'#f12711', to:'#f5af19'},
  {direction:"to right", from:'#7F7FD5', to:'#91EAE4'},
  {direction:"to right", from:'#00b09b', to:'#96c93d'},
  {direction:"to right", from:'#FC5C7D', to:'#6A82FB'},
];
const COLORS_2 = [
  "#116dff",
  "#7411ff",
  "#ffa611",
  "#333333",
  "#808080",
  "#ececec",
  "#116dff",
  "#3e89ff",
  "#72a9ff",
  "#98c0ff",
  "#c6dcff",
  "#e1edff",
  "#7411ff",
  "#9a51ff",
  "#b782ff",
  "#cda8ff",
  "#dec6ff",
  "#ecdeff",
  "#333333",
  "#808080",
  "#808080",
  "#cccccc",
  "#ececec",
  "#ffffff",
];



const DIRECTION_OPTIONS = [
  { display: "Disable", value: "" },
  { display: "to right", value: "to right" },
  { display: "to left", value: "to left" },
  { display: "to top", value: "to top" },
  { display: "to bottom", value: "to bottom" }
];
