import {
  LEFT_PANEL_BLOCKS_FEATURE,
  LEFT_PANEL_BLOCKS_FAQ,
  LEFT_PANEL_BLOCKS_FOOTER,
  LEFT_PANEL_BLOCKS_HEADER,
  LEFT_PANEL_BLOCKS_HERO,
  LEFT_PANEL_BLOCKS_PRICING,
  LEFT_PANEL_BLOCKS_NAV,
  LEFT_PANEL_BLOCKS_LOGO,
  LEFT_PANEL_BLOCKS_HTML,
  LEFT_PANEL_BLOCKS_CONTENT,
  LEFT_PANEL_BLOCKS_TEAM,
  LEFT_PANEL_BLOCKS_FORM,
  LEFT_PANEL_BLOCKS_CTA,
  LEFT_PANEL_BLOCKS_TESTIMONIAL,
  LEFT_PANEL_BLOCK_CARDS,
  LEFT_PANEL_BLOCK_NAVBAR,
} from "../../../constants/labels.constants";
import * as JSONS from "../../../jsons";

/* import { FEATURE_BLOCK_1 } from "../../../jsons/block.feature.metadata";
import { PRICING_BLOCK_1 } from "../../../jsons/block.pricing.metadata";
import { FOOTER_BLOCK_1 } from "../../../jsons/block.footer.metadata"; */

// Block popup Header menu and corresponding items and meta data
const PANEL_BLOCKS_TYPES = {
  TYPE_LEFT_BLOCK_HEADER: "HEADER_ID",
  TYPE_LEFT_BLOCK_HERO: "HERO_ID",
  TYPE_LEFT_BLOCK_FEATURE: "FEATURE_ID",
  TYPE_LEFT_BLOCK_FAQ: "FAQ_ID",
  TYPE_LEFT_BLOCK_FEATURE_GRID: "FEATURE_GRID_ID",
  TYPE_LEFT_BLOCK_PRICING: "SETTINGS_ID",
  TYPE_LEFT_BLOCK_FOOTER: "FOOTER_ID",
  TYPE_LEFT_BLOCK_NAV: "NAV_ID",
  TYPE_LEFT_BLOCK_CONTENT: "CONTENT_ID",
  TYPE_LEFT_BLOCK_CAT: "CAT_ID",
  TYPE_LEFT_BLOCK_TESTIMONAIL: "Testimonials_ID",
  TYPE_LEFT_BLOCK_HTML: "HTML_ID",
  TYPE_LEFT_BLOCK_LOGO: "LOGO_ID",
  TYPE_LEFT_BLOCK_TEAM: "TEAM_ID",
  TYPE_LEFT_BLOCK_FORM: "FOMR_ID",
  TYPE_LEFT_BLOCK_CARDS: "CARDS_ID",
  TYPE_LEFT_BLOCK_NAVBAR: "NAVBAR_ID",
};

const PANEL_BLOCKS_LABELS = {
  /*
  HEADER_WITH_SB_BTN: "Header with sublinks and button",
  HEADER_WITH_LN_BTN: "Header with links and buttons",
  HEADER_WITH_HB_MN: "Header with hamburger menu",
  HEADER_WITH_IC_DC: "Header with sublinks, icons and description",
  */
  HEADER_BLOCK_1: "Header Block 1",
  HEADER_BLOCK_2: "Header Block 2",
  HEADER_BLOCK_3: "Header Block 3",
};

export const LEFT_PANEL_METADATA_BLOCKS = [
  {
    title: LEFT_PANEL_BLOCKS_NAV,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_NAVBAR,
    iconImg:'pointer-01.svg',
    description:
      "This is where you can select Navigation Bar for you application. Note that the selected Navbar can be further configured to different colors and available controls.",
    showItemsRow: 2,
    subMenu: [
      {
        label: "NavBar Block 1",
        image:
          "https://freshflowxyz.b-cdn.net/assets/section/Navigation_01.jpg",
        metadata: JSONS.NAVBAR_BLOCK_1,
      },
    ],
  },
  {
    title: LEFT_PANEL_BLOCKS_HEADER,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_HEADER,
    iconImg:'layout-top.svg',
    description:
      "This is where you can select Header for you application. Note that the selected Header can be further configured to different colors, combinations and available controls.",
    showItemsRow: 2,
    subMenu: [
      {
        label: PANEL_BLOCKS_LABELS.HEADER_BLOCK_1,
        image: "https://freshflowxyz.b-cdn.net/assets/section/Header_01.jpg",
        metadata: JSONS.HEADER_BLOCK_01,
      },
      {
        label: PANEL_BLOCKS_LABELS.HEADER_BLOCK_2,
        image: "https://freshflowxyz.b-cdn.net/assets/section/Header_02.jpg",
        metadata: JSONS.HEADER_BLOCK_02,
      },
      {
        label: PANEL_BLOCKS_LABELS.HEADER_BLOCK_3,
        image: "https://freshflowxyz.b-cdn.net/assets/section/Header_03.jpg",
        metadata: JSONS.HEADER_BLOCK_04,
      },
      /* {
        label: "HERO Panel 1",
        image:
          "https://via.placeholder.com/500",
        metadata: JSONS.HERO_BLOCK_1,
      },
      {
        label: "HERO Panel 1",
        image:
          "https://via.placeholder.com/500",
        metadata: JSONS.HERO_BLOCK_3,
      }, */
    ],
  },
  {
    title: LEFT_PANEL_BLOCK_CARDS,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_CARDS,
    iconImg:'perspective.svg',
    description:
      "This is where you can 'Design Your Own Card' to display data. You can add elements like Text, Images, Video, Buttons, Links to cards as needed in any combination. You can also add Filter functionalty to cards.",
    showItemsRow: 2,
    subMenu: [
      /* {
        label: "Cards Section 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/Cards_01.jpg",
        metadata: JSONS.GenericCardMetadata,
      }, */
      {
        label: "Design Your Own Card",
        image: "https://freshflowxyz.b-cdn.net/assets/section/Cards_01.jpg",
        metadata: JSONS.DesignCardsMetadata,
      },
    ],
  },
  {
    title: LEFT_PANEL_BLOCKS_HTML,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_HTML,
    iconImg:'embed.svg',
    description:
      "This is where you can select HTML section when you want to add adhoc html/javascript to your application. You can further configure it with various backgrounds and alignments as well.",
    showItemsRow: 2,
    subMenu: [
      {
        label: "HTML Section 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/HTML_01.jpg",
        metadata: JSONS.HTML_BLOCK,
      },
    ],
  },
  {
    title: LEFT_PANEL_BLOCKS_LOGO,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_LOGO,
    iconImg:'color-swatch-01.svg',
    description:
      "This is where you can add Logo section to your application and showcase your customers. You can further configure this sections with various options.",
    showItemsRow: 1,
    subMenu: [
      {
        label: "Logos Section 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/Logos_01.jpg",
        metadata: JSONS.LOGOS_BLOCK_1,
      },
    ],
  },
  {
    title: LEFT_PANEL_BLOCKS_FAQ,
    blockId: PANEL_BLOCKS_TYPES.LEFT_PANEL_BLOCKS_FAQ,
    iconImg:'message-typing.svg',
    description:
      "This is where you can select Features section for you application. Note that the selected Features Block can be further configured to different colors, combinations and available controls.",
    showItemsRow: 2,
    subMenu: [
      {
        label: "FAQ Block 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/FAQ_01.jpg",
        metadata: JSONS.FAQ_BLOCK_1,
      },
    ],
  },
  /* {
    title: LEFT_PANEL_BLOCKS_CONTENT,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_CONTENT,
    description:
      "We’ve been focused on making the from v4 to v5 a but we’ve also not been afraid to step away",
    showItemsRow: 2,
    subMenu: [
      {
        label: "Content Panel",
        image:
          "https://via.placeholder.com/500",
        metadata: JSONS.CONTENT_BLOCK,
      },
    ],
  }, */
  /* DEVELOP new feature section */
  /*{
    title: LEFT_PANEL_BLOCKS_FEATURE,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_FEATURE,
    iconImg:'check-square-broken.svg',
    description:
      "This is where you can select Features section for you application. Note that the selected Features Block can be further configured to different colors, combinations and available controls.",
    showItemsRow: 2,
    subMenu: [
      {
        label: "Feature Block 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/Features_01.jpg",
        metadata: JSONS.FEATURE_BLOCK_2,
      },
    ],
  },*/
  /* {
    title: LEFT_PANEL_BLOCKS_TEAM,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_TEAM,
    description:
      "We’ve been focused on making the from v4 to v5 a but we’ve also not been afraid to step away",
    showItemsRow: 2,
    subMenu: [],
  },
  {
    title: LEFT_PANEL_BLOCKS_FORM,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_FORM,
    description:
      "We’ve been focused on making the from v4 to v5 a but we’ve also not been afraid to step away",
    showItemsRow: 2,
    subMenu: [],
  }, */
  {
    title: LEFT_PANEL_BLOCKS_CTA,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_CAT,
    iconImg:'cursor-pointer.svg',
    description:
      "This is where you can select CTA (Call-To-Action) section for you application. Note that the selected CTA Block can be further configured to different colors, combinations and available controls.",
    showItemsRow: 1,
    subMenu: [
      {
        label: "CTA Block 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/CTA_01.jpg",
        metadata: JSONS.CTA_01_BLOCK,
      },
      {
        label: "CTA Block 2",
        image: "https://freshflowxyz.b-cdn.net/assets/section/CTA_02.jpg",
        metadata: JSONS.CTA_02_BLOCK,
      },
    ],
  },
  /* {
    title: LEFT_PANEL_BLOCKS_TESTIMONIAL,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_TESTIMONAIL,
    description:
      "We’ve been focused on making the from v4 to v5 a but we’ve also not been afraid to step away",
    showItemsRow: 2,
    subMenu: [],
  }, */
  /* {
    title: LEFT_PANEL_BLOCKS_PRICING,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_PRICING,
    description:
      "We’ve been focused on making the from v4 to v5 a but we’ve also not been afraid to step away",
    showItemsRow: 2,
    subMenu: [
      {
        label: "Pricing Panel",
        image:
          "https://via.placeholder.com/500",
        metadata: JSONS.PRICING_BLOCK_1,
      },
      {
        label: "Pricing New",
        image:
          "https://via.placeholder.com/500",
        metadata: JSONS.PRICING_01,
      },
    ],
  }, */
  {
    title: LEFT_PANEL_BLOCKS_FOOTER,
    blockId: PANEL_BLOCKS_TYPES.TYPE_LEFT_BLOCK_FOOTER,
    iconImg:'layout-bottom.svg',
    description:
      "This is where you can select Footer for you application. Note that the selected Footer can be further configured to different colors, combinations and available controls.",
    showItemsRow: 2,
    subMenu: [
      {
        label: "Footer Block 1",
        image: "https://freshflowxyz.b-cdn.net/assets/section/Footer_01.jpg",
        metadata: JSONS.FOOTER_BLOCK_1,
      },
      {
        label: "Footer Block 2",
        image: "https://freshflowxyz.b-cdn.net/assets/section/Footer_02.jpg",
        metadata: JSONS.FOOTER_BLOCK_2,
      },
    ],
  },
  {
    title: "Elements",
    blockId: "Element_1",
    iconImg:'layers-02.svg',
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    showItemsRow: 2,
    subMenu: [
      {
        label: "Video Element 1",
        image:
          "https://freshflowxyz.b-cdn.net/assets/section/Elements_Video_01.jpg",
        metadata: JSONS.VIDEO_ELEMENT_BLOCK,
      },
      {
        label: "Image Element 1",
        image:
          "https://freshflowxyz.b-cdn.net/assets/section/Elements_Image_01.jpg",
        metadata: JSONS.IMAGE_ELEMENT_BLOCK,
      },
      {
        label: "Text Element 1",
        image:
          "https://freshflowxyz.b-cdn.net/assets/section/Elements_Text_01.jpg",
        metadata: JSONS.TEXT_ELEMENT_BLOCK,
      },
      {
        label: "Buttons Element 1",
        image:
          "https://freshflowxyz.b-cdn.net/assets/section/Elements_Button_01.jpg",
        metadata: JSONS.BUTTONS_ELEMENT_BLOCK,
      },
      {
        label: "Image Slider 1",
        image:
          "https://freshflowxyz.b-cdn.net/assets/section/Image_Slider_01.jpg",
        metadata: JSONS.CAROUSEL_ELEMENT_BLOCK,
      },
    ],
  },
];
