import { getDefaultFields } from "../common/common.fields";

export const HERO_FORM_1 = {
  sectionName: "Hero Section",
  elements: [
    {
      inputType: "image",
      label: "Image",
      updateTo: "imageBlock.image",
      hasStyleProps: true,
      styleElementId: 1,
      hideLabel: true,
    },
    {
      inputType: "text",
      updateTo: "imageBlock.alt",
      label: "Alt",
      defaultValue:"",
      hasStyleProps: false
    },
    {
      inputType: "color",
      label: "Text Block BG Color",
      updateTo: "textBlock.styleProps.background-color",
      hasStyleProps: false,
      hideLabel: true,
    },
    {
      inputType: "text",
      label: "Hero Info Primary",
      updateTo: "textBlock.heading1.text",
      hasStyleProps: true,
      styleElementId: 2,
      hideLabel: true,
    },
    {
      inputType: "text",
      label: "Hero Info Secondary",
      updateTo: "textBlock.heading2.text",
      hasStyleProps: true,
      styleElementId: 3,
      hideLabel: true,
    },
    {
      inputType: "list",
      label: "Hero descriptions",
      hasStyleProps: false,
      updateTo: "textBlock.description",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 4,
        elements: [
          {
            inputType: "textarea",
            label: "Description",
            defaultValue:
              "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
            updateTo: "textBlock.description[i].text",
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "text",
      label: "Button one info",
      updateTo: "textBlock.button1.text",
      hasStyleProps: false,
      styleElementId: 1,
      hideLabel: true,
    },
    {
      inputType: "text",
      label: "Button two info",
      updateTo: "textBlock.button2.text",
      hasStyleProps: false,
      styleElementId: 1,
      hideLabel: true,
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Hero",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Hero Image",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "imageBlock.styleProps",
          fields: ["backgroundcolor"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Hero",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.heading1.styleProps",
          fields: ["color", "fontsize", "fontweight"],
        }),
        {
          inputType: "select",
          label: "Align",
          updateTo: "textBlock.heading1.styleProps.textAlign",
          hideLabel: false,
          options: [
            {
              value: "left",
              display: "Left",
            },
            {
              value: "center",
              display: "Center",
            },
            {
              value: "right",
              display: "Right",
            },
          ],
        },
        {
          inputType: "select",
          label: "Underline",
          updateTo: "textBlock.heading1.hideUnderline",
          hideLabel: false,
          options: [
            {
              value: true,
              display: "Display",
            },
            {
              value: false,
              display: "Hide",
            },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.heading1.underLineStyle",
          fields: ["backgroundcolor", "height"],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Hero",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.heading2.styleProps",
          fields: ["color", "fontsize", "fontweight"],
        }),
        {
          inputType: "select",
          label: "Align",
          updateTo: "textBlock.heading2.styleProps.textAlign",
          options: [
            {
              value: "left",
              display: "Left",
            },
            {
              value: "center",
              display: "Center",
            },
            {
              value: "right",
              display: "Right",
            },
          ],
        },
        {
          inputType: "select",
          label: "Underline",
          updateTo: "textBlock.heading2.hideUnderline",
          hideLabel: false,
          options: [
            {
              value: true,
              display: "Display",
            },
            {
              value: false,
              display: "Hide",
            },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.heading2.underLineStyle",
          fields: ["backgroundcolor", "height"],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Hero",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.description[i].styleProps",
          fields: ["color", "fontsize", "fontweight", "textalign"],
        }),
      ],
    },
  ],
};
