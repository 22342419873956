import { twdClass } from "../constants/tailwind.constants";
import { useAuth } from "../contexts/Auth";
import { useHistory, Link } from "react-router-dom";
import { BTN_NORMAL, INPUT_TEXT } from "../constants/var.constants";
import { createProject, getProjectList, deleteProject } from "../api/services";
import { useEffect, useState } from "react";
import { getLowerCaseHashId } from "../utils/hashid.utils";
import party from "party-js";
import ProjectsList from "../components/projects/ProjectsList";
import FaIcons from "../components/common/FaIcons";
import { useSelector } from "react-redux";
import Header from "../components/common/Header";

const Dashboard = () => {
  /* const { signOut } = useAuth();
  const history = useHistory();
  const { first_name, last_name } = useSelector((state) => state.userdetails);

  const handleSignOut = async () => {
    await signOut();
    history.push("/login");
  }; */

  return (
    <div className={twdClass("relative")}>
      {/* <div
        className={`${twdClass(
          "w-full p-3 flex justify-between items-center shadow-md fixed w-full top-0 z-20 bg-white"
        )}`}
      >
        <span className={twdClass("")}>
          <FaIcons icon="globe" className={twdClass("text-2xl")} />
          <span className={twdClass("ml-2 text-xl font-bold")}>Flezr</span>
        </span>
        <span>
          <span className="">
            <span className={twdClass("mr-2")}>{first_name}</span>
            <span className={twdClass("mr-5")}>{last_name}</span>
          </span>
          <button onClick={handleSignOut} className={twdClass(BTN_NORMAL)}>
            Sign out
          </button>
        </span>
      </div> */}
      <Header />
      <div className={twdClass("mt-12")}>
        <ProjectsList />
      </div>
    </div>
  );
};

export default Dashboard;
