export const VIDEO_ELEMENT_BLOCK = {
  blockType: "ELEMENTS",
  blockDesign: "VideoElement",
  editFormName: "VIDEO_ELEMENT_FORM",
  styleProps: {
    "background-color": "",
    "background-image": "url('')",
  },
  stylePropsTab: {},
  stylePropsMob: {},
  videosPerRow: {
    desktop: 1,
  },
  videos: {
    styleProps: {
      "background-image": "url('')",
      padding: "10px 10px 10px 10px",
    },
    stylePropsTab: {},
    stylePropsMob: {},
    list: [
      {
        url: "https://www.youtube.com/watch?v=aqz-KE-bpKQ",
        styleProps: {
          "justify-content": "center",
        },
        videoHeight: {
          height: "400px",
        },
        innerDivStyle: {
          width: "75%",
        },
      },
    ],
  },
};
