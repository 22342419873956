import { twdClass } from "../../../constants/tailwind.constants";
import styled from "styled-components";
import { useState } from "react";
import { get } from "lodash";
import { useEffect } from "react";
import { getUpToWith, Device, States} from "./util";

const CI_1 = twdClass("border-2 border-gray-300 hover:border-gray-500  pb-2 rounded-lg px-2");
const CI_1b = twdClass("border border-gray-100  hover:border-gray-300 rounded-lg px-2");
const CI_2 = twdClass("flex items-center py-2 cursor-pointer");
const CI_3 = twdClass("w-16 text-xs text-gray-900 font-semibold ");
const CI_4 = twdClass("flex-grow text-blue-500");
const CI_5 = twdClass("flex items-center");
const CI_6 = twdClass("w-8 h-4 mr-2 rounded-sm transform scale-125");
const CI_7 = twdClass("w-4 h-4 mr-1 rounded-sm cursor-pointer");
const CI_8 = twdClass("flex flex-wrap bg-gray-100 rounded-md p-2 w-auto mr-5");
const CI_9 = twdClass("flex flex-wrap");
const CI_10 = twdClass("w-8 h-7 mb-1 mr-1 rounded-md cursor-pointer");
const CI_11 = twdClass("flex flex-wrap mt-4 items-end w-full");
const CI_12 = twdClass(
  "w-5/12 h-6 rounded-md border-transparent cursor-pointer"
);
const CI_13 = twdClass("w-7/12 flex flex-col relative pl-2");

const CI_15 = twdClass(
  "w-full rounded-md px-2 rounded-md border-2 border-transparent bg-white  appearance-none focus:border-blue-500 focus:outline-none"
);

const ColorInput = ({ element, data, updateElementValue }) => {
  const { label, name, styleStates=false, updateTo, hideLabel } = element;
  const [device, setDevice] = useState("");
  const [curValue, setCurValue] = useState("");
  const [expandCol, setExpandCol] = useState(false);
  const [dispValue, setDispValue] = useState("");
  const upToWith = getUpToWith({prop:name , updateTo, device})
  const updateTo2 = styleStates == true ? upToWith : updateTo
  if(styleStates) console.log("element2", element);
  
  useEffect(() => {
    //console.log(updateTo);
    //console.log(data);
    let updateTo2 = upToWith_(device)
    const value = get(data, updateTo2);
    //console.log(value);
    //setDispValue(value);
    updateDsiplay(value)
  }, []);
  //const imgSrc = `/assets/images/icon_arrow_${expandCol ? "up" : "down"}.png`;
  const imgSrc = `/assets/images/chevron-${expandCol ? "up" : "down"}.svg`;
  const upToWith_= (device) => {
    const upToWith2 = getUpToWith({prop:name , updateTo, device})   
    return styleStates == true ? upToWith2 : updateTo
  }
  const updateDsiplay = (color, dv) => {
    if(styleStates){
      if(dv==''){
        setDispValue(color);
      }
    }else{
      setDispValue(color);
    }   
    setCurValue(color) 
  }
  const setDevice2 = (dv) => {    
    setDevice(prev => {
      let updateTo2 = upToWith_(dv)
      const v = get(data, updateTo2);   
      //updateDsiplay(v, dv)
      setCurValue(v) 
      return dv
    })  
  };
  const updateColor = (color) => {
    updateDsiplay(color, device)
    updateElementValue(updateTo2, color);
  };
  return (
    <div className={expandCol ? CI_1 : CI_1b}>
      <div className={CI_2} onClick={() => setExpandCol(!expandCol)}>
        {!hideLabel && <div className={CI_3}  >{label}</div>}
        <div className={CI_4}>
          <div className={CI_5}>
            <BGDiv className={CI_6} color={dispValue}></BGDiv>
            <div className={twdClass("flex")}>
              {COLORS_1.map((item) => (
                <BGDiv
                  className={CI_7}
                  color={item}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateColor(item);
                    setExpandCol(false);
                  }}
                ></BGDiv>
              ))}
            </div>
          </div>
        </div>
        <div
          className={twdClass("cursor-pointer")}
          onClick={() => setExpandCol(!expandCol)}
        >
          <img alt="" src={imgSrc} />
        </div>
      </div>
      <div className={`${!expandCol ? twdClass("hidden py-2") : "py-2"}`}>
        {styleStates && <div className={twdClass("flex justify-end")}>
          <States {...{device, setDevice:setDevice2, value:curValue, type:'color'}}/>
        </div>}
        <div className={CI_8}>          
          <div className={CI_9}>
            {COLORS_2.map((item) => (
              <BGDiv
                className={CI_10}
                color={item}
                onClick={() => {
                  updateColor(item);
                }}
              ></BGDiv>
            ))}
          </div>
          <div className={CI_11}>
            <InputColorTag
              className={CI_12}
              color={curValue}
              value={curValue}
              type="color"
              onChange={(e) => {
                updateColor(e.target.value);
              }}
            ></InputColorTag>
            <div className={CI_13}>
              <div className={twdClass("text-sm opacity-50")}>Hex code</div>
              <input
                className={CI_15}
                value={curValue}
                onChange={(e) => {
                  updateColor(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorInput;

const BGDiv = styled.div`
  background-color: ${(props) => props.color};
`;

const InputColorTag = styled.input`
  background-color: ${(props) => props.color};
`;

const COLORS_1 = [
  "#116dff",
  "#7411ff",
  "#ffa611",
  "#333333",
  "#808080",
  "#cccccc",
];
const COLORS_2 = [
  "#116dff",
  "#7411ff",
  "#ffa611",
  "#333333",
  "#808080",
  "#ececec",
  "#116dff",
  "#3e89ff",
  "#72a9ff",
  "#98c0ff",
  "#c6dcff",
  "#e1edff",
  "#7411ff",
  "#9a51ff",
  "#b782ff",
  "#cda8ff",
  "#dec6ff",
  "#ecdeff",
  "#333333",
  "#808080",
  "#808080",
  "#cccccc",
  "#ececec",
  "#ffffff",
];
