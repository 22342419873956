import RenderElements from "./RenderElements";
import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "../common/FaIcons";
import { useState } from "react";
import { getDefaultFields } from "../../jsons/common/common.fields";
import { StyleBtn, StyleBackBtn} from "../ui/presets";

const SubscriptionForm = ({ element, data, updateElementValue }) => {
  const [configs, setConfigs] = useState(false);
  const [styleId, setStyleId] = useState(0);

  const ELEMENTLIST = [
    {
      inputType: "select",
      label: "Email Section",
      hasStyleProps: false,
      updateTo: `${element.updateTo}.showSection`,
      options: [
        {
          display: "Show",
          value: "show",
        },
        {
          display: "Hide",
          value: "hide",
        },
      ],
    },
    {
      inputType: "text",
      label: "Place Holder",
      updateTo: `${element.updateTo}.email_placeholder.text`,
    },
    {
      inputType: "text",
      label: "Button Label",
      hasStyleProps: true,
      styleElementId: 1,
      updateTo: `${element.updateTo}.buttonText.text`,
    },
    {
      inputType: "select",
      label: "Action",
      updateTo: `${element.updateTo}.subConfigs.configType`,
      options: [
        {
          display: "MailChimp",
          value: "mailChimp",
        },
        {
          display: "Mailerlite",
          value: "mailerlite",
        },
        {
          display: "Convert Kit",
          value: "convertkit",
        },
        {
          display: "Zapier",
          value: "zapier",
        },
        {
          display: "Integromat",
          value: "integromat",
        },
      ],
      hideLabel: false,
    },
    {
      inputType: "text",
      label: "URL",
      updateTo: `${element.updateTo}.subConfigs.configUrl`,
    },
    {
      inputType: "text",
      label: "Success Message",
      hasStyleProps: true,
      styleElementId: 2,
      updateTo: `${element.updateTo}.successMessage.text`,
    },
    {
      inputType: "text",
      label: "Error Message",
      hasStyleProps: true,
      styleElementId: 3,
      updateTo: `${element.updateTo}.errorMessage.text`,
    },
  ];

  const STYLEELEMENTLIST = [
    {
      styleElementId: 1,
      label: "Button Configs",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: `${element.updateTo}.buttonText.styleProps`,
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "backgroundcolor",
            "padding",
            "margin",
            "bordercolor",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Success Message",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: `${element.updateTo}.successMessage.styleProps`,
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Error Message",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: `${element.updateTo}.errorMessage.styleProps`,
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "padding",
            "margin",
          ],
        }),
      ],
    },
  ];

  return (
    <>
      {!configs &&
        ELEMENTLIST.map((item) => (
          <div className={twdClass("relative mb-1")}>
            {item.hasStyleProps && (
              <div
                className={twdClass(
                  "absolute p-1 top-0 right-1"
                )}
              >
                <StyleBtn 
                  onClick={() => {
                    setConfigs(true);
                    setStyleId(item.styleElementId);                    
                  }}
                  size={'xss'}
                />
                {/*<span
                  className={twdClass(
                    "absolute p-2 top-0 -right-3 text-sm text-blue-500 cursor-pointer"
                  )}
                  onClick={() => {
                    setConfigs(true);
                    setStyleId(item.styleElementId);
                  }}
                >
                  <FaIcons icon="arrowRight" />
                </span>*/}
              </div>              
            )}
            <RenderElements
              element={item}
              data={data}
              updateElementValue={updateElementValue}
              inputArgs={{ display_label: true, inputStyle: 2 }}
            />
          </div>
        ))}
      {configs && (
        <div >
          <div className={twdClass("mb-3 ml-2 flex items-center")}>
            <StyleBackBtn 
              onClick={() => {
                setConfigs(false);
                setStyleId(0);
              }}
              className={'mr-2'}
              size={'xs'}
            />
            <span
              className={twdClass("text-xs px-1 text-gray-700 font-semibold cursor-pointer")}
              onClick={() => {
                setConfigs(false);
                setStyleId(0);
              }}
            >
              Back to Email Panel
            </span>
          </div>

          <div>
            {STYLEELEMENTLIST.filter(
              (fitem) => parseInt(fitem.styleElementId) === parseInt(styleId)
            )[0]?.elements.map((sitem) => {
              return (
                <div className={twdClass("mb-1")}>
                  <RenderElements
                    element={sitem}
                    data={data}
                    updateElementValue={updateElementValue}                   
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionForm;
