import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  UPDATE_GENERATED,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";
import {get, set, cloneDeep} from 'lodash'



const MapColumns = ({settingsData={}, setSettingsData, updateSettingsData}) => {
  const dispatch = useDispatch();
  const {
    pageInfo,
    generated,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);

  const {sheetColumns=[], params=[]} = settingsData
  if(!sheetColumns.length) return false

  

  //console.log("Generated", generated)
  //console.log("MapColumns", params)


  let options=[    
    {label:"text", value:"textEl"},
    {label:"hide", value:"hide"},
    {label:"id", value:"idEl"},
    {label:"heading", value:"headingEl"},
    {label:"sub heading", value:"subHeadingEl"},
    {label:"category", value:"categoryEl"}, 
    {label:"tags", value:"tagsEl"}, 
    {label:"type", value:"typeEl"},   
    {label:"status", value:"statusEl"},    
    {label:"link", value:"linkEl"},
    {label:"button", value:"buttonEl"},
    {label:"phone", value:"phoneEl"},
    {label:"email", value:"emailEl"},
    {label:"style", value:"styleProperty"},
    {label:"image", value:"imageEl"},
    {label:"thumbImage", value:"thumbImageEl"},
    {label:"detailsLink", value:"detailsLinkEl"},
  ]
  let columns_=sheetColumns.map(({key, value}, ci)=>{
    return(<td className={twdClass("px-4 py-2")}>{key}</td>)
  })
  let types_=sheetColumns.map(({key, value, type=''}, ci)=>{
    let options_=options.map((opt, oi)=>{
      return(<option className={twdClass("")} value={opt.value}>{opt.label}</option>)
    })
    let c_ = type=='hide' ? 'bg-red-100' : 'bg-gray-100'
    return(<td className={twdClass("px-2 py-1")}><div className={twdClass(`px-1 py-1 ${c_} rounded-md`)}><select value={type} onChange={(e)=>{    
      let sheetColumns2=set(cloneDeep(sheetColumns), `[${ci}].type`, e.target.value)
      updateSettingsData('sheetColumns', sheetColumns2)
      /*setSettingsData(prev=>{
        let next={...prev, sheetColumns:sheetColumns2}
        //console.log(e.target.value, next)
        dispatch({
          type: UPDATE_GENERATED,
          data: {settings:next}
        }); 
        return(next)
      })*/
    }}  className={twdClass(`px-2 py-1  rounded-md`)} >{options_}</select></div></td>)
  })
 
  return(
    <div className={twdClass("relative flex flex-col px-4 py-4  rounded-xl border border-gray-200 bg-white w-full overflow-auto")}>
        <div className={twdClass("text-md font-seminbold mb-2 uppercase text-secondary-500")}>Sheet Columns</div>
        <div  className={twdClass("mx-auto overflow-auto bg-white rounded-lg border")}>
          <table  className={twdClass("")}> 
            <tr className={twdClass("border-b text-black text-opacity-50")}>
              <td className={twdClass("px-2 py-1 bg-primary-200 whitespace-nowrap")}>Column Name</td>
              {columns_}
            </tr>
            <tr className={twdClass("")}>
              <td className={twdClass("px-2 py-1 bg-primary-200 whitespace-nowrap")}>Column Type</td>
              {types_}
            </tr>
          </table>    
        </div>    
    </div>
  )
}
export  default MapColumns