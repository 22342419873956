let sd01={
  logo:"FlexBee.",
  description:"The company you can always trust.",
  copy:"© Copyright 2022 FlexBee. All Rights Reserved.",
  terms:"Terms",
  privacy:"Privacy"
}

let a01={
  footer:{
    logo:{text:"FlexBee.", styleProps:{fontSize:'32px', fontWeight:'bold', color:"#ffffff",  padding:"0px 0px 10px 0px"}},
    description:{text:"The company you can always trust.", styleProps:{fontSize:'14px', color:"#ffffff", padding:"0px 0px 40px 0px"}},
    copy:{text:"© Copyright 2022 FlexBee. All Rights Reserved.", styleProps:{fontSize:'14px', color:"#ffffff"}},
    terms:{text:"Terms", styleProps:{fontSize:'14px', color:"#ffffff"}},
    privacy:{text:"Privacy", styleProps:{fontSize:'14px', color:"#ffffff"}},
    styleProps:{backgroundColor:'#1b1b31', padding:'40px 20px'}  
  } 
}

let sd02={
  logo:"FlexBee.",
  description:"The company you can always trust.",
  copy:"© Copyright 2022 FlexBee. All Rights Reserved.",
  terms:"Terms",
  privacy:"Privacy"
}

let a02={
  footer:{
    logo:{text:"FlexBee.", styleProps:{fontSize:'44px', fontWeight:'bold', color:"#333333", padding:"0px 0px 10px 0px"}},
    description:{text:"The company you can always trust.", styleProps:{fontSize:'20px', fontWeight:'600', color:"#333333", padding:"0px 0px 40px 0px"}},
    copy:{text:"© Copyright 2022 FlexBee. All Rights Reserved.", styleProps:{fontSize:'16px', color:"#333333", opacity:0.7}},
    terms:{text:"Terms", styleProps:{fontSize:'16px', color:"#333333", opacity:0.7}},
    privacy:{text:"Privacy", styleProps:{fontSize:'16px', color:"#333333", opacity:0.7}},
    styleProps:{ backgroundColor:'#ffa611', padding:'40px 20px'},
  } 
}

let sd03={
  logo:"FlexBee.",
  description:"The company you can always trust.",
  copy:"© Copyright 2022 FlexBee. All Rights Reserved.",
  terms:"Terms",
  privacy:"Privacy"
}

let a03={
  footer:{
    logo:{text:"FlexBee.", styleProps:{fontSize:'44px', fontWeight:'bold', color:"#333333",  padding:"0px 0px 10px 0px"}},
    description:{text:"The company you can always trust.", styleProps:{fontSize:'20px', color:"#333333", padding:"0px 0px 40px 0px"}},
    copy:{text:"© Copyright 2022 FlexBee. All Rights Reserved.", styleProps:{fontSize:'14px', color:"#333333"}},
    terms:{text:"Terms", styleProps:{fontSize:'14px', color:"#333333"}},
    privacy:{text:"Privacy", styleProps:{fontSize:'14px', color:"#333333"}},
    styleProps:{backgroundColor:'#ffffff', padding:'40px 20px'}  
  } 
}



export default {
  a01, sd01,
  a02, sd02,
  a03, sd03,
}
