import { 
  connect_supabase_vs,
  connect_google_vs 
} from "./vs_data_com";

import { 
  dynamic_cards_vs 
} from "./vs_data_dynamic_cards";

import { 
  dynamic_page_vs 
} from "./vs_data_dynamic_page";



const project_vs = {  
  title:"Cards and Detailed Pages from Google Sheet" ,
  desc:"You need to click 'Data Sync' to pull the latest form submissions.",
  steps:[
    {t:'For now, after every Form submission, you need to click \'Data Sync\' to pull the latest form submissions. In future, this will be automated to automatically.', 
      img:'vs_project_01.png', lx:'48%', ly:'5%'},
    {t:'Give Read-Only access to the Google Sheet where Google Form Responses reside.', 
      img:'vs_project_03.png', lx:'14%', ly:'29%' },
    {t:'Give Read-Only access to the Google Drive where Google Form uploads the images for all form submissions.', 
      img:'vs_project_04.png', lx:'65%', ly:'91%'},        
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}

const tutorials_vs= { 
  title:"Cards and Detailed Pages from Google Sheet" ,
  desc:"You need to click 'Data Sync' to pull the latest form submissions.",
  steps:[
    {t:'For now, after every Form submission, you need to click \'Data Sync\' to pull the latest form submissions. In future, this will be automated to automatically.', 
      img:'vs_project_01.png', lx:'48%', ly:'5%'},
    {t:'Give Read-Only access to the Google Sheet where Google Form Responses reside.', 
      img:'vs_project_03.png', lx:'14%', ly:'29%' },
    {t:'Give Read-Only access to the Google Drive where Google Form uploads the images for all form submissions.', 
      img:'vs_project_04.png', lx:'65%', ly:'91%'},        
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}
const customise_card_vs= { 
  id:3,
  title:"Customizing the design of a card" ,
  desc:"To customise the design of an individual card, click the card section in the main canvas.",
  steps:[
    {t:'To customise the design of an individual card, click the card section in the main canvas. Select \'Card Details\' and click the -> button, to bring the card design panel.', 
      img:'3_cc_01.png', lx:'92%', ly:'80%'},
    {t:'Click  \'Configure Card Style\' button, as shown below. ', 
      img:'3_cc_02.png', lx:'85%', ly:'28%' },
    {t:'The card style panel will appear where you can customize the colour, border, margin, padding etc., of an individual card.', 
      img:'3_cc_03.png', lx:'70%', ly:'26%'},        
  ],
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}

const connect_data_source_vs= { 
  id:4,
  title:"Connecting Data Source" ,
  desc:"Select any one data source from below options to connect.",
  options:[
    {t:'Google Sheet', data:connect_google_vs},
    {t:'Supabase', data:connect_supabase_vs}
  ],    
  related_links:[
    {t:'How to create Cards and Detailed Pages from Google Form submissions?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
    {t:'How to Create Dynamic Pages?.', l:'https://help.flezr.com/site-settings/external-javascript-and-external-css'},
  ]
}
    
export {
  tutorials_vs,
  project_vs,
  customise_card_vs,
  connect_data_source_vs,
  
  dynamic_cards_vs,
  dynamic_page_vs
}
