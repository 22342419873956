import Hashids from "hashids";
const hashidsLowerCase = new Hashids(
  process.env.HASHIDS_KEY,
  0,
  "abcdefghijklmnopqrstuvwxyz"
);
// const hashidsNormal = new Hashids(process.env.HASHIDS_KEY);

export const getLowerCaseHashId = (value) => {
  return hashidsLowerCase.encode(value);
};

export const decodeLowerCaseId = (value) => {
  return hashidsLowerCase.decode(value)[0];
};
