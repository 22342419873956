import React, { useContext, useState, useEffect } from "react";
import { supabaseDB } from "../supabase";
import { getLoginUserInfo } from "../api/services";
import { useDispatch } from "react-redux";

export let getUUID = () => {};
const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  getUUID = () => {
    return user?.id;
  };
  useEffect(() => {
    // Check active sessions and sets the user
    const session = supabaseDB.auth.session();

    setUser(session?.user ?? null);
    setLoading(false);

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabaseDB.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null);
        setLoading(false);
      }
    );

    return () => {
      listener?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      getLoginUserInfo().then((resp) => {
        if (resp.success) {
          dispatch({ type: "UPDATE_LOGINUSER", data: resp.data });
        }
      });
    }
  }, [user]);

  // Will be passed down to Signup, Login and Dashboard components
  const value = {
    signUp: (data) => supabaseDB.auth.signUp(data),
    signIn: (data) => supabaseDB.auth.signIn(data),
    signOut: () => supabaseDB.auth.signOut(),
    resetPassowrd: ({email, redirectTo}) => supabaseDB.auth.api.resetPasswordForEmail(email, { redirectTo }),
    updatePassowrd: ({password}) => supabaseDB.auth.update({password}),
    user,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
