import { twdClass } from "../../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import {
  StyleBtn,
  CollapseBtn,
  AddItemBtn,
  DeleteItemBtn,
} from "../../ui/presets";
import { get, replace, isEmpty, cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";


const LC1 = twdClass(
  "px-1 text-sm border border-gray-200 hover:border-gray-300 mb-2 rounded-2xl "
);
const LC1b = twdClass("px-1 text-sm border-gray-300 border mb-2 rounded-2xl ");
const LC2 = twdClass("pl-3 pr-0 flex items-center");
const LC3 = twdClass("text-gray-500 cursor-pointer flex-1");
const LC4 = twdClass("cursor-pointer ml-3");

const Collapsible = ({ children, label}) => {
  const [show, setShow] = useState(true);  
  return (
    <div className={show ? LC1b : LC1}>
      <div className={LC2} onClick={() => setShow(!show)}>
        <div className={LC3}>
          <div
            className={twdClass(
              "text-gray-800 font-semibold flex items-center "
            )}
          >
            <img
              width="20px"
              className={twdClass("mr-2")}
              src={"/assets/images/flex-align-top-01.svg"}
            />
            {label}
          </div>
        </div>
        <div className={`${LC4} `}>Style
          {/*hasStyleProps && (
            <StyleBtn
              onClick={(e) => {
                e.stopPropagation();
                dispatch({ type: UPDATE_STYLE_ID, id: styleElementId });
              }}
            />
            )*/}
        </div>
        <CollapseBtn className={"ml-1"} isOpen={show} />
      </div>
      <div className={`${!show ? twdClass("hidden") : ""}`}>
        {children}
      </div>      
    </div>
  );
};

export default Collapsible;
