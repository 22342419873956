import { twdClass } from "../../constants/tailwind.constants";
import { Button, BtnStyle, BtnDelete, BtnEdit} from "../ui/com_ui";

export const DividerElementPanel = ({ section, updateStylePanel }) => {
  return (
    <div className={twdClass("text-sm mb-2")}>
      <div className={twdClass("flex justify-between items-center")}>
        <label>Divider Style:</label>
        <div>
          <BtnStyle            
            title="Style"
            onClick={() => {
              updateStylePanel({ sectionId: section.id, showStyles: true });
            }}
          >            
          </BtnStyle>
        </div>
      </div>
    </div>
  );
};
