export const DesignCardsMetadata = {
  blockType: "CARDS",
  blockDesign: "BYOCards",
  editFormName: "DESIGN_CARD_PANEL",
  styleProps: {
    "background-color": "#1b1b31",
    padding: "10px 20px 10px 20px",
  },
  headings: [
    {
      text: "Lorem ipsum dolor sit amet",
      styleProps: {
        "text-align": "center",
        color: "#cccccc",
        "font-size": "2.25rem",
        "line-height": "2.5rem",
      },
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      styleProps: {
        color: "#cccccc",
        "font-size": "1.25rem",
        "line-height": "1.75rem",
        margin: "10px 0px 10px 0px",
        "text-align": "center",
      },
    },
  ],
  cardElements: [],
  cardConfig: {
    perRow: {
      mobile: 1,
      tab: 1,
      desktop: 3,
    },
  },
  configs: {
    dataType: "dynamic",
    connection: {
      spreadsheetId: "",
      sheetName: "",
      connectionId: "",
      connectionTable: "",
      timestamp: 123456,
    },
    params: [],
    filters: {
      categories: [],
    },
    searchConfig: {
      showSearchBar: false, 
      searchButton: {       
        styleProps: {
          color: "#ffffff",
          padding: "5px 20px 5px 20px",          
          "background-color": "#333333",
          "border-radius": "1.5rem",
          margin: "0px 10px 0px 10px",  
          "font-weight": "bold" ,
          width: "auto"         
        },
        stylePropsMob: {
          width: "auto"
        }
      },
      clearButton: {       
        styleProps: {
          "background-color": "#ececec",          
          color: "#333333",
          padding: "5px 20px 5px 20px",
          "border-radius": "9999px"
        }
      },     
      searchContainer: {
        styleProps: {
          "background-color": "#ececec",
          "border-radius": "1.5rem",
          padding: "10px 20px 10px 10px",
          margin: "30px 0px 30px 0px",
          width: "auto"
        },
        stylePropsTab: {
          "border-radius": "9999px"
        },
        stylePropsMob: {
          "border-radius": "1rem",
          width: "100%"
        }
      },
      input: {
        styleProps: {
          "border-radius": "9999px",
          padding: "5px 20px 5px 20px",
          color: "#333333",  
          "font-weight": "100",
          "background-color": "#ffffff",
          width: "auto"
        },
        stylePropsMob: {
          "margin": "0px 0px 10px 0px",
          width: "100%"
        }
      }
    },
    sortBy: { columnName: "", ascending: true },
  },
};
