import AdvancedElemLabel from "./elementprops/AdvancedElemLabel";
import AdvancedElementsProps from "./elementprops/AdvancedElementsProps";

const AdvancedPropsDetails = ({
  sectionData,
  sectionElem,
  blocks,
  styleElementId,
  listIndex,
}) => {
  return (
    <>
      <AdvancedElemLabel
        sectionElem={sectionElem}
        styleElementId={styleElementId}
      />
      <AdvancedElementsProps
        sectionData={sectionData}
        sectionElem={sectionElem}
        styleElementId={styleElementId}
        blocks={blocks}
        listIndex={listIndex}
      />
    </>
  );
};

export default AdvancedPropsDetails;
