import { twdClass } from "../../constants/tailwind.constants";
import RenderElements from "../form/RenderElements";
import { BTN_NORMAL, INPUT_TEXT } from "../../constants/var.constants";
import FaIcons from "../common/FaIcons";
import { useState, useEffect } from "react";
import { getDefaultFields } from "../../jsons/common/common.fields";
import { Button, BtnStyle, BtnBack, BtnDelete, BtnAdd} from "../ui/com_ui";

export const HeadingText = ({ updateElementValue, data, setActivePanel }) => {
  const [actSubTab, setActSubTab] = useState("text");
  const [selectIndex, setSelectIndex] = useState(0);
  const updateSelection = (index) => {
    setSelectIndex(index);
    setActSubTab("styles");
  };
  return (
    <div>
      <div className={twdClass("px-3 py-5 flex items-center")}>
        <BtnBack
          className={"mr-4"}
          onClick={() => {
            if (actSubTab === "text") {
              setActivePanel("main");
            } else {
              setActSubTab("text");
            }
          }}
        >          
        </BtnBack>
        <div className={twdClass("flex-grow")}>
          <div className={twdClass("text-gray-800 text-sm font-semibold")}>Heading Texts</div>
        </div>
      </div>
      <div className={twdClass("px-3")}>
        {actSubTab === "text" && (
          <RenderHeadingTexts
            data={data}
            updateElementValue={updateElementValue}
            updateSelection={updateSelection}
          />
        )}
        {actSubTab === "styles" && (
          <HeadingTextStyles
            data={data}
            selectIndex={selectIndex}
            updateElementValue={updateElementValue}
          />
        )}
      </div>
    </div>
  );
};

const RenderHeadingTexts = ({ data, updateElementValue, updateSelection }) => {
  const { headings } = data;
  const deleteItem = (index) => {
    const values = headings.filter((item, fIndex) => fIndex !== index);
    updateElementValue("headings", values);
  };
  return (
    <div>
      <div className={twdClass("flex items-center justify-between pt-3 pb-2")}>
        <span className={twdClass("text-sm")}>Title / Description</span>
        <span>
          <BtnAdd            
            className={'whitespace-nowrap'}
            onClick={() => {
              headings.push({
                text:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                styleProps: {
                  "text-align": "center",
                  color: "#ffffff",
                },
              });
              updateElementValue("headings", headings);
            }}
          >            
            Add Text
          </BtnAdd>
        </span>
      </div>
      {headings &&
        headings.map((item, index) => {
          return (
            <div className={twdClass("my-2 p-1 bg-white border border-gray-100 hover:border-gray-200 rounded-2xl")}>
              <div className={twdClass("text-sm mb-1 flex justify-between")}>
                <lable></lable>
                <div className={twdClass("flex justify-center items-center")}>
                  <BtnDelete
                    onClick={() => {
                      deleteItem(index);
                    }}
                    className={''}
                  >
                    <FaIcons
                      icon="trashalt"
                      className={twdClass(
                        "text-sm text-red-300 hover:text-red-500"
                      )}
                    />
                  </BtnDelete>
                  <BtnStyle
                    onClick={() => {
                      updateSelection(index);
                    }}
                    className={"ml-2"}
                  >                    
                  </BtnStyle>
                </div>
              </div>
              <textarea
                value={item.text}
                rows={4}
                className={twdClass(INPUT_TEXT)}
                onChange={(e) => {
                  updateElementValue(`headings[${index}].text`, e.target.value);
                }}
              ></textarea>
            </div>
          );
        })}
    </div>
  );
};

const HeadingTextStyles = ({ updateElementValue, selectIndex, data }) => {
  const ELEMENTS = getDefaultFields({
    type: "TEXT",
    updateTo: `headings[${selectIndex}].styleProps`,
    fields: [
      "color",
      "fontsize",
      "fontweight",
      "fontfamily",
      "textalign",
      "padding",
      "margin",
    ],
  });
  return (
    <div>
      <div className={twdClass("pt-3 pb-2")}>
        {ELEMENTS.map((item) => (
          <RenderElements
            element={item}
            data={data}
            updateElementValue={updateElementValue}
          />
        ))}
      </div>
    </div>
  );
};
