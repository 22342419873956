
export const theme_prompt1=`

'WEBSITE_FOR' = "Generative AI Developer Profile"

'REGISTERED_FONTS'=[
    "Open Sans",
    "Helvetic",
    "Time New Roman",
    "Impact",
    "Lato",
    "Roboto",
]

'JSON_SCHEMA' = {
    "fonts":[],
    "colors": [], 
    "linearGradients": [],
}


Create website theme colors in JSON for [WEBSITE_FOR]

Use 4 saturated theme colors.

use minimum 3 gradients

Use [REGISTERED_FONTS] for fonts


JSON output schema should follow [JSON_SCHEMA]

Give me the output in JSON format

Just give me the JSON data


`



export const theme_prompt=({opts={}})=>{
    if(!opts.websiteFor) opts.websiteFor='Generative AI Developer Profile'

    return(`

'WEBSITE_FOR' = "${opts.websiteFor}"    

Create website page content in JSON for [WEBSITE_FOR]

JSON output should not contains css styling
JSON output should be valid JSON data

Give me the output in JSON format

Just give me the JSON data
    `)
}


const schema={
    "name": "theme_response",
    "strict": true,
    "schema": {
        "type": "object",
        "properties": {
            "colors": {
                "type": "object",
                "description": "website colors", 
                "properties": {
                    "primary": { 
                        "type": "string",
                        "description": "website primary color"
                    },
                    "secondary": {
                        "type": "string",
                        "description": "website secondary color"
                    },
                    "tertiary": {
                        "type": "string",
                        "description": "website tertiary color"
                    },
                    "background": {
                        "type": "string",
                        "description": "website background color"
                    },
                    "text": {
                        "type": "string",
                        "description": "website text color"
                    }, 
                    "accent": {
                        "type": "string",
                        "description": "website accent color"
                    }               
                },
                "required": [
                    "primary",
                    "secondary",
                    "tertiary",
                    "background",
                    "text",
                    "accent"
                ],
                "additionalProperties": false
            },
            "color_combinations": {
                "type": "array",
                "description": "website color combinations",
                "items":{
                    "$ref": "#/$defs/color_combination"
                } 
            },
            "background_gradients":{
                "type": "array",
                "description": "website background gradients",
                "items":{
                    "type": "string"
                }
            }           
        },
        "$defs": {
            "color_combination":{
                "type": "object",
                "properties": {
                    "text_color": { 
                        "type": "string",
                        "description": "website text color"
                    },
                    "background_color": {
                        "type": "string",
                        "description": "website background color"
                    },
                    "background_gradient": {
                        "type": "string",
                        "description": "website background gradient"
                    }                 
                },
                "required": [
                    "text_color",
                    "background_color",
                    "background_gradient"
                ],
                "additionalProperties": false
            }               
        },
        "additionalProperties": false,
        "required": [
            "colors",
            "color_combinations",
            "background_gradients"
        ]
    }
}
export const theme_format = {
    "type":"json_schema",
    "json_schema": schema
  }