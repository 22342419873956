import { twdClass } from "../../constants/tailwind.constants";
import FaIcons from "../common/FaIcons";
import { useState, useEffect } from "react";
import { INPUT_TEXT } from "../../constants/var.constants";
import { TextElementPanel } from "./TextElementPanel";
import { ImageElementPanel } from "./ImageElementPanel";
import { VideoElementPanel } from "./VideoElementPanel";
import { ButtonElementPanel } from "./ButtonElementPanel";
import { DividerElementPanel } from "./DividerElementPanel";
import { LinkElementPanel } from "./LinkElementPanel";
import { Button, BtnStyle, BtnBottom, BtnTop, BtnDelete} from "../ui/com_ui";
import ToggleBtn from "../common/ToggleBtn";

const CE1 = twdClass("p-2 clear-both  flex items-center");
const CE2 = twdClass("flex items-center justify-center h-6 w-6 cursor-pointer");
const CE3 = twdClass("text-sm font-semibold cursor-pointer flex-1 capitalize");
const CE4 = twdClass(
  "ml-1 flex items-center justify-center rounded-full h-6 w-6 bg-gray-300 hover:bg-gray-100 cursor-pointer"
);
const CE5 = twdClass("text-sm text-red-400 hover:text-red-600");
const CE6 = twdClass("text-sm text-blue-400 hover:text-blue-600");

export const CardSectionPanel = ({
  columnNames,
  section,
  deletedElement,
  updateValues,
  shaffilElements,
  updateStylePanel,
  elementIndex,
}) => {
  const [expand, setExpand] = useState(true);
  const addNewSectionData = (e) => {
    updateValues(section.id, {
      ...section,
      type: e.target.value,
      text: "",
    });
  };
  const updateLocation = (e) => {
    //alert(section.id)
    updateValues(section.id, {
      ...section,
      location: e.target.value,      
    });
  };

  const ContentType = () => {
    return(
      <div className={twdClass("flex items-center justify-between mb-2")}>
        <label className={twdClass("text-sm mr-2")}>Content Type:</label>
        <ToggleBtn
          toggleId={`renderHTML-${section.id}`}
          value={section.renderHTML}
          falseLabel={`Normal`}
          trueLabel={`HTML`}
          updateValue={(value) => {
            console.log(value, section)
            updateValues(section.id, {
              ...section,
              renderHTML: !value,      
            });
          }}
        />
      </div>  
    )
  }
  
  const CSV = () => {
    return(
      <div className={twdClass("flex items-center justify-between mb-2")}>
        <label className={twdClass("text-sm mr-2")}>Comma Separated Text:</label>
        <div className={twdClass("mr-2")}>
          <ToggleBtn
          toggleId={`csv-${section.id}`}
          value={section.csv}
          falseLabel={`Off`}
          trueLabel={`On`}
          updateValue={(value) => {
            console.log(value, section)
            updateValues(section.id, {
              ...section,
              csv: !value,      
            });
          }}
        />
        </div>
        <div className={twdClass("w-10")}>
          <BtnStyle              
            title="Style"
            onClick={() => {
              updateStylePanel({ sectionId: section.id, showStyles: true, styleId:'csvElement' });
            }}
          >              
          </BtnStyle>
        </div>
      </div>  
    )
  }


 
  return (
    <div className={twdClass("my-3 bg-gray-50 border border-gray-100  hover:border-gray-200 rounded-2xl px-1 py-1")}>
      <div className={CE1}>
        <span className={CE2} onClick={() => setExpand(!expand)}>
          <FaIcons
            icon={`${expand ? "angleUp" : "angleDown"}`}
            className={twdClass("text-sm")}
          />
        </span>
        <span className={CE3} onClick={() => setExpand(!expand)}>
          Element {elementIndex + 1}: {section.type ? section.type : ""}
        </span>
        <span className={twdClass("flex items-center")}>
          <span className={twdClass("flex items-center bg-gray-100 px-2 py-2 rounded-full mr-2")}> 
            <BtnTop            
              title="Move Up"
              className={'mr-2'}
              onClick={() => shaffilElements("MOVE_UP", section.id)}
            >           
            </BtnTop>
            <BtnBottom            
              title="Move Down"
              className={''}
              onClick={() => shaffilElements("MOVE_DOWN", section.id)}
            >           
            </BtnBottom>
          </span>
          <BtnDelete            
            title="Delete" className={"p-2"}
            onClick={() => deletedElement(section.id)}
          >           
          </BtnDelete>
        </span>
      </div>
      {expand && (
        <div className={twdClass("p-2 bg-white border border-gray-100 hover:border-gray-200 rounded-xl")}>
          <div className={twdClass("flex items-center mb-2")}>
            <div className={twdClass("w-36 text-sm mb-1")}>Type:</div>
            <div className={twdClass("w-full")}>
              <select
                className={twdClass(INPUT_TEXT)}
                onChange={(e) => addNewSectionData(e)}
                value={section.type}
              >
                <option value="">Select</option>
                <option value="image">Image</option>
                {/* <option value="video">Video</option> */}
                <option value="text">Text</option>
                <option value="button">Button</option>
                <option value="link">Link</option>
                <option value="divider">Divider</option>
              </select>
            </div>
          </div>
          <div className={twdClass("flex items-center mb-2")}>
            <div className={twdClass("w-36 text-sm mb-1")}>Location:</div>
            <div className={twdClass("w-full")}>
              <select
                className={twdClass(INPUT_TEXT)}
                onChange={(e) => updateLocation(e)}
                value={section.location}
              >
                <option value="">Select</option>
                <option value="A">A</option>               
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
              </select>
            </div>
          </div>
          {section.type === "text" && (
            <div>
              <TextElementPanel
                columnNames={columnNames}
                updateValues={updateValues}
                section={section}
                updateStylePanel={updateStylePanel}
              />
              {ContentType()}
              {CSV()}
            </div>
          )}
          {section.type === "image" && (
            <ImageElementPanel
              columnNames={columnNames}
              updateValues={updateValues}
              section={section}
              updateStylePanel={updateStylePanel}
            />
          )}
          {section.type === "video" && (
            <VideoElementPanel
              columnNames={columnNames}
              updateValues={updateValues}
              section={section}
              updateStylePanel={updateStylePanel}
            />
          )}
          {section.type === "button" && (
            <div>
              <ButtonElementPanel
                columnNames={columnNames}
                updateValues={updateValues}
                section={section}
                updateStylePanel={updateStylePanel}
              />              
            </div>
          )}
          {section.type === "divider" && (
            <DividerElementPanel
              columnNames={columnNames}
              updateValues={updateValues}
              section={section}
              updateStylePanel={updateStylePanel}
            />
          )}
          {section.type === "link" && (
            <LinkElementPanel
              columnNames={columnNames}
              updateValues={updateValues}
              section={section}
              updateStylePanel={updateStylePanel}
            />
          )}
        </div>
      )}
    </div>
  );
};
