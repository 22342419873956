import React from "react";
import { get } from "lodash";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { twdClass } from "../../../constants/tailwind.constants";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import { UPLOADTO_S3 } from "../../../api/endpoint.urlconstants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import S3Uploader from "./S3Uploader";

const ImageUploader = ({ element, data, updateElementValue }) => {
  const [value, setValue] = useState();
  const { updateTo, defaultValue } = element;

  useEffect(() => {
    const dataValue = get(data, updateTo);
    const value = dataValue ? dataValue : defaultValue;
    setValue(value);
  }, [get(data, updateTo)]);

  const updateImagePath = (url) => {
    setValue(url);
    updateElementValue(updateTo, url);
  };

  return (
    <ImgContainer>
      <S3Uploader imageUrl={value} updateS3Path={updateImagePath} />
      <input
        className={twdClass(`w-full mt-2 bg-white ${INPUT_TEXT}`)}
        value={value}
        onChange={(e) => {
          updateElementValue(updateTo, e.target.value);
          setValue(e.target.value);
        }}
      />
    </ImgContainer>
  );
};

export default ImageUploader;

const ImgContainer = styled.div`
  .uppy-DragDrop-inner {
    padding: 0px;
  }
  .uppy-DragDrop-arrow {
    height: 22px;
    width: 20px;
    margin-bottom: 0px;
  }
  .uppy-DragDrop-label {
    font-size: 0.75rem;
  }
`;
