import { getDefaultFields } from "../common/common.fields";

export const HEADER_04_FORM = {
  sectionName: "Header Section",
  elements: [
    {
      inputType: "select",
      label: "Background Overlay",
      styleElementId: 12,
      hasStyleProps: true,
      updateTo: "position",
      options: [
        {
          display: "Hide",
          value: "hide",
        },
        {
          display: "Show",
          value: "show",
        }
      ],
      hideLabel: true,
    },
    
    {
      inputType: "group",      
      label: "Image/Video/HTML",
      hasStyleProps: false,
      elements: [        
        {
          inputType: "select",
          label: "Position",
          updateTo: "imageVideoPostion",
          hasStyleProps: false,
          hideLabel: false,
          options: [
            {
              value: "left",
              display: "Left",
            },
            {
              value: "right",
              display: "Right",
            },
          ],
        },
        {
          inputType: "select",
          label: "Type",
          updateTo: "imageType",
          hasStyleProps: false,
          hideLabel: false,
          options: [
            {
              value: "image",
              display: "Image",
            },
            {
              value: "video",
              display: "Video",
            },
            {
              value: "html",
              display: "HTML",
            },
          ],
        },
        {
          inputType: "image",
          label: "Image",
          updateTo: "imageBlock.image",
          hasStyleProps: true,
          styleElementId: 1,
          hideLabel: true,
          showBasedElemVal: {
            elem: "imageType",
            value: "image",
          },
        },
        {
          inputType: "text",
          updateTo: "imageBlock.alt",
          label: "Alt",
          defaultValue:"",
          hasStyleProps: false,
          showBasedElemVal: {
            elem: "imageType",
            value: "image",
          },
        }, 
        {
          inputType: "text",
          label: "Video Link",
          updateTo: "videoBlock.url",
          hasStyleProps: true,
          styleElementId: 2,
          hideLabel: true,
          showBasedElemVal: {
            elem: "imageType",
            value: "video",
          },
        },
        {
          inputType: "textarea",
          label: "HTML",
          updateTo: "htmlBlock.value",
          hasStyleProps: true,
          styleElementId: 11,
          hideLabel: true,
          showBasedElemVal: {
            elem: "imageType",
            value: "html",
          },
        },
    
      ],
    },
    {
      inputType: "select",
      label: "Text Block",
      updateTo: "textBlock.alingBlock",
      hasStyleProps: true,
      styleElementId: 3,
      hideLabel: true,
      options: [
        {
          value: "start",
          display: "Top",
        },
        {
          value: "center",
          display: "Middle",
        },
        {
          value: "end",
          display: "Bottom",
        },
      ],
    },
    {
      inputType: "list",
      label: "Headings Block",
      hasStyleProps: false,
      updateTo: "textBlock.headings",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 4,
        elements: [
          {
            inputType: "textarea",
            label: "Headings",
            defaultValue:
              "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
            updateTo: "textBlock.headings[i].text",
            extraPropUpdateTo: "textBlock.headings[i]",
            extraPropValues: {
              text:
                "Join our community and thrive with us! We can all help each other, learn together, and grow together.",
              hideUnderline: false,
              underLineStyle: {
                "background-color": "yellow",
                height: "2px",
              },
              styleProps: {
                color: "#ffffff",
                "font-size": "1.125rem",
                "line-height": "1.75rem",
              },
              alignStyleProps: {
                "text-align": "left",
                margin: "10px 0px 10px 0px",
                padding: "10px 0px 10px 0px",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "simplelist",
      label: "Dynamic Text",
      defaultValue: "Build custom apps less time",
      updateTo: "textBlock.dynamicText.strings",
      hasStyleProps: true,
      styleElementId: 5,
    },
    {
      inputType: "list",
      label: "Descriptions",
      hasStyleProps: true,
      styleElementId: 6,
      updateTo: "textBlock.descriptions",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 7,
        elements: [
          {
            inputType: "textarea",
            label: "Descriptions",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            updateTo: "textBlock.descriptions[i].text",
            extraPropUpdateTo: "textBlock.descriptions[i]",
            extraPropValues: {
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              styleProps: {
                "font-size": "1.125rem",
                "line-height": "1.75rem",
                color: "#ffffff",
              },
            },
            hasStyleProps: false,
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "list",
      label: "Buttons",
      hasStyleProps: true,
      styleElementId: 8,
      updateTo: "textBlock.buttons",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 9,
        elements: [
          {
            inputType: "text",
            label: "Button",
            defaultValue: "Click Here",
            updateTo: "textBlock.buttons[i].text",
            extraPropUpdateTo: "textBlock.buttons[i]",
            extraPropValues: {
              text: "Click Here",
              styleProps: {
                color: "#ffffff",
                "border-color": "#3b82f680",
              },
            },
            hasStyleProps: false,
          },
          {
            inputType: "text",
            label: "Href",
            defaultValue: "#",
            updateTo: "textBlock.buttons[i].href",
            hasStyleProps: false,
          },
          /*{
            inputType: "text",
            label: "Alt Text",
            defaultValue: "#",
            updateTo: "textBlock.buttons[i].alt",
            hasStyleProps: false,
          },*/
          {
            inputType: "select",
            label: "Open In",
            defaultValue: "sametab",
            updateTo: "textBlock.buttons[i].openType",
            hasStyleProps: false,
            options: [
              { value: "sametab", display: "Same Tab" },
              { value: "newtab", display: "New Tab" },
            ],
          },
          {
            inputType: "group",
            label: "Advanced",
            initShow:false,
            elements: [
              {
                inputType: "text",
                label: "Button ID",
                defaultValue: "",
                updateTo: "textBlock.buttons[i].button_id",
                hasStyleProps: false,
              }, 
              {
                inputType: "text",
                label: "Alt Text",
                defaultValue: "",
                updateTo: "textBlock.buttons[i].alt",
                hasStyleProps: false,
              },             
            ]
          },
        ],
      },
      elements: [],
    },
    {
      inputType: "componentTag",
      subType: "SubscriptionTag",
      label: "Email Subscription",
      updateTo: "textBlock.subscription",
      hasStyleProps: true,
      styleElementId: 10,
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Image",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "imageBlock.styleProps",
          fields: [
            "backgroundcolor",
            "backgroundimage",
            "borderwidth",
            "bordercolor",
            "borderradius",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Video",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "videoBlock.styleProps",
          fields: [
            "backgroundcolor",
            "backgroundimage",
            "borderwidth",
            "bordercolor",
            "borderradius",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 3,
      label: "Text Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.styleProps",
          fields: [
            "backgroundcolor",
            "backgroundimage",
            "borderwidth",
            "bordercolor",
            "borderradius",
            "padding",
            "margin",
          ],
        }),
      ],
    },
    {
      styleElementId: 4,
      label: "Header Text",
      elements: [
        { inputType: "section", label: "Text Configs" },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.headings[i].styleProps",
          fields: ["color", "fontsize", "fontfamily", "fontweight"],
        }),
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.headings[i].alignStyleProps",
          fields: ["textalign", "padding", "margin"],
        }),
        { inputType: "section", label: "Underline Configs" },
        {
          inputType: "select",
          label: "Underline",
          updateTo: "textBlock.headings[i].hideUnderline",
          hideLabel: false,
          options: [
            {
              value: true,
              display: "Display",
            },
            {
              value: false,
              display: "Hide",
            },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.headings[i].underLineStyle",
          fields: ["backgroundcolor", "height"],
        }),
      ],
    },
    {
      styleElementId: 5,
      label: "Dynamic Block",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.dynamicText.styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
            "textalign",
          ],
        }),
      ],
    },
    {
      styleElementId: 6,
      label: "Descriptions Section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.descriptionsStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 7,
      label: "Description",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.descriptions[i].styleProps",
          fields: [
            "color",
            "fontsize",
            "fontweight",
            "fontfamily",
            "padding",
            "margin",
            "textalign",
          ],
        }),
      ],
    },
    {
      styleElementId: 8,
      label: "Button section",
      elements: [
        {
          inputType: "select",
          label: "Button Align",
          updateTo: "textBlock.buttonAlign",
          hideLabel: false,
          options: [
            {
              value: "start",
              display: "Left",
            },
            {
              value: "center",
              display: "Center",
            },
            {
              value: "end",
              display: "Right",
            },
          ],
        },
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.buttonsStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 9,
      label: "Button",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.buttons[i].styleProps",
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "backgroundcolor",
            "padding",
            "margin",
            "borderwidth",
            "bordercolor",
            "borderradius",
          ],
        }),
      ],
    },
    {
      styleElementId: 10,
      label: "Email section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "textBlock.emailSecStyleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 11,
      label: "HTML section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "htmlBlock.styleProps",
          fields: ["padding", "margin"],
        }),
      ],
    },
    {
      styleElementId: 12,
      label: "Bg Overlay",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "bgoverlay.styleProps",
          fields: [
            "opacity",
            "backgroundcolor",            
          ],
        }),
      ],
    },
  ],
};
