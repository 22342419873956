import Dropdown from "./Dropdown";
import { get, endsWith, replace, find } from "lodash";
import { twdClass } from "../../../constants/tailwind.constants";
import { useState, useEffect } from "react";
import FaIcons from "../../common/FaIcons";
import { getUpToWith, Device} from "./util";

const SelectRes = ({ element, data, updateElementValue }) => {
  const [device, setDevice] = useState("");
  const { label, hideLabel, updateTo, options, prop, responsive } = element;
  //const upToWith = replace(updateTo, ".width", `${device}.width`);
  const upToWith= getUpToWith({prop:prop, updateTo, device})
  const value = get(data, upToWith);

  const updateValue = (selectedValue) => {
    console.log("selectRes", label, updateTo, upToWith, selectedValue)
    updateElementValue(upToWith, selectedValue);    
  };

  return (
    <div className={twdClass("relative w-full")}>
      <div className={twdClass("absolute flex top-2 right-0 mr-10")}>
         <Device {...{device, setDevice}}/>
      </div>
      <Dropdown
        options={options}
        hideLabel={hideLabel}
        label={label}
        value={value}
        updateValue={updateValue}
      />
    </div>
  );
};

export default SelectRes;
