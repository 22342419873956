import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "./Common";
import {cards_prompt, cards_format} from "../prompts/cards_prompt";

const Link = (link) => {
  const {text}= link
  return(<DivTag {...link}>{text}</DivTag>)
}

const Links = ({links}) => {
  let links_=links.map((link, i)=>{
    return(<Link key={i} {...link}/>)
  })
  return(<DivTag className={twdClass("")}>
    <div className={twdClass("flex items-center gap-2")}>{links_}</div>
  </DivTag>)
}

const Text = ({textEl}) => {
  const {text}= textEl
  return(<DivTag {...textEl}>{text}</DivTag>)
}
const Button = ({url, text, ...element}) => { 
  return(<DivTag {...element} href={url}>{text}</DivTag>)
}

const Image = ({src, ...element}) => {  
  return(<ImgTag {...element} src={src} alt={src}></ImgTag>)
}

const Element = ({element}) => {
  const {elementType="", text}= element
  let out={}
  if(elementType=="imageEl" || elementType=="thumbImageEl"){
    out=<Image {...element}></Image>
  }else if(elementType=="buttonEl" || elementType=="detailsLinkEl"){
    out=<Button {...element}></Button>
  }else{
    out=<DivTag {...element}>{text}</DivTag>
  }

  return(<div>{out}</div>)
}

const Column = ({column}) => {
  const {elements}= column
  return(<DivTag {...column}>
    {elements?.map((element, ei)=>{
       return(<Element key={ei} {...{element}} />)
    })}
  </DivTag>)
}

const Card = ({card, cardStyles, cardOuterStyles={}}) => {
  const {card_columns}= card
  if(cardStyles?.styleProps?.width) cardStyles.styleProps.width=''
  return(<DivTag {...cardOuterStyles} >
    <DivTag {...cardStyles}>
      {card_columns?.map((column, ei)=>{
        return(<Column key={ei} {...{column}} />)
      })}
    </DivTag>
  </DivTag>)
}
const SearchBar = ({searchbar}) => {
  const {search_input, search_button, clear_button}= searchbar
  return(<div className={twdClass("flex justify-center")}>
    <DivTag className={twdClass("flex justify-center")} {...searchbar}>
      <InputTag {...search_input} placeholder="Search..."></InputTag>
      <DivTag {...search_button}>Search</DivTag>
      <DivTag {...clear_button}>Clear</DivTag>
    </DivTag>
  </div>)
}
const Design2 = ({obj={},  opts={}, sample='', sdata={}, data=[]}) => { 
  let user_prompt = cards_prompt({opts, sample, sdata, data })  
  return (<Design {...{type:'cards', obj, user_prompt, format:cards_format}}/>)
}

const Cards = ({cards={}, opts={}, design=false, sample='', sdata={}, data=[]}) => {
  
  let obj=cards.cards ? cards.cards : cards
  //console.log("cards", obj, data)
  const {title={}, description={}, searchbar={}, card_items=[], cards_config={},
  cards_item_styles={}, cards_outer_styles={}, cards_holder_styles={}, cards_item_outer_styles={},
  
  ...obj1} = obj

  const {cards_per_row={}} = cards_config

  cards_outer_styles.styleProps = cards_outer_styles.styleProps ? cards_outer_styles.styleProps : {}
  cards_outer_styles.stylePropsTab = cards_outer_styles.stylePropsTab ? cards_outer_styles.stylePropsTab : {}
  cards_outer_styles.stylePropsMob = cards_outer_styles.stylePropsMob ? cards_outer_styles.stylePropsMob : {}

  cards_outer_styles.styleProps={
    //...cards_outer_styles.styleProps,
    display:'grid', gap:'20px', gridTemplateColumns: `repeat(${cards_per_row.desktop ? cards_per_row.desktop : 3}, minmax(0, 1fr))`
  }
  cards_outer_styles.stylePropsTab={
    //...cards_outer_styles.stylePropsTab,
    display:'grid', gridTemplateColumns: `repeat(${cards_per_row.tab ? cards_per_row.tab : 2}, minmax(0, 1fr))`
  }
  cards_outer_styles.stylePropsMob={
    //...cards_outer_styles.stylePropsMob,
    display:'grid', gridTemplateColumns: `repeat(${cards_per_row.mobile ? cards_per_row.mobile : 1}, minmax(0, 1fr))`
  }

  if(!cards_item_outer_styles.styleProps) cards_item_outer_styles.styleProps={}
  cards_item_outer_styles.styleProps.width='100%'
  cards_item_outer_styles.styleProps.display='flex'
  cards_item_outer_styles.styleProps.flexWrap='wrap'

  return(
    <DivTag {...obj1} className={twdClass("relative flex items-center gap-8 mb-4 px-4 py-2 ")}>
        <Design2 {...{design, obj, opts, sample, data, sdata}}/>
        <div className={twdClass("relative mx-auto")} style={{'max-width':'1536px'}}>
          <Text {...{textEl:title}}/>
          <Text {...{textEl:description}}/>  
          <SearchBar {...{searchbar}}/>           
          <DivTag className={twdClass("mt-4")}>    
              <DivTag {...cards_outer_styles} >                       
                {card_items?.map((card, ci)=>{
                  return(<Card key={ci} {...{card, cardStyles:cards_item_styles, cardOuterStyles:cards_item_outer_styles, cards_per_row}} />)
                })}  
              </DivTag>  
          </DivTag>
        </div>      
    </DivTag>
  )
}
export  default Cards