import { twdClass } from "../../../constants/tailwind.constants";
import { useState, useEffect, useRef } from "react";
import { replace, cloneDeep, set, get } from "lodash";
import FaIcons from "../../common/FaIcons";

const PaddingMargin = ({ element, data, updateElementValue }) => {
  const [device, setDevice] = useState("");
  const [expandOpt, setExpandOpt] = useState(false);
  const [displayValue, setDisplayValue] = useState("0 : 0 : 0 : 0");
  const [values, setValues] = useState({
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  });
  const inputRef = useRef();

  useEffect(() => {
    const vals = get(data, element.updateTo);
    if (vals) {
      const lst = vals.split(" ");
      const pds = cloneDeep(values);
      Object.keys(pds).forEach((itm, i) => {
        pds[itm] = lst[i];
      });
      setValues(pds);
    }
  }, []);

  useEffect(() => {
    const val = replace(Object.values(values).join(" : "), /px/g, "");
    setDisplayValue(val);
  }, [values]);

  useEffect(() => {
    let upToWith = replace(element.updateTo, ".padding", `${device}.padding`);
    upToWith = replace(upToWith, ".margin", `${device}.margin`);
    const valuesDev = get(data, upToWith);
    if (valuesDev) {
      const vals = replace(valuesDev, /px/g, ":")
        .split(":")
        .filter((item) => !!item);
      const val = vals.join(" : ");
      setDisplayValue(val);

      const pds = cloneDeep(values);
      Object.keys(pds).forEach((itm, i) => {
        if (vals[i] !== undefined) {
          pds[itm] = `${vals[i].trim()}px`;
        }
      });
      setValues(pds);
      inputRef.current.value = "";
    }
  }, [device]);

  const updateValues = (x, y) => {
    const val = y ? y : 0;
    const pds = set(cloneDeep(values), x, `${val}px`);
    const v = Object.values(pds).join(" ");
    setValues(pds);
    let upToWith = replace(element.updateTo, ".padding", `${device}.padding`);
    upToWith = replace(upToWith, ".margin", `${device}.margin`);
    updateElementValue(upToWith, v);
    console.log(element.updateTo, "upToWith", upToWith, v, values, x, y )
  };

  const updateAllVals = (pds) => {
    const v = Object.values(pds).join(" ");
    setValues(pds);
    //updateElementValue(`${element.updateTo}${device}`, v);
    let upToWith = replace(element.updateTo, ".padding", `${device}.padding`);
    upToWith = replace(upToWith, ".margin", `${device}.margin`);
    updateElementValue(upToWith, v);
  };

  const CI_1 = twdClass("border-2 border-gray-300 hover:border-gray-500  pb-2 rounded-lg px-2");
  const CI_1b = twdClass("border border-gray-100  hover:border-gray-300 rounded-lg px-2");
  //const imgSrc = `/assets/images/icon_arrow_${expandOpt ? "up" : "down"}.png`;
  const imgSrc = `/assets/images/chevron-${expandOpt ? "up" : "down"}.svg`;
  return (
    <div className={expandOpt ? CI_1 : CI_1b}>
      <div
        className={twdClass("flex items-center py-2 cursor-pointer")}
        onClick={() => setExpandOpt(!expandOpt)}
      >
        <div className={twdClass("w-16 text-xs text-gray-700 font-semibold")}>
          {element.inputType === "padding" ? "Padding" : "Margin"}
        </div>
        <div className={twdClass("flex-grow ")}>{displayValue}</div>
        <div>
          <img src={imgSrc} alt="" />
        </div>
      </div>
      <div className={twdClass(`py-2 ${!expandOpt ? "hidden" : ""}`)}>
        <div className={twdClass("w-full flex justify-end mb-2")}>
          <span
            className={twdClass(
              `mr-1 rounded-md p-2 flex justify-center items-center ${
                device === "" ? `bg-gray-200` : "bg-gray-50"
              }`
            )}
            onClick={() => setDevice("")}
          >
            <FaIcons icon="desktop" className={twdClass("text-xs")} />
          </span>
          <span
            className={twdClass(
              `mr-1 rounded-md p-2 flex justify-center items-center ${
                device === "Tab" ? `bg-gray-200` : "bg-gray-50"
              }`
            )}
            onClick={() => setDevice("Tab")}
          >
            <FaIcons icon="tablet" className={twdClass("text-xs")} />
          </span>
          <span
            className={twdClass(
              `mr-6 rounded-md p-2 flex justify-center items-center ${
                device === "Mob" ? `bg-gray-200` : "bg-gray-50"
              }`
            )}
            onClick={() => setDevice("Mob")}
          >
            <FaIcons icon="mobile" className={twdClass("text-xs")} />
          </span>
        </div>
        <div
          className={twdClass(
            "flex flex-wrap bg-gray-100 rounded-md px-2 py-2 w-auto mr-5"
          )}
        >
          <div className={twdClass("flex flex-col items-center px-6")}>
            <div className={twdClass("flex mb-1")}>
              <div className={twdClass("w-4/12 pr-1")}></div>
              <div className={twdClass("w-4/12 pr-1")}>
                <input
                  className={twdClass(
                    "w-full text-gray-900 text-center rounded-md border-2 border-transparent bg-white appearance-none focus:border-blue-500 focus:outline-none"
                  )}
                  value={replace(values.top, "px", "")}
                  onChange={(e) => {
                    updateValues("top", e.target.value);
                  }}
                />
              </div>
              <div className={twdClass("w-4/12 pr-1")}></div>
            </div>
            <div className={twdClass("flex mb-1")}>
              <div className={twdClass("w-4/12 pr-1")}>
                <input
                  className={twdClass(
                    "w-full text-gray-900 text-center rounded-md border-2 border-transparent bg-white appearance-none focus:border-blue-500 focus:outline-none"
                  )}
                  value={replace(values.left, "px", "")}
                  onChange={(e) => {
                    updateValues("left", e.target.value);
                  }}
                />
              </div>
              <div className={twdClass("w-4/12 pr-1")}>
                <input
                  className={twdClass(
                    "w-full opacity-50 hover:opacity-100 focus:opacity-100 text-gray-500 text-center rounded-md border-2 border-transparent bg-white appearance-none focus:border-blue-500 focus:outline-none"
                  )}
                  ref={inputRef}
                  onChange={(e) => {
                    let val = cloneDeep(values);
                    const x = e.target.value;
                    Object.keys(val).forEach((itm) => {
                      val[itm] = `${x}px`;
                    });
                    updateAllVals(val);
                  }}
                />
              </div>
              <div className={twdClass("w-4/12 pr-1")}>
                <input
                  className={twdClass(
                    "w-full text-gray-900 text-center rounded-md border-2 border-transparent bg-white appearance-none focus:border-blue-500 focus:outline-none"
                  )}
                  value={replace(values.right, "px", "")}
                  onChange={(e) => {
                    updateValues("right", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={twdClass("flex")}>
              <div className={twdClass("w-4/12 pr-1")}></div>
              <div className={twdClass("w-4/12 pr-1")}>
                <input
                  className={twdClass(
                    "w-full text-gray-900 text-center rounded-md border-2 border-transparent bg-white appearance-none focus:border-blue-500 focus:outline-none"
                  )}
                  value={replace(values.bottom, "px", "")}
                  onChange={(e) => {
                    updateValues("bottom", e.target.value);
                  }}
                />
              </div>
              <div className={twdClass("w-4/12 pr-1")}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaddingMargin;
