import { twdClass } from "../../../constants/tailwind.constants";
import { TOGGEL_POPUP } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { UserMenu } from "../../common/ui";
import SectionsPanel from "./SectionsPanel";
import PagesLayout from "../pages/PagesLayout";
import { Button} from "../../ui/com_ui";
import FaIcons from "../../common/FaIcons";
import { ConnectionPanel } from "../connections/ConnectionPanel";
import SiteSettingsPanel from "../site-settings/SiteSettingsPanel";
import { TutorialsTip, ProjectTip, CustomiseCardTip, ConnectDataSourceTip} from "../../content/tooltips";
import { ConnectDataSourceVs_play, DynamicPageVs_play} from "../../content/visual_steps";

const LP_CLS_1 = twdClass(
  "p-2 rounded-xl border border-purple-200 my-3 shadow-md hover:shadow-lg"
);
const LiftSidePanel = () => {
  const dispatch = useDispatch();
  const openBlocksPanel = () => {
    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        title: "Sections",
        desc:'Sections and blocks are pre-designed building blocks to help you create and design your website.',
        img:'section_img.png',
        color:'primary', 
        component: <SectionsPanel />,
      },
    });
  };

  const openPagePanel = () => {
    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        title: "Pages",
        component: <PagesLayout />,
        action:<DynamicPageVs_play/>,
      },
    });
  };

  const openConnections = () => {
    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        title: (<div className={twdClass('flex items-center')}><div  className={twdClass('mr-12')}>Connections</div><ConnectDataSourceVs_play/></div>),
        component: <ConnectionPanel />,
      },
    });
  };

  const openSiteSettings = () => {
    dispatch({
      type: TOGGEL_POPUP,
      data: {
        showPopup: true,
        title: "Site Settings",
        component: <SiteSettingsPanel />,
      },
    });
  };

  return (
    <div className={twdClass("p-2 h-screen bg-white border-r border-gray-200 flex flex-col")}>
      <div className={twdClass("bg-gray-100 p-2 rounded-full ")}>
        <div>
          <Button onClick={openBlocksPanel} 
            type={'icon'}
            iconImg={'add-block.svg'}
            cType={'icon'}
            color={'primary'}
            className={'mb-2'}
          >            
          </Button>
        </div>
        <div>
          <Button onClick={openPagePanel}
            type={'icon'}
            iconImg={'file-multiple.svg'}
            cType={'icon'}
            color={'black'}
          >            
          </Button> 
        </div>
      </div>
      <div className={twdClass("bg-white p-2 rounded-full")}>
        <div>          
          <Button onClick={openConnections}
            type={'icon'}
            iconImg={'link-angled.svg'}
            cType={'icon'}
            color={'white'}
            className={'mb-2'}
          >            
          </Button> 
        </div>
        <div>          
          <Button onClick={openSiteSettings}
            type={'icon'}
            iconImg={'settings.svg'}
            cType={'icon'}
            color={'white'}
          >            
          </Button> 
        </div>        
      </div>
      <div className={twdClass("flex-grow")}></div>
      <div className={twdClass("bg-gray-100 p-2 rounded-full mb-40 flex flex-col")}>
        <UserMenu studio={true}/>
      </div>
    </div>
  );
};

export default LiftSidePanel;
