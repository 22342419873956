import { twdClass } from "../../../constants/tailwind.constants";
import { BTN_CLS } from "../../../constants/var.constants";
import { useSelector, useDispatch } from "react-redux";
import { deleteConnection, loadConnections } from "../../../api/services";
import { toast } from "react-toastify";
import { UPDATED_CONNECTIONS } from "../../../constants/action.constants";
import FaIcons from "../../common/FaIcons";
import { useEffect } from "react";
import { CONNECTIONS_METADATA } from "./connection.metadata";
import {Button} from "../../ui/com_ui";

export const ConnectionList = ({ updateEditId }) => {
  const {
    projectData: { connections, project_id },
  } = useSelector((state) => state.studioDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    reloadConnections();
  }, []);

  const removeConnection = async (item) => {
    const { connection_id } = item;
    try {
      const resp = await deleteConnection(connection_id);
      if (resp.success) {
        toast.success("Connection deleted");
        reloadConnections();
      }
    } catch (error) {}
  };

  const reloadConnections = async () => {
    try {
      const resp = await loadConnections(project_id);
      dispatch({ type: UPDATED_CONNECTIONS, data: resp.data });
    } catch (error) {}
  };

  const appendGooglLink = (item) => {
    const {
      connection_type,
      connection_details: { spreadsheetId },
    } = item;
    if (connection_type === "googleSheet") {
      return `https://docs.google.com/spreadsheets/d/${spreadsheetId}`;
    }
    return "#";
  };

  const CONNECT_META = CONNECTIONS_METADATA.reduce((a, b, c) => {
    return { ...a, [b.key]: b.value };
  }, {});

  return (
    <>
      <div className={twdClass("max-h-64 overflow-auto -mx-4 -mb-1")}>
        <div className={twdClass("flex pb-2 px-6 text-sm")}>
          <div className={twdClass("w-1/4")}>Type</div>
          <div className={twdClass("w-2/4")}>Name</div>
          <div className={twdClass("w-1/4")}></div>
        </div>
        {connections && connections.length === 0 && (
          <div
            className={twdClass("text-center py-10 italic text-2xl font-thin")}
          >
            Please click "Add New Connection" button to start adding connections
          </div>
        )}
        <div className={twdClass("bg-white py-2 rounded-2xl")}>
        {connections &&
          connections.map((item, i) => (
            <div
              className={twdClass(`flex items-center font-semibold border-gray-100 py-2 text-sm  px-6 ${i < (connections.length-1) ? "border-b" : "" }`)}
            >
              <div className={twdClass("w-1/4")}>
                {CONNECT_META[item.connection_type]}
              </div>
              <div className={twdClass("flex-1 text-sm")}>
                {item.connection_type === "googleSheet" && (
                  <a
                    className={twdClass("hover:underline")}
                    href={appendGooglLink(item)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.connection_details.displayName}
                  </a>
                )}
                {item.connection_type === "supabase" && (
                  <span>{item.connection_details.displayName}</span>
                )}
              </div>
              <div className={twdClass("flex items-center w-36")}>
                <Button
                  type="icon" iconImg={"trash-04-red.svg"}  size={"xs"} color="white" className={'bg-red-100 inline-block mx-2'} 
                  onClick={() => removeConnection(item)}
                >                  
                </Button>
                <div  className={twdClass("flex items-center bg-gray-100 rounded-full p-1")}>
                  <Button
                    type={"default"} cType="iconText" iconImg={"pencil-01.svg"}  size={"xs"} color="white" className={''} 
                    onClick={() => {
                      updateEditId({
                        id: item.connection_id,
                        panelType: "addedit",
                      });
                    }}
                  > Edit              
                  </Button>
                  
                  {item.connection_type === "googleSheet" && (
                    <a
                      href={appendGooglLink(item)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="icon" iconImg={"link-external.svg"}  size={"xs"} color="white" className={'ml-2'}                        
                      >                  
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
