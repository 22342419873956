import { find, findIndex } from "lodash";
import { getDefaultFields } from "../../jsons/common/common.fields";
import RenderElements from "../form/RenderElements";
import { twdClass } from "../../constants/tailwind.constants";

const PROPS_ITEMS = {
  text: [
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: [
        "fontsize",
        "textalign",
        "fontweight",
        "fontfamily",
        "white-space",
        "color",
        "padding",
        "margin",
        "display",
        "flexwrap",
        "alignitems",
      ],
    }),
  ],
  csvElement: [
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `csvElementStyleProps`,
      fields: [
        "color",
        "backgroundcolor",
        "fontsize",
        "fontfamily",
        "fontweight", 
        "padding",
        "margin",
        "borderwidth",
        "borderradius",
        "bordercolor",
      ],
    }),
  ],
  image: [
    {
      inputType: "select",
      label: "Align",
      updateTo: "align",
      hideLabel: false,
      options: [
        {
          value: "start",
          display: "Left",
        },
        {
          value: "center",
          display: "Center",
        },
        {
          value: "end",
          display: "Right",
        },
      ],
    },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `imgStyleProps`,
      fields: ["height2px", "borderradius", "padding", "margin", "width2px"],
    }),
  ],
  video: [
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `videoHeight`,
      fields: ["height2px"],
    }),
  ],
  button: [
    { inputType: "section", label: "Button Section" },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: ["backgroundcolor", "padding", "margin"],
    }),
    { inputType: "section", label: "Button Configs" },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: ["textalign"],
    }),
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `btnStyleProps`,
      fields: [
        "color",
        "backgroundcolor",
        "fontsize",
        "fontfamily",
        "fontweight",
        "borderwidth",
        "borderradius",
        "bordercolor",
        "padding",
        "margin",
        "width-percent",
      ],
    }),
  ],
  link: [
    { inputType: "section", label: "Link Section" },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: ["backgroundcolor", "padding", "margin"],
    }),
    { inputType: "section", label: "Link Configs" },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: ["textalign"],
    }),
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `btnStyleProps`,
      fields: [
        "color",
        "fontsize",
        "fontfamily",
        "fontweight",
        "padding",
        "margin",
      ],
    }),
  ],
  divider: [
    { inputType: "section", label: "Divider Section" },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `styleProps`,
      fields: ["justify-align", "padding", "margin"],
    }),
    { inputType: "section", label: "Divider" },
    ...getDefaultFields({
      type: "TEXT",
      updateTo: `divStyleProps`,
      fields: [
        "backgroundcolor",
        "width-percent",
        "height",
        "padding",
        "margin",
      ],
    }),
  ],
};

export const CardElementStylesPanel = ({
  activeSecId,
  sections,
  updateElementValue,
  styleId=false
}) => {
  const activeSection = find(sections, ["id", activeSecId]);
  const indexOf = findIndex(sections, ["id", activeSecId]);
  console.log("PROPS_ITEMS", styleId)
  const list = PROPS_ITEMS[styleId ? styleId : activeSection.type];

  const updateElmentStylesValues = (key, value) => {
    updateElementValue(`cardElements[${indexOf}].${key}`, value);
  };

  return (
    <div>
      {list &&
        list.map((item) => (
          <div className={twdClass("mb-1")}>
            <RenderElements
              element={item}
              data={activeSection}
              updateElementValue={updateElmentStylesValues}
            />
          </div>
        ))}
    </div>
  );
};
