const local_sample=`
    [
        {
            "type":"headerDyn",           
            "heading":"{{Title}}",  
            "description":"{{locaton}}"
        },
        {
            "type":"textElement",           
            "texts":[
                {"label":"Job Title", "value":"{{Title}}"},
                {"label":"Job Type", "value":"{{Job Type}}"},
                {"label":"Category", "value":"{{category}}"},
                {"label":"Location", "value":"{{location}}"},
                {"label":"Job Description", "value":"{{description}}"},
                {"label":"Job Requiremnts", "value":"{{requirements}}"},
            ]            
        },
        {
            "type":"imageElement",           
            "image":'{{image}}'          
        },
        {
            "type":"buttonElement",           
            "buttons":[
                {"text":"Apply", "url":"{{apply}}"},                
            ]            
        },     
    ]

`

export const structureDyn_prompt=({opts={}, sample=false, params=[], data=false})=>{

    if(!sample){
        sample=local_sample
    }

    if(!data) {
        data=[
            {"Title":"Senior Full Stack Developer",  "Job Type":"Full Time", "date":"24-4-2024",  "category":"Software Development",  
                "location":"Remote - USA", "apply":"/apply/1", "image":"https://iili.io/HxOyhHQ.png"},
            {"Title":"UI Developer",  "Job Type":"Full Time", "date":"24-4-2024",   "category":"UI Development",  
                "location":"Remote - USA", "apply":"/apply/2", "image":"https://iili.io/HxOyhHQ.png"},
        ]
    }

    if(!opts.websiteFor) opts.websiteFor=`Generative AI Developer Profile  `

    //console.log("structureDyn_prompt", opts)

    return(`

    'DYNAMIC_DATA'=${JSON.stringify(data)}

    'PARAMS'=${JSON.stringify(params)}

    'JSON_INPUT'={
        "sections":${sample}
    }

    'WEBSITE_FOR' = "${opts.websiteFor}"    

    Create website page content in JSON for [WEBSITE_FOR]

    Use all [PARAMS] 'key' data for dynamic variables

    Use [PARAMS] 'key' data for building content for sections 'headerDyn', 'textElement', imageElement' & 'buttonElement'    

    Add label before each variables like Title : {{Title}}

    JSON output should not contains css styling

    JSON output should be valid JSON data

    Give me the output in JSON format

    Just give me the JSON data

    `)
}

export const structureDyn_format = {
    "type":"json_schema",
    "json_schema": {
        "name": "structureDyn_response",
        "strict": true,
        "schema": {
            "type": "object",
            "properties": {
                "headerDyn": {
                    "type": "object",
                    "description": "Text contains dynamic variables in double flower brackets {{}}",
                    "properties": {
                        "heading": {
                            "type": "string",
                            "description": "For section heading"
                        },
                        "description": {
                            "type": "string",
                            "description": "for section description"
                        }
                    },
                    "additionalProperties": false,
                    "required": [
                        "heading",
                        "description"
                    ]
                },
                "textElement": {
                    "type": "object",
                    "description": "Text contains dynamic variables in double flower brackets {{}}", 
                    "properties": {                   
                        "texts": {
                            "type": "array",  
                            "description": "details page text",                         
                            "items":{
                                "$ref": "#/$defs/text"
                            }
                        }
                    },
                    "additionalProperties": false,
                    "required": [                    
                        "texts"
                    ]
                },
                "imageElement": {
                    "type": "object",
                    "description": "Image Url contains dynamic variables in double flower brackets {{}}",
                    "properties": {                   
                        "image": {
                            "type": "string",                            
                        }
                    },
                    "additionalProperties": false,
                    "required": [                    
                        "image"
                    ]
                    
                    
                },               
                "buttonElement": {
                    "type": "object",
                    "description": "Button contains dynamic variables in double flower brackets {{}}", 
                    "properties": {                   
                        "buttons": {
                            "type": "array",
                            "description": "details page action buttons",
                            "items":{
                                "$ref": "#/$defs/button"
                            }
                        }
                    },
                    "additionalProperties": false,
                    "required": [                    
                        "buttons"
                    ]
                }
            },          
            "$defs": {
                "text": {
                    "type": "object",
                    "properties": {
                        "text": {
                            "type": "string",  
                            "description": "Text contains dynamic variables in double flower brackets {{}}"                          
                        }                  
                    },
                    "required": [
                        "text"
                    ],
                    "additionalProperties": false
                },
                "button": {
                    "type": "object",
                    "properties": {
                        "text": {
                            "type": "string",
                            "description": "Text contains dynamic variables in double flower brackets {{}}" 
                        },
                        "url": {
                            "type": "string",
                            "description": "url contains dynamic variables for url in double flower brackets {{}}"
                        }                     
                    },
                    "required": [
                        "text",
                        "url"
                    ],
                    "additionalProperties": false
                }                
            },
            "additionalProperties": false,
            "required": [
                "headerDyn", 
                "textElement",
                "imageElement",
                "buttonElement"               
            ]
        }        
    }
}