import Select from "react-select";
import { twdClass } from "../../constants/tailwind.constants";
import { isObject, isEmpty } from "lodash";
import { OpenLinkIn } from "./OpenLinkIn";
import { Button, BtnStyle, BtnDelete, BtnEdit} from "../ui/com_ui";

export const ImageElementPanel = ({
  columnNames = [],
  section,
  updateValues,
  updateStylePanel,
}) => {
  const optionList = columnNames.map((itm) => ({
    value: itm.key,
    label: itm.value,
  }));

  let defaultValue = [];
  if (isObject(section.params) && !isEmpty(section.params)) {
    defaultValue = [section.params];
  }

  return (
    <div>
      <div className={twdClass("items-start mb-4")}>
        <div className={twdClass("w-36 text-sm mb-2")}>Map Columns:</div>
        <div className={twdClass("w-full text-xs")}>
          <Select
            name="image-element"
            defaultValue={defaultValue}
            options={optionList}
            onChange={(value) => {
              updateValues(section.id, { params: value });
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius:'20px',                
              }),
            }}
          />
        </div>
      </div>
      <div className={twdClass("text-sm mb-2")}>
        <div className={twdClass("flex justify-between items-center")}>
          <label>Image Style:</label>
          <div>
            <BtnStyle             
              title="Style"
              onClick={() => {
                updateStylePanel({ sectionId: section.id, showStyles: true });
              }}
            >              
            </BtnStyle>
          </div>
        </div>
      </div>

      <OpenLinkIn
        columnNames={columnNames}
        updateValues={updateValues}
        section={section}
        updateStylePanel={updateStylePanel}
        popup_option={true}
      />
    </div>
  );
};
