

let d=[
  {
    title:"Lee Hawlkings",
    role:"Bubble",
    desc:"An innovative problem-solver with a proven track record in creating dynamic and complex web applications using Bubble",
    rate:"$100 per hour",
    email:"lee._hawlkings@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527389070.jpg"
  },
  {
    title:"Adam Grey",
    role:"Webflow, Zapier",
    desc:"A seasoned Webflow designer who excels in automating workflows with Zapier to create seamless user experiences",
    rate:"$200 per hour",
    email:"adam._grey@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527401764.jpg"
  },
  {
    title:"Samra Chan",
    role:"Zapier, Flezr",
    desc:"A versatile no-code expert with a knack for integrating apps via Zapier and building robust dynamic websites with Flezr",
    rate:"$250 per hour",
    email:"samra._chan@gmail.com",
    image:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527330757.jpg"
  }
]



let sd={
  title:"No-Code Experts",
  description:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.",
}

let a={
  cards:{
    title:{text:"No-Code Experts", styleProps:{textAlign: 'center', color:' #ececec', fontSize: '2.25rem', lineHeight: '2.5rem', margin: '40px 0px 20px'}},
    description:{text:"Welcome to the premier directory of No-Code Experts! Connect with top-tier freelancers and contractors skilled in Bubble, Webflow, Zapier, Flezr, Shopify, and more. Let's build your digital dream together, no coding required.", styleProps:{color: 'rgb(236, 236, 236)', fontSize: '1.25rem', lineHeight: '1.75rem',  margin: '10px 100px', textAlign: 'center'}},
    searchbar:{
      styleProps:{ display:'flex', backgroundColor: '#6f6f6f', borderRadius: '1.5rem', padding: '10px 20px 10px 10px', margin: '30px 0px'},
      search_input:{ 
        styleProps:{borderRadius: '9999px',  padding: '5px 20px', color: 'rgb(51, 51, 51)', fontWeight: 100, backgroundColor: 'rgb(255, 255, 255)', width: 'auto'}
      },
      search_button:{
        styleProps:{color: 'rgb(51, 51, 51)', padding: '5px 20px', backgroundColor: '#86cfc8', borderRadius: '1.5rem', margin: '0px 10px',        fontWeight: 'bold', width: 'auto'}
      },
      clear_button:{
        styleProps:{backgroundColor: 'rgb(236, 236, 236)',  color: 'rgb(51, 51, 51)', padding: '5px 20px', borderRadius: '9999px'}
      }
    },
    cards_per_row:{
      desktop:2,
      tab:2,
      mobile:1
    },
    card_template:{
      card_columns:[
        {
          styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 0px '} ,
          elements:[ 
            {elementType:"headingEl", text:"[[title]]", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
            {elementType:"textEl", text:"[[role]]", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 20px 0px '} }, 
            {elementType:"textEl", text:"[[desc]]", styleProps:{padding: '0px 0px 20px 0px', color: 'rgb(51, 51, 51)'} }, 
            {elementType:"textEl", text:"[[rate]]", styleProps:{fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
            {elementType:"textEl", text:"Contact: [[email]]", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
          ]
        },
        {
          styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'} ,
          elements:[              
            {elementType:"imageEl", src:"[[image]]", styleProps:{minwidth:'300px', width:'300px', borderRadius:'20px'} },
          ]
        }
      ],
    },
    card_items:[
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 0px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Lee Hawlkings", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
              {elementType:"textEl", text:"Bubble", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 20px 0px '} }, 
              {elementType:"textEl", text:"An innovative problem-solver with a proven track record in creating dynamic and complex web applications using Bubble", styleProps:{padding: '0px 0px 20px 0px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$100 per hour", styleProps:{fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: lee._hawlkings@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'} ,
            elements:[              
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527389070.jpg", styleProps:{minwidth:'300px', width:'300px', borderRadius:'20px'} },
            ]
          }
        ],
      },
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 0px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Adam Grey", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
              {elementType:"textEl", text:"Webflow, Zapier", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 20px 0px '} }, 
              {elementType:"textEl", text:"A seasoned Webflow designer who excels in automating workflows with Zapier to create seamless user experiences", styleProps:{padding: '0px 0px 20px 0px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$200 per hour", styleProps:{fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: adam._grey@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'} ,
            elements:[              
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527401764.jpg", styleProps:{minwidth:'300px', width:'300px', borderRadius:'20px'} },
            ]
          }
        ],
      },
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 0px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Samra Chan", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
              {elementType:"textEl", text:"Zapier, Flezr", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 20px 0px '} }, 
              {elementType:"textEl", text:"A versatile no-code expert with a knack for integrating apps via Zapier and building robust dynamic websites with Flezr", styleProps:{padding: '0px 0px 20px 0px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$250 per hour", styleProps:{fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: samra._chan@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'} ,
            elements:[              
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527330757.jpg", styleProps:{minwidth:'300px', width:'300px', borderRadius:'20px'} },
            ]
          }
        ],
      },     
      {
        card_columns:[
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', padding:'0px 20px 0px 0px '} ,
            elements:[ 
              {elementType:"headingEl", text:"Cody Bell", styleProps:{fontSize:'24px', fontWeight:'bold', padding:'0px 0px 0px 0px '} },
              {elementType:"textEl", text:"Shopify", styleProps:{fontSize: '1.25rem',lineHeight: '1.75rem',color: '#c26196',    margin: '0px 0px 20px 0px '} }, 
              {elementType:"textEl", text:"A seasoned e-commerce specialist skilled in building profitable and user-friendly online stores using Shopify.", styleProps:{padding: '0px 0px 20px 0px', color: 'rgb(51, 51, 51)'} }, 
              {elementType:"textEl", text:"$150 per hour", styleProps:{fontWeight: 700, margin: '0px 0px 20px', color: 'rgb(51, 51, 51)',     fontSize: '1.125rem', lineHeight: '1.75rem'} }, 
              {elementType:"textEl", text:"Contact: cody._bell@gmail.com", styleProps:{margin:' 0px 0px 20px', backgroundColor: '#86cfc8', borderWidth: '0px', padding:'6px 20px', borderRadius:'10px', fontWeight:'600'} },
            ]
          },
          {
            styleProps:{display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'} ,
            elements:[              
              {elementType:"imageEl", src:"https://d11lx8wl9i3fvs.cloudfront.net/1648919366374/1676527416801.jpg", styleProps:{minwidth:'300px', width:'300px', borderRadius:'20px'} },
            ]
          }
        ],
      },     
    ],
    styleProps:{backgroundColor:'#4b4848', color:'#ececec', padding:'80px 20px'},
    cards_holder_styles:{},
    cards_outer_styles:{
      styleProps:{backgroundColor:'', gap:'20px', marginTop:'40px' }
    },
    cards_item_outer_styles:{
      styleProps:{} 
    },
    cards_item_styles:{
      styleProps:{display:'flex', padding:'20px', backgroundColor: 'rgb(246, 255, 255)', borderRadius: '1rem', color:'#000000', boxShadow:'4px 4px 2px rgba(0,0,0,0.1)'} 
    }
  }
}


export default {
  a, d, sd,
}
