export const BUTTONS_ELEMENT_BLOCK = {
  blockType: "ELEMENTS",
  blockDesign: "ButtonElement",
  editFormName: "BUTTONS_ELEMENT_FORM",
  styleProps: {
    "background-color": "",
    "background-image": "url('')",
  },
  stylePropsTab: {},
  stylePropsMob: {},
  buttons: {
    styleProps: {
      "background-image": "url('')",
      padding: "10px",
      "justify-content": "center",
    },
    stylePropsTab: {},
    stylePropsMob: {},
    list: [
      {
        text: "Click Here",
        url: "",
        styleProps: {},
      },
    ],
  },
};
