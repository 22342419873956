import Dropdown from "./Dropdown";
import { get } from "lodash";

const FontWeight = ({ element, data, updateElementValue }) => {
  const { label, hideLabel, updateTo } = element;
  const value = get(data, updateTo);
  const updateValue = (value) => {
    console.log(value);
    updateElementValue(updateTo, value);
  };
  return (
    <Dropdown
      options={FONT_WEIGHT_OPTIONS}
      hideLabel={hideLabel}
      label={label}
      value={value}
      updateValue={updateValue}
    />
  );
};

export default FontWeight;

const FONT_WEIGHT_OPTIONS = [
  { display: "thin", value: "100" },
  { display: "extralight", value: "200" },
  { display: "light", value: "300" },
  { display: "normal", value: "400" },
  { display: "medium", value: "500" },
  { display: "semibold", value: "600" },
  { display: "bold", value: "700" },
  { display: "extrabold", value: "800" },
  { display: "black", value: "900" },
];
