const local_sample=`
    [
        {
            "type":"navbar",
            "logo":{'text':'logo text'}
            "links":[
                "About",
                "Services",
                "Contact Us",
                "Get Started
            ]            
        },
        {
            "type":"header",
            "title":"section title",
            "description":"section description",
            "cta_button":"Get Started"
        },  
        {
            "type":"cards",
            "heading":"section title",
            "description":"section description",            
        },
        {
            "type":"footer",
            "links":[
                "About",
                "Services",
                "Contact Us",
                "Get Started
            ],
            "extra_links":[
                "Privacy Policy",
                "Terms & Conditions"
            ],
            "logo":"logo text",
            "copy":"website copyright text"
        }        
    ]

`

export const structure_prompt=({opts={}, sample=false})=>{

    if(!sample){
        sample=local_sample
    }

    if(!opts.websiteFor) opts.websiteFor=`Generative AI Developer Profile  `

    //console.log("structure_prompt", opts)

    return(`

    'JSON_INPUT'={
        "sections":${sample}
    }

    'WEBSITE_FOR' = "${opts.websiteFor}"    

    Create website page content in JSON for [WEBSITE_FOR]

    JSON output should not contains css styling
    JSON output should be valid JSON data

    Give me the output in JSON format

    Just give me the JSON data

    `)
}

export const structure_format = {
    "type":"json_schema",
    "json_schema": {
        "name": "structure_response",
        "strict": true,
        "schema": {
            "type": "object",
            "properties": {
                "navbar": {
                    "type": "object",
                    "description": "The user object to insert into the database",
                    "properties": {
                        "logo": {
                            "type": "string",
                            "description": "The name of the user"
                        },
                        "links": {
                            "type": "array",
                            "description": "website links",
                            "items":{
                                "$ref": "#/$defs/link"
                            }
                        }
                    },
                    "additionalProperties": false,
                    "required": [
                        "logo",
                        "links"
                    ]
                },
                "header": {
                    "type": "object",
                    "description": "The user object to insert into the database",
                    "properties": {
                        "heading": {
                            "type": "string",
                            "description": "For section heading"
                        },
                        "description": {
                            "type": "string",
                            "description": "for section description"
                        },
                        "cta_button": {
                            "type": "string",
                            "description": "for call to action button text"
                        }
                    },
                    "additionalProperties": false,
                    "required": [
                        "heading",
                        "description",
                        "cta_button"
                    ]
                },
                "cards": {
                    "type": "object",
                    "description": "The user object to insert into the database",
                    "properties": {
                        "heading": {
                            "type": "string",
                            "description": "For section heading"
                        },
                        "description": {
                            "type": "string",
                            "description": "for section description"
                        }
                    },
                    "additionalProperties": false,
                    "required": [
                        "heading",
                        "description"
                    ]
                },
                "footer": {
                    "type": "object",
                    "description": "The user object to insert into the database",
                    "properties": {
                        "logo": {
                            "type": "string",
                            "description": "for website name as logo"
                        },
                        "copy": {
                            "type": "string",
                            "description": "for website copyright text"
                        },
                        "description": {
                            "type": "string",
                            "description": "for website copyright text"
                        }
                    },
                    "additionalProperties": false,
                    "required": [
                        "logo",
                        "copy",
                        "description"
                    ]
                },
                "item": {
                    "anyOf": [
                    {
                        "type": "object",
                        "description": "The user object to insert into the database",
                        "properties": {
                        "name": {
                            "type": "string",
                            "description": "The name of the user"
                        },
                        "age": {
                            "type": "number",
                            "description": "The age of the user"
                        }
                        },
                        "additionalProperties": false,
                        "required": [
                        "name",
                        "age"
                        ]
                    },
                    {
                        "type": "object",
                        "description": "The address object to insert into the database",
                        "properties": {
                        "number": {
                            "type": "string",
                            "description": "The number of the address. Eg. for 123 main st, this would be 123"
                        },
                        "street": {
                            "type": "string",
                            "description": "The street name. Eg. for 123 main st, this would be main st"
                        },
                        "city": {
                            "type": "string",
                            "description": "The city of the address"
                        }
                        },
                        "additionalProperties": false,
                        "required": [
                            "number",
                            "street",
                            "city"
                        ]
                    }
                    ]
                }
            },
            "$defs": {
                "link": {
                    "type": "object",
                    "properties": {
                        "text": {
                            "type": "string"
                        }                  
                    },
                    "required": [
                        "text"
                    ],
                    "additionalProperties": false
                }
            },
            "additionalProperties": false,
            "required": [
                "navbar",
                "header",
                "cards",
                "footer",
                "item"
            ]
        }
    }
}