import { getTimeForUniqID, getTimestamp, getTemplateSample, getParams, styles_, links_} from "../transform/t_util";

export const pg_buttonElement=({obj={}})=>{    
    if(obj.buttons?.list?.length) {
        obj.buttons.list=obj.buttons.list.map((button, index)=>{
            if(button.styleProps && button.styleProps.display) delete button.styleProps.display
            if(button.styleProps && button.styleProps.width) delete button.styleProps.width
            return button
        })       
    }
    return obj
}
