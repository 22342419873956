import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../../constants/tailwind.constants";
import { INPUT_TEXT } from "../../../../constants/var.constants";
import styled from "styled-components";

import {Design, DivTag, InputTag, ImgTag} from "../Common";
import {dataTypes_prompt, dataTypes_format} from "../../prompts/dataTypes_prompt";

const Text = (args) => {
  const {text, text_sample}= args
  return(<DivTag {...args} alt={text}>{text}</DivTag>)
}


const Texts = ({texts}) => {
  const { list=[], ...obj1} = texts
  let list_=list.map((text, i)=>{
    return(<Text key={i} {...text}/>)
  })
  return(<DivTag {...obj1} className={twdClass("")}>
    <div className={twdClass("flex flex-col gap-2")}>{list_}</div>
  </DivTag>)
}

const Design2 = ({obj={}, opts={}, sample='', sdata={}, params, data=[]}) => {  
  let user_prompt = dataTypes_prompt({opts, sample, sdata, params, data }) 
  return (<Design {...{type:'dataTypes', obj, user_prompt, format:dataTypes_format}}/>)
}


const DataTypes = ({dataTypes={}, opts={}, design=false, sample='', sdata={}, params={}, data=[]}) => {
  let obj=dataTypes.dataTypes ? dataTypes.dataTypes : dataTypes
  const { texts=[], ...obj1} = obj
  console.log("dataTypes", obj)
  return(
    <DivTag {...obj1} className={twdClass("relative mb-4 px-4 py-12 bg-gray-200")}>
      {design && <Design2 {...{design,  obj, opts, sample, sdata, params, data}}/>}
      <div className={twdClass("flex flex-col gap-8 relative mx-auto")} style={{'max-width':'1536px'}}>  
        <div className={twdClass("ml-2")}> 
          Data Types
        </div>
      </div>
    </DivTag>
  )
}
export  default DataTypes